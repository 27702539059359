import { Button, ButtonProps } from "Shared/components/design/button/Button";
import { SSOButtonOptions } from "Shared/components/design/SSOButton/constants";
import styled from "styled-components";

export const SSOButtonBtn = styled(Button)<ButtonProps & { options: Partial<SSOButtonOptions>}>`
  gap            : 10px;
  display        : flex;
  color          : ${({ options }) => options.color};
  padding        : 5px 12px 5px 7px;
  font-size      : 16px;
  background     : ${({ options }) => options.background};
  box-shadow     : ${({ options }) => options.shadow ?? 'none'};
  align-items    : center;
  justify-content: center;

  &:hover {
    background: ${({ options }) => options.background};
  }

  & > img {
    width       : 24px !important;
    height      : 24px !important;
    padding-left: 0 !important;
  }
`;
