import React from 'react';
import {useTranslation} from "react-i18next";
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {HEADER_TEST_ID} from 'Pages/help/testId';
import AuthHeader, { HeaderIcon } from 'Shared/components/design/authHeader/authHeader';
import {ICON_IDS} from 'Shared/components/icons/constants';

type HeaderProps = {
  questionCallback  : () => void;
}

function Header({questionCallback}: HeaderProps): JSX.Element {
  const {t} = useTranslation('signIn');

  const rightIcon: HeaderIcon = {
    visible : true,
    callback: questionCallback,
    iconId  : ICON_IDS.QUESTION_OUTLINE,
    color   : DESIGN_TOKENS.color.text.normal.value,
  };

  return (
    <AuthHeader
      data-testid = {HEADER_TEST_ID}
      rightIcon   = {rightIcon}
      title       = {t('signIn--header')}></AuthHeader>
  );
}

export default Header;
