import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {TextFieldTypes} from 'Shared/components/design/textField/TextField';
import {LABEL_SMALL, PARAGRAPH} from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface TextInputProps {
  fieldType      : TextFieldTypes,
  isPreFilled   ?: boolean,
  isSearchBar   ?: boolean,
  clickable     ?: boolean,
}

interface IconProps {
  fieldType: TextFieldTypes
}

interface ClearButtonProps {
  isVisible: boolean;
}

interface TextFieldProps {
  fieldType       : TextFieldTypes,
  isRequiredField?: boolean
}

interface TextLabelProps {
  fieldType       : TextFieldTypes,
  isRequiredField : boolean
}

function isError(prop: TextFieldTypes): boolean {
  return prop === TextFieldTypes.ERROR;
}

function isDisabledWithBox(prop: TextFieldTypes): boolean {
  return prop === TextFieldTypes.DISABLED_WITH_BOX;
}

function isDisabledWithoutBox(prop: TextFieldTypes): boolean {
  return prop === TextFieldTypes.DISABLED_NO_BOX;
}

function getBackgroundColor(prop: TextInputProps): string {
  switch(prop.fieldType){
    case TextFieldTypes.ERROR:
      return DESIGN_TOKENS.color.background.critical.light.value;
    case TextFieldTypes.DISABLED_WITH_BOX:
      return DESIGN_TOKENS.color.background.secondary.value;
    case TextFieldTypes.DISABLED_NO_BOX:
      return 'inherit';
    default:
      if (prop.isPreFilled) {
        return DESIGN_TOKENS.color.background.info.light.value;
      }
      return DESIGN_TOKENS.color.background.primary.value;
  }
}

function getLabelColor(prop: TextFieldTypes): string {
  switch (prop) {
    case TextFieldTypes.DISABLED_WITH_BOX:
      return DESIGN_TOKENS.color.text.disabled.value;
    case TextFieldTypes.DISABLED_NO_BOX:
      return DESIGN_TOKENS.color.text.secondary.value;
    case TextFieldTypes.ERROR:
      return DESIGN_TOKENS.color.text.critical.value;
    default:
      return DESIGN_TOKENS.color.text.normal.value;
  }
}

function getHelperColor(prop: TextFieldTypes): string {
  switch (prop) {
    case TextFieldTypes.DISABLED_WITH_BOX:
    case TextFieldTypes.DISABLED_NO_BOX  :
      return DESIGN_TOKENS.color.text.disabled.value;
    case TextFieldTypes.ERROR:
      return DESIGN_TOKENS.color.text.critical.value;
    default:
      return DESIGN_TOKENS.color.text.secondary.value;
  }
}

function getRequiredFieldAsterisk(isRequiredField: boolean) {
  if (isRequiredField) {
    return  `:after {
      content: " *";
      color  : red;
    }`;
  }
}

export const TextLabel = styled.div<TextLabelProps>`
  margin-bottom: 4px;
  color        : ${props => getLabelColor(props.fieldType)};
  ${LABEL_SMALL.DEFAULT}
  ${props => getRequiredFieldAsterisk(props.isRequiredField)}
`;

export const TextInput = styled.input<TextInputProps>`
  width                  : 100%;
  border                 : 1px solid ${DESIGN_TOKENS.color.border.light.value};
  cursor                 : ${props => props.clickable ? 'pointer' : 'auto' };
  border-color           : ${props => isError(props.fieldType) ? DESIGN_TOKENS.color.border.critical.value : DESIGN_TOKENS.color.border.light.value};
  border                 : ${props => isDisabledWithoutBox(props.fieldType) && 'none'};
  padding                : ${$props => $props.isSearchBar ? '10px 36px' : '10px 12px'};
  padding-left           : ${props => isDisabledWithoutBox(props.fieldType) && '0px'};
  color                  : ${props => isDisabledWithBox(props.fieldType) ? DESIGN_TOKENS.color.text.disabled.value :  DESIGN_TOKENS.color.text.normal.value};
  -webkit-text-fill-color: ${props => isDisabledWithBox(props.fieldType) ? DESIGN_TOKENS.color.text.disabled.value :  DESIGN_TOKENS.color.text.normal.value};
  border-radius          : 2px;
  background-color       : ${props => getBackgroundColor(props)};
  outline-color          : ${DESIGN_TOKENS.color.border.info.light.value};
  ${PARAGRAPH.DEFAULT}

  &:hover, &:active, &:focus {
    border-color: ${props => (!isError(props.fieldType) && !isDisabledWithBox(props.fieldType)) && '#8e8e8e'};  // TODO: update with figma tokens
  }

  &:focus + div {
    color: ${DESIGN_TOKENS.color.text.normal.value};
  }
`;

export const TextHelper = styled.div<TextFieldProps>`
  margin-top: 4px;
  width     : 100%;
  color     : ${props => getHelperColor(props.fieldType)};
  ${LABEL_SMALL.DEFAULT}
`;

export const TextBoxContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div<IconProps>`
  position: absolute;
  top     : 15px;
  left    : 14px;

  svg {
    fill: ${props => isDisabledWithBox(props.fieldType)
      ? DESIGN_TOKENS.color.text.disabled.value
      :  DESIGN_TOKENS.color.text.normal.value
    };
  }
`;

export const ClearTextButton = styled.div<ClearButtonProps>`
  position: absolute;
  top     : 15px;
  right   : 14px;
  pointer : cursor;
  display : ${props => props.isVisible ? '' : 'none'};
`;