import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const Container = styled.div`
  transform      : translateY(-5px);
  animation      : flying-pig 2s infinite ease-in-out;
  animation-delay: 1.2s;

  @keyframes flying-pig {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  img {
    width: 188px;
  }

  margin-top: 24px;
    
  @media ${DEVICE_BREAKPOINT.maxMobile} {
    margin-top: 54px;
  }
`;