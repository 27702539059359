import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { RIGHT_CONTENT_CONTAINER_SIZE } from 'App/components/style';
import { DEVICE_BREAKPOINT, INTER_FONT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

const DARK_BLUE = '#080345';

export const FooterContainer = styled.div`
  display        : flex;
  max-width      : ${RIGHT_CONTENT_CONTAINER_SIZE};
  width          : 100%;
  margin         : 0 auto;
  align-items    : center;
  justify-content: space-between;
  padding        : 24px;
  
  @media ${ DEVICE_BREAKPOINT.maxDesktop } {
    padding : 24px 14px;
  }
`;

export const FooterLinksContainer = styled.div`
  display       : flex;
  flex-direction: column;
  gap           : 20px;
`;
export const AgreementLinksContainer = styled.div`
  display: flex;
`;
export const AgreementLinkItem = styled.a`
  ${INTER_FONT.PARAGRAPH};
  text-decoration: none;
  color          : ${DARK_BLUE};
  padding        : 0 10px;
  border-right   : 1px solid ${DARK_BLUE};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
`;
export const AgreementItem = styled.span`
  ${INTER_FONT.PARAGRAPH};
  color: ${DARK_BLUE};
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  gap    : 16px;
`;
export const SocialLinkItem = styled.a`
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 40px;
  height          : 40px;
  border-radius   : 8px;
  background-color: #505050;
  transition      : .1s background-color;
  &:hover {
    background-color: ${DESIGN_TOKENS.color.button.auxiliary.value};
  }
`;
export const SocialLinkItemImage = styled.img`
  height: 20px;
`;
