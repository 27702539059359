import { isValidAUZipCode, isValidCAZipCode, isValidUSZipCode } from "Shared/services/inputValidationService";

export const COUNTRY_CODES = {
  US : 'US',
  CA : 'CA',
  AU : 'AU'
};

/**
 * Returns current build country code for use in basic profile
 * @returns
 */
export const getCountryCodeFromZipCode = (zipCode: string): string =>{
  switch (true) {
    case isValidUSZipCode(zipCode):
      return COUNTRY_CODES.US;
    case isValidAUZipCode(zipCode):
      return COUNTRY_CODES.AU;
    case isValidCAZipCode(zipCode):
      return COUNTRY_CODES.CA;
  }

  return '';
};