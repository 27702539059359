import { getAutoEmailTypeId } from 'App/services/autoEmailService';
import { getUserId } from 'App/services/idpTokenService';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';
import { getDeviceId } from 'Shared/services/deviceId';
import { getDeviceScreenDimensions, getSurveyDevice, getUspDevice } from 'Shared/services/deviceService';
import { getRFGFingerprint } from 'Shared/services/rfgService';

interface CommonAESurveyParams {
  aeId : string;
  aeCId: string;
  aeT  : string;
}

export interface GetAutoEmailSurveyParams extends CommonAESurveyParams {
  umid        : string;
  autoEmailKey: string;
}

export interface GetDirectSurveyParams extends CommonAESurveyParams {
  surveyId       : string;
  pId            : string;
  directSurveyKey: string;
}

/**
 * Function to get the RDT survey request URL based on the params: Direct Survey or Auto Email
 * @param params
 * @param surveyId
 * @returns
 */
export const getRdtSurveyRequestUrl = (
  params   : CommonAESurveyParams & Partial<GetAutoEmailSurveyParams & GetDirectSurveyParams>,
  surveyId?: string,
): string => {
  const baseUrl        = process.env.REACT_APP_AES_BASE_URL;
  const userId         = getUserId();
  const deviceType     = getUspDevice();
  const device         = getSurveyDevice();
  const sessionTypeId  = getAutoEmailTypeId();
  const screenSize     = getDeviceScreenDimensions();
  const rfgFingerPrint = getRFGFingerprint();

  //standard URL params
  const urlParams = [
    `deviceType=${encodeURIComponent(deviceType)}`,
    `device=${encodeURIComponent(device)}`,
    `sessionTypeId=${encodeURIComponent(sessionTypeId)}`,
    `aeId=${encodeURIComponent(params.aeId)}`,
    `aeCId=${encodeURIComponent(params.aeCId)}`,
    `aeT=${encodeURIComponent(params.aeT)}`,
    `fp_rfg=${encodeURIComponent(rfgFingerPrint)}`,
    `fp=${encodeURIComponent(getDeviceId())}`,
  ];

  if (screenSize) {
    urlParams.push(`deviceWidth=${encodeURIComponent(screenSize.width)}`);
    urlParams.push(`deviceHeight=${encodeURIComponent(screenSize.height)}`);
  }

  if (params.umid) {
    urlParams.push(`umid=${encodeURIComponent(params.umid)}`);
  }

  if (params.autoEmailKey) {
    urlParams.push(`autoEmailKey=${encodeURIComponent(params.autoEmailKey)}`);
  }

  if (params.directSurveyKey) {
    urlParams.push(`directSurveyKey=${encodeURIComponent(params.directSurveyKey)}`);
  }

  if (params.pId) {
    urlParams.push(`pId=${encodeURIComponent(params.pId)}`);
  }

  const urlParamString = urlParams.join('&');
  const surveyPath     = surveyId ? `/survey/${surveyId}` : '/survey';

  return `${baseUrl}/v1/users/${userId}${surveyPath}?${urlParamString}`;
};

/**
 * Gets a survey based on Auto Email
 *
 * @returns {Promise<APIResponse<any>>}
 */
const getAutoEmailSurvey = (params: GetAutoEmailSurveyParams): Promise<APIResponse<any>> => {
  return MS_API({
    method : 'GET',
    url    : getRdtSurveyRequestUrl(params)
  });
};

/**
 * Gets a survey based on Direct Survey email
 *
 * @returns {Promise<APIResponse<any>>}
 */
const getDirectSurvey = ({ surveyId, ...params }: GetDirectSurveyParams): Promise<APIResponse<any>> => {
  return MS_API({
    method : 'GET',
    url    : getRdtSurveyRequestUrl(params, surveyId)
  });
};

export {
  getAutoEmailSurvey,
  getDirectSurvey
};
