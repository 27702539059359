import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import steFlatSrc from 'Shared/assets/images/ste-flat.png';
import { DEVICE_BREAKPOINT, FONT_WEIGHTS, LABEL_SMALL, PARAGRAPH, TITLES_MONO } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface CardProps {
  locked      ?: boolean,
}

export const SteContent = styled.div`
  position  : absolute;
  top       : 0;
  left      : 0;
  right     : 0;
  bottom    : 0;
  transition: transform 0.2s ease-in-out;
`;

export const CardContainer  = styled.div<CardProps>`
  overflow        : hidden;
  display         : flex;
  flex-direction  : column;
  align-items     : stretch;
  border-radius   : 12px;
  color           : ${DESIGN_TOKENS.color.text.normal.value};
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  transition      : all 0.2s linear;
  width           : 344px;
  height          : 192px;
  box-shadow      : ${DESIGN_TOKENS.shadow.xs.value};
  cursor          : ${props => props.locked ? 'default' : 'pointer'};
  padding         : 20px 16px;
  gap             : 16px;

  @media ${DEVICE_BREAKPOINT.minTwoColumn} {
    flex-grow: 1;
  }

  &:hover {
    box-shadow: ${DESIGN_TOKENS.shadow.sm.value};
  }

  &:hover ${SteContent} {
    transform: translateY(-100%);
  }
`;

export const CardDataSurveyId  = styled.div`
  ${PARAGRAPH.MONOSPACE};
  display       : flex;
  flex-direction: column;
  align-items   : start;
  padding       : 0;
  gap           : 2px;
  color         : ${DESIGN_TOKENS.color.text.normal.value};
  width         : 80px;

  span {
    ${LABEL_SMALL.DEFAULT};
    color: ${DESIGN_TOKENS.color.text.secondary.value};
  }
`;

export const CardDataWrapper  = styled.div`
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : start;
  padding        : 0;
  gap            : 4px;
  height         : 100%;
`;

export const CardDataPoints = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  align-self    : center;
  text-align    : center;
  margin-top    : 28px;
`;

export const CardDataPointsAmount = styled.div`
  ${TITLES_MONO.FT_01};

  span {
    ${TITLES_MONO.FT_04};
    font-family: ${DESIGN_TOKENS.text.number.fontFamily.value};
  }
`;

export const CardDataPointsPerMinute = styled.div`
  ${LABEL_SMALL.DEFAULT}
  color     : ${DESIGN_TOKENS.color.text.secondary.value};
  min-height: 20px;
  margin-top: 4px;

  span {
    font-weight: ${FONT_WEIGHTS.poppins_1}
  }
`;

export const LOADING_POINT_COUNT = 14;
const FT_03_LINE_HEIGHT_PX = parseInt(TITLES_MONO.FT_03['line-height']);

export const CardLoadingPointsContainer = styled.div`
  ${TITLES_MONO.FT_03}
  overflow   : hidden;
  max-height : ${TITLES_MONO.FT_03['line-height']};
  font-family: ${DESIGN_TOKENS.text.number.fontFamily.value};

  & > div {
    display       : flex;
    flex-direction: column;
    position      : relative;
    animation     : 3s infinite scroll;
  }

  @keyframes scroll {
    0% {
      top: 0;
    }
    100% {
      top: ${-LOADING_POINT_COUNT * FT_03_LINE_HEIGHT_PX}px;
    }
  }
`;

export const CardDataDuration = styled.div`
  ${PARAGRAPH.DEFAULT};
  display        : flex;
  gap            : 4px;
  align-items    : center;
  justify-content: flex-end;
  align-self     : start;
  width          : 80px;
  flex-shrink    : 0;
  white-space    : nowrap;

  > svg {
    fill          : ${DESIGN_TOKENS.color.text.secondary.value};
    width         : 20px;
    height        : 20px;
    vertical-align: bottom;
    flex-shrink   : 0;
  };
`;

export const CardCTA  = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: flex-end;
  align-items    : stretch;
`;

export const SurveyLink = styled.a`
  text-decoration: none;
  display        : contents;
`;

export const SteOverflowHider = styled.div`
  position : relative;
  overflow : hidden;
  min-width: 160px;
  height   : 100%;
`;

export const SteImageContainer = styled.div`
  position  : relative;
  width     : 80px;
  height    : 40px;
  box-sizing: border-box;
`;

export const SteFlatImage = styled.img`
  height: 32px;
`;

SteFlatImage.defaultProps = {
  src: steFlatSrc
};

export const SurveyIdContainer = styled.div`
  position  : absolute;
  bottom    : calc(-100% - 5px);
  left      : 0;
  right     : 0;
  box-sizing: border-box;
`;
