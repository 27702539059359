import { AE_QUERY_PARAMS } from "App/constants";
import { getSessionId } from 'App/services/idpTokenService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import {AutoEmailEventModel, AutoEmailSessionModel} from 'Shared/models/EventModels';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';

/**
 * Sets auto email data in localStorage along with the current session ID
 * @param params
 */
const setAutoEmailSession = (aeCId: string, aeId: string, aeT: string): void => {
  const sessionId = getSessionId();

  if (!sessionId || !aeT) {
    return;
  }

  const autoEmailSessionData = {
    sessionId: sessionId,
    aeData: {
      [AE_QUERY_PARAMS.CATEGORY_ID]: aeCId || '',
      [AE_QUERY_PARAMS.ID]         : aeId  || '',
      [AE_QUERY_PARAMS.SENT_TIME]  : aeT   || '',
    } as AutoEmailEventModel
  };

  setLocalStorage(LOCAL_STORAGE_IDS.AUTO_EMAIL_SESSION_ID, autoEmailSessionData, true);
};

/**
 * Retrieves AutoEmail session data stored in localStorage
 * @returns {AutoEmailSessionModel | null}
 */
const getAutoEmailSession = (): AutoEmailSessionModel | null => {
  return getLocalStorage(LOCAL_STORAGE_IDS.AUTO_EMAIL_SESSION_ID, true);
};

/**
 * Returns the session type ID based on the auto email saved params
 * @returns number
 */
export const getAutoEmailTypeId = (): number => {
  return getAutoEmailSession() ? 0 : 1;
};

/**
 * Returns whether the user is in an AutoEmail session
 * @returns {boolean}
 */
const isUserInAutoEmailSession = (): boolean => {
  const currentSessionId = getSessionId();
  const autoEmailSession = getAutoEmailSession();

  if (!autoEmailSession || currentSessionId !== autoEmailSession.sessionId) {
    return false;
  }

  const { aeData } = autoEmailSession;
  return !!aeData.aeT;
};

function hasAutoLoginParams (params: URLSearchParams = new URLSearchParams(location.search)) {
  return params.has('aeT');
}

export {
  getAutoEmailSession,
  hasAutoLoginParams,
  isUserInAutoEmailSession,
  setAutoEmailSession
};
