import { Link } from 'react-router-dom';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

const TOP_CONTENT_VERTICAL_MARGIN = 25;
const TOP_CONTENT_HEIGHT = 27;
const TOP_CONTENT_FULL_HEIGHT = TOP_CONTENT_HEIGHT + 2 * TOP_CONTENT_VERTICAL_MARGIN;
const CONTENT_WIDTH = '414px';

export const Title = styled.h1`
  text-align: center;
  margin    : 12px 0 16px 0;
  max-width : ${CONTENT_WIDTH};
  ${TITLES_NORMAL.FT_04}
`;

export const Blurb = styled.div`
  text-align: center;
  color     : ${DESIGN_TOKENS.color.text.secondary.value};
  max-width : 414px;
  min-height: 3em;
  ${PARAGRAPH.DEFAULT}
`;

export const SurveyContainer = styled.div`
  margin-top: 40px;
`;

export const ErrorContentWrapper = styled.div`
  width         : 414px;
  max-width     : 100vw;
  padding       : 16px;
  display       : flex;
  flex-direction: column;
  align-items   : stretch;
`;

export const ErrorIcon = styled.div`
  ${TITLES_NORMAL.FT_04}
`;

export const ErrorTitle = styled.h1`
  margin: 12px 0 16px 0;
  ${TITLES_NORMAL.FT_04}
`;

export const ErrorBlurb = styled.div`
  color: ${DESIGN_TOKENS.color.text.secondary.value};
  ${PARAGRAPH.DEFAULT}
`;

export const ErrorCodes = styled.div`
  color     : ${DESIGN_TOKENS.color.text.placeholder.value};
  margin-top: 16px;
  ${PARAGRAPH.DEFAULT}
`;

export const ErrorButtonContainer = styled.div`
  padding       : 24px;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  gap           : 16px;
  position      : absolute;
  bottom        : 0;
  left          : 0;
  right         : 0;
  margin        : 0 auto;
  border-top    : 1px solid ${DESIGN_TOKENS.color.border.light.value};
  max-width     : ${CONTENT_WIDTH};
`;

export const ErrorLink = styled(Link)`
  ${PARAGRAPH.DEFAULT}
  color: #3063FF;
  text-decoration: underline;
`;

export const RedirectingContentWrapper = styled.div`
  height         : calc(100vh - ${TOP_CONTENT_FULL_HEIGHT}px);
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
`;

export const BottomButtonWrapper = styled.div`
  width     : 100%;
  margin-top: 34px;
`;
