import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const CenterAlignedDiv = styled.div`
  text-align: center;
  margin-top: ${DESIGN_TOKENS.spacing.lg.value};
`;

export const StyledAnchor = styled.a`
  ${LABEL_SMALL.DEFAULT}
  text-decoration: underline;
  color          : ${DESIGN_TOKENS.color.text.link.value};
`;