import { SSOProvider } from 'Shared/components/design/SSOButton/constants';
import { capitalize } from 'Shared/utils/formatUtils';

export const getSSOLoginUrl = (provider: SSOProvider) => {
  if (Object.values(SSOProvider).includes(provider)) {
    return `${process.env.REACT_APP_MONOLITH_BASE_URL}/${provider}/login`;
  }
  throw new Error('Invalid provider');
};

export const openSSOProviderWindow = (provider: SSOProvider): WindowProxy | null => {
  const url = getSSOLoginUrl(provider);
  const signUpWindow = window.open(url, `Login in using ${capitalize(provider)}`, "width=1000,height=700,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0");
  signUpWindow?.focus();
  return signUpWindow;
};