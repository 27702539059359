import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, LABEL_LARGE, PARAGRAPH,POPUP_BACKGROUND,QUESTIONNAIRE_CONTENT_MAX_WIDTH,TITLES_NORMAL, Z_INDEX_OVER_FRESHCHAT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const QuestionnaireModalContainer = styled.div`
  position        : relative;
  top             : 50%;
  left            : 50%;
  transform       : translate(-50%, -50%);
  width           : 100%;
  max-width       : 1000px;
  height          : 70vh;
  background-color: #fff;
  box-shadow      : 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius   : 10px;
  overflow        : hidden;
  z-index         : 500;
  background-color: ${DESIGN_TOKENS.color.background.primary.value};

  ::-webkit-scrollbar { width: 0 !important }

  @media (max-height: 1000px) {
    height: 90vh;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    z-index : ${Z_INDEX_OVER_FRESHCHAT + 1};
  }
`;

export const ModalContent = styled.div`
  width         : 100%;
  position      : relative;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  height        : 100%;
  overflow-y    : auto;
  box-sizing    : border-box;

  ::-webkit-scrollbar { width: 0 !important }
`;

export const PopupBackground = styled.div`
  ${POPUP_BACKGROUND};
  width  : 100%;
  z-index: 500;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    z-index : ${Z_INDEX_OVER_FRESHCHAT + 1};
  }
`;

export const ContentContainer = styled.div`
  max-width: ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
  margin   : 0 auto;
  display  : block;
  width    : 100%;
`;

export const QuestionTitle = styled.h1`
  padding: 14px 0 24px;
  ${TITLES_NORMAL.FT_02}
`;

export const QuestionnaireCompleteTitle = styled.h1`
  ${TITLES_NORMAL.FT_04}
`;

export const QuestionnaireCompleteMessage = styled.p`
  ${PARAGRAPH.DEFAULT}
  padding: 16px 0 40px;
`;

export const QuestionsContainer = styled.div`
  padding       : 0px 24px 100px 24px;

  ::-webkit-scrollbar { width: 0 !important }

  ::after {
    content         : " ";
    position        : fixed;
    bottom          : 100px;
    width           : 380px;
    z-index         : 1111;
    background-image: linear-gradient(transparent, ${DESIGN_TOKENS.color.primitive.white.value});
    pointer-events  : none;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    margin-bottom : 10px;
  }

  @media ${DEVICE_BREAKPOINT.maxSmallMobile} {
    padding: 0 16px 100px 16px;
  }
`;

export const SearchContainer = styled.div`
  width        : auto;
  margin-bottom: 24px;
`;

export const ButtonsContainer = styled.div`
  background: ${DESIGN_TOKENS.color.background.primary.value};
  position  : fixed;
  bottom    : 0;
  max-width : ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
  width     : 100%;
  z-index   : 201;
`;

export const Divider = styled.div`
  max-width : ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
  width     : 100%;
  margin    : 0 auto;
  border-top: 1px solid ${DESIGN_TOKENS.color.border.light.value};
`;

export const QuestionnaireCompleteContainer = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  margin-top     : 104px;
  padding-left   : 24px;
  padding-right  : 24px;
`;

export const Title = styled.h2`
  ${TITLES_NORMAL.FT_03}
  text-align: center;
  max-width : 412px;
  margin-top: 40px;

  span {
    font-family: ${DESIGN_TOKENS.text.number.fontFamily.value};
    font-weight: 600;
  }
`;

export const Description = styled.div`
  ${PARAGRAPH.DEFAULT}
  max-width    : 411px;
  margin-top   : 16px;
  text-align   : center;
  margin-bottom: 40px;
  color        : ${DESIGN_TOKENS.color.text.secondary.value};

  span {
    font-family: ${DESIGN_TOKENS.text.number.fontFamily.value};
    font-weight: 600;
  }
`;

export const IconContainer = styled.svg`
  width   : 24px;
  height  : 24px;
  position: absolute;
  top     : 24px;
  right   : 24px;
  cursor  : pointer;
`;

export const StepperContainer = styled.div`
  max-width    : ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
  padding-left : 10px;
  min-height   : 215px;
  margin-bottom: 40px;
`;

export const StepperTitle = styled.div`
  span:nth-child(2) {
    float: right;
    ${PARAGRAPH.MONOSPACE}
  }
`;

export const SuccessIconContainer = styled.svg`
  width   : 24px;
  height  : 24px;
  position: absolute;
  top     : 24px;
  right   : 24px;
  cursor  : pointer;
`;

export const CurrentPoints = styled.div`
  position        : absolute;
  top             : 24px;
  right           : 65px;
  width           : 53px;
  background-color: ${DESIGN_TOKENS.color.primitive.yellow['200'].value};
  border-radius   : 100px;
  color           : ${DESIGN_TOKENS.color.text.normal.value};
  text-align      : center;
  ${LABEL_LARGE.MONOSPACE}
`;

export const LoadingSpinnerContainer = styled.div`
  text-align : center;
  padding-top: 25vh;
`;

export const Backdrop = styled.div<{ show?: boolean, isAnimating?: boolean }>`
  position  : fixed;
  top       : 0;
  left      : 0;
  z-index   : 100;
  width     : 100%;
  height    : 100%;
  background: rgba(0, 0, 0, 0.32);
`;

export const ModalBackdrop = styled.div`
  position  : fixed;
  top       : 0;
  left      : 0;
  z-index   : 100;
  width     : 100%;
  height    : 100%;
  background: rgba(0, 0, 0, 0.32);
  padding   : 0 16px;
`;
