import React, { useEffect, useState } from 'react';
import { setAutoEmailSession } from 'App/services/autoEmailService';
import AEDataLoader from 'Pages/autoEmail/components/dataLoader';
import AETopContent from 'Pages/autoEmail/components/topContent';
import { AutoEmailRoot } from 'Pages/autoEmail/style';
import SurveyPrompt from 'Pages/surveyExperience/components/surveyPrompt/SurveyPrompt';
import AELoginGuard from 'Shared/components/design/email/autoLoginGuard';
import EmailGenericError from 'Shared/components/design/email/genericError';
import AERedirecting from 'Shared/components/design/email/redirecting';
import { INDEX_TEST_ID } from 'Shared/constants/email/testId';
import { useQueryParam } from 'Shared/hooks/useQueryParam';

/**
 * Renders the AutoEmail page
 * Example URL http://localhost:8080/ae/?aeId=100&aeCId=2&userId=767&aeT=2023-03-22T23:36:28Z&autoEmailKey=foo
 */
function AutoEmail(): JSX.Element {
  const userId                          = useQueryParam('userId')       || '';
  const aeT                             = useQueryParam('aeT', true)    || '';
  const autoLoginKey                    = useQueryParam('autoLoginKey') || '';
  const aeId                            = useQueryParam('aeId')         || '';
  const aeCId                           = useQueryParam('aeCId')        || '';
  const umid                            = useQueryParam('umid')         || '';
  const autoEmailKey                    = useQueryParam('autoEmailKey') || '';
  const [doneLoading, setDoneLoading]   = useState(false);
  const [stillLoading, setStillLoading] = useState(false);

  useEffect(() => {
    if (doneLoading) {
      return;
    }

    const stillLoadingTimeoutId = setTimeout(() => setStillLoading(true), 3000);
    return () => {
      clearTimeout(stillLoadingTimeoutId);
    };
  }, [doneLoading]);

  /**
   * Set the auto email session
   */
  useEffect(() => {
    setAutoEmailSession(aeCId, aeId,aeT);
  },[aeT,aeId,aeCId]);

  function renderContent () {
    if (!userId || !aeT || !autoLoginKey) {
      return (
        <EmailGenericError />
      );
    } else if (userId && aeId && aeCId && umid && aeT && autoEmailKey) {
      return (
        <AELoginGuard userId={userId} aeT={aeT} autoLoginKey={autoLoginKey} stillLoading={stillLoading}>
          <AEDataLoader
            aeId           = {aeId}
            aeCId          = {aeCId}
            umid           = {umid}
            aeT            = {aeT}
            autoEmailKey   = {autoEmailKey}
            stillLoading   = {stillLoading}
            setDoneLoading = {setDoneLoading}
          />
        </AELoginGuard>
      );
    } else {
      return (
        <AELoginGuard userId={userId} aeT={aeT} autoLoginKey={autoLoginKey} stillLoading={stillLoading}>
          <AERedirecting reason='universal-link' />
        </AELoginGuard>
      );
    }
  }

  return (
    <AutoEmailRoot data-testid={INDEX_TEST_ID}>
      <AELoginGuard userId={userId} aeT={aeT} autoLoginKey={autoLoginKey} stillLoading={stillLoading} emptyLoading>
        <AETopContent aeId={aeId} />
      </AELoginGuard>
      {renderContent()}
      <SurveyPrompt isAutoEmail={true} />
    </AutoEmailRoot>
  );
}

export default AutoEmail;
