import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { Button, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { ErrorBlurb, ErrorButtonContainer, ErrorCodes, ErrorContentWrapper, ErrorIcon, ErrorLink, ErrorTitle } from 'Shared/components/design/email/commonStyle';
import { ERROR_CODES, JWT_MISMATCH_ERROR } from 'Shared/constants/email/testId';

interface Props {
  errorCodes?: number[];
}

const warningSignEmoji = '\u26A0\uFE0F';

/**
 * Renders the EmailJwtMismatchError component
 */
function EmailJwtMismatchError({ errorCodes }: Props): JSX.Element {
  const { t }    = useTranslation('app');
  const navigate = useNavigate();

  const renderErrorCode = (): JSX.Element => {
    if (!errorCodes?.length) {
      return <></>;
    }

    return (
      <ErrorCodes data-testid={ERROR_CODES}>
        {t('Email--error-error-codes', { count: errorCodes.length, codes: errorCodes.join(', ') })}
      </ErrorCodes>
    );
  };

  return (
    <ErrorContentWrapper data-testid={JWT_MISMATCH_ERROR}>
      <ErrorIcon>{warningSignEmoji}</ErrorIcon>
      <ErrorTitle>{t('Email--error-jwt-mismatch-title')}</ErrorTitle>
      <ErrorBlurb>{t('Email--error-jwt-mismatch-blurb')}</ErrorBlurb>
      { renderErrorCode() }
      <ErrorButtonContainer>
        <Button
          size    = {ButtonSizes.LARGE}
          width   = {ButtonWidth.FULL}
          context = {ContextTypes.PRIMARY}
          onClick = {() => navigate(MENU_ROUTES.HOME)}
        >
          {t('Email--error-jwt-mismatch-continue')}
        </Button>
        <ErrorLink to={MENU_ROUTES.ACCOUNT_CONTACT_US}>Report an issue</ErrorLink>
      </ErrorButtonContainer>
    </ErrorContentWrapper>
  );
}

export default EmailJwtMismatchError;
