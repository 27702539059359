import { ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { PROMOPromotion, PROMOSegment, PROMOStep } from 'Shared/models/swagger/promo';
import { getUserPromotions } from 'Shared/services/promo/api/promoApi';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import useSWR from 'swr';


// --------------------------Promo Models--------------------------//

/**
 * Enum for the promo step types
 * In API these are represented as value of state param in each steps object
 */
export enum STEP_TYPE {
  IGNORE_BY_FRONTEND,
  POINTS_PROGRESS,
  ACTIVITIES_PROGRESS,
  REDEEM
}

export enum REDEMPTION_STEP_STATE {
  INCOMPLETE,
  READY_TO_REDEEM,
  COMPLETE,
  COMPLETE_NOT_READY_TO_REDEEM
}

export interface PromoProgress {
  percentage: number;
  hasRedeemed: boolean;
  pointsGoal: number | undefined;
  pointsCompleted: number;
}

export enum UI_STATES {
  'promoCompletedTs'    = 'promoCompletedTs',
  'confettiShown'       = 'confettiShown',
  'tooltipDesktopShown' = 'tooltipDesktopShown',
  'tooltipMobileShown'  = 'tooltipMobileShown',
}

export type PromoUiState = {
  autoOptIn?: boolean;
} & {
    [key in UI_STATES]?: any;
  };

// i need to create a type that omits the `uiState` property from `PROMOPromotion` so I can override it below with my custom `uiState`
type PROMOPromotionBase = Omit<PROMOPromotion, 'uiState'>;

// creating a new interface that extends the modified `PROMOPromotion` and includes the custom `uiState` which is controlled by the frontend
export interface CustomPROMOPromotion extends PROMOPromotionBase {
  uiState : PromoUiState;
  timezone: string | null;
}

// --------------------------Promo Constants------------------------//

let    promotionsPromise: Promise<CustomPROMOPromotion[]> | null = null;
const  FIVE_SECONDS_IN_MS                                        = 5000;
export const COMPLETED_STEP                                      = 1;
export const TIP_VIEWED                                          = 'true';
export let refreshIntervalDependingOnData: number                = FIVE_SECONDS_IN_MS;

// --------------------------Promo Service--------------------------//

/**
 * Calls the API to get user promotions
 * @returns
 */
const getUserPromotion = (): Promise<CustomPROMOPromotion[]> => {
  if (promotionsPromise) {
    return promotionsPromise;
  }

  promotionsPromise = new Promise<CustomPROMOPromotion[]>((resolve, reject) => {
    getUserPromotions()
      .then((response) => {
        const promotionsArray: CustomPROMOPromotion[] = response.data;
        resolve(promotionsArray);
      })
      .catch((error) => {
        reject(error.response);
      })
      .finally(() => {
        promotionsPromise = null;
      });
  });

  return promotionsPromise;
};

/**
 * Hook to get user promotions
 * Currently only returns the first (the only) promotion
 */
export const useUserPromotions = () => {
  const { data, error, isLoading, mutate } = useSWR<CustomPROMOPromotion[]>(SwrKeys.UserPromotions, getUserPromotion, {
    refreshInterval: refreshIntervalDependingOnData
  });

  const availablePromotion = Array.isArray(data) && data.length > 0
    ? data.reduce((highest: CustomPROMOPromotion | null, promo) => {
      //check if the promotion has not been opted into
      if (!promo.optinTs) {
        // need to default to Infinity if sortOrder is undefined
        const promoSortOrder = promo.sortOrder ?? Infinity;
        const highestSortOrder = highest?.sortOrder ?? Infinity;

        // get promotion with sortOrder 0 else find the one with the highest sort order
        if (promoSortOrder === 0 || promoSortOrder < highestSortOrder) {
          return promo;
        }
      }
      return highest;
    }, null as CustomPROMOPromotion | null)
    : null;

  const activePromotions = Array.isArray(data) && data.length > 0
    ? data.filter(promo => {
      // check expiration and completion status
      const {
        expiration,
        completedAt
      } = getActivePromoData(promo);
      const currentTime = new Date().toISOString();
      // promotion is active if its been opted in, is not celebrated, and has not expired
      return promo.optinTs && !promoCelebrationPeriodExpired(completedAt) && expiration > currentTime;
    })
    : [];

  if (!activePromotions || activePromotions.length === 0) {
    refreshIntervalDependingOnData = 0;
  } else {
    refreshIntervalDependingOnData = FIVE_SECONDS_IN_MS;
  }

  // condition to show the promo widget
  const showPromoWidget = !isLoading && !error && activePromotions.length > 0;

  // condition to show the promo banner
  const showPromoBanner = !isLoading && !error && (availablePromotion !== null) && !isAutoOptable(availablePromotion);

  // condition to show the promo popup
  const showPromoPopup = !isLoading && !error && (availablePromotion !== null) && !showPromoBanner;

  return {
    availablePromotion, // promotion with the highest sort order without optinTs
    activePromotions,   // array of active promotions
    showPromoWidget,    // show promo widget
    showPromoBanner,    // show promo banner
    showPromoPopup,     // show promo popup
    mutate              // mutate function to manually trigger revalidation
  };
};

/**
 * Returns the value of the autoOptIn property of the promo if it exists, otherwise returns false
 * It determines whether the needs to interact and opt in to the promotion (via promo banner)
 * @param promo
 * @returns
 */
export const isAutoOptable = (promo: CustomPROMOPromotion): boolean => {
  return promo.uiState?.autoOptIn ?? false;
};

/**
 * Determines if user can be shown the active promo segment
 * @param segment
 * @returns
 */
export const showActivePromoSegment = (segment: PROMOSegment[] | undefined): boolean => {
  //first check if segment exists and it has a expiration timestamp
  //expirationTs is generated only after user opts in to a promotion
  if (!segment || !segment[0].expirationTs) {
    return false;
  }

  const now            = new Date();
  const expirationDate = new Date(segment[0].expirationTs || '');

  return now < expirationDate;
};

interface PromoExpiration {
  expiration         : string;
  completed          : boolean;
  completedAt        : string;
  confettiShown      : boolean;
  tooltipDesktopShown: boolean;
  tooltipMobileShown : boolean;
  updatedAt          : string;
}

/**
 * Gets the active promo expiration date
 * @param segment
 * @returns
 */
export const getActivePromoData = (promotion: CustomPROMOPromotion | null): PromoExpiration => {
  const segment = promotion?.segments;

  if (!promotion || !segment || segment.length === 0 || !segment[0].expirationTs || !segment[0].steps) {
    return {
      expiration         : '',
      completed          : false,
      completedAt        : '',
      confettiShown      : false,
      tooltipDesktopShown: false,
      tooltipMobileShown : false,
      updatedAt          : ''
    };
  }

  const completedAt         = promotion?.uiState?.promoCompletedTs ?? '';
  const confettiShown       = promotion?.uiState?.confettiShown ?? false;
  const tooltipDesktopShown = promotion?.uiState?.tooltipDesktopShown ?? false;
  const tooltipMobileShown  = promotion?.uiState?.tooltipMobileShown ?? false;
  const updatedAt           = promotion?.updatedAt ?? '';
  const steps               = segment[0].steps;

  // check if all steps have a completedAt timestamp
  const completed = steps.every(step => step.completedAt !== null);

  return {
    expiration: segment[0].expirationTs, // promotion expiration date
    completed, // all promo steps are completed
    completedAt, // completion timestamp (time when all steps are completed and promo is celebrated)
    confettiShown, // confetti shown state
    tooltipDesktopShown, // tooltip shown state
    tooltipMobileShown, // tooltip shown state
    updatedAt // promo updated at timestamp
  };
};

/**
 * Returns the progress percentage of a promotion step
 * @param step
 * @returns
 */
export const getPromotionProgressPercentage = (step: PROMOStep): number => {
  let progress: number | undefined;

  if (step.state === STEP_TYPE.POINTS_PROGRESS) {
    progress = (step.pointsCounter ?? 0) / (step.target ?? 1);
  } else if (step.state === STEP_TYPE.ACTIVITIES_PROGRESS) {
    progress = (step.activityCounter ?? 0) / (step.target ?? 1);
  }

  if (progress === undefined) {
    return 0;
  }

  // ensure progress is between 0 and 1 and it doesn't exceed 1 (100%)
  if (progress > COMPLETED_STEP) {
    return COMPLETED_STEP;
  }

  return progress;
};
/**
 * Determines the state of the redemption step
 * @param step
 * @param userPointsData
 * @returns
 */
export const getRedemptionStepState = (step: PROMOStep, userPointsData: ACTUserPointsResponse | null | undefined): REDEMPTION_STEP_STATE => {
  if (step.completedAt !== null) {
    return REDEMPTION_STEP_STATE.COMPLETE;
  }

  const pointsCounter = step.pointsCounter ?? 0;
  const points = step.points ?? 0;
  const currentPoints = userPointsData?.currentPoints ?? 0;
  const minRedeemablePoints = userPointsData?.minRedeemablePoints ?? 0;

  if (pointsCounter >= points && currentPoints >= minRedeemablePoints) {
    return REDEMPTION_STEP_STATE.READY_TO_REDEEM;
  }

  if (pointsCounter && points && pointsCounter >= points && currentPoints < minRedeemablePoints) {
    return REDEMPTION_STEP_STATE.COMPLETE_NOT_READY_TO_REDEEM;
  }

  return REDEMPTION_STEP_STATE.INCOMPLETE;
};

/**
 * Determines if the promo celebration period has expired (2 hours)
 * @param promoCelebratedTs
 * @returns
 */
export const promoCelebrationPeriodExpired = (promoCelebratedTs: string): boolean => {
  if (promoCelebratedTs === '') {
    return false;
  }

  const promoCelebratedDate = new Date(promoCelebratedTs);
  const currentTime = new Date();
  const twoHoursAgo = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000);

  return promoCelebratedDate < twoHoursAgo;
};
