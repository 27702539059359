import React, { useState } from 'react';
import { STEPPER_TEST_IDS } from 'Shared/components/design/stepper/constants';
import {
  IconContainer,
  IconDonut,
  IconDonutCenter,
  IconDot,
  IconIcon,
  IconNumeric,
  StepperContainer,
  StepperDescription,
  StepperDescriptionInner,
  StepperItem,
  StepperItemContent,
  StepperLine,
  StepperSidebar,
  StepperTitle,
}  from 'Shared/components/design/stepper/style';
import { ICON_IDS, IconId } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

export enum StepperTheme {
  DEFAULT = 'DEFAULT',
  PROMO   = 'PROMO',
}

export enum StepperType {
  DONUT   = 'DONUT',
  DOT     = 'DOT',
  NUMERIC = 'NUMERIC',
  ICON    = 'ICON',
  CHECK   = 'CHECK',
}

export enum StepperState {
  DISABLED = 'DISABLED',
  DEFAULT  = 'DEFAULT',
  ACTIVE   = 'ACTIVE',
}

type Step = {
  title               : React.ReactNode;
  content?            : React.ReactNode;
  description?        : React.ReactNode;
  typeStart           : StepperType;
  typeEnd?            : StepperType;
  iconStart?          : IconId;
  iconEnd?            : IconId;
  stateStart          : StepperState;
  stateEnd?           : StepperState;
  noBorderIcon?       : boolean;
  collapseDescription?: boolean;
  onActiveClick?      : () => void;
}

type UnorderedStepperProps = {
  steps         : Step[];
  activeStep    : number;
  animateToStep?: boolean;
  theme?        : StepperTheme;
  onClick?      : (step: number) => void;
}

/**
 * Renders <Stepper /> component
 *
 * Example of how to use:
  <Stepper steps={[{
    title: 'This is a title',
    description: 'My content',
    stateStart: StepperState.DEFAULT,
    stateEnd: StepperState.ACTIVE,
    typeStart: StepperType.DOT,
    typeEnd: StepperType.DONUT,
  },{
    title: 'This is also a title',
    description: 'My description',
    stateStart: StepperState.DEFAULT,
    stateEnd: StepperState.ACTIVE,
    typeStart: StepperType.DOT,
    typeEnd: StepperType.CHECK,
  },{
    title: 'This is also another title',
    description: 'My other description other description other description other description other description other description other description other description other description other description',
    stateStart: StepperState.DEFAULT,
    stateEnd: StepperState.ACTIVE,
    typeStart: StepperType.ICON,
    iconStart: ICON_IDS.BULB,
    typeEnd: StepperType.ICON,
    iconEnd: ICON_IDS.BOLT,
  },{
    title: 'This is also another another title',
    description: 'My other other description',
    stateStart: StepperState.DEFAULT,
    stateEnd: StepperState.ACTIVE,
    typeStart: StepperType.DOT,
    typeEnd: StepperType.DONUT,
  },{
    title: 'This is also another another title',
    description: 'My other other description',
    stateStart: StepperState.DEFAULT,
    stateEnd: StepperState.ACTIVE,
    typeStart: StepperType.NUMERIC,
    typeEnd: StepperType.NUMERIC,
  }]} activeStep={4} animateToStep={false} />
 */
function Stepper({ steps, activeStep = 0, animateToStep, theme = StepperTheme.DEFAULT, onClick }: UnorderedStepperProps): JSX.Element {
  const DEFAULT_STEP                          = -1;
  const stepsCount                            = steps.length - 1;
  const [activatedStep, setActivatedStep]     = useState<number>(animateToStep ? DEFAULT_STEP : stepsCount);
  const [isStepActivated, setIsStepActivated] = useState<boolean>(false);

  const handleStepClick = (idx: number, step: Step, isActiveState: boolean): void => {
    if (onClick) {
      onClick(idx);
    }

    if (isActiveState && step.onActiveClick) {
      step.onActiveClick();
    }
  
  };

  const renderSidebarIcon = (stepNumber: number, type: StepperType, state: StepperState, icon: IconId | undefined, isVisible: boolean, noBorderIcon = false): JSX.Element => {
    if (type === StepperType.DONUT) {
      return (
        <IconDonut isVisible={isVisible} state={state} theme={theme}>
          <IconDonutCenter />
        </IconDonut>
      );
    } else if (type === StepperType.DOT) {
      return (
        <IconDot isVisible={isVisible} state={state} theme={theme} />
      );
    } else if (type === StepperType.NUMERIC) {
      return (
        <IconNumeric isVisible={isVisible} state={state} theme={theme}>
          {stepNumber}
        </IconNumeric>
      );
    } else if (type === StepperType.CHECK) {
      return (
        <IconIcon isVisible={isVisible} state={state} theme={theme}>
          <Icon iconId={ICON_IDS.CHECKMARK}/>
        </IconIcon>
      );
    } else if (type === StepperType.ICON) {
      return (
        <IconIcon isVisible={isVisible} state={state} isIcon={ true } noBorderIcon={noBorderIcon} theme={theme}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <Icon iconId={icon!}/>
        </IconIcon>
      );
    }

    return <></>;
  };

  const renderStepTitle = (step: Step, state: StepperState, idx: number, isActiveState: boolean, role: string): JSX.Element => {
    return (
      <StepperTitle state={state} tabIndex={0} onClick={() => handleStepClick(idx, step, isActiveState)} role={role}>
        { step.title }
      </StepperTitle>
    );
  };

  const renderStepContent = (step: Step, state: StepperState): JSX.Element => {
    if (step.description) {
      return (
        <StepperDescription state={state} tabIndex={0} collapse={step.collapseDescription}>
          <StepperDescriptionInner>
            { step.description }
          </StepperDescriptionInner>
        </StepperDescription>
      );
    }

    return (
      <>
        { step.content }
      </>
    );
  };

  const renderSidebar = (idx: number, step: Step, isActiveState: boolean, role: string): JSX.Element => {
    const stateStart: StepperState = step.stateStart;
    const stateEnd: StepperState   = isActiveState ? (step.stateEnd || step.stateStart) : step.stateStart;
    const iconStart                = step.iconStart;
    const iconEnd                  = isActiveState ? (step.iconEnd  || step.iconStart)  : step.iconStart;
    const typeStart: StepperType   = step.typeStart;
    const typeEnd: StepperType     = isActiveState ? (step.typeEnd  || step.typeStart)  : step.typeStart;
    const isNextStep: boolean      = idx < activatedStep;

    return <>
      <IconContainer onClick={() => handleStepClick(idx, step, isActiveState)} role={role}>
        { renderSidebarIcon(idx + 1, typeStart, stateStart, iconStart, !isActiveState) }
        { renderSidebarIcon(idx + 1, typeEnd, stateEnd, iconEnd, isActiveState) }
      </IconContainer>
      <StepperLine isVisible={isNextStep} state={ stateEnd } type={ typeEnd } />
    </>;
  };

  return (
    <StepperContainer data-testid={STEPPER_TEST_IDS.INDEX}>
      {
        steps.map((step: Step, idx: number) => {
          const isVisible           = idx <= activatedStep;
          const isCurrentStep       = idx === activatedStep;
          const isActiveState       = isVisible && idx <= activeStep && (!animateToStep || (isCurrentStep && isStepActivated) || idx < activatedStep);
          const state: StepperState = isActiveState ? (step.stateEnd || step.stateStart) : step.stateStart;
          const role: string        = onClick || isActiveState && step.onActiveClick ? 'button' : 'text';

          if (activatedStep === DEFAULT_STEP) {
            setTimeout(() => {
              setActivatedStep(activatedStep + 1);
            }, 100);
          } else if (animateToStep) {
            if (isCurrentStep) {
              if (!isStepActivated) {
                setTimeout(() => {
                  setIsStepActivated(true);
                }, 375);
              } else if (isStepActivated) {
                setTimeout(() => {
                  if (activatedStep < stepsCount) {
                    setIsStepActivated(false);
                    setActivatedStep(activatedStep + 1);
                  }
                }, 375);
              }
            }
          }

          return (
            <StepperItem key={idx} isVisible={isVisible} animateToStep={!!animateToStep}>
              <StepperSidebar>
                { renderSidebar(idx, step, isActiveState, role) }
              </StepperSidebar>
              <StepperItemContent>
                { renderStepTitle(step, state, idx, isActiveState, role) }
                { renderStepContent(step, state) }
              </StepperItemContent>
            </StepperItem>
          );
        })
      }
    </StepperContainer>
  );
}

export default Stepper;
