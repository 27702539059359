import { initReactI18next } from 'react-i18next';
import footer from 'App/components/footer/i18n/us.json';
import qrDownloadApp from 'App/components/qrDownloadApp/i18n/us.json';
import app from 'App/i18n/us.json';
import i18n from 'i18next';
import createAccount from 'Pages/createAccount/i18n/us.json';
import signupEmailSubmit from 'Pages/emailSubmit/i18n/us.json';
import forgotPassword from 'Pages/forgotPassword/i18n/us.json';
import accountHelp from 'Pages/help/i18n/us.json';
import profileQuestionsDetail from 'Pages/help/subPages/profileQuestionDetail/i18n/us.json';
import profileQuestions from 'Pages/help/subPages/profileQuestions/i18n/us.json';
import howOnfidoWorks from 'Pages/howOnfidoWorks/i18n/us.json';
import onfidoReviewYourPersonalInfo from 'Pages/onfidoReviewYourPersonalInfo/i18n/us.json';
import pageNotFound from 'Pages/pageNotFound/i18n/us.json';
import panelsPage from 'Pages/panels/i18n/us.json';
import pinningInstructions from 'Pages/pinningInstructions/i18n/us.json';
import stePromo from 'Pages/rewards/components/rewardsPage/surfToEarn/i18n/us.json';
import rewards from 'Pages/rewards/i18n/us.json';
import signIn from 'Pages/signIn/i18n/us.json';
import siteShutDown from 'Pages/siteShutDown/i18n/us.json';
import surveyCallback from 'Pages/surveyCallback/i18n/us.json';
import surveyExperience from 'Pages/surveyExperience/i18n/us.json';
import surveyMedleyPopup from 'Pages/surveyMedley/components/surveyMedleyPopup/i18n/us.json';
import surveyQualification from 'Pages/surveyQualification/i18n/us.json';
import uninstall from 'Pages/uninstall/i18n/us.json';
import unrecognizedDevice from 'Pages/unrecognizedDevice/i18n/us.json';
import verifyDevice from 'Pages/verifyDevice/i18n/us.json';
import welcome from 'Pages/welcome/i18n/us.json';
import leaderBoard from 'Shared/components/design/leaderBoard/i18n/us.json';
import promoNavButton from 'Shared/components/design/promoNavButton/i18n/us.json';
import promoPopup from 'Shared/components/design/promoPopup/i18n/us.json';
import promoStepper from 'Shared/components/design/promoStepper/i18n/us.json';
import questionnairePopup from 'Shared/components/design/questionnairePopup/i18n/us.json';

// set namespaces to the `default` language
const resources = {
  en: {
    app,
    createAccount,
    rewards,
    surveyCallback,
    surveyExperience,
    accountHelp,
    howOnfidoWorks,
    onfidoReviewYourPersonalInfo,
    forgotPassword,
    signIn,
    signupEmailSubmit,
    pinningInstructions,
    uninstall,
    questionnairePopup,
    surveyQualification,
    unrecognizedDevice,
    verifyDevice,
    welcome,
    siteShutDown,
    surveyMedleyPopup,
    profileQuestions,
    profileQuestionsDetail,
    panelsPage,
    stePromo,
    pageNotFound,
    promoStepper,
    promoNavButton,
    qrDownloadApp,
    footer,
    leaderBoard,
    promoPopup
  },
};

i18n
  .use(initReactI18next)    // passes i18n to react-i18next
  .init({
    resources,
    lng          : 'en',    // set the default to english
    ns           : ['app'], // default namespace
    interpolation: {
      escapeValue: false    // react already protects from xss
    }
  });

export default i18n;
