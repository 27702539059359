import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AgreementItem,
  AgreementLinkItem,
  AgreementLinksContainer,
  FooterContainer,
  FooterLinksContainer,
  SocialLinkItem,
  SocialLinkItemImage,
  SocialLinksContainer,
} from 'App/components/footer/style';
import { FOOTER_TEST_ID } from 'App/components/footer/testId';
import { QrDownloadApp } from 'App/components/qrDownloadApp/QrDownloadApp';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import facebook from 'Shared/assets/images/social/facebook.svg';
import instagram from 'Shared/assets/images/social/instagram.svg';
import pinterest from 'Shared/assets/images/social/pinterest.svg';
import x from 'Shared/assets/images/social/x.svg';
import { isAndroidDevice, isIosDevice } from 'Shared/services/deviceService';

/**
 * Footer component
 * @return JSX.Element
 */
const Footer = () => {
  const { t }             = useTranslation('footer');
  const currentYear       = new Date().getFullYear();
  const facebookUrl       = 'https://www.facebook.com/surveyjunkie';
  const instagramUrl      = 'https://www.instagram.com/surveyjunkie';
  const pinterestUrl      = 'https://www.pinterest.com/surveyjunkie';
  const xUrl              = 'https://twitter.com/surveyjunkie';
  const iosAppEnabled     = process.env.IOS_APP_ENABLED;
  const androidAppEnabled = process.env.ANDROID_APP_ENABLED;

  /**
   * Do not render if mobile app is not enabled for the device user is on
   * @returns
   */
  const renderQrDownloadApp = (): JSX.Element => {
    if (isIosDevice() && !iosAppEnabled) {
      return <></>;
    }

    if (isAndroidDevice() && !androidAppEnabled) {
      return <></>;
    }

    return <QrDownloadApp />;
  };


  return (
    <FooterContainer data-testid={FOOTER_TEST_ID}>
      <FooterLinksContainer>
        <AgreementLinksContainer>
          <AgreementLinkItem href={MENU_ROUTES.ACCOUNT_TERMS} target='_blank'>
            {t('Footer--terms')}
          </AgreementLinkItem>
          <AgreementLinkItem href={MENU_ROUTES.ACCOUNT_PRIVACY} target='_blank'>
            {t('Footer--privacy')}
          </AgreementLinkItem>
          <AgreementLinkItem href={process.env.REACT_APP_ONE_TRUST_DO_NOT_SELL_URL} target='_blank'>
            {t('Footer--doNotSell')}
          </AgreementLinkItem>
        </AgreementLinksContainer>
        <SocialLinksContainer>
          <SocialLinkItem href={facebookUrl} target='_blank'>
            <SocialLinkItemImage src={facebook} />
          </SocialLinkItem>
          <SocialLinkItem href={instagramUrl} target='_blank'>
            <SocialLinkItemImage src={instagram} />
          </SocialLinkItem>
          <SocialLinkItem href={pinterestUrl} target='_blank'>
            <SocialLinkItemImage src={pinterest} />
          </SocialLinkItem>
          <SocialLinkItem href={xUrl} target='_blank'>
            <SocialLinkItemImage src={x} />
          </SocialLinkItem>
        </SocialLinksContainer>
        <AgreementItem>
          {t('Footer--copyright', {symbol:'\u00A9', year: currentYear})}
        </AgreementItem>
      </FooterLinksContainer>
      {renderQrDownloadApp()}
    </FooterContainer>
  );
};

export default Footer;
