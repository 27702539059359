import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_BASIC_PROFILE_TYPES, ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type BasicProfileStepStartEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.login_method]: string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : string,
  [ANALYTICS_EVENT_ATTRIBUTES.current_step]: number,
  [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : number,
  [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES,
}

/**
 * Sends analytic event and attributes for Basic Profile to Google Analytics
 * @param basicProfileStepStartEvent
 * @returns void
 */
export const analyticsTrackBasicProfileStepStart = (basicProfileStepStartEvent: BasicProfileStepStartEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      ...basicProfileStepStartEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]    : ANALYTICS_EVENT_TYPE.questionnaire,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.basic_profile_step,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
};