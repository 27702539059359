enum BSS_EXTENSION_SIGNAL_TYPE {
  EXTENSION_TERMS_ACCEPTED = 1,
  EXTENSION_INSTALL        = 3,
  EXTENSION_UNINSTALL      = 4
}
enum BSS_DESKTOP_SIGNAL_TYPE {
  DESKTOP_DOWNLOADED  = 1,
  DESKTOP_INSTALLED   = 4,
  DESKTOP_UNINSTALLED = 5,
}

export {
  BSS_DESKTOP_SIGNAL_TYPE,
  BSS_EXTENSION_SIGNAL_TYPE,
};
