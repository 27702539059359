import { INTER_FONT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface ActivityStateProps {
  isCompleted: boolean;
}

export const ActivitiesProgress = styled.div`
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  width          : 60%;
`;

export const ActivityStateWrapper = styled.div`
  display       : flex;
  align-items   : center;
  flex-direction: column;
  flex          : 1;
`;

export const ActivityState = styled.div<ActivityStateProps>`
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  height          : 24px;
  width           : 24px;
  border-radius   : 50%;
  background-color: ${props => props.isCompleted ? '#9749F4' : '#D9D9D9'};
  color           : white;
  z-index         : 1;
  position        : relative;

  svg {
    position: absolute;
    top     : 3px;
    left    : 3px;
  }
`;

export const ActivitiesContainer = styled.div`
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  margin-bottom  : 20px;
`;

export const StepNumber = styled.span`
  ${INTER_FONT.PARAGRAPH};
  margin-top : 45px;
  color      : #6F6F6F;
  font-size  : 12px;
  white-space: nowrap;
  text-align : center;
`;

export const Line = styled.div`;
  width           : 100%;
  height          : 1px;
  background-color: #CBCBCB;
`;

export const InnerCircle = styled.div`
  display       : inline-block;
  width         : 20px;
  height        : 20px;
  border-radius : 50%;
  border-style  : solid;
  border-width  : 1px;
  border-color  : #791CEA;
  position      : absolute;
  top           : 2px;
  left          : 2px;
  pointer-events: none;
`;