import { DEVICE_BREAKPOINT, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const CAIntroWrapper = styled.div`
  display       : flex;
  flex-direction: column;
  max-width     : calc(100vw - 32px);
  width         : 414px;
  min-height    : 450px;
  align-items   : stretch;
  height        : 100vh;
  margin        : 60px 16px 40px 16px;
`;

export const CAIntroGap = styled.div`
  margin-top: 120px;

  @media ${ DEVICE_BREAKPOINT.maxMobile } {
    margin-top: 0;
    flex-grow: 1;
  }
`;

export const CAIntroBlurb = styled.div`
  ${TITLES_NORMAL.FT_01}
  text-align   : center;
  margin-bottom: 40px;
`;
