import { useEffect, useState } from 'react';
import { MEDIA_SIZES_IN_PIXELS } from 'Shared/styles/designConstants';

type MediaSizeName = keyof typeof MEDIA_SIZES_IN_PIXELS;

/**
 * Used to calculate whether the window is at most the width of the given media size.
 * @param size The name of one of the keys in `MEDIA_SIZES_IN_PIXELS`.
 * @returns Whether the screen's width is at most the number of pixels given by `size`.
 */
function calculateIsMaxSize (size: MediaSizeName) {
  const maxSize = MEDIA_SIZES_IN_PIXELS[size];
  return window.innerWidth <= maxSize;
}

/**
 * Returns whether the window is at most the width of the given media size.
 * @param size The name of one of the keys in `MEDIA_SIZES_IN_PIXELS`.
 * This gets translated into a number of pixels to check the width of the screen against.
 * @returns `true` if the window is at most the number of pixels given by the media size,
 * `false` if the window is larger.
 */
export function useIsMaxSize (size: MediaSizeName) {
  const [isMaxSize, setIsMaxSize] = useState(calculateIsMaxSize(size));

  useEffect(() => {
    function resizeListener () {
      setIsMaxSize(calculateIsMaxSize(size));
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [size, setIsMaxSize]);

  return isMaxSize;
}
