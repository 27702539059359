import { getUserId } from 'App/services/idpTokenService';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';
import { CustomPROMOPromotion, PromoUiState } from 'Shared/services/promo/app/promoService';

/**
 * Promo MS Routes
 */
export const API_CONFIG_PROMO_MS = '/v1/users';
export const PROMO_PROMOTIONS    = '/promotions';

const promoPromiseMap: any = {};

const USER_BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Generates the promo URL
 * @param promoId
 * @returns
 */
const getPromoUrl = (promoId?: string): string => {
  const userId = getUserId();

  if (promoId) {
    return `${API_CONFIG_PROMO_MS}/${userId}${PROMO_PROMOTIONS}/${promoId}`;
  }

  return `${API_CONFIG_PROMO_MS}/${userId}${PROMO_PROMOTIONS}`;
};

/**
 * GET User promotions from PROMO MS
 * @param userId
 */
export const getUserPromotions = (): Promise<APIResponse<CustomPROMOPromotion[]>> => {
  const baseURL  = process.env.REACT_APP_PROMO_BASE_URL;
  const url      = getPromoUrl();

  if (promoPromiseMap[url]) {
    return promoPromiseMap[url];
  }

  promoPromiseMap[url] = MS_API({
    method : 'GET',
    baseURL: baseURL,
    url    : url
  });

  promoPromiseMap[url].finally(() => {
    promoPromiseMap[url] = null;
  });

  return promoPromiseMap[url];
};

/**
 * Handles the optin for a promo
 * @param promoId
 * @param optIn
 * @returns
 */
export const patchPromoOptin = (promoId: string, optIn: boolean): Promise<APIResponse<CustomPROMOPromotion>> => {
  const baseURL  = process.env.REACT_APP_PROMO_BASE_URL;
  const url      = getPromoUrl(promoId);

  return MS_API({
    method : 'PATCH',
    baseURL: baseURL,
    url    : url,
    data   : {
      optin   : optIn,
      timezone: USER_BROWSER_TIMEZONE
    },
  });
};

/**
 * Handles patching of uiState for a promo
 * @param promoId
 * @param uiState
 * @returns
 */
export const patchPromoUiState = (promoId: string, uiState: PromoUiState): Promise<APIResponse<CustomPROMOPromotion>> => {
  const baseURL = process.env.REACT_APP_PROMO_BASE_URL;
  const url     = getPromoUrl(promoId);

  return MS_API({
    method: 'PATCH',
    baseURL: baseURL,
    url: url,
    data: {
      uiState: uiState,
    },
  });
};

/**
 * Handles patching of timezone for a promo
 * @param promoId
 * @param timezone
 * @returns
 */
export const patchPromoTimezone = (promoId: string, timezone: string): Promise<APIResponse<CustomPROMOPromotion>> => {
  const baseURL = process.env.REACT_APP_PROMO_BASE_URL;
  const url     = getPromoUrl(promoId);

  return MS_API({
    method: 'PATCH',
    baseURL: baseURL,
    url: url,
    data: {
      timezone: timezone,
    },
  });
};
