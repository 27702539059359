import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';

enum FirstPromptStateTypes {
  NOT_VIEWED,
  VIEWED_CLOSED,
  VIEWED_ROUTED
}

/**
 * Set viewed value in local storage when user views but closes first survey click prompt
 */
const setViewedClosed = (): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_PROMPT, String(FirstPromptStateTypes.VIEWED_CLOSED));
};

/**
 * Set routed value in local storage user views and click continue on first survey click prompt
 */
const setViewedRouted = (): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_PROMPT, String(FirstPromptStateTypes.VIEWED_ROUTED));
};

/**
 * Checks local storage for survey prompt view status
 */
const getSurveyPromptStatus = (): FirstPromptStateTypes => {
  const promptStatus = parseInt(getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_PROMPT));

  switch (promptStatus) {
    case FirstPromptStateTypes.VIEWED_CLOSED:
      return FirstPromptStateTypes.VIEWED_CLOSED;
    case FirstPromptStateTypes.VIEWED_ROUTED:
      return FirstPromptStateTypes.VIEWED_ROUTED;
    default:
      return FirstPromptStateTypes.NOT_VIEWED;
  }
};

/**
 * Helper function for
 * Checking enum if it is ViewedRouted
 */
const isViewedRouted = (): boolean => {
  return getSurveyPromptStatus() === FirstPromptStateTypes.VIEWED_ROUTED;
};

/**
 * Validates the survey prompt based on the user's current and lifetime points.
 * If the current points are not equal to the lifetime points, it sets the survey prompt status to 'VIEWED_ROUTED'.
 *
 * @param {Object} points - An object containing the current and lifetime points.
 * @param {number} points.currentPoints - The user's current points.
 * @param {number} points.lifetimePoints - The user's lifetime points.
 */
const validateSurveyPromptByPoints = ({ currentPoints, lifetimePoints }: { currentPoints: number, lifetimePoints: number }): void => {
  if (isViewedRouted()) {
    return;
  }

  if (currentPoints === lifetimePoints) {
    return;
  }

  setViewedRouted();
};

export {
  FirstPromptStateTypes,
  getSurveyPromptStatus,
  isViewedRouted,
  setViewedClosed,
  setViewedRouted,
  validateSurveyPromptByPoints
};