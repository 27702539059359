import { LoaderEventProps } from 'Pages/surveyExperience/components/surveysStreamLoader/LoaderEventModels';
import { StePreinstallModalEventProps, SurveyPromptEventProps, SurveyRemoveEventProps } from 'Pages/surveyExperience/models/EventModels';
import { addEventListener, removeEventListener } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * AddEventListener: window.mfEvents.surveyExperience.surveyPrompt
 * @param callback
 */
export const addSurveyPromptEvent = (callback: (props: SurveyPromptEventProps) => void): void => {
  addEventListener<SurveyPromptEventProps>(MF_EVENTS.surveyExperience.surveyPrompt, callback);
};

/**
 * AddEventListener: window.mfEvents.surveyExperience.removeSurvey
 * @param callback
 */
export const addSurveyRemoveEvent = (callback: (props: SurveyRemoveEventProps) => void): void => {
  addEventListener<SurveyRemoveEventProps>(MF_EVENTS.surveyExperience.removeSurvey, callback);
};

/**
 * Add STE app preinstall modal event
 * @param callback
 */
export const addStePreInstallModalEvent = (callback: (props: StePreinstallModalEventProps) => void): void => {
  addEventListener(MF_EVENTS.app.stePreinstallModal, callback );
};

/**
 * Add email confirmation modal event
 * @param callback
 */
export const addSteEmailModalEvent = (callback: () => void): void => {
  addEventListener(MF_EVENTS.app.emailModal, callback );
};

/**
 * RemoveEventListener: window.mfEvents.surveyExperience.removeSurvey
 * @param callback
 */
export const removeSurveyRemoveEvent = (callback: (props: SurveyRemoveEventProps) => void): void => {
  removeEventListener<SurveyRemoveEventProps>(MF_EVENTS.surveyExperience.removeSurvey, callback);
};

/**
 * AddEventListener: window.mfEvents.surveysLoading.action
 * @param callback
 */
export const addSurveysLoadingEvent = (callback: (props: LoaderEventProps) => void): void => {
  addEventListener<LoaderEventProps>(MF_EVENTS.surveysLoading.action, callback);
};

/**
 * AddEventListener: window.MF_EVENTS.promotions.optinStart
 * @param callback
 */
export const addPromoOptinStartEvent = (callback: () => void): void => {
  addEventListener(MF_EVENTS.promotions.optinStart, callback);
};