import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled from "styled-components";

export const ButtonContainer = styled.button`
  display: none;
  @media ${DEVICE_BREAKPOINT.maxMobile} {
    all     : unset;
    cursor  : pointer;
    width   : 24px;
    height  : 25px;
    position: absolute;
    top     : 8px;
    left    : 16px;

    svg {
      width : 24px;
      height: 24px;
    }
  }
`;