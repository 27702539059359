
import { getAutoEmailSurvey, GetAutoEmailSurveyParams, getDirectSurvey, GetDirectSurveyParams } from 'Shared/services/rdt/api/rdtApi';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import useSWRImmutable from 'swr/immutable';

/**
 * SWR hook to get the auto email survey
 * @param param0
 * @returns
 */
const useAutoEmailSurvey = ({ aeId, aeCId, umid, aeT, autoEmailKey }: GetAutoEmailSurveyParams) => {
  return useSWRImmutable({ key: SwrKeys.AeEmailSurvey, aeId, aeCId, aeT, autoEmailKey },
  async ({ aeId, aeCId, aeT, autoEmailKey }) => {
    const response = await getAutoEmailSurvey({
      aeId,
      aeCId,
      umid,
      aeT,
      autoEmailKey
    });

    return response.data;
  }, {
    errorRetryCount: 0
  });
};

/**
 * SWR hook to get the direct survey
 * @param param0
 * @returns
 */
const useDirectSurvey = ({ surveyId, aeId, aeCId, pId, aeT, directSurveyKey }: GetDirectSurveyParams) => {
  return useSWRImmutable({ key: SwrKeys.AeDirectSurvey, surveyId, aeId, aeCId, pId, aeT, directSurveyKey },
  async ({ surveyId, aeId, aeCId, pId, aeT, directSurveyKey }) => {
    const response = await getDirectSurvey({
      surveyId,
      aeId,
      aeCId,
      pId,
      aeT,
      directSurveyKey
    });

    return response.data;
  }, {
    errorRetryCount: 0
  });
};

export {
  useAutoEmailSurvey,
  useDirectSurvey
};
