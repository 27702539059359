import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { SelectFieldTypes } from 'Shared/components/design/select/Select';
import { LABEL_SMALL, PARAGRAPH } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type SelectProps = {
  fieldType: SelectFieldTypes;
}

function isError(prop: SelectFieldTypes): boolean {
  return prop === SelectFieldTypes.ERROR;
}

function getBackgroundColor(prop: SelectFieldTypes): string {
  switch(prop){
    case SelectFieldTypes.ERROR:
      return DESIGN_TOKENS.color.background.critical.light.value;
    default:
      return DESIGN_TOKENS.color.background.primary.value;
  }
}

function getLabelColor(prop: SelectFieldTypes): string {
  switch (prop) {
    case SelectFieldTypes.ERROR:
      return DESIGN_TOKENS.color.text.critical.value;
    default:
      return DESIGN_TOKENS.color.text.normal.value;
  }
}

export const SelectLabel = styled.div<SelectProps>`
  margin-bottom: 4px;
  color        : ${props => getLabelColor(props.fieldType)};
  ${LABEL_SMALL.DEFAULT}
`;

export const SelectInput = styled.select<SelectProps>`
  width              : 100%;
  border             : 1px solid ${DESIGN_TOKENS.color.border.light.value};
  border-color       : ${props => isError(props.fieldType) ? DESIGN_TOKENS.color.border.critical.value : DESIGN_TOKENS.color.border.light.value};
  padding            : 10px 12px;
  color              : ${DESIGN_TOKENS.color.text.normal.value};
  border-radius      : 2px;
  background-color   : ${props => getBackgroundColor(props.fieldType)};
  outline-color      : ${DESIGN_TOKENS.color.border.info.light.value};
  appearance         : none;
  background-image   : url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat  : no-repeat;
  background-position: right 15px center;
  background-size    : 15px;
  ${PARAGRAPH.DEFAULT}

  &:hover, &:active, &:focus {
    border-color: ${ DESIGN_TOKENS.color.primitive.gray['600'].value };
  }

  &:focus + div {
    color: ${DESIGN_TOKENS.color.text.normal.value};
  }
`;

export const SelectHelper = styled.div`
  margin-top: 4px;
  width     : 100%;
  color     : ${DESIGN_TOKENS.color.text.secondary.value};
  ${LABEL_SMALL.DEFAULT}
`;