import { SurveyCallbackStatuses } from 'Pages/surveyCallback/models/SurveyCallbackModels';
import { APP_TYPES, LOCAL_STORAGE_IDS } from 'Shared/constants';
import { isMobile } from 'Shared/services/deviceService';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';
import { EligibleAppType } from 'Shared/services/pis/types/installationFlowCheckTypes';

/**
 * Checks if Desktop app is available based device device eligibility payload from pis microservice (usually retrieved via useDeviceEligibility hook)
 * @param deviceDataFromPis
 * @returns
 */
export const isSteDesktopAppAvailable = (deviceDataFromPis: {availableOptions: EligibleAppType[]}) => {
  return deviceDataFromPis.availableOptions.some(option => option.type === APP_TYPES.DESKTOP);
};

/**
 * Checks if Extension is available based device device eligibility payload from pis microservice (usually retrieved via useDeviceEligibility hook)
 * @param deviceDataFromPis
 * @returns
 */
export const isSteExtensionAvailable = (deviceDataFromPis: {availableOptions: EligibleAppType[]}) => {
  return deviceDataFromPis.availableOptions.some(option => option.type === APP_TYPES.EXTENSION);
};

/**
 * Checks if the Surf to Earn browser extension is installed
 * @returns {boolean}
 */
export const isSTEExtensionInstalled = (): boolean => {
  return typeof window.sjPulseInstalled !== 'undefined';
};

/**
 * Checks if the Surf to Earn desktop app is installed
 * @returns {boolean}
 */
export const isSTEDesktopInstalled = (): boolean => {
  return typeof window.sjPulseDesktopInstalled !== 'undefined';
};

/**
 * Checks whether is desktop app or extension already installed
 * @returns
 */
export const isAnySteInstalled = (): boolean => {
  return isSTEExtensionInstalled() || isSTEDesktopInstalled();
};

/**
 * Checks if the Surf to Earn is available for the user:
 * returns true if the user is on desktop and STE is not already installed and the passive metering is enabled
 * @returns
 */
export const isSteAvailableForUser = (): boolean => {
  return !isMobile() && !isAnySteInstalled() && !!process.env.PASSIVE_METERING_ENABLED;
};

/**
 * Checks if the Surf to Earn is available for the user:
 * returns true if the user is on desktop and STE is not already installed and the passive metering is enabled
 * @returns
 */
export const isSteDesktopAvailableForUser = (): boolean => {
  return !isMobile() && !isSTEDesktopInstalled() && !!process.env.PASSIVE_METERING_ENABLED;
};

/**
 * Returns the Survey Completion Status from local storage
 */
export const getSurveyCompletionStatus = () => {
  return getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_COMPLETION_STATUS, true);
};

/**
 * Returns the Survey Completion status Setter
 */
export const setSurveyCompletionStatus = (updatedStatus: { completed: number; disqualified: number; }) => {
  setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_COMPLETION_STATUS, updatedStatus, true);
};

/**
 * Returns the Last Survey Callback Status from local storage
 */
export const getLastSurveyCallbackStatus = () => {
  return getLocalStorage(LOCAL_STORAGE_IDS.LAST_SURVEY_CALLBACK_STATUS, true);
};

/**
 * Returns the Last Survey Callback Status Setter
 */
export const setLastSurveyCallbackStatus = (callbackStatus: number) => {
  setLocalStorage(LOCAL_STORAGE_IDS.LAST_SURVEY_CALLBACK_STATUS, callbackStatus, true);
};

/**
 * Deletes the Last Survey Callback Status
 */
export const deleteLastSurveyCallbackStatus = () => {
  deleteLocalStorage(LOCAL_STORAGE_IDS.LAST_SURVEY_CALLBACK_STATUS);
};

/**
 * Set the survey completion status depending on callbackStatus
 */
export const checkAndSetSurveyCallbackStatus = (callbackStatus: number | null) => {
  if (!isSteAvailableForUser()) {
    return null;
  }

  if (callbackStatus !== null) {
    const surveyCompletionStatus: { completed: number, disqualified: number } | null = getSurveyCompletionStatus();
    let updatedStatus;

    if (surveyCompletionStatus) {
      if (callbackStatus === SurveyCallbackStatuses.COMPLETE && surveyCompletionStatus.completed <= 2) {
        updatedStatus = { ...surveyCompletionStatus, completed: surveyCompletionStatus.completed + 1 };
      } else if (callbackStatus !== SurveyCallbackStatuses.COMPLETE && surveyCompletionStatus.disqualified <= 2) {
        updatedStatus = { ...surveyCompletionStatus, disqualified: surveyCompletionStatus.disqualified + 1 };
      }
    } else {
      updatedStatus = callbackStatus === SurveyCallbackStatuses.COMPLETE ? { completed: 1, disqualified: 0 } : { completed: 0, disqualified: 1 };
    }

    if (updatedStatus) {
      setSurveyCompletionStatus(updatedStatus);
      setLastSurveyCallbackStatus(callbackStatus);
    }
  }
};
