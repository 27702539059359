import { logError } from 'App/services/coralogixService';
import { LOCAL_STORAGE_IDS } from "Shared/constants";
import { VERPutRelevantIdInfoRequest, VERPutRelevantIdInfoResponse, VERUserVerificationStatus } from "Shared/models/swagger/ver";
import { ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES } from 'Shared/services/analytics/constants';
import { analyticsTrackRelevantIdEnd } from 'Shared/services/analytics/events/relevantIdEnd';
import { analyticsTrackRelevantIdStart } from 'Shared/services/analytics/events/relevantIdStart';
import { APIResponse } from "Shared/services/apiInterface";
import { getLocalStorage, setLocalStorage } from "Shared/services/localStorageService";
import { RelevantIdCache } from "Shared/services/localStorageTypes";
import { MobileAppEvent, sendAppMessage } from 'Shared/services/mobileAppMessenger';
import { getUserVerificationStatus, putRelevantIdVerificationStatus } from "Shared/services/ver/api/verificationApi";

enum RelevantIdStatus {
  NONE     = 'NONE',
  REQUIRED = 'REQUIRED',
  DONE     = 'DONE'
}

type RelevantIdFormInput = {
  name      : string,
  value    ?: string,
  answerKey?: string
}

let startTimestamp = 0;

/**
 * Extracts the RelevantID data from the form
 * and sends it to the verification microservice.
 */
const saveRelevantIdData = async (): Promise<void> => {
  const relevantIdData: any = {};

  getRelevantIdInputs().forEach((formInput: RelevantIdFormInput) => {
    if (!formInput.answerKey) {
      return;
    }

    const formInputElement: HTMLElement | null = document.getElementById(formInput.name);

    if (!formInputElement) {
      return;
    }

    relevantIdData[formInput.answerKey] = (<HTMLInputElement>formInputElement).value;
  });

  try {
    const relevantIdDataPayload: VERPutRelevantIdInfoRequest  = <VERPutRelevantIdInfoRequest>relevantIdData;
    const { data }: APIResponse<VERPutRelevantIdInfoResponse> = await putRelevantIdVerificationStatus(relevantIdDataPayload);
    const updatedRelevantIdCache                              = getRelevantIdFromLocalStorage() || {} as RelevantIdCache;
    updatedRelevantIdCache.status                             = data.rvidStatus;

    // Store RelevantID status
    setRelevantIdToLocalStorage(updatedRelevantIdCache);
    analyticsTrackRelevantIdEnd(ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES.success, startTimestamp);
  } catch (error) {
    analyticsTrackRelevantIdEnd(ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES.save_error, startTimestamp);
    logError('getRelevantIdInputs', error);
  }
};

/**
 * Returns the RelevantID from local storage
 */
const getRelevantIdFromLocalStorage = () => {
  return getLocalStorage(LOCAL_STORAGE_IDS.RELEVANT_ID_STATUS, true);
};

/**
 * Store the RelevantID to local storage
 */
const setRelevantIdToLocalStorage = (updatedRelevantIdCache: RelevantIdCache): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.RELEVANT_ID_STATUS, updatedRelevantIdCache, true);
};

/**
 * Binds all the RelevantID window functions so that
 * it can process the RelevantID request.
 */
const bindRelevantIdWindowFunctions = () => {
  window.RVIDResponseComplete = function() {
    saveRelevantIdData();
  };

  window.RVIDNoResponse = function() {
    analyticsTrackRelevantIdEnd(ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES.no_response, startTimestamp);
  };
};

/**
 * Returns the list of inputs that are needed
 * per the documentation: https://jira.disqotech.com/browse/SJRET-1198
 * @param userId
 * @param clientId
 * @param surveyId
 * @param userCountryCode
 */
const getRelevantIdInputs = (userId = '', userCountryCode = ''): RelevantIdFormInput[] => {
  return [{
    name: 'wm',
    value: '0',
  },{
    name: 'ClientID',
    value: process.env.RELEVANT_ID_CLIENT_ID,
  },{
    name: 'RelevantID',
  },{
    name: 'PanelistID',
    value: userId,
  },{
    name: 'SurveyID',
    value: process.env.RELEVANT_ID_SURVEY_ID,
  },{
    name: 'GeoCodes',
    value: `1,${userCountryCode}`
  },{
    name: 'CID',
    value: '0',
  },{
    name: 'TID',
  },{
    name: 'TimePeriod',
  },{
    name: 'RVIDCompleted',
    value: '0',
  },{
    name: 'RequestCSOData',
    value: '0',
  },{
    name: 'RVid',
    answerKey: 'rvid',
  },{
    name: 'isNew',
    answerKey: 'isNew',
  },{
    name: 'Score',
    answerKey: 'score',
  },{
    name: 'GeoIP',
    answerKey: 'geoIP',
  },{
    name: 'Country',
    answerKey: 'country',
  },{
    name: 'OldId',
    answerKey: 'oldId',
  },{
    name: 'OldIDDate',
    answerKey: 'oldIdDate',
  },{
    name: 'CompleteFlag',
    answerKey: 'completeFlag',
  },{
    name: 'CompleteDate',
    answerKey: 'completeDate',
  },{
    name: 'ScreenoutFlag',
    answerKey: 'screenoutFlag',
  },{
    name: 'ScreenoutDate',
    answerKey: 'screenoutDate',
  },{
    name: 'TotalCompletes',
    answerKey: 'totalCompletes',
  },{
    name: 'Domain',
    answerKey: 'domain',
  },{
    name: 'FraudProfileScore',
    answerKey: 'fraudProfileScore',
  },{
    name: 'FPF1',
    answerKey: 'fpf1',
  },{
    name: 'FPF2',
    answerKey: 'fpf2',
  },{
    name: 'FPF3',
    answerKey: 'fpf3',
  },{
    name: 'FPF4',
    answerKey: 'fpf4',
  },{
    name: 'FPF5',
    answerKey: 'fpf5',
  },{
    name: 'FPF6',
    answerKey: 'fpf6',
  },{
    name: 'FPF7',
    answerKey: 'fpf7',
  },{
    name: 'FPF8',
    answerKey: 'fpf8',
  },{
    name: 'FPF9',
    answerKey: 'fpf9',
  },{
    name: 'FPF10',
    answerKey: 'fpf10',
  },{
    name: 'FPF11',
    answerKey: 'fpf11',
  },{
    name: 'FPF12',
    answerKey: 'fpf12',
  },{
    name: 'FPF13',
    answerKey: 'fpf13',
  },{
    name: 'FPF14',
    answerKey: 'fpf14',
  },{
    name: 'FraudFlagCount',
    answerKey: 'fraudFlagCount',
  },{
    name: 'RVIDHash2',
    answerKey: 'rvidHash2',
  },{
    name: 'isMobile',
    answerKey: 'isMobile',
  },{
    name: 'CIDFlag',
    answerKey: 'cidFlag',
  },{
    name: 'TIDFlag',
    answerKey: 'tidFlag',
  }];
};

/**
 * Sends the RelevantID end event to the mobile app
 * @returns void
 */
export const sendRelevantIdEndEvent = () => {
  sendAppMessage({
    event : MobileAppEvent.endRvId,
  });
};

/**
 * Creates the RelevantID form and pre-fills the data
 * as needed for the verification to be done.
 * @param userId
 * @param clientId
 * @param surveyId
 * @param userCountryCode
 */
const createRelevantIdForm = (userId: string, userCountryCode: string): void => {
  const relevantIdFormId = 'rid-form';

  if (document.getElementById(relevantIdFormId)) {
    return;
  }

  const relevantIdForm: HTMLFormElement = document.createElement('form');
  relevantIdForm.style.display          = 'none';
  relevantIdForm.id                     = relevantIdFormId;

  getRelevantIdInputs(userId, userCountryCode).forEach((formInput: RelevantIdFormInput) => {
    const input = document.createElement('input');
    input.type = 'hidden';

    if (typeof formInput.name !== 'undefined') {
      input.setAttribute('name', formInput.name);
      input.name = formInput.name;
      input.setAttribute('id', formInput.name);
      input.id = formInput.name;
    }

    if (typeof formInput.value !== 'undefined') {
      input.setAttribute('value', formInput.value);
      input.value = formInput.value;
    }

    relevantIdForm.appendChild(input);
  });

  document.body.appendChild(relevantIdForm);
};

/**
 * Create the RelevantID script tag
 */
const createRelevantIdScript = (): void => {
  const relevantIdScriptId = 'rid-script';

  if (document.getElementById(relevantIdScriptId)) {
    return;
  }

  // Create the script tag, set the appropriate attributes
  const scriptElement  = document.createElement('script');
  scriptElement.id     = relevantIdScriptId;
  scriptElement.onload = function() {
    window.callRVIDNow(); // This function is defined by the RelevantID JS library

    sendRelevantIdEndEvent();
  };

  scriptElement.onerror = function() {
    analyticsTrackRelevantIdEnd(ANALYTICS_RELEVANT_ID_RESPONSE_MESSAGES.load_error, startTimestamp);
  };

  scriptElement.src = process.env.RELEVANT_ID_SCRIPT_SRC || '';
  const script = document.getElementsByTagName('script')[0];

  // Insert the script tag into the document
  if (script.parentNode) {
    script.parentNode.insertBefore(scriptElement, script);
  }
};

/**
 * Starts the RelevantID process
 * @param userId
 * @param userCountryCode
 */
const initializeRelevantId = (userId: string, userCountryCode: string): void => {
  startTimestamp = Date.now();
  analyticsTrackRelevantIdStart();
  bindRelevantIdWindowFunctions();
  createRelevantIdForm(userId, userCountryCode);
  createRelevantIdScript();
};

/**
 * Checks if the RelevantID can be triggered
 * @returns boolean
 */
export const canTriggerRelevantIdCheck = (): boolean => {
  const TEN_MINUTES  = 10 * 60 * 1000; // 10 minutes in milliseconds

  // Get the last run timestamp from localStorage
  const lastRunTimestamp = getLocalStorage(LOCAL_STORAGE_IDS.RELEVANT_ID_CHECK_TIMESTAMP, true);
  const now              = Date.now();

  if (!lastRunTimestamp) {
    // Save the new timestamp
    setLocalStorage(LOCAL_STORAGE_IDS.RELEVANT_ID_CHECK_TIMESTAMP, now, true);
    return true;
  }

  // Check if timestamp is older than 10 minutes
  if (now - lastRunTimestamp > TEN_MINUTES) {
    // Save the new timestamp
    setLocalStorage(LOCAL_STORAGE_IDS.RELEVANT_ID_CHECK_TIMESTAMP, now, true);
    return true;
  } else {
    return false;
  }
};

/**
 * Conditionally triggers the Relevant ID
 */
export const triggerRelevantIdCheck = async (userId: string, userCountryCode: string): Promise<void> => {
  // Don't run the check if RelevantID is disabled or incorrect attributes are passed
  if (
    !userId ||
    !userCountryCode ||
    !process.env.RELEVANT_ID_ENABLED ||
    !process.env.RELEVANT_ID_SCRIPT_SRC ||
    !process.env.RELEVANT_ID_CLIENT_ID ||
    !process.env.RELEVANT_ID_SURVEY_ID
  ) {
    return;
  }

  // Load the frontend cached status of RelevantID
  const relevantIdCache = getRelevantIdFromLocalStorage();

  // Check if the cached data is for the current user
  if (relevantIdCache && relevantIdCache.userId == userId) {
    // Skip the RelevantID check if the user has already completed it
    if (relevantIdCache.status == RelevantIdStatus.DONE) {
      return;
    }

    // Skip the RelevantID check if it's not required
    if (relevantIdCache.status == RelevantIdStatus.NONE) {
      return;
    }
  }

  // Update the current state of Relevant ID
  // so that we don't make the same call twice.
  const updatedRelevantIdCache: RelevantIdCache = {
    userId    : userId,
    status    : relevantIdCache?.status || undefined,
  };

  // Store RelevantID status
  setRelevantIdToLocalStorage(updatedRelevantIdCache);

  // Get the verification status from the API
  // and initialize the RelevantID process if needed
  try {
    const { data }: APIResponse<VERUserVerificationStatus> = await getUserVerificationStatus();
    const updatedRelevantIdCache: RelevantIdCache = {
      userId    : userId,
      status    : data.rvidStatus,
    };

    // Store RelevantID status
    setRelevantIdToLocalStorage(updatedRelevantIdCache);

    if (data.rvidStatus == RelevantIdStatus.REQUIRED) {
      initializeRelevantId(userId, userCountryCode);
    }
  } catch (error) {
    logError('triggerRelevantIdCheck', error);
  }
};
