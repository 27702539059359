import { getUserId } from 'App/services/idpTokenService';
import { REPDATA_PROVIDER_ID } from 'Pages/surveyExperience/constants';
import { API, APIResponse } from 'Shared/services/apiInterface';
import { getUserCacheData, setUserCacheData, UserSessionCacheKeys } from "Shared/services/sessionCacheService";
import { UspSurveysModel } from "Shared/services/usp/models/UspSurveysModel";

enum REPDATA_VERIFIED_STATUS {
  VERIFIED     = 0,
  NOT_VERIFIED = 1
}

type ResponseData = {
  flag: REPDATA_VERIFIED_STATUS;
};

type RepdataVerification = {
  id        : string,
  isVerified: boolean
}

const repDataPromiseCache: Record<string, Promise<void>> = {};

/**
 * Filters out non verified repdata surveys and verifies those
 * that haven't been verified yet and stores it in the session cache.
 */
const filterVerifyRepData = (surveys: UspSurveysModel[]): UspSurveysModel[] => {
  const getVerifiedSurveyList = () => getUserCacheData(UserSessionCacheKeys.REPDATA_SURVEY_VERIFICATION) ?? [];

  // Calls repdata to verify the survey
  const verifySurvey = (survey: UspSurveysModel) => {
    if (
      typeof survey.preflightUrl !== 'string' ||
      !survey.preflightUrl.startsWith('https://') ||
      !survey.id ||
      repDataPromiseCache[survey.id] !== undefined
    ) {
      return;
    }

    // Append "shrink=1" if it doesn't already exist in the preflightUrl
    const url = new URL(survey.preflightUrl);
    if (!url.searchParams.has('shrink')) {
      url.searchParams.append('shrink', '1');
    }

    // Add the user to the request so we can get it back in the response
    const userId = getUserId(true);
    if (!url.searchParams.has('sn_ud') && userId) {
      url.searchParams.append('sn_ud', userId);
    }

    repDataPromiseCache[survey.id] = API({
      method : 'GET',
      url    : url.toString(),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response: APIResponse) => {
      const data: ResponseData = response.data;

      // Store verification result in cache
      let verificationResult: RepdataVerification | undefined = undefined;

      if (data.flag === REPDATA_VERIFIED_STATUS.VERIFIED) {
        verificationResult = {
          id: survey.id,
          isVerified: true,
        };
      } else if (data.flag === REPDATA_VERIFIED_STATUS.NOT_VERIFIED) {
        verificationResult = {
          id: survey.id,
          isVerified: false,
        };
      }

      if (verificationResult) {
        const list = getVerifiedSurveyList();
        list.push(verificationResult);
        setUserCacheData(UserSessionCacheKeys.REPDATA_SURVEY_VERIFICATION, list);
      }

      // setUserCacheData(UserSessionCacheKeys.REPDATA_SURVEY_VERIFICATION, verifiedSurveyList);
    }).finally(() => {
      delete repDataPromiseCache[survey.id];
    });
  };

  return surveys.filter((survey: UspSurveysModel) => {
    // If the survey isn't repdata or doesn't have a preflight URL, return true
    if (survey.providerId !== REPDATA_PROVIDER_ID || !survey.preflightUrl) {
      return true;
    }

    // Check if we already have verified this survey
    const checkedSurvey = getVerifiedSurveyList()
      .find((verifiedSurvey) => verifiedSurvey.id === survey.id);

    if (!checkedSurvey) {
      verifySurvey(survey);
      return false;
    }

    return checkedSurvey.isVerified;
  });
};

export {
  filterVerifyRepData,
  RepdataVerification
};
