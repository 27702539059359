
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import LoadingCard from 'Pages/surveyExperience/components/surveyCard/LoadingCard';
import styled from 'styled-components';

export const ShadowLoadingCard = styled(LoadingCard)`
  box-shadow: ${DESIGN_TOKENS.shadow.md.value};

  :hover {
    box-shadow: ${DESIGN_TOKENS.shadow.md.value};
  }
`;
