import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { PARAGRAPH } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface RadioGroupProps {
  layoutContext: boolean;
}
interface RadioContainerProps {
  checked          : boolean;
  disabled         : boolean;
  isColorBackground: boolean;
}

interface ToggleProps {
  checked : boolean;
  disabled: boolean;
}

interface LabelProps {
  checked          ?: boolean;
  isColorBackground: boolean;
  disabled         : boolean;
}

function checkboxBackground(checked: boolean, disabled: boolean): string {
  switch (true) {
    case disabled && checked:
      return DESIGN_TOKENS.color.text.disabled.value;
    case checked && !disabled:
      return DESIGN_TOKENS.color.primitive.green['500'].value;
    case !checked && !disabled:
      return DESIGN_TOKENS.color.text.onSuccess.value;
  }

  return '';
}

function setCheckboxBorder(checked: boolean, disabled: boolean): string {
  switch (true) {
    case checked && disabled:
    case checked && !disabled:
      return 'none;';
    case !checked && disabled:
      return `2px solid ${DESIGN_TOKENS.color.text.disabled.value};`;
    case !checked && !disabled:
      return `2px solid ${DESIGN_TOKENS.color.primitive.gray['700'].value};`;
  }

  return '';
}

function setLabelColor(disabled: boolean, checked?: boolean): string {
  if (disabled) {
    return DESIGN_TOKENS.color.text.disabled.value;
  }
  if (checked) {
    return DESIGN_TOKENS.color.primitive.gray['900'].value;
  }
  return DESIGN_TOKENS.color.primitive.gray['800'].value;
}

export const RadioButtonWrapper = styled.div`
  display        : flex;
  justify-content: center;
  align-items    : center;
  position       : relative;
  margin         : 11px 12px 12px;
  max-width      : 22px;
  width          : 100%;
  height         : 22px;
`;

export const RadioButtonCircle = styled.div<ToggleProps>`
  position        : absolute;
  top             : 10%;
  width           : 22px;
  height          : 22px;
  border-radius   : 50%;
  background-color: white;
  cursor          : pointer;
  border          : ${props => setCheckboxBorder(props.checked, props.disabled)};
`;

export const RadioButtonInput = styled.input<ToggleProps>`
  opacity      : 0;
  z-index      : 1;
  border-radius: 50%;
  cursor       : pointer;

  &:checked + ${RadioButtonCircle} {
    background-color: ${props => checkboxBackground(props.checked, props.disabled)};
    width           : 22px;
    height          : 22px;
  }

  &:checked + ${RadioButtonCircle}::after {
    content         : "";
    width           : 6px;
    height          : 6px;
    border-radius   : 50%;
    background-color: ${DESIGN_TOKENS.color.text.onSuccess.value};
    position        : absolute;
    left            : 50%;
    top             : 50%;
    transform       : translate(-50%, -50%);
  }
`;

export const RadioContainer = styled.label<RadioContainerProps>`
  display      : flex;
  align-items  : flex-start;
  width        : auto;
  min-height   : 48px;
  border-radius: 8px;
  cursor       : ${props => props.disabled ? 'default' : 'pointer'};

  &:has(:focus-visible) {
    z-index: 1;
    outline: 2px solid ${DESIGN_TOKENS.color.primitive.gray['800'].value};
  }
`;

export const Label = styled.p<LabelProps>`
  ${PARAGRAPH.DEFAULT};
  flex-grow: 1;
  color : ${props => setLabelColor(props.disabled, props.checked)};
  margin: 6px 16px 6px 0;
  width: calc(100% - 16px);
  word-wrap: break-word;
  user-select: none;
  display: flex;
  align-items: center;
  min-height: 36px;
`;

export const GroupContainer = styled.div<RadioGroupProps>`
  display       : flex;
  flex-direction: ${props => props.layoutContext ? 'row' : 'column'};
`;