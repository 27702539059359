import { LoginModalOpenEventProps } from 'Shared/components/design/loginModal/models/eventModels';
import { addEventListener } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * AddEventListener: MF_EVENTS.loginModal.open
 * @param callback
 */
export const addLoginModalOpenEventListener = (callback: (props: LoginModalOpenEventProps) => void) => {
  return addEventListener<LoginModalOpenEventProps>(MF_EVENTS.loginModal.open, callback);
};
