import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CAButtonWrapper, CAQuestionText, CAQuestionWrapper } from 'Pages/createAccount/components/question/style';
import { Button, ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import { ICON_IDS } from 'Shared/components/icons/constants';

interface Props {
  children     : ReactNode;
  onContinue   : () => void;
  question     : string;
  disableButton: boolean;
  buttonText?  : string;
  loading?     : boolean;
}

function CreateAccountQuestion (props: Props) {
  const { t } = useTranslation('createAccount');
  const {
    children,
    onContinue,
    question,
    disableButton,
    buttonText = t('CreateAccount--Question-next'),
    loading = false
  } = props;

  return (
    <CAQuestionWrapper
      onSubmit={(ev) => {
        ev.preventDefault();
        if (!loading && !disableButton) {
          onContinue();
        }
      }}
    >
      <CAQuestionText
        tabIndex = {0}
      >
        {question}
      </CAQuestionText>
      {children}
      <CAButtonWrapper>
        <Button
          size={ButtonSizes.LARGE}
          iconRight={ICON_IDS.ARROW_RIGHT}
          disabled={disableButton}
          isLoading={loading}
          interactable={!loading}
          width={ButtonWidth.FULL}
        >
          {buttonText}
        </Button>
      </CAButtonWrapper>
    </CAQuestionWrapper>
  );
}

export default CreateAccountQuestion;
