import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { ProgressBarSizes } from 'Shared/components/design/progressBar/ProgressBar';
import styled from 'styled-components';

type ProgressBackgroundProps = {
  size: ProgressBarSizes;
};

type ProgressFillProps = {
  size : ProgressBarSizes;
  width: number;
};

export const ProgressBackground = styled.div<ProgressBackgroundProps>`
  height          : ${props => props.size == ProgressBarSizes.SMALL ? '4px' : '8px'};
  background-color: ${DESIGN_TOKENS.color.background.tertiary.value};
  border-radius   : ${props => props.size == ProgressBarSizes.SMALL ? '0' : '50px'};
`;

export const ProgressFill = styled.div<ProgressFillProps>`
  height          : 100%;
  width           : ${props => props.width + '%'};
  background-color: ${DESIGN_TOKENS.color.text.success.value};
  border-radius   : ${props => props.size == ProgressBarSizes.SMALL ? '0' : '50px'};
  transition      : width 0.3s linear;
`;