import FacebookLogo from 'Shared/assets/images/icons/facebook.svg';
import GoogleLogo from 'Shared/assets/images/icons/google.svg';
import { ButtonProps } from 'Shared/components/design/button/Button';

// Test IDs
export const SSO_BUTTON_TEST_IDS = {
  CONTAINER: 'sso-button-test-id-container'
};

// Enums
export enum SSOProvider {
  GOOGLE   = 'google',
  FACEBOOK = 'facebook',
}

// Interfaces
export interface SSOButtonOptions {
  name      : string;
  color     : string;
  shadow?   : string;
  logoUri   : string,
  background: string;
}

export interface SSOButtonProps extends ButtonProps {
  provider: SSOProvider;
}

// Options
export const SSOButtonOptionsByProvider: Record<SSOProvider, SSOButtonOptions> = {
  [SSOProvider.GOOGLE]: {
    name      : 'Google',
    color     : '#757575',
    shadow    : '0px 1px 1px 0px rgba(0, 0, 0, 0.168)',
    logoUri   : GoogleLogo,
    background: '#FFFFFF',
  },
  [SSOProvider.FACEBOOK]: {
    name      : 'Facebook',
    color     : '#FFFFFF',
    logoUri   : FacebookLogo,
    background: '#1877F2',
  }
};