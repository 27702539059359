import React from 'react';
import {
  PromoProgressBar,
  PromoProgressContainer,
  PromoProgressDot,
  PromoProgressDotContainer,
  PromoProgressFill,
  PromoProgressLabel,
  PromoProgressRemainder,
  PromoProgressStartEndText
} from 'Shared/components/design/promoProgress/style';

export type PromoProgressProps = {
  progress: number;
  animate?: boolean;
  startText?: string;
  endText?: string;
  progressText?: string;
  onAnimationEnd?: () => void;
}

export const PromoProgress: React.FC<PromoProgressProps> = ({
  progress,
  animate = false,
  startText,
  endText,
  progressText,
  onAnimationEnd,
}) => {
  progress = Math.max(0, Math.min(progress, 1));

  return (
    <PromoProgressContainer>
      {startText && <PromoProgressStartEndText>{startText}</PromoProgressStartEndText>}
      <PromoProgressBar>
        <PromoProgressFill
          progress={progress}
          animate={animate}
          onAnimationEnd={() => onAnimationEnd?.()}
        />
        <PromoProgressDotContainer>
          <PromoProgressDot />
          {progressText && <PromoProgressLabel>{progressText}</PromoProgressLabel>}
        </PromoProgressDotContainer>
        <PromoProgressRemainder
          progress={progress}
          animate={animate}
        />
      </PromoProgressBar>
      {endText && <PromoProgressStartEndText>{endText}</PromoProgressStartEndText>}
    </PromoProgressContainer>
  );
};
