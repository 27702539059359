import React, { ReactNode, useContext, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { NOTIFICATION_BANNER_TEST_ID } from 'Shared/components/design/notificationsBanner/constants';
import { BannerWrapper, ButtonContainer, ContentContainer, IconContainer } from 'Shared/components/design/notificationsBanner/style';
import { ICON_IDS, IconId } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { BannerHeight } from 'Shared/context/bannerHeight';

export enum BannerContext {
  DEFAULT,
  WARNING,
}

type NotificationsBannerProps = {
  children           ?: ReactNode | ReactNode[];
  buttonText         ?: string;
  isButtonVisible    ?: boolean;
  buttonCallback     ?: () => void;
  bannerIcon         ?: IconId;
  isBannerIconVisible?: boolean;
  bannerContext      ?: BannerContext;
}

/**
 * Member Notifications Banner
 * This component is using portals to render the banner outside of the application root (See index.html)
 */
function NotificationsBanner({
  children,
  buttonText,
  isButtonVisible = false,
  buttonCallback,
  bannerIcon,
  isBannerIconVisible = true,
  bannerContext = BannerContext.DEFAULT
}: NotificationsBannerProps) {
  const bannerRoot = document.getElementById('notifications-banner-root');
  const [, setBannerHeight] = useContext(BannerHeight);

  /**
   * Conditionally renders the banner button
   * @returns
   */
  const renderButton = (): JSX.Element | undefined => {
    if (isButtonVisible) {
      return (
        <ButtonContainer onClick={buttonCallback}> { buttonText }</ButtonContainer>
      );
    }
  };

  /**
   * Conditionally renders the banner icon
   * @returns
   */
  const renderIcon = (): JSX.Element | undefined => {
    if (isBannerIconVisible) {
      return (
      <IconContainer>
        <Icon iconId={bannerIcon || ICON_IDS.WARNING_FILL} height={24} width={24} />
      </IconContainer>
      );
    }
  };

  /*
   * Renders the banner with all of its children
   */
  const renderBanner = (): JSX.Element => {
    return(
      <BannerWrapper data-testid = {NOTIFICATION_BANNER_TEST_ID}>
        <ContentContainer bannerContext = {bannerContext}>
          { renderIcon() }
          { children }
          { renderButton() }
        </ContentContainer>
      </BannerWrapper>
    );
  };

  useLayoutEffect(() => {
    function changeListener() {
      if (bannerRoot) {
        setBannerHeight(bannerRoot.clientHeight);
      } else {
        setBannerHeight(0);
      }
    }
    
    changeListener();

    if (bannerRoot) {
      bannerRoot.addEventListener('resize', changeListener);
      return () => {
        setBannerHeight(0);
        bannerRoot.removeEventListener('resize', changeListener);
      };
    }
  }, [bannerRoot, setBannerHeight]);

  if (!bannerRoot) {
    return <></>;
  }

  return createPortal(
    renderBanner(),
    bannerRoot
  );
}

export default NotificationsBanner;