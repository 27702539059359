import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { useFullHeight } from 'Shared/context/bannerHeight';
import { DEVICE_BREAKPOINT, PARAGRAPH } from "Shared/styles/designConstants";
import styled from "styled-components";

type MenuProps = {
  show: boolean;
}

type LinkProps = {
  isActive: boolean;
}

export const SideMenuContainer = styled.div<MenuProps>`
  display: none;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    visibility                 : ${props => props.show ? 'visible' : 'hidden' };
    transform                  : ${props => props.show ? 'translateX(0)' : 'translateX(-100%)'};
    transition                 : all 0.35s ease-out;
    display                    : flex;
    -webkit-tap-highlight-color: transparent;
    flex-direction             : column;
    background                 : ${DESIGN_TOKENS.color.background.primary.value};
    position                   : absolute;
    top                        : 0;
    left                       : 0;
    width                      : 336px;
    z-index                    : inherit;
    overflow-x                 : hidden;
    min-height                 : ${() => useFullHeight()};
  }
`;

export const ButtonContainer = styled.button`
  @media ${DEVICE_BREAKPOINT.maxMobile} {
    all           : unset;
    cursor        : pointer;
    display       : flex;
    flex-direction: row;

    svg {
      width : 24px;
      height: 24px;
      margin: 25px 33px;
    }
  }
`;

export const LinkContainer = styled.a<LinkProps>`
  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    margin        : 0 auto 8px;
    width         : 312px;
    height        : 56px;
    color         : ${$props => $props.isActive ? DESIGN_TOKENS.color.text.success.value : DESIGN_TOKENS.color.text.secondary.value};
    background    : ${$props => $props.isActive ? DESIGN_TOKENS.color.background.success.light.value : 'none'};
    cursor        : pointer;
    border-radius : 100px;
    ${PARAGRAPH.DEFAULT}


    svg {
      width       : 24px;
      height      : 24px;
      fill        : ${$props => $props.isActive ? DESIGN_TOKENS.color.text.success.value : DESIGN_TOKENS.color.text.secondary.value};
      margin-left : 18px;
      margin-right: 14px;
    }
  }
`;