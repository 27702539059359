import React from 'react';
import { INNER_CIRCLE_TEST_ID } from 'Shared/components/design/promoStepper/components/constants';
import {
  ActivitiesContainer,
  ActivitiesProgress,
  ActivityState,
  ActivityStateWrapper,
  InnerCircle,
  Line,
  StepNumber
} from 'Shared/components/design/promoStepper/components/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { PROMOStep } from 'Shared/models/swagger/promo';

interface ActivityProgressProps {
  steps: PROMOStep[];
}

/**
 * Determines which steps should have an inner circle based on the completion status.
 */
function determineCurrentPendingComplete(steps: PROMOStep[]): (PROMOStep & { hasInnerCircle: boolean })[] {
  return steps.map((step, index) => {
    const isFirstStep = index === 0;
    const isLastStep  = index === steps.length - 1;

    const hasInnerCircle =
      // inner circle for first step if it is not completed
      (isFirstStep && step.completedAt == null) ||
      // inner circle for step following a completed step
      (index > 0 && steps[index - 1].completedAt != null) ||
      // inner circle for last step if it has a completed ts
      (isLastStep && step.completedAt != null);

    return {
      ...step,
      hasInnerCircle,
    };
  });
}

/**
 * Component that renders the progress of the activities
 * @param param0
 * @returns
 */
function ActivityProgress({ steps }: ActivityProgressProps): JSX.Element {
  const updatedSteps = determineCurrentPendingComplete(steps);

  return (
    <ActivitiesContainer>
      <ActivitiesProgress>
        {updatedSteps.map((step, index) => (
          <React.Fragment key={index}>
            <ActivityStateWrapper>
              <ActivityState isCompleted={!!step.completedAt}>
                {step.completedAt && (
                  <Icon
                    iconId={ICON_IDS.CHECKMARK}
                    height={18}
                    width={18}
                    color="white"
                  />
                )}
                {step.hasInnerCircle && <InnerCircle data-testid={INNER_CIRCLE_TEST_ID}/>}
                <StepNumber>{step.value}</StepNumber>
              </ActivityState>
            </ActivityStateWrapper>
            {index < steps.length - 1 && <Line />}
          </React.Fragment>
        ))}
      </ActivitiesProgress>
    </ActivitiesContainer>
  );
}

export default ActivityProgress;
