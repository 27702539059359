import { useCallback,useReducer } from 'react';

type ReducerModel = {
  maxSlides  : number;
  activeSlide: number;
};

type UsePopupModel = {
  activeSlide  : number;
  nextSlide    : () => void;
  previousSlide: () => void;
  forceSlide   : (slideNumber: number) => void;
  setMaxSlides : (maxSlideNumber: number) => void;
  reset        : () => void;
};

const REDUCER_ACTIONS = {
  nextSlide    : 'next',
  previousSlide: 'previous',
  forceSlide   : 'force',
  setMaxSlide  : 'setMax',
  reset        : 'reset',
};

/**
 * Popup reducer for returning correct state number based on action
 * @param state - state of reducer
 * @param action - used to indicate which action to take and if there's an input slide number
 * @param action.type - which action to take
 * @param action.inputNumber - used primarily for `force` or `setMax`
 */
function reducer(state: ReducerModel, action: {type: string; inputNumber?:number}): ReducerModel {
  switch (action.type) {
    // NEXT SLIDE
    case REDUCER_ACTIONS.nextSlide:
      if (state.activeSlide + 1 >= state.maxSlides) {
        return {
          maxSlides  : state.maxSlides,
          activeSlide: state.activeSlide,
        };
      } else {
        return {
          maxSlides  : state.maxSlides,
          activeSlide: state.activeSlide + 1,
        };
      }
    // PREVIOUS SLIDE
    case REDUCER_ACTIONS.previousSlide:
      if (state.activeSlide - 1 < 0) {
        return {
          maxSlides  : state.maxSlides,
          activeSlide: state.activeSlide,
        };
      } else {
        return {
          maxSlides  : state.maxSlides,
          activeSlide: state.activeSlide - 1,
        };
      }
    // FORCE SLIDE NUMBER
    case REDUCER_ACTIONS.forceSlide:
      if (action.inputNumber === undefined || action.inputNumber > state.maxSlides || action.inputNumber < 0) {
        return state;
      } else {
        return {
          maxSlides  : state.maxSlides,
          activeSlide: action.inputNumber,
        };
      }
    // SET MAX SLIDES NUMBER (used for dynamically setting the max number of slides)
    case REDUCER_ACTIONS.setMaxSlide:
      return {
        maxSlides  : action.inputNumber || 1,
        activeSlide: state.activeSlide,
      };
    // RESET STATE (reset activeSlide to 0)
    case REDUCER_ACTIONS.reset:
      return {
        maxSlides  : 0,
        activeSlide: 0
      };
    default:
      return state;
  }
}

/**
 * Custom hook for controlling which slide a popup flow is on
 *
 * @param numberOfSlides in particular popup flow
 * @returns [ActiveState, DispatchFunction]
 */
function usePopup(numberOfSlides: number): UsePopupModel {
  const initialState: ReducerModel = {
    maxSlides  : numberOfSlides,
    activeSlide: 0
  };

  const [{activeSlide}, dispatch] = useReducer(reducer, initialState);

  /**
   * Increment slide number
   * NOTE: had to use useCallback here otherwise using these functions in useEffect would trigger infinite renders
   */
  const nextSlide = useCallback(() => {
    dispatch({type: REDUCER_ACTIONS.nextSlide});
  }, []);

  /**
   * Decrement slide number
   * NOTE: had to use useCallback here otherwise using these functions in useEffect would trigger infinite renders
   */
  const previousSlide = useCallback(() => {
    dispatch({type: REDUCER_ACTIONS.previousSlide});
  }, []);

  /**
   * Force a particular slide number
   * NOTE: had to use useCallback here otherwise using these functions in useEffect would trigger infinite renders
   * @param slideNumber
   */
  const forceSlide = useCallback((slideNumber: number) => {
    dispatch({
      type       : REDUCER_ACTIONS.forceSlide,
      inputNumber: slideNumber
    });
  }, []);

  /**
   * Set the MAX slide number for the reducer
   * NOTE: had to use useCallback here otherwise using these functions in useEffect would trigger infinite renders
   * @param maxSlides
   */
  const setMaxSlides = useCallback((maxSlides: number) => {
    dispatch({
      type       : REDUCER_ACTIONS.setMaxSlide,
      inputNumber: maxSlides
    });
  }, []);

  /**
   * Reset the activeSlide to 0 without modifying maxSlides
   */
  const reset = useCallback(() => {
    dispatch({type: REDUCER_ACTIONS.reset});
  }, []);

  return { activeSlide, nextSlide, previousSlide, forceSlide, setMaxSlides, reset };
}

export default usePopup;
