import { LABEL_LARGE } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const QuestionSubTitle = styled.h2`
  ${LABEL_LARGE.DEFAULT}
  padding: 25px 0 12px;
`;

export const ChildQuestionContainer = styled.h2`
  & + & {
    padding-top: 12px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;