import React from 'react';
import { ICON_IDS, ICON_TEST_ID, IconId } from 'Shared/components/icons/constants';
import icons from 'Shared/components/icons/icons.svg';
import { IconSVG } from 'Shared/components/icons/style';

const iconPath = typeof icons === 'string' ? icons : icons['src'];

type IconProps = {
  iconId    : IconId;
  width?    : number;
  height?   : number;
  color?    : string;
  testId?   : string;
  className?: string;
  clickable?: boolean;
  onClick?  : () => void;
};

/**
 * Generates path to individual svg in Icons.svg
 * @param iconId
 */
const generateIconPath = (iconId: string): string => {
  return iconPath + "#" + iconId;
};

/**
 * React component which renders individual icons from Icons sprite
 */
function Icon({ iconId, width, height, color, testId, className, onClick, clickable = false }: IconProps): JSX.Element {
  // If the value passed in the icon is not one
  // of the supported icons then throw an error
  if (!Object.values(ICON_IDS).includes(iconId)) {
    throw new Error(`Icon Error: Icon ${iconId} not found in icon library`);
  }

  return (
    <IconSVG data-testid={testId || ICON_TEST_ID} className={className} width={width} height={height} fill={color} onClick={ () => onClick && onClick() } clickable={ clickable || !!onClick }>
      <use href={generateIconPath(iconId)}/>
    </IconSVG>
  );
}

export default Icon;
