import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import {
  BackButton,
  EmailField,
  ModalForm,
  ModalHeader,
  ModalWrapper,
  SubmitButton,
} from 'Shared/components/design/loginModal/components/forgotPasswordForm/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { useSendResetPassword } from 'Shared/services/userActivation/api/idpApiService';

interface ForgotPasswordFormProps {
  show       : boolean;
  goBack     : () => void;
  goToConfirm: (email: string) => void;
}

/**
 * Renders PartialLoginModal component
 */
const ForgotPasswordForm = ({ show, goBack, goToConfirm }: ForgotPasswordFormProps) => {
  const { t }                 = useTranslation('app');
  const [email, setEmail]     = useState('');
  const [isValid, setIsValid] = useState(false);
  const formRef               = useRef<HTMLFormElement>(null);
  const inputRef              = useRef<any>(null);
  const {
    isMutating: isSubmitting,
    trigger: resetPassword
  } = useSendResetPassword();

  async function postData () {
    if (!isValid) {
      return;
    }

    try {
      await resetPassword(email);
    } finally {
      goToConfirm(email);
    }
  }

  function onSubmit (event: FormEvent) {
    event.preventDefault();
    postData();
  }

  /**
   * Sets whether the form is valid every time `email` changes
   */
  useEffect(() => {
    (async () => {
      // Wait for the email input to be fully initialized
      let done = false;
      while (!done) {
        done = await inputRef.current?.updateComplete ?? true;
      }

      // Set isValid based on whether the form is valid
      setIsValid(formRef.current?.checkValidity() ?? false);
    })();
  }, [email, formRef, inputRef, setIsValid]);

  return (
    <ModalWrapper data-testid='modal-forgot-password' show={show}>
      <ModalHeader>
        <BackButton type='button' onClick={() => goBack()}>
          <Icon iconId={ICON_IDS.ARROW_LEFT} width={24} height={24} />
        </BackButton>
        {t('LoginModal--forgotPassword-title')}
      </ModalHeader>
      <ModalForm onSubmit={onSubmit} ref={formRef}>
        <p>
          {t('LoginModal--forgotPassword-explain-1')}
        </p>
        <p>
          {t('LoginModal--forgotPassword-explain-2')}
        </p>
        <EmailField
          ref        = {inputRef}
          type       = 'email'
          label      = {t('LoginModal--forgotPassword-label')}
          value      = {email}
          onVdsInput = {(event: KeyboardEvent<HTMLInputElement>) => {
            setEmail(event.currentTarget.value);
          }}
          required
        />
        <SubmitButton
          size         = {ButtonSizes.LARGE}
          width        = {ButtonWidth.FULL}
          onClick      = {postData}
          isLoading    = {isSubmitting}
          interactable = {!isSubmitting}
          disabled     = {!isValid}
        >
          {t('LoginModal--forgotPassword-submit')}
        </SubmitButton>
      </ModalForm>
    </ModalWrapper>
  );
};

export default ForgotPasswordForm;
