import React from 'react';
import { LabelRoot } from 'Shared/components/design/label/style';

export const LabelVariantType = {
  positive: 'positive',
  negative: 'negative',
  neutral : 'neutral',
  notice  : 'notice',
  info    : 'info'
} as const;

interface LabelProps {
  children: React.ReactNode;
  variant : keyof typeof LabelVariantType;
  onClick?: () => void;
}

/**
 * Label component.
 *
 * @param {LabelProps} props - Props for the Label component.
 * @returns {JSX.Element} The Label component.
 */
const Label = ({ children, variant, onClick }: LabelProps): JSX.Element => {
  return (
    <LabelRoot
      variant   = {variant}
      clickable = {!!onClick}
      onClick   = {onClick}
    >
      {children}
    </LabelRoot>
  );
};

export default Label;