import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type QrContainerProps = {
  inverse?: boolean;
}

type DownloadCtaTitleProps = {
  titleMaxWidth?: number
}

const DARK_BLUE = '#080345';

export const QrContainer = styled.div<QrContainerProps>`
  display        : flex;
  gap            : 25px;
  flex-direction : ${props => props.inverse ? 'row-reverse' : 'row' };
  justify-content: center;
`;

export const DownloadCtaContainer = styled.div`
  display       : flex;
  flex-direction: column;
  gap           : 2px;
`;
export const QrCodeContainer = styled.div`
  border         : 1px solid ${DESIGN_TOKENS.color.primitive.green['500'].value};
  border-radius  : 4px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 64px;
  height         : 64px;
  padding        : 3px;
  background     : ${DESIGN_TOKENS.color.primitive.white.value};
`;
export const QrCodeImage = styled.img`
  width: 100%;
`;
export const MobileAppBadgeContainer = styled.div`
  display        : flex;
  align-items    : center;
  justify-content: center;
`;
export const AppDownloadBadgeButton = styled.button`
  display        : flex;
  align-items    : center;
  justify-content: center;
  background     : none;
  border         : none;
  width          : 120px;
  height         : 40px;
`;
export const AppDownloadBadge = styled.img`
  max-width : 120px;
  height    : 40px;
`;
export const DownloadCtaTitle = styled.p<DownloadCtaTitleProps>`
  ${LABEL_SMALL.BOLD};
  color         : ${DARK_BLUE};
  max-width     : ${props => props.titleMaxWidth ? `${props.titleMaxWidth}px` : '290px' };
  letter-spacing: -0.2px
`;
export const TrustPilotImage = styled.img`
  width: 168px;

  @media ${DEVICE_BREAKPOINT.minMobile} {
    width: 100%;
  }
`;
