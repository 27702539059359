import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CloseButton, EmailMatch, EmailName, MatchIconCircle, TopContentWrapper } from 'Pages/autoEmail/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { CLOSE_BUTTON, TOP_CONTENT } from 'Shared/constants/email/testId';
import { analyticsTrackEmailWindowClosed } from 'Shared/services/analytics/events/emailedWindowClosed';

interface Props {
  aeId: string;
}

/**
 * Renders the AETopContent component
 */
function AETopContent({ aeId }: Props): JSX.Element {
  const { t }                                                      = useTranslation('app');
  const supportedEmailIds                                          = ['100'];
  const emailName = supportedEmailIds.includes(aeId)
      ? t(`Email--email-name-${aeId as '100'}`)
      : t('Email--email-name');

  return (
    <TopContentWrapper data-testid={TOP_CONTENT}>
      <EmailMatch>
        <MatchIconCircle>
          <Icon iconId={ICON_IDS.EMAIL_FILL} width={19} height={19} color='currentcolor' />
        </MatchIconCircle>
        {
          emailName && (
            <Trans t={t} i18nKey='Email--match' values={{ emailName }}>
              From your <EmailName>{emailName}</EmailName> email
            </Trans>
          )
        }
      </EmailMatch>
      <CloseButton to='/' data-testid={CLOSE_BUTTON} onClick={ () => analyticsTrackEmailWindowClosed() }>
        <Icon iconId={ICON_IDS.CLOSE} width={24} height={24} />
      </CloseButton>
    </TopContentWrapper>
  );
}

export default AETopContent;
