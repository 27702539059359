import React, { ReactNode, useEffect, useState } from 'react';
import Loading from 'App/components/loading';
import { logError } from 'App/services/coralogixService';
import { getAccessTokenPayload, getUserId } from 'App/services/idpTokenService';
import EmailLoading from 'Shared/components/design/email/loading';
import { FULL_SCOPE } from 'Shared/constants/token';
import { ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_RESPONSE_CODES } from 'Shared/services/analytics/constants';
import { analyticsTrackLoginEnd } from 'Shared/services/analytics/events/loginEnd';
import { analyticsTrackLoginStart } from 'Shared/services/analytics/events/loginStart';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { loginUsingAutoLoginKey } from 'Shared/services/userActivation/api/idpApiService';

interface Props {
  userId                 : string;
  aeT                    : string;
  autoLoginKey           : string;
  stillLoading           : boolean;
  isAutoLogin?           : boolean;
  loadingText?           : string;
  fallbackRedirectionURL?: string;
  emptyLoading?          : boolean;
  children               : ReactNode;
}

/**
 * Renders the EmailAutoLoginGuard component
 */
function AELoginGuard ({ userId, aeT, autoLoginKey, stillLoading, isAutoLogin, loadingText, fallbackRedirectionURL, emptyLoading, children }: Props): JSX.Element {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    function reset () {
      setIsReady(false);
    }

    const userIdMatches = userId === getUserId(true);
    const accessTokenPayload = getAccessTokenPayload(true);
    const isTokenFullScope = accessTokenPayload?.scp?.includes(FULL_SCOPE) ?? false;
    const isTokenExpired = (accessTokenPayload?.exp ?? 0) < (Date.now() / 1000);
    if (userIdMatches && isTokenFullScope && !isTokenExpired) {
      setIsReady(true);
      return reset;
    }

    analyticsTrackLoginStart(ANALYTICS_EVENT_SUB_TYPE.auto_email);

    const numberUserId = Number.parseInt(userId);
    loginUsingAutoLoginKey(autoLoginKey, numberUserId, aeT).then(() => {
      analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.auto_email);
      initializeSplitTests(SplitTestInitializationLocation.AUTOLOGIN);
      setIsReady(true);
    }).catch((error) => {
      analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.auto_email, error?.response?.data.errorCodes[0] || ANALYTICS_RESPONSE_CODES.error);
      setIsReady(true);
      logError('loginUsingAutoLoginKey', error);

      // Redirect to login page if user gets an error
      if (isAutoLogin && fallbackRedirectionURL) {
        window.location.href = fallbackRedirectionURL;
      }
    });

    return reset;
  }, [setIsReady, userId, aeT, autoLoginKey, isAutoLogin, fallbackRedirectionURL]);

  if (isReady) {
    return <>{children}</>;
  } else if (emptyLoading) {
    return <></>;
  } else if (isAutoLogin) {
    return <Loading loadingText={loadingText} />;
  } else {
    return <EmailLoading stillLoading={stillLoading} />;
  }
}

export default AELoginGuard;
