import { getSurveyDevice, getUspDevice } from 'Shared/services/deviceService';
import { isInAndroidApp, isInIOSApp } from 'Shared/services/mobileAppMessenger';

interface UserDetails {
  userId  : string;
  userName: string;
}

type UspDeviceType = {
  is_mobile           : boolean,
  complete_device_name: string,
  form_factor         : string
}

enum DeviceType {
  iOS     = 'ios',
  Android = 'android',
  Web     = 'web'
}

/**
 * Gets the user device from the survey cookie
 */
const getUserDevice = (): UspDeviceType | undefined => {
  try {
    return JSON.parse(getSurveyDevice());
  } catch (error) {
    logError('getUserDevice', error);
    return {
      is_mobile           : false,
      complete_device_name: 'UNABLE_TO_RETRIEVE',
      form_factor         : 'UNABLE_TO_RETRIEVE'
    };
  }
};

/**
 * Gets the user device app
 */
const getUserDeviceApp = (): DeviceType => {
  if (isInIOSApp()) {
    return DeviceType.iOS;
  }

  if (isInAndroidApp()) {
    return DeviceType.Android;
  }

  return DeviceType.Web;
};

/**
 * Sets the user context for Coralogix
 */
export const setUserContext = (userDetails: UserDetails): void => {
  const userDevice = getUserDevice();

  window.CoralogixRum?.setUserContext({
    user_id      : userDetails.userId,
    user_name    : userDetails.userName,
    user_metadata: {
      user_device_is_mobile           : userDevice?.is_mobile,
      user_device_complete_device_name: userDevice?.complete_device_name,
      user_device_form_factor         : userDevice?.form_factor,
      user_device_usp                 : getUspDevice(),
      user_device_app                 : getUserDeviceApp(),
    },
  });
};

/**
 * Gets the Coralogix initialization state
 */
export const isCoralogixInitialized = (): boolean => {
  return window.CoralogixRum?.isInited ?? false;
};

/**
 * Logs an error to Coralogix
 * @param message
 * @param data
 * @returns void
 */
export const logError = (message: string, data: any): void => {
  const errorStackOrData = data?.stack ?? data?.message ?? data;

  if (typeof window.CoralogixRum?.error === 'function') {
    window.CoralogixRum?.error(message, errorStackOrData);
  } else if (typeof window.logCoralogixErrorBuffered === 'function') {
    // Fallback to the global buffered error logger
    // defined in index.html.
    window.logCoralogixErrorBuffered(message, errorStackOrData);
  }
};

/**
 * Gets the user context from Coralogix
 */
export const getUserContext = () => {
  return window.CoralogixRum?.getUserContext();
};

/**
 * Initializes Coralogix
 */
export const initCoralogixDependencies = () => {
  if (process.env.APP_ENV === 'e2e' || process.env.MOCK_API === 'on') {
    return;
  }

  // Create and load the Coralogix script
  const coralogixScript  = document.createElement('script');
  coralogixScript.async  = true;
  coralogixScript.src    = 'https://cdn.rum-ingress-coralogix.com/coralogix/browser/1.7.0/coralogix-browser-sdk.js';
  coralogixScript.onload = function() {
    // Use type assertions to avoid TypeScript errors
    const coralogixRum = window.CoralogixRum;
    const config       = window.__coralogixConfig;
    const flushBuffer  = window.flushCoralogixErrorBuffer;

    if (coralogixRum && typeof coralogixRum.init === 'function') {
      coralogixRum.init(config || {});

      // Call the global function to flush any buffered errors
      if (typeof flushBuffer === 'function') {
        flushBuffer();
      }
    }
  };

  document.head.appendChild(coralogixScript);
};
