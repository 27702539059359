import { DEVICE_BREAKPOINT, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const CAQuestionWrapper = styled.form`
  display       : flex;
  flex-direction: column;
  max-width     : calc(100vw - 32px);
  width         : 414px;
  min-height    : 450px;
  align-items   : stretch;
  margin-top    : 40px;
  gap           : 40px;

  @media ${ DEVICE_BREAKPOINT.maxMobile } {
    height: 100vh;
    margin: 40px 16px;
    gap   : 30px;
  }
`;

export const CAQuestionText = styled.div`
  ${TITLES_NORMAL.FT_02}
`;

export const CAButtonWrapper = styled.div`
  padding-bottom: 30px;
`;