import React from 'react';
import { useNavigate } from 'react-router-dom';
import { INITIALIZE_USER_ACTIVATION_TEST_ID } from 'App/components/initializeUserActivation/testId';
import { MEMBER_SIGN_UP_URL } from 'App/routes/constants';
import { getSessionId } from 'App/services/idpTokenService';
import QuestionnairePopup from 'Shared/components/design/questionnairePopup/QuestionnairePopup';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getLocalStorage } from 'Shared/services/localStorageService';
import { useGetUserInfo } from 'Shared/services/userActivation/api/idpApiService';
import { triggerGeneralProfilePopup } from 'Shared/services/userActivation/app/questionService';

/**
 * Initializes the User Activation MF on page load
 */
function InitializeUserActivation (): JSX.Element {
  const { data: userInfo }     = useGetUserInfo();
  const navigate               = useNavigate();
  const hasMissingBasicProfile = userInfo && (!userInfo.dateOfBirth || !userInfo.gender || !userInfo.postalCode);

  // Navigate to the sign up page if the user doesn't have a basic profile yet.
  if (hasMissingBasicProfile) {
    navigate(MEMBER_SIGN_UP_URL);
    return <></>;
  }

  // Don't render the rest if we don't have a user info
  if (!userInfo) {
    return <></>;
  }

  // Load the GP questionnaire if the user hasn't seen it before
  const initGeneralProfilePopup = () => {
    const sessionId              = getSessionId();
    const hasSeenGPQuestionnaire = getLocalStorage(LOCAL_STORAGE_IDS.HAS_SEEN_GP, true) == sessionId;

    if (!hasSeenGPQuestionnaire || !sessionId) {
      triggerGeneralProfilePopup();
    }
  };

  return (
    <div data-testid={INITIALIZE_USER_ACTIVATION_TEST_ID}>
      <QuestionnairePopup onInit={ initGeneralProfilePopup } />
    </div>
  );
}

export default InitializeUserActivation;
