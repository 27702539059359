import { useEffect } from 'react';
import { disableScroll, enableScroll } from 'Shared/utils/scrollUtils';

/**
 * A hook to conditionally disable scrolling of the `body` element.
 * @param disableBodyScroll Whether to disable scrolling.
 */
function useScrollStop (disableBodyScroll: boolean) {
  useEffect(() => {
    if (disableBodyScroll) {
      disableScroll();
      return () => {
        enableScroll();
      };
    }
  }, [disableBodyScroll]);
}

export default useScrollStop;