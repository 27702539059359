import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DIALOG_TEST_IDS } from 'Shared/components/design/dialog/constants';
import { Backdrop, DialogContainer, DialogContentWrapper, DialogRoot } from 'Shared/components/design/dialog/style';
import useOnClickOutside from 'Shared/hooks/useClickOutside';
import useScrollStop from 'Shared/hooks/useScrollStop';

interface DialogProps {
  show                    : boolean;
  children                : JSX.Element;
  onBackdropClick        ?: () => void;
  zIndex                 ?: number
  allowAnimation         ?: boolean;
  'data-testid'          ?: string;
  maxWidth               ?: number;
  verticalAlign          ?: 'center' | 'top' | 'bottom';
  mobileHorizontalSpacing?: number;
}

const TRANSITION_DURATION = 200;

/**
 * @typedef DialogProps
 * @property {boolean} show - Whether the dialog should be displayed or not.
 * @property {JSX.Element} children - The content to be displayed inside the dialog.
 * @property {function} onBackdropClick - Function to execute when the backdrop is clicked.
 * @property {number} zIndex - The z-index to apply to the dialog.
 * @property {boolean} allowAnimation - Whether to animate modal appearance in and out.
 * @property {string} data-testid - The data-testid attribute to apply to the dialog.
 * @property {number} maxWidth - The maximum width of the dialog.
 * @property {'center' | 'top' | 'bottom'} verticalAlign - The vertical alignment of the dialog.
 * @property {number} mobileHorizontalSpacing - The horizontal spacing to apply to the dialog on mobile.
 */

/**
 * @component
 * @param {DialogProps} props - Props for Dialog component.
 * @description A modal Dialog component that can be toggled visible or hidden.
 */
const Dialog = ({ show, children, onBackdropClick, zIndex, allowAnimation = true, 'data-testid': testid, maxWidth, verticalAlign = 'center', mobileHorizontalSpacing = 0 }: DialogProps) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isAnimating, setAnimating] = useState(false);
  const [isVisible, setVisible]     = useState(show);

  useScrollStop(isVisible);

  // Triggered when backdrop is clicked
  const backdropClick = () => {
    if (typeof onBackdropClick === 'function') {
      onBackdropClick();
    }
  };

  useOnClickOutside(rootRef, backdropClick);

  useEffect(() => {
    let timerId: number;

    if (show) {
      setVisible(true);
    } else {
      setAnimating(true);
      timerId = setTimeout(() => {
        setAnimating(false);
        setVisible(false);
      }, TRANSITION_DURATION) as unknown as number;
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [show]);

  /**
   * @function
   * @description Renders the actual dialog and backdrop.
   * @returns {JSX.Element} The JSX for the dialog and backdrop.
   */
  const renderModal = (): JSX.Element => {
    return (
      <DialogContainer zIndex={zIndex} data-testid={testid}>
        <Backdrop show={show} isAnimating={isAnimating} data-testid={DIALOG_TEST_IDS.DIALOG_BACKDROP} />
        <DialogContentWrapper verticalAlign={verticalAlign}>
          <DialogRoot
            ref                     = { rootRef }
            show                    = { show }
            isAnimating             = { isAnimating }
            allowAnimation          = { allowAnimation }
            maxWidth                = { maxWidth }
            mobileHorizontalSpacing = { mobileHorizontalSpacing }
            role                    = "dialog"
          >
            {children}
          </DialogRoot>
        </DialogContentWrapper>
      </DialogContainer>
    );
  };

  return isVisible ? createPortal(renderModal(), document.body) : null;
};

export default Dialog;