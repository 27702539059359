import { useCallback, useEffect } from 'react';

/**
 * Most commonly used keyboard key values
 * If need to use another key please add it
 * here instead of hardcoding it in actual component
 */
export const KEYBOARD_KEYS = {
  ENTER        : 'Enter',
  NUMPAD_ENTER : 'NumpadEnter',
  ALT_LEFT     : 'AltLeft',
  ALT_RIGHT    : 'AltRight',
  ESCAPE       : 'Escape',
  CONTROL_LEFT : 'ControlLeft',
  CONTROL_RIGHT: 'ControlRight',
  TAB          : 'Tab'
};

/**
 * Hook that handles keyboard presses, by default it handles "Enter" presses
 * @param callback
 * @param keys
 */
export const useKeyDown = (callback: (event: KeyboardEvent) => void , keys: Array<string> = [KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.NUMPAD_ENTER]) => {
  /**
   * Checks when the user presses a key on the keyboard
   */
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    const isAnyKeyPressed = keys.some((key) => event.key === key);
    if (isAnyKeyPressed) {
      event.preventDefault();
      callback(event);
    }
  },[callback, keys]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};
