import React, { HTMLAttributes, useState } from 'react';
import { TEXT_FIELD_TEST_ID } from 'Shared/components/design/textField/constants';
import { ClearTextButton, IconContainer, TextBoxContainer, TextHelper, TextInput, TextLabel } from 'Shared/components/design/textField/style';
import {ICON_IDS} from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';


/**
 * ENUMs defining style for component
 */
enum TextFieldTypes {
  DEFAULT,
  ERROR,
  DISABLED_WITH_BOX,
  DISABLED_NO_BOX
}

interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  className?       : string;
  label?           : string;
  placeholder?     : string;
  helperText?      : string;
  initialText?     : string;
  fieldType?       : TextFieldTypes;
  value?           : string;
  changeCallback  ?: (x: string) => void;
  blurCallback?    : (x: string) => void;
  isSearchBar?     : boolean;
  clickable?       : boolean;
  isRequiredField? : boolean;
}

function TextField({
  className,
  label,
  placeholder,
  helperText,
  initialText,
  fieldType = TextFieldTypes.DEFAULT,
  changeCallback,
  blurCallback,
  isSearchBar     = false,
  clickable       = false,
  isRequiredField = false,
  value,
  ...rest
}: TextFieldProps) {
  const [inputText, setInputText] = useState<string>(initialText || '');

  /**
   * Conditionally render label
   * isRequiredField adds red asterisk at the end of the label
   */
  const renderLabel = (): JSX.Element | undefined => {
    if (label) {
      return (
        <TextLabel fieldType={fieldType} isRequiredField={isRequiredField}>
          { label }
        </TextLabel>
      );
    }
  };

  /**
   * Render helper text
   */
  const renderHelperText = (): JSX.Element | undefined => {
    return (
      <TextHelper fieldType={fieldType}>
        { helperText ?? '' }
      </TextHelper>
    );
  };

  /**
   * Run callback on text change and set state
   * @param event
   */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { value } = event.target;
    setInputText(value);
    if (changeCallback) {
      changeCallback(value);
    }
  };

  /**
   * Run callback on text change and set state
   * @param event
   */
  const blurTextInput = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (blurCallback && typeof blurCallback === 'function') {
      const { value } = event.target;
      blurCallback(value);
    }
  };

  /**
   * Checks if the component is disabled based on `fieldType` input
   */
  const componentIsDisabled  = (): boolean => {
    return fieldType === TextFieldTypes.DISABLED_NO_BOX || fieldType === TextFieldTypes.DISABLED_WITH_BOX;
  };

  /**
   * Renders the magnifying glass icon inside the input field
   */
  const renderSearchIcon = (): JSX.Element | undefined => {
    if (!isSearchBar) {
      return;
    }

    return(
      <IconContainer fieldType={fieldType}>
        <Icon iconId={ICON_IDS.SEARCH} height={16} width={16} />
      </IconContainer>
    );
  };

  /**
   * Clears entered input field text
   */
  const clearText = (): void => {
    setInputText('');
    if(changeCallback) {
      changeCallback('');
    }
  };

  /**
   * Sets the visibility of the clear button
   */
  const isButtonVisible = (): boolean => {
    return (inputText === '') ? false : true;
  };

  /**
   * Renders the clear text button/icon inside the input field
   */
  const renderClearTexButton = (): JSX.Element | undefined => {
    if (!isSearchBar) {
      return;
    }

    return(
      <ClearTextButton isVisible={isButtonVisible()}>
        <Icon
          iconId  = {ICON_IDS.CLOSE_FILL}
          height  = {16}
          width   = {16}
          onClick = {clearText}
        />
      </ClearTextButton>
    );
  };


  return (
    <TextBoxContainer className={className} data-testid={TEXT_FIELD_TEST_ID}>
      { renderLabel() }
      { renderSearchIcon() }
      <TextInput
        type           = 'text'
        autoCorrect    = 'off'
        autoCapitalize = 'none'
        placeholder    = {placeholder}
        value          = {value != undefined ? value : inputText}
        onChange       = {handleTextChange}
        onBlur         = {blurTextInput}
        fieldType      = {fieldType}
        isPreFilled    = {!!initialText}
        readOnly       = {componentIsDisabled()}
        isSearchBar    = {isSearchBar}
        clickable      = {clickable}
        aria-label     = {label}
        {...rest}
      />
      { renderClearTexButton() }
      { renderHelperText() }
    </TextBoxContainer>
  );
}

export {
  TextField,
  TextFieldTypes
};