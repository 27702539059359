import React from 'react';
import { createRoot } from 'react-dom/client';
import { Providers } from 'App/components/providers/Providers';

import 'App/i18n/config';
import 'bootstrap';
import '@disqotech/vinyl-design-system';
import '@disqotech/vinyl-design-system/consumer.css';
import '@disqotech/vinyl-design-system/globals.css';
import 'App/style/reset.css';
import 'App/style/style.css';
import Router from './router';

//Conditionally enable/start mocking server
if (process.env.APP_ENV === 'development' && process.env.MOCK_API === 'on') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('Mocks/browser');
  worker.start();
}

// Register service worker if not in development mode
if (process.env.NODE_ENV !== 'development') {
  if ('serviceWorker' in navigator) {
    const registerServiceWorker = () => {
      navigator.serviceWorker.register('/service-worker.js').then(
        (registration) => {
          // eslint-disable-next-line no-console
          console.log("SW Succeeded:", registration);
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(`SW Failed: ${error}`);
        },
      );
    };

    if (document.readyState === 'complete') {
      registerServiceWorker();
    } else {
      window.addEventListener('load', () => {
        registerServiceWorker();
      });
    }
  }
}

const render = () => {
  const container = document.getElementById('app-root') as HTMLElement;
  const root = createRoot(container);

  root.render(<Providers><Router /></Providers>);
  window.clearLoadTimeout();
};

if (document.readyState === 'loading') {
  window.addEventListener('load', render);
} else {
  render();
}