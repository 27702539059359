import { TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  width          : 100%;
  height         : 90vh;
  display        : flex;
  justify-content: center;
  align-items    : center;
  ${TITLES_NORMAL.FT_01}
`;

export const LoadingSpinnerContainer = styled.div`
  padding-right: 16px;
  padding-top  : 8px;
`;
