import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { Button } from 'Shared/components/design/button/Button';
import { transitionSeconds } from 'Shared/components/design/loginModal/style';
import Icon from 'Shared/components/icons/icon';
import { LABEL_SMALL, PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface WrapperProps {
  show: boolean;
}

export const ModalWrapper = styled.div<WrapperProps>`
background-color: ${DESIGN_TOKENS.color.background.primary.value};
  position        : absolute;
  inset           : 0;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  opacity         : ${({ show }) => show ? 1 : 0};
  visibility      : ${({ show }) => show ? 'visible' : 'hidden'};
  transition      : opacity ${transitionSeconds}s;
  pointer-events  : ${({ show }) => show ? 'auto' : 'none'};
`;

export const ModalHeader = styled.h1`
  ${TITLES_NORMAL.FT_01};
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  position        : relative;
  width           : 100%;
  text-align      : center;
  padding         : 19px;
  box-shadow      : ${DESIGN_TOKENS.shadow.xs.value};
`;

export const BackButton = styled.button`
  cursor         : pointer;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 64px;
  height         : 64px;
  position       : absolute;
  top            : 1px;
  left           : 1px;
  background     : none;
  border         : none;
`;

export const ModalContent = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
  width         : 360px;
  padding       : 32px 0 40px 0;
  flex-grow     : 1;
`;

export const EmailBanner = styled.div`
  background    : ${DESIGN_TOKENS.color.background.info.light.value};
  padding       : 8px 16px;
  display       : flex;
  flex-direction: row;
  align-items   : center;
`;

export const BannerIcon = styled(Icon)`
  margin-right: 26px;
  flex-shrink : 0;
`;

export const BannerText = styled.div`
  ${LABEL_SMALL.DEFAULT}
`;

export const EmailTitle = styled.div`
  ${LABEL_SMALL.DEFAULT}
  margin-top: 24px;
  color     : ${DESIGN_TOKENS.color.text.secondary.value};
`;

export const EmailAddress = styled.div`
  ${PARAGRAPH.DEFAULT}
  padding   : 4px 0;
`;

export const ReturnLink = styled.a`
  ${PARAGRAPH.DEFAULT}
  cursor         : pointer;
  color          : ${DESIGN_TOKENS.color.text.link.value};
  margin-top     : 16px;
  padding        : 4px 0;
  text-decoration: underline;
`;

export const SubmitButton = styled(Button)`
  margin-top: 40px;
`;
