export const SURVEY_MEDLEY_QUESTION_COUNT      = 3;
export const MAX_SURVEY_DISPLAY_AGE_SECONDS    = 90;
export const SURVEY_LOAD_INTERVAL_SECONDS      = 60;
export const NO_SURVEY_RETRY_INTERVAL_SECONDS  = 15;
export const SURVEY_UI_RENDER_INTERVAL_SECONDS = 1;
export const MIN_INCOMPLETE_SURVEY_COUNT       = 10;
export const BATCH_MAX_WAIT_SECONDS            = 2;
export const BATCH_MIN_PROVIDERS               = 0;
export const BATCH_MIN_TPA_PROVIDERS           = 0;
export const BATCH_MIN_WAIT_SECONDS            = 2;
export const SURVEY_CACHE_LOW_WATER_MARK       = 0;
export const SURVEY_CACHE_HIGH_WATER_MARK      = 180;