import { LABEL_XS } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const LockTooltipContainer = styled.div<{ isVisible: boolean }>`
  ${LABEL_XS.DEFAULT}
  position        : absolute;
  width           : 200px;
  top             : 460px;
  transform       : translateX(-94%);
  background-color: black;
  color           : white;
  padding         : 8px;
  border-radius   : 4px;
  opacity         : ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events  : ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  transition      : opacity 0.5s ease-in-out, pointer-events 0s linear 0.5s;
  cursor          : pointer;

  svg {
    position: absolute;
    fill    : white;
    top     : 17px;
    right   : 4px;
  }
`;

const getArrowPosition = (isMobile: boolean) => {
  if (isMobile) {
    return `
      border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
    `;
  }
  return `
    top              : 50%;
    margin-top       : -5px;
    border-width     : 5px 0 5px 5px;
    border-left-color: #000;
    right            : -5px;
    left             : auto;
  `;
};

export const LockTooltipArrow = styled.span<{isMobile: boolean}>`
  position    : absolute;
  top         : ${({isMobile}) => (isMobile ? '-10px': '30px')};
  left        : ${({isMobile}) => (isMobile ? '140px': '194px')};
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  ${props => getArrowPosition(props.isMobile)}
`;
