import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

interface steInstallationEvent {
  [ANALYTICS_EVENT_ATTRIBUTES.step_name]       : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.type]            : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : number | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.response_message]: string | undefined;
}

/**
 * Sends Surf to Earn Installation events to Google Analytics
 * @returns void
 * @param stepName
 * @param type
 * @param responseCode
 * @param responseMessage
 */
export const analyticsTrackSteInstallationEnd = (stepName: string | undefined, type: string | undefined, responseCode: number, responseMessage: string): void => {
  const steEventProps: steInstallationEvent = {
    [ANALYTICS_EVENT_ATTRIBUTES.step_name]       : `install_${stepName?.toLowerCase()}`,
    [ANALYTICS_EVENT_ATTRIBUTES.type]            : type,
    [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : responseCode,
    [ANALYTICS_EVENT_ATTRIBUTES.response_message]: `${responseMessage}_install`,
  };

  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      ...steEventProps
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.ste, [event]);
};
