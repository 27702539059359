import FlyingPig from 'Pages/surveyCallback/components/flyingPig/FlyingPig';
import { shouldShowShortText } from 'Pages/surveyCallback/helpers';
import { SurveyCallbackMessage, SurveyCallbackStatuses } from "Pages/surveyCallback/models/SurveyCallbackModels";

export const SURVEY_MODAL_TEST_IDS = {
  SURVEY_MODAL_TEST_CONTAINER     : 'survey-modal-test-container',
  SURVEY_MODAL_TEST_CLOSE_ICON    : 'survey-modal-test-close-icon',
  SURVEY_MODAL_TEST_CANCEL_BUTTON : 'survey-modal-test-cancel-button',
  SURVEY_MODAL_TEST_CONFIRM_BUTTON: 'survey-modal-test-confirm-button'
};

export const SURVEY_RECOMMENDATION_MODAL_TEST_ID = 'survey-recommendation-modal-test';

export const SURVEY_CALLBACK_SCREENS: Record<SurveyCallbackStatuses, SurveyCallbackMessage> = {
  [SurveyCallbackStatuses.COMPLETE]: {
    title       : 'SurveyCallback--title--complete',
    text        : 'SurveyCallback--text--complete',
    emojiElement: '\u2728',
  },[SurveyCallbackStatuses.QUOTA_FULL]: {
    title       : 'SurveyCallback--title--quotaFull',
    text        : 'SurveyCallback--text--quotaFull',
    emojiElement: '\uD83D\uDCEB',
    reportIssue : false
  },[SurveyCallbackStatuses.TERMINATED]: {
    title       : 'SurveyCallback--title--terminated',
    text        : 'SurveyCallback--text--terminated',
    emojiElement: '\uD83D\uDDE3\uFE0F',
    reportIssue : false
  },[SurveyCallbackStatuses.REJECTED]: {
    title       : 'SurveyCallback--title--rejected',
    text        : shouldShowShortText(SurveyCallbackStatuses.REJECTED) ? 'SurveyCallback--text--rejected-short' : 'SurveyCallback--text--rejected',
    emojiElement: '\uD83E\uDD1D',
    reportIssue : false
  },[SurveyCallbackStatuses.DUPLICATE]: {
    title       : 'SurveyCallback--title--duplicate',
    text        : 'SurveyCallback--text--duplicate',
    emojiElement: '\uD83D\uDC6F',
    reportIssue : false
  },[SurveyCallbackStatuses.SURVEY_NOT_AVAILABLE]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.NO_SURVEY_AVAILABLE]: {
    title       : 'SurveyCallback--title--noSurveyAvailable',
    text        : 'SurveyCallback--text--noSurveyAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.PRE_SCREEN_OUT]: {
    title       : 'SurveyCallback--title--preScreenOut',
    text        : 'SurveyCallback--text--preScreenOut',
    emojiElement: '\uD83C\uDFAF',
    reportIssue : false
  },[SurveyCallbackStatuses.CLIENT_REJECTED]: {
    title       : 'SurveyCallback--title--clientRejected',
    text        : shouldShowShortText(SurveyCallbackStatuses.CLIENT_REJECTED) ? 'SurveyCallback--text--clientRejected-short' : 'SurveyCallback--text--clientRejected',
    emojiElement: '\uD83E\uDD1D',
    reportIssue : false
  },[SurveyCallbackStatuses.COMPLETE_BUT_INTERNAL_QUOTA_FULL]:{
    title       : 'SurveyCallback--title--completeInternalQuotaFull',
    text        : 'SurveyCallback--text--completeInternalQuotaFull',
    emojiElement: '\uD83D\uDCEB',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_PRE_SCREEN_OUT]: {
    title       : 'SurveyCallback--title--preScreenOut',
    text        : 'SurveyCallback--text--preScreenOut',
    emojiElement: '\uD83C\uDFAF',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_USER_PROVIDER_BLOCK]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_INCORRECT_DEVICE]: {
    title       : 'SurveyCallback--title--incorrectDevice',
    text        : 'SurveyCallback--text--incorrectDevice',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_QUOTA_FULL]: {
    title       : 'SurveyCallback--title--quotaFull',
    text        : 'SurveyCallback--text--sjQuotaFull',
    emojiElement: '\uD83D\uDCEB',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_SURVEY_NOT_AVAILABLE]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_SURVEY_DUPLICATE]: {
    title       : 'SurveyCallback--title--surveyDuplicate',
    text        : 'SurveyCallback--text--surveyDuplicate',
    emojiElement: '\uD83D\uDC6F',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_DISPLAY_RULES]: {
    title       : 'SurveyCallback--title--displayRules',
    text        : 'SurveyCallback--text--displayRules',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_CLICK_THROTTLED]: {
    title       : 'SurveyCallback--title--clickThrottled',
    text        : 'SurveyCallback--text--clickThrottled',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_OK]: {
    title       : 'SurveyCallback--title--ok',
    text        : 'SurveyCallback--text--ok',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_PARTNER_REJECTED_CLICK]: {
    title       : 'SurveyCallback--title--partnerRejectedClick',
    text        : 'SurveyCallback--text--partnerRejectedClick',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_SURVEY_INVALID_URL]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_SURVEY_URL_MISMATCH]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.SJ_SURVEY_SURVEY_FILTERED]: {
    title       : 'SurveyCallback--title--surveyNotAvailable',
    text        : 'SurveyCallback--text--surveyNotAvailable',
    emojiElement: '\uD83E\uDEE5',
    reportIssue : false
  },[SurveyCallbackStatuses.UNEXPECTED_ERROR]: {
    title         : 'SurveyCallback--title--unexpectedError',
    text          : 'SurveyCallback--text--unexpectedError',
    buttonText    : 'SurveyCallback--button--unexpectedError',
    emojiElement  : '',
    emojiComponent: FlyingPig,
    centered      : true
  }
};

export const NO_POINTS = 0;
