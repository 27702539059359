import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { BannerContextTypes } from 'Shared/components/design/banner/Banner';
import { LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface BannerProps {
  context        : BannerContextTypes,
  hasIcon        : boolean,
  hasLabel       : boolean,
  hasClose       : boolean,
  fillParent?    : boolean
}

function getTextColor(context: BannerContextTypes): string {
  switch (context) {
    case BannerContextTypes.WARNING:
    case BannerContextTypes.POSITIVE:
    case BannerContextTypes.LIGHT_INFO:
    case BannerContextTypes.LIGHT_WARNING:
    case BannerContextTypes.LIGHT_POSITIVE:
    case BannerContextTypes.LIGHT_NEGATIVE:
      return DESIGN_TOKENS.color.text.normal.value;
    case BannerContextTypes.INFO:
    case BannerContextTypes.NEGATIVE:
      return DESIGN_TOKENS.color.text.onSuccess.value;
  }
}

function getLabelColor(context: BannerContextTypes): string {
  switch (context) {
    case BannerContextTypes.LIGHT_INFO:
    case BannerContextTypes.LIGHT_WARNING:
    case BannerContextTypes.LIGHT_POSITIVE:
    case BannerContextTypes.LIGHT_NEGATIVE:
      return DESIGN_TOKENS.color.text.onSuccess.value;
    case BannerContextTypes.WARNING:
    case BannerContextTypes.INFO:
    case BannerContextTypes.POSITIVE:
    case BannerContextTypes.NEGATIVE:
      return DESIGN_TOKENS.color.text.normal.value;
  }
}

function getLabelBackgroundColor(context: BannerContextTypes): string {
  switch (context) {
    case BannerContextTypes.WARNING:
    case BannerContextTypes.INFO:
    case BannerContextTypes.POSITIVE:
    case BannerContextTypes.NEGATIVE:
      return DESIGN_TOKENS.color.background.primary.value;
    case BannerContextTypes.LIGHT_INFO:
    case BannerContextTypes.LIGHT_WARNING:
    case BannerContextTypes.LIGHT_POSITIVE:
    case BannerContextTypes.LIGHT_NEGATIVE:
      return DESIGN_TOKENS.color.text.normal.value;
  }
}

function getBackgroundColor(context: BannerContextTypes): string {
  switch (context) {
    case BannerContextTypes.WARNING:
      return DESIGN_TOKENS.color.background.warning.value;
    case BannerContextTypes.POSITIVE:
      return DESIGN_TOKENS.color.background.success.value;
    case BannerContextTypes.LIGHT_INFO:
      return DESIGN_TOKENS.color.background.info.light.value;
    case BannerContextTypes.LIGHT_WARNING:
      return DESIGN_TOKENS.color.background.warning.light.value;
    case BannerContextTypes.LIGHT_POSITIVE:
      return DESIGN_TOKENS.color.background.success.light.value;
    case BannerContextTypes.LIGHT_NEGATIVE:
      return DESIGN_TOKENS.color.background.critical.light.value;
    case BannerContextTypes.INFO:
      return DESIGN_TOKENS.color.background.info.value;
    case BannerContextTypes.NEGATIVE:
      return DESIGN_TOKENS.color.background.critical.value;
  }
}

export const BannerLabelContainer  = styled.div<BannerProps>`
  display      : flex;
  align-items  : center;
  flex-grow    : 0;
  margin-left  : 8px;
  align-content: center;
`;

export const BannerLabel = styled.div<BannerProps>`
  display         : flex;
  align-items     : center;
  border-radius   : 24px;
  padding         : 2px 8px;
  align-content   : center;
  color           : ${props => getLabelColor(props.context)};
  background-color: ${props => getLabelBackgroundColor(props.context)};
`;

export const BannerText = styled.div<BannerProps>`
  display    : flex;
  align-items: center;
  flex-grow  : 1;
  color      : ${props => getTextColor(props.context)};
`;

export const BannerTextContent = styled.div`
  display: inline;
`;

export const BannerContainer = styled.div<BannerProps>`
  ${LABEL_SMALL.DEFAULT}
  display         : flex;
  flex-wrap       : nowrap;
  align-content   : stretch;
  padding         : 16px;
  margin          : 16px;
  background-color: ${props => getBackgroundColor(props.context)};
  width           : ${ props => props.fillParent ? '100%' : 'auto' };
`;

export const BannerClose = styled.div<BannerProps>`
  display    : flex;
  align-items: center;
  flex-grow  : 0;
  margin-left: 17px;
  cursor     : pointer;

  > svg {
    fill  : ${props => getTextColor(props.context)};
    width : 24px;
    height: 24px;
  }
`;

export const BannerIcon = styled.div<BannerProps>`
  display     : flex;
  align-items : center;
  flex-grow   : 0;
  margin-right: 15px;

  > svg {
    fill  : ${props => getTextColor(props.context)};
    width : 24px;
    height: 24px;
  }
`;
