import React from 'react';
import { Container } from 'Pages/surveyCallback/components/flyingPig/style';
import flyingPigImage from 'Shared/assets/images/flying-pig.png';

/**
 * Flying Pig component
 */
const FlyingPig = (): JSX.Element => {
  return (
    <Container>
      <img src={flyingPigImage} alt="Flying Pig" />
    </Container>
  );
};

export default FlyingPig;