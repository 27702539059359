import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { ButtonIconPosition, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { LABEL_LARGE, PULSE_COLOR_APPLICATION,TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface ButtonProps {
  context          : ContextTypes,
  disabled         : boolean,
  size             : ButtonSizes,
  width            : ButtonWidth,
}

interface ButtonIconProps {
  context: ContextTypes,
  isLoading?: boolean,
  disabled: boolean,
  size: ButtonSizes,
  iconPosition?: ButtonIconPosition,
  iconCurrentColor?: boolean
}

enum ButtonState {
  REGULAR,
  HOVER,
  FOCUS,
  PRESSED
}

function getButtonContentColor(context: ContextTypes, state: ButtonState, disabled: boolean): string {
  if (disabled) {
    return DESIGN_TOKENS.color.text.disabled.value;
  }

  switch (context) {
    case ContextTypes.PRIMARY:
    case ContextTypes.NEGATIVE:
    case ContextTypes.AUXILIARY:
    case ContextTypes.SURF_TO_EARN:
      return DESIGN_TOKENS.color.text.onSuccess.value;
    case ContextTypes.SECONDARY:
    case ContextTypes.PULSE:
      return DESIGN_TOKENS.color.text.normal.value;
    case ContextTypes.NEGATIVE_SECONDARY:
      return DESIGN_TOKENS.color.text.critical.value;
    case ContextTypes.SURF_TO_EARN_SECONDARY:
      return DESIGN_TOKENS.color.primitive.green['900'].value;
    default:
      return '';
  }
}

function getDisabledButtonBackgroundColor(context: ContextTypes): string {
  switch (context) {
    case ContextTypes.PRIMARY:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.disabled.value};`;
    case ContextTypes.SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.disabled.value};`;
    case ContextTypes.PAYPAL:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.disabled.value};`;
  }

  return `background-color: ${DESIGN_TOKENS.color.button.primary.disabled.value};`;
}

function getRegularButtonBackgroundColor(context: ContextTypes): string {
  switch (context) {
    case ContextTypes.PRIMARY:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.value};`;
    case ContextTypes.SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.value};`;
    case ContextTypes.NEGATIVE:
      return `background-color: ${DESIGN_TOKENS.color.button.critical.value};`;
    case ContextTypes.NEGATIVE_SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.value};`;
    case ContextTypes.PULSE:
      return `background-color: ${PULSE_COLOR_APPLICATION.buttonBackground.pulseDefault};`;
    case ContextTypes.AUXILIARY:
      return `background-color: ${DESIGN_TOKENS.color.button.auxiliary.value};`;
    case ContextTypes.PAYPAL:
      return `background-color: #FFC439;`; //TODO: add correct token once its available in DESIGN_TOKENS
    case ContextTypes.SURF_TO_EARN:
      return `background: linear-gradient(90deg, #2CA2B4 0%, #5598DE 20.83%, #7F87FF 50.52%, #F65AAD 79.69%, #EC3D43 100%);`;
    case ContextTypes.SURF_TO_EARN_SECONDARY:
      return `background-color: #A1FF67;`;
  }

  return '';
}

function getHoverButtonBackgroundColor(context: ContextTypes): string {
  switch (context) {
    case ContextTypes.PRIMARY:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.hovered.value};`;
    case ContextTypes.SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.hovered.value};`;
    case ContextTypes.PULSE:
      return `background-color: ${PULSE_COLOR_APPLICATION.buttonBackground.pulseHover};`;
    case ContextTypes.AUXILIARY:
      return `background-color: ${DESIGN_TOKENS.color.button.auxiliary.hovered.value};`;
    case ContextTypes.PAYPAL:
      return `background-color: #FFC439;`; //TODO: add correct token once its available in DESIGN_TOKENS
    case ContextTypes.SURF_TO_EARN:
      return `background: linear-gradient(90deg, #2CA2B4 -22.76%, #5598DE 7.39%, #7F87FF 50.35%, #F65AAD 92.56%, #EC3D43 121.96%);`;
    case ContextTypes.SURF_TO_EARN_SECONDARY:
      return `background-color: #C3FF9E;`;
  }

  return '';
}

function getFocusButtonBackgroundColor(context: ContextTypes): string {
  switch (context) {
    case ContextTypes.PRIMARY:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.focused.value};`;
    case ContextTypes.SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.focused.value};`;
    case ContextTypes.PULSE:
      return `background-color: ${PULSE_COLOR_APPLICATION.buttonBackground.pulseFocus};`;
    case ContextTypes.AUXILIARY:
      return `background-color: ${DESIGN_TOKENS.color.button.auxiliary.focused.value};`;
    case ContextTypes.PAYPAL:
      return `background-color: #FFC439;`; //TODO: add correct token once its available in DESIGN_TOKENS
    case ContextTypes.SURF_TO_EARN_SECONDARY:
      return `background-color: #A1FF67;`;
  }

  return '';
}

function getPressedButtonBackgroundColor(context: ContextTypes): string {
  switch (context) {
    case ContextTypes.PRIMARY:
      return `background-color: ${DESIGN_TOKENS.color.button.primary.pressed.value};`;
    case ContextTypes.SECONDARY:
      return `background-color: ${DESIGN_TOKENS.color.button.secondary.pressed.value};`;
    case ContextTypes.PULSE:
      return `background-color: ${PULSE_COLOR_APPLICATION.buttonBackground.pulsePressed};`;
    case ContextTypes.AUXILIARY:
      return `background-color: ${DESIGN_TOKENS.color.button.auxiliary.pressed.value};`;
    case ContextTypes.PAYPAL:
      return `background-color: #FFC439;`;//TODO: add correct token once its available in DESIGN_TOKENS
    case ContextTypes.SURF_TO_EARN_SECONDARY:
      return `background-color: #86F442;`;
  }

  return '';
}

function getButtonBackgroundColor(context: ContextTypes, state: ButtonState, disabled: boolean): string {
  if (disabled) {
    return getDisabledButtonBackgroundColor(context);
  }

  if (state === ButtonState.REGULAR && getRegularButtonBackgroundColor(context)) {
    return getRegularButtonBackgroundColor(context);
  }

  if (state === ButtonState.HOVER && getHoverButtonBackgroundColor(context)) {
    return getHoverButtonBackgroundColor(context);
  }

  if (state === ButtonState.FOCUS && getFocusButtonBackgroundColor(context)) {
    return getFocusButtonBackgroundColor(context);
  }

  if (state === ButtonState.PRESSED && getPressedButtonBackgroundColor(context)) {
    return getPressedButtonBackgroundColor(context);
  }

  return '';
}

function getButtonBorder(context: ContextTypes, state: ButtonState, disabled: boolean): string {
  if (disabled) {
    return 'border: none;';
  }

  if (state === ButtonState.REGULAR) {
    switch (context) {
      case ContextTypes.SECONDARY:
        return `border: 2px solid ${DESIGN_TOKENS.color.border.normal.value};`;
      case ContextTypes.NEGATIVE_SECONDARY:
        return `border: 1px solid ${DESIGN_TOKENS.color.border.critical.value};`;
    }
  }

  if (state === ButtonState.HOVER) {
    switch (context) {
      case ContextTypes.SECONDARY:
        return `border: 2px solid ${DESIGN_TOKENS.color.text.secondary.value};`;
      case ContextTypes.NEGATIVE_SECONDARY:
        return `border: 1px solid ${DESIGN_TOKENS.color.border.critical.value};`;
    }
  }

  if (state === ButtonState.FOCUS) {
    switch (context) {
      case ContextTypes.SECONDARY:
        return `border: 2px solid ${DESIGN_TOKENS.color.text.normal.value};`;
      case ContextTypes.NEGATIVE_SECONDARY:
        return `border: 1px solid ${DESIGN_TOKENS.color.border.critical.value};`;
    }
  }

  if (state === ButtonState.PRESSED) {
    switch (context) {
      case ContextTypes.SECONDARY:
        return `border: 2px solid ${DESIGN_TOKENS.color.border.normal.value};`;
      case ContextTypes.NEGATIVE_SECONDARY:
        return `border: 1px solid ${DESIGN_TOKENS.color.border.critical.value};`;
    }
  }

  return 'border: none;';
}

export const ButtonContainer = styled.button<ButtonProps>`
  ${props => getButtonBackgroundColor(props.context, ButtonState.REGULAR, props.disabled)}
  ${props => getButtonBorder(props.context, ButtonState.REGULAR, props.disabled)}
  ${props => props.size === ButtonSizes.MEDIUM ? TITLES_NORMAL.FT_01 : LABEL_LARGE.DEFAULT}
  padding: ${props => props.size === ButtonSizes.MEDIUM ? '4px 21px' : '10px 24px'};
  color: ${props => getButtonContentColor(props.context, ButtonState.REGULAR, props.disabled)};
  display: ${props => props.width === ButtonWidth.FULL ? 'block' : 'inline-block'};
  width: ${props => props.width === ButtonWidth.FULL ? '100%' : 'initial'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-radius: 100px;
  transition: all 0.1s linear;
  background-size: calc(100% + 25%);
  user-select: none;
  text-align: center;

  :hover {
    ${props => props.context !== ContextTypes.SURF_TO_EARN && getButtonBackgroundColor(props.context, ButtonState.HOVER, props.disabled)};
    background-position: 75% 0;
    box-shadow: ${props => props.disabled ? '' : DESIGN_TOKENS.shadow.xs.value};
  }

  :active {
    ${props => getButtonBackgroundColor(props.context, ButtonState.PRESSED, props.disabled)};
  }

  :focus-visible { // for when focusing via tabbing rather than clicking; refer to https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
    box-shadow: ${props => props.disabled ? '' : '0 0 0 2px #ffffff, 0 0 0 4px #4b4b4b'};
  }

  span {
    margin-left: 8px;
  }
`;

export const ButtonIcon = styled.div<ButtonIconProps>`
  margin        : ${props => props.iconPosition === ButtonIconPosition.LEFT ? '0 4px 0 0' : '0 0 0 4px'};
  display       : inline-block;
  height        : 26px;
  vertical-align: middle;

  > svg {
    fill     : ${props => (
      props.iconCurrentColor
        ? 'currentcolor'
        : getButtonContentColor(props.context, ButtonState.REGULAR, props.disabled)
    )};
    width    : 24px;
    height   : 24px;
  }
`;
