import { COOKIE_IDS } from 'App/constants';
import { GIFT_CARD_SKU_VALUES } from 'Pages/rewards/constants';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getCookie, setCookie } from 'Shared/services/cookieService';
import { getLocalStorage } from 'Shared/services/localStorageService';

type BrazeCustomEventProperties = {
  [key in BrazeCustomPropertyNames]?: any;
};

/**
 * List of the Braze Gaming Triggers
 */
enum BrazeGamingTrigger {
  viewed_non_incent   = "viewed_non_incent",
  viewed_incent       = "viewed_incent",
}

export enum BrazeCustomEventNames {
  "survey_load"          = "survey_load",
  "viewed_rewards_page"  = "viewed_rewards_page",
  "clicked_rewards_tile" = "clicked_rewards_tile",
  "gaming_enabled"       = "gaming_enabled",
  "completed_gp"         = "completed_gp"
}

export enum BrazeCustomPropertyNames {
  "survey_count"       = "survey_count",
  "current_points"     = "current_points",
  "reward_type"        = "reward_type",
  "optimizely_variant" = "optimizely_variant",
  "gaming_triggered"   = "gaming_triggered",
}

type BrazeCookie = (typeof BRAZE_COOKIES)[keyof typeof BRAZE_COOKIES];

export const BRAZE_COOKIES = {
  SURVEY_LOAD        : COOKIE_IDS.BRAZE_SURVEY_LOAD_EVENT,
  REWARDS_PAGE_VISIT : COOKIE_IDS.BRAZE_REWARD_PAGE_VISIT,
  REWARD_OPTION_CLICK: COOKIE_IDS.BRAZE_REWARD_OPTION_CLICK,
  GAMING_ENABLED     : COOKIE_IDS.BRAZE_GAMING_ENABLED_EVENT,
};

const ONE_HOUR_BRAZE_COOKIE_TTL = 1/24;  // 1 hour
const ONE_DAY_BRAZE_COOKIE_TTL  = 1;     // 1 day

/**
 * Returns the reward type based on the rewardType to match Hermes reward type
 * https://gitlab.disqotech.com/opinion/retention/hermes-microservice/-/blob/61e414193d7c96552b0beb45b957b6b7c32366b4/internal/models/enums/virtual_incentives.go
 * @param rewardType
 * @returns
 */
const getGiftCardType = (rewardType: string): string => {
  switch (rewardType) {
    case GIFT_CARD_SKU_VALUES.AMAZON:
      return 'Amazon Gift Card';
    case GIFT_CARD_SKU_VALUES.WALMART:
      return 'Walmart Gift Card';
    case GIFT_CARD_SKU_VALUES.I_TUNES:
      return 'iTunes Gift Card';
    case GIFT_CARD_SKU_VALUES.TARGET:
      return 'Target Gift Card';
    case GIFT_CARD_SKU_VALUES.STARBUCKS:
      return 'Starbucks Gift Card';
    case GIFT_CARD_SKU_VALUES.SEPHORA:
      return 'Sephora Gift Card';
    case GIFT_CARD_SKU_VALUES.GROUPON:
      return 'Groupon Gift Card';
    default:
      return 'Visa Gift Card';
  }
};

export const PAYPAL_BRAZE_EVENT_NAME = 'PayPal';
export const DWOLLA_BRAZE_EVENT_NAME = 'Bank Transfer';

/**
 * Logs a custom event to Braze
 * Braze documentation: https://www.braze.com/docs/api/objects_filters/event_object/
 * @param eventName
 * @param properties
 * @returns
 */
const logCustomBrazeEvent = (eventName: BrazeCustomEventNames | BrazeGamingTrigger, properties: BrazeCustomEventProperties = {}, retryCount = 0): void => {
  const brazeSdk = window.appboy || window.braze;

  if (!brazeSdk || !brazeSdk.logCustomEvent) {
    if (retryCount <= 5) {
      setTimeout(() => {
        retryCount = retryCount + 1;
        logCustomBrazeEvent(eventName, properties, retryCount);
      }, 1000);
    }
    return;
  }

  try {
    brazeSdk.logCustomEvent(eventName, properties);
  } catch  {
    // Do nothing
  }
};

/**
 * Send a Braze event every hour (controlled by a cookie) with the survey count
 * @param surveyCount
 * @returns
 */
export const sendBrazeSurveyLoadEvent = (surveyCount: number): void => {
  const surveyEventSent = getCookie(BRAZE_COOKIES.SURVEY_LOAD);

  if (surveyEventSent) {
    return;
  }

  //send event to braze
  logCustomBrazeEvent(BrazeCustomEventNames.survey_load, {
    [BrazeCustomPropertyNames.survey_count]: surveyCount,
  });

  //set cookie for the next hour
  setBrazeSentEventCookie(BRAZE_COOKIES.SURVEY_LOAD, ONE_HOUR_BRAZE_COOKIE_TTL);
};

/**
 * Send a Braze event every day (controlled by a cookie) when the user visits the rewards page
 * @returns
 */
export const sendBrazeRewardsPageVisitEvent = (currentPoints: number): void => {
  const rewardsPageVisitEventSent = getCookie(BRAZE_COOKIES.REWARDS_PAGE_VISIT);

  if (rewardsPageVisitEventSent) {
    return;
  }

  //send event to braze
  logCustomBrazeEvent(BrazeCustomEventNames.viewed_rewards_page, {
    [BrazeCustomPropertyNames.current_points]: currentPoints,
  });

  // set cookie for the next 24 hours
  setBrazeSentEventCookie(BRAZE_COOKIES.REWARDS_PAGE_VISIT, ONE_DAY_BRAZE_COOKIE_TTL);
};

/**
 * Send a Braze event every day (controlled by a cookie) when the user clicks on a reward option
 * @param rewardType
 * @param currentPoints
 * @returns
 */
export const sendBrazeRewardOptionClickEvent = (rewardType: string, currentPoints: number, isGiftCard: boolean): void => {
  const rewardOptionClickEventSent = getCookie(BRAZE_COOKIES.REWARD_OPTION_CLICK);

  if (rewardOptionClickEventSent) {
    return;
  }

  let rewardTypeValue;

  if (isGiftCard) {
    rewardTypeValue = getGiftCardType(rewardType);
  } else {
    rewardTypeValue = rewardType;
  }

  logCustomBrazeEvent(BrazeCustomEventNames.clicked_rewards_tile, {
    [BrazeCustomPropertyNames.current_points]: currentPoints,
    [BrazeCustomPropertyNames.reward_type]   : rewardTypeValue
  });

  // set cookie for the next 24 hours
  setBrazeSentEventCookie(BRAZE_COOKIES.REWARD_OPTION_CLICK, ONE_DAY_BRAZE_COOKIE_TTL);
};

/**
 * Sets a cookie to indicate that the event has been sent to Braze
 * @param cookieName
 * @param ttl
 */
export const setBrazeSentEventCookie = (cookieName: BrazeCookie, ttl: number): void => {
  setCookie(cookieName, 'true', ttl);
};

/**
 * Checks if the games local storage exists
 * @returns {boolean}
 */
export const hasViewedGamesPage = (): boolean => {
  const config = getLocalStorage(LOCAL_STORAGE_IDS.BRAZE_GAMING_EVENTS, true) || {};
  return config[BrazeGamingTrigger.viewed_non_incent] || config[BrazeGamingTrigger.viewed_incent];
};

/**
 * Record the Braze event for the completion of the GP
 */
export const sendGpCompleteBrazeEvent = (): void => {
  logCustomBrazeEvent(BrazeCustomEventNames.completed_gp);
};