import React from 'react';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { StyledSpinner } from 'Shared/components/design/loadingSpinner/style';

type SpinnerProps = {
  heightWidth?  : string;
  circleColor?  : string;
  thickness?    : string;
  speed?        : number;
  'data-testid'?: string;
}

/**
 * Renders <LoadingSpinner /> component
 * @param heightWidth determines the size of the spinner in pixels
 * @param circleColor color of the spinning animation
 * @param thickness thickness of the circle border
 */
const LoadingSpinner = ({
  heightWidth  = '24px',
  circleColor  = DESIGN_TOKENS.color.text.normal.value,
  thickness    = '3px',
  speed        = 1,
  'data-testid': testId
}: SpinnerProps) => (
  <StyledSpinner
    heightWidth  = {heightWidth}
    circleColor  = {circleColor}
    thickness    = {thickness}
    speed        = {speed}
    data-testid  = {testId}
  >
    <circle/>
  </StyledSpinner>
);


export default LoadingSpinner;