import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const QualificationPromptContainer = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: center;
  padding-top    : 168px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    margin: 0px 24px 100px 24px;
  }

  h1 {
    ${TITLES_NORMAL.FT_02}
    margin-bottom: 16px;
  }

  p {
    ${PARAGRAPH.DEFAULT}
    color: ${DESIGN_TOKENS.color.text.secondary.value};
    margin-bottom: 40px;
  }
`;

export const QualificationPromptEmoji = styled.div`
  font-size    : 48px;
  margin-bottom: 40px;
`;
