import { INTER_FONT, LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type RedemptionStepProps = {
  hasRedeemed: boolean;
};

type ProgressBarProps = {
  isVisible: boolean;
};

type VerticalLineProps = {
  isStepCompleted: boolean;
};

type LockIconProps = {
  isLocked?: boolean;
};

export const StepperContainer = styled.div`
  display       : flex;
  flex-direction: column;
  margin-top    : 8px;
  margin-bottom : 8px;
`;

export const PointsStepContainer = styled.div<ProgressBarProps>`
  display       : flex;
  flex-direction: column;
  gap           : 4px;
  align-self    : stretch;
  min-height    : ${props => props.isVisible ? '75px' : '40px'};
`;

export const RedeemStepContainer = styled.div`
  display       : flex;
  flex-direction: column;
  align-self    : stretch;
  margin-top    : 4px;
`;

export const StepDescription = styled.div`
  ${LABEL_SMALL.DEFAULT};
  display        : flex;
  gap            : 8px;
  justify-content: flex-start;
  align-items    : center;
`;

export const StepDescriptionIconContainer = styled.div<LockIconProps>`
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 32px;
  cursor         : ${props => props.isLocked ? 'pointer' : 'default'};
`;

export const ProgressBarContainer = styled.div`
  display       : flex;
  flex-direction: row;
  gap           : 24px;
  max-height    : 65px;
  min-height    : 20px;
  flex-grow     : 1;
  flex-shrink   : 1;
`;

export const VerticalLine = styled.div<VerticalLineProps>`
  width           : 2px;
  max-height      : ${props => props.isStepCompleted ? '25px' : '90px'};
  background-color: lightgrey;
  margin-left     : 15px;
`;

export const Progress = styled.div<ProgressBarProps>`
  display   : ${props => props.isVisible ? 'flex' : 'none'};
  margin-top: -17px;
  flex-grow : 1;
  height    : 100%;
`;

export const ButtonContainer = styled.div`
  margin-left: 41px;
  margin-top : 15px;
`;

export const RedemptionDisclaimer = styled.p`
  ${INTER_FONT.PARAGRAPH}
  margin-left: 41px;
  color      : var(--System-Text-Text-Subtle, #6F6F6F);
  font-size  : 12px;
  font-style : normal;
  font-weight: 500;
  line-height: 16px;
`;

export const RedemptionStep = styled.div<RedemptionStepProps>`
  ${LABEL_SMALL.DEFAULT};
  display        : flex;
  gap            : ${props => props.hasRedeemed ? '16px' : '8px'};
  justify-content: flex-start;
  align-items    : center;

  svg {
    fill: purple;
  }
`;
