export const CHILDREN_QUESTION_TEST_ID                = 'children-test';
export const QUESTIONNAIRE_TEST_ID                    = 'questionnaire-test';
export const QUESTIONNAIRE_POPUP_TEST_ID              = 'questionnaire-popup-test';
export const HEADER_TEST_ID                           = 'survey-prompt-header-test';
export const HEADER_CLOSE_TEST_ID                     = 'survey-prompt-header-close-test';
export const MULTI_SELECT_TEST_ID                     = 'multiple-select-test';
export const NAV_BUTTONS_TEST_ID                      = 'nav-buttons-test';
export const QUESTION_TRACKER_TEST_ID                 = 'question-tracker-test';
export const SINGLE_SELECT_TEST_ID                    = 'single-select-test';
export const QUESTION_EMOJI_TEST_ID                   = 'question-emoji-test';
export const SURVEY_PROMPT_TEST_ID                    = 'survey-prompt-test';
export const SURVEY_PROMPT_BUTTON_TEST_ID             = 'survey-prompt-button-test';
export const QUAL_PROMPT_TEST_ID                      = 'qualification-prompt-test';
export const QUAL_PROMPT_BUTTON_TEST_ID               = 'qualification-prompt-button-test';
export const LOADING_SPINNER_TEST_ID                  = 'questionnaire-popup-loading-spinner';
export const SKIP_LINK_TEST_ID                        = 'skip-questionnaire-link';
export const SURVEY_MEDLEY_QUESTIONNAIRE_EXIT_TEST_ID = 'survey-medley-questionnaire-exit-test';
