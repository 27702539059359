import { logError } from 'App/services/coralogixService';
import { getUserId } from 'App/services/idpTokenService';
import { validateSurveyPromptByPoints } from 'Pages/surveyExperience/services/surveyPromptService';
import { ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { getUserCompletedActivities, getUserPoints } from 'Shared/services/act/api/actAPI';
import { getUserCacheData, setUserCacheData,UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import useSWR, { Middleware, SWRHook, SWRResponse } from 'swr';

let pointsPromise:Promise<ACTUserPointsResponse | null> | null = null;

/**
 * Gets user points data from ACT MS
 */
const getUserPointsFromACT = (optional = false): Promise<ACTUserPointsResponse | null> => {
  if (optional && !getUserId(true)) {
    return Promise.resolve(null);
  }

  if (pointsPromise) {
    return pointsPromise;
  }

  pointsPromise = new Promise<ACTUserPointsResponse>((resolve, reject) => {
    getUserPoints().then((response) => {
      const pointsModel = {
        currentPoints        : response?.data?.currentPoints,
        lifetimePoints       : response?.data?.lifetimePoints,
        minRedeemablePoints  : response?.data?.minRedeemablePoints,
        maxRedeemablePoints  : response?.data?.maxRedeemablePoints,
        pointsToCurrencyRatio: response?.data?.pointsToCurrencyRatio
      };

      setUserCacheData(UserSessionCacheKeys.USER_POINTS, pointsModel);

      resolve(pointsModel);
    })
    .catch(error => {
      logError('getUserPoints', error);
      reject(null);
    })
    .finally(() => {
      pointsPromise = null;
    });
  });

  return pointsPromise;
};

/**
 * Middleware function for SWR that retrieves user points and validates the legal prompt.
 */
const pointsRetrievalMiddleware: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const swr: SWRResponse<any, any> = useSWRNext(key, fetcher, config);

    const { lifetimePoints, currentPoints } = swr.data || {};

    if (currentPoints && lifetimePoints) {
      validateSurveyPromptByPoints({ currentPoints, lifetimePoints });
    }

    return swr;
  };
};

/**
 * Checks with ACT API to ensure that the user did not redeem in the first 24 hours of sign-up.
 *
 */
const useRedemptionsInFirst24Hours = (): SWRResponse => {
  const initialData = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_DATES);
  const createdAt   = initialData?.createdAt;

  const signUpDateObj = createdAt ? new Date(createdAt) : new Date();

  // Add 24 hours to the sign up date
  const oneDayAfterSignUp = new Date(signUpDateObj.getTime() + 24 * 60 * 60 * 1000).toISOString();

  const swr = useSWR(
    createdAt ? SwrKeys.UserActivitiesInLast24Hours : null,
    () => getUserCompletedActivities(signUpDateObj.toISOString(), oneDayAfterSignUp),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    ...swr,
    data: swr?.data?.data?.activities
  };
};

const useUserPointsFromACT = (optional = false) => {
  const initialData = getUserCacheData(UserSessionCacheKeys.USER_POINTS);
  const swr = useSWR(SwrKeys.UserPoints, () => getUserPointsFromACT(optional), {
    fallbackData: initialData,
    use         : [pointsRetrievalMiddleware]
  });
  let isLoading = swr.isLoading;

  if (typeof initialData !== 'undefined') {
    isLoading = false;
  }

  return {
    ...swr,
    isLoading
  };
};

export {
  getUserPointsFromACT,
  useRedemptionsInFirst24Hours,
  useUserPointsFromACT};

