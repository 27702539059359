import React, { useCallback } from 'react';

export type SwipeDirection = 'LEFT' | 'RIGHT' | 'UP' | 'DOWN';

function getSwipeDirection(deltaX: number, deltaY: number): SwipeDirection {
  const isHorizontal = Math.abs(deltaX) - Math.abs(deltaY) > 0;
  if (isHorizontal) {
    if (deltaX < 0) {
      return 'LEFT';
    } else {
      return 'RIGHT';
    }
  } else {
    if (deltaY < 0) {
      return 'UP';
    } else {
      return 'DOWN';
    }
  }
}

const DEFAULT_THRESHOLD = 50;

interface SwipeOptions {
  /**
   * If provided, makes the callback of the `useSwipe` function only be called when swiped in the given direciton.
   * @default undefined
   */
  onlyDirection?: SwipeDirection
  /**
   * The distance, in pixels, the user must swipe in order for it to count as a swipe gesture.
   * @default 50
   */
  threshold?: number
}

/**
 * Registers a gesture by which the app can determine if the user has swiped in any given direction (up, down, left, or right).
 * @param callback The callback to be called when the user swipes.  Can take in the direction the user swiped.
 * @param opts Sets the options to control the behavior of the swipe.
 * @returns A function to be used as an `onTouchStart` event on an element.
 * @example
 * const SwipeableDiv = () => {
 *   const onSwipe = useSwipe((direction) => alert(`You swiped me ${direction}`));
 *   return <div onTouchStart={onSwipe}>Swipe me!</div>;
 * }
 */
export function useSwipe<TElementType extends HTMLElement> (callback: (direction: SwipeDirection) => void, opts?: SwipeOptions) {
  const {
    onlyDirection,
    threshold = DEFAULT_THRESHOLD,
  } = opts || {};

  return useCallback((startEv: React.TouchEvent<TElementType>) => {
    // Ignore multi-touch gestures; we only care if the user swipes with one finger
    if (startEv.touches.length > 1) { return; }

    const touchTarget = startEv.currentTarget as TElementType;
    const touch = startEv.changedTouches[0];
    const startX = touch.clientX;
    const startY = touch.clientY;
    touchTarget.addEventListener('touchend', (endEv) => {
      // Ignore multi-touch gestures; we only care if the user swipes with one finger
      if (endEv.touches.length > 0) { return; }

      const touch = endEv.changedTouches[0];
      const deltaX = touch.clientX - startX;
      const deltaY = touch.clientY - startY;
      const isSwipe = Math.abs(deltaX) >= threshold || Math.abs(deltaY) >= threshold;
      if (!isSwipe) { return; }

      const direction = getSwipeDirection(deltaX, deltaY);
      if (onlyDirection && direction !== onlyDirection) { return; }
      
      callback(direction);
    }, { once: true });
  }, [callback, onlyDirection, threshold]);
}
