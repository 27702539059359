import { APP_TYPES } from 'Shared/constants';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';

/**
 * Set Opt-in event into Behavioral Signals Service
 */
const setOptInEventInBss = (data: object, platform: string): Promise<APIResponse> => {
  const baseURL = platform === APP_TYPES.EXTENSION
    ? process.env.BEHAVIORAL_SIGNALS_BASE_EXT : process.env.BEHAVIORAL_SIGNALS_BASE_DESK;
  const baseURI = platform === APP_TYPES.EXTENSION
    ? process.env.BEHAVIORAL_SIGNALS_SERVICE_EXT : process.env.BEHAVIORAL_SIGNALS_SERVICE_DESK;
  return MS_API({
    method : 'POST',
    baseURL: baseURL,
    url    : baseURI,
    data   : data,
  });
};

export {
  setOptInEventInBss
};
