import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type VdsDatepickerTextProps = {
  hasError: boolean
}

type VdsDatepickerContainerProps = {
  isIOS: boolean
}

export const VdsDatepickerContainer = styled.div<VdsDatepickerContainerProps>`

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    ${ props => props.isIOS ? `
      vds-datepicker::part(base) {
        position: relative;
        background: transparent;
        z-index: 2;
      }
    ` : '' }
  }
`;

export const VdsDatepickerText = styled.span<VdsDatepickerTextProps>`
  ${ props => props.hasError ? `color: ${ DESIGN_TOKENS.color.text.critical.value }` : '' }
`;