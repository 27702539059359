import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_BASIC_PROFILE_TYPES, ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE, ANALYTICS_RESPONSE_CODES } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type BasicProfileEndEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.login_method]     : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.total_steps]      : number,
  [ANALYTICS_EVENT_ATTRIBUTES.item_variant]     : ANALYTICS_BASIC_PROFILE_TYPES,
  [ANALYTICS_EVENT_ATTRIBUTES.response_code]    : ANALYTICS_RESPONSE_CODES | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.response_message] : string | undefined;
  [ANALYTICS_EVENT_ATTRIBUTES.current_step]     : number | undefined;
}

/**
 * Sends analytic event and attributes for Basic Profile to Google Analytics
 * @param basicProfileEndEvent
 * @returns void
 */
export const analyticsTrackBasicProfileEnd = (basicProfileEndEvent: BasicProfileEndEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      ...basicProfileEndEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]       : ANALYTICS_EVENT_TYPE.questionnaire,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]   : ANALYTICS_EVENT_SUB_TYPE.basic_profile,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
};