import React, { useState } from 'react';
import { ACTIVE_INDICATOR_TEST_ID, PROMO_NAV_BUTTON_TEST_ID } from 'Shared/components/design/promoNavButton/constants';
import PromoToolTip from 'Shared/components/design/promoNavButton/promoToolTip/PromoToolTip';
import { ActiveIndicator, PromoButtonContainer } from 'Shared/components/design/promoNavButton/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

type PromoNavButtonProps = {
  clickCallback: () => void;
  isActive     : boolean;
};

/**
 * Renders the Promo Nav Button in mobile view
 * @param param0
 * @returns
 */
function PromoNavButton({clickCallback, isActive}: PromoNavButtonProps): JSX.Element {
  const [isTooltipViewed, setIsTooltipViewed] = useState(false);

  /**
   * Handles the the nav button press
   */
  const handleClick = () => {
    if (!isTooltipViewed) {
      setIsTooltipViewed(true);
    }
    clickCallback();
  };

  return (
    <>
      <PromoButtonContainer onClick={handleClick} data-testid={PROMO_NAV_BUTTON_TEST_ID}>
        <Icon iconId={ICON_IDS.PROMO_BOLT} color='#3C245D' height={24} width={24}/>
        <ActiveIndicator isActive={isActive} data-testid={ACTIVE_INDICATOR_TEST_ID}>
          <Icon iconId={ICON_IDS.ACTIVE_INDICATOR} height={8} width={8}/>
        </ActiveIndicator>
      </PromoButtonContainer>
      <PromoToolTip closeTooltip={isTooltipViewed} isMobile={true} />
    </>
  );
}

export default PromoNavButton;
