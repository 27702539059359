import { SURVEY_LAYOUTS } from 'Shared/constants';
import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES, ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_EVENT_TYPE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";
import { getSurveyLayout } from 'Shared/services/helperService';
import { UspSurveysModel } from "Shared/services/usp/models/UspSurveysModel";

interface ActivityImpressionEvent {
  [ANALYTICS_EVENT_ATTRIBUTES.item_id]        : string,
  [ANALYTICS_EVENT_ATTRIBUTES.item_name]      : string,
  [ANALYTICS_EVENT_ATTRIBUTES.item_variant]   : string,
  [ANALYTICS_EVENT_ATTRIBUTES.item_brand]     : string,
  [ANALYTICS_EVENT_ATTRIBUTES.item_category]  : ANALYTICS_EVENT_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.item_category2] : ANALYTICS_EVENT_SUB_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.item_category3] : SURVEY_LAYOUTS,
  [ANALYTICS_EVENT_ATTRIBUTES.item_category4] : number,
  [ANALYTICS_EVENT_ATTRIBUTES.item_category5] : number,
  [ANALYTICS_EVENT_ATTRIBUTES.price]          : number,
  [ANALYTICS_EVENT_ATTRIBUTES.index]          : number,
  [ANALYTICS_EVENT_ATTRIBUTES.currency]       : string,
  [ANALYTICS_EVENT_ATTRIBUTES.quantity]       : number,
}

const MAX_SURVEYS_PER_EVENT                           = 100;
let   previousActivityList: ActivityImpressionEvent[] = [];

/**
 * Determines whether if the activityList is different from the previous one.
 * @returns {boolean}
 */
const isActivityListDifferent = (activityList: ActivityImpressionEvent[]): boolean => {
  if (activityList.length !== previousActivityList.length) {
    return true;
  }

  for (let x = 0; x < activityList.length; x++) {
    const currentActivity = activityList[x];
    const previousActivity = previousActivityList[x];

    if (currentActivity[ANALYTICS_EVENT_ATTRIBUTES.item_id] !== previousActivity[ANALYTICS_EVENT_ATTRIBUTES.item_id]) {
      return true;
    }
  }

  return false;
};

/**
 * Extracts recursively the survey keys start from 'is' and have value 'true' from the survey object.
 * @param survey
 * @param result
 */
export const extractSurveyAttributes = (survey: any, result = ''): string => {
  if (!survey) {
    return result;
  }

  Object.keys(survey).forEach((key: string) => {
    const value = survey[key];

    if (value === null) {
      return;
    }

    if (typeof value === 'object' && value.constructor === Object) {
      result = `${extractSurveyAttributes(value, result)} `;
    } else if (key.startsWith('is') && value === true) {
      result += `${key} `;
    }
  });
  return result.trim();
};

/**
 * Allows tracking of activity impressions. Currently, they are only surveys.
 * @param activityList - list of surveys
 */
export const analyticsTrackActivityImpressions = (activityList: UspSurveysModel[]): void => {
  const surveyLayout: SURVEY_LAYOUTS = getSurveyLayout();
  let   hasAdm                       = false;

  // Create the list of the top 100 activity items
  const finalActivityList: ActivityImpressionEvent[] = activityList.slice(0, MAX_SURVEYS_PER_EVENT).map((activityItem: UspSurveysModel, index: number): ActivityImpressionEvent => {
    if (!hasAdm && activityItem.attributes.isAdm === true) {
      hasAdm = true;
    }

    return {
      [ANALYTICS_EVENT_ATTRIBUTES.item_id]       : activityItem.id,
      [ANALYTICS_EVENT_ATTRIBUTES.item_name]     : activityItem.id,
      [ANALYTICS_EVENT_ATTRIBUTES.item_variant]  : extractSurveyAttributes(activityItem),
      [ANALYTICS_EVENT_ATTRIBUTES.item_brand]    : (activityItem.providerId || '') + '',
      [ANALYTICS_EVENT_ATTRIBUTES.item_category] : ANALYTICS_EVENT_TYPE.survey,
      [ANALYTICS_EVENT_ATTRIBUTES.item_category2]: ANALYTICS_EVENT_SUB_TYPE.survey_list,
      [ANALYTICS_EVENT_ATTRIBUTES.item_category3]: surveyLayout,
      [ANALYTICS_EVENT_ATTRIBUTES.item_category4]: index + 1,
      [ANALYTICS_EVENT_ATTRIBUTES.item_category5]: activityItem.duration,
      [ANALYTICS_EVENT_ATTRIBUTES.price]         : activityItem.points,
      [ANALYTICS_EVENT_ATTRIBUTES.index]         : index,
      [ANALYTICS_EVENT_ATTRIBUTES.currency]      : 'gbp',
      [ANALYTICS_EVENT_ATTRIBUTES.quantity]      : 1,
    };
  });

  // Create the view_item_list event
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.view_item_list,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.item_list_name]: `survey_list_${activityList.length}`,
      [ANALYTICS_EVENT_ATTRIBUTES.items]         : finalActivityList
    }
  };

  //determine the event category based on presence of ADM surveys
  const eventCategory = hasAdm ? GA4_EVENT_CATEGORY.survey_impressions_adm : GA4_EVENT_CATEGORY.survey_impressions;

  if (isActivityListDifferent(finalActivityList)) {
    previousActivityList = finalActivityList;
    trackEventConditionally(eventCategory, [event]);
  }
};

/**
 * Tracks survey impressions with an analytics event if no surveys are currently present
 * and there are no more surveys to display.
 *
 * @param {number} surveyListLength - The length of the survey list.
 * @param {boolean} hasMoreSurveys - Flag indicating if there are more surveys available to load.
 * @returns {void}
 */
export const analyticsTrackSurveyImpressionsIfAbsent = (surveyListLength: number, hasMoreSurveys: boolean): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.view_item_list,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.item_list_id]: 'no_surveys',
      [ANALYTICS_EVENT_ATTRIBUTES.items]       : []
    }
  };

  if (surveyListLength === 0 && !hasMoreSurveys) {
    trackEventConditionally(GA4_EVENT_CATEGORY.survey_impressions, [event]);
  }
};