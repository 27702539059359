import React from 'react';
import { SSOButtonsContainer } from 'Pages/signIn/components/SSOButtons/style';
import { CONTAINER } from 'Pages/signIn/components/SSOButtons/testId';
import { SSOProvider } from 'Shared/components/design/SSOButton/constants';
import SSOButton from 'Shared/components/design/SSOButton/SSOButton';

export interface SSOButtonsProps {
  onClick?: (provider: SSOProvider) => void;
}

function SSOButtons({ onClick }: SSOButtonsProps): JSX.Element {
  return <SSOButtonsContainer data-testid={CONTAINER}>
    {Object.values(SSOProvider).map((provider) => {
      return <SSOButton key={`sso-button-provider-${provider}`} provider={provider} onClick={() => onClick?.(provider)} />;
    })}
  </SSOButtonsContainer>;
}

export default SSOButtons;
