import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES, ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_EVENT_TYPE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

interface ActivityClickEvent {
  [ANALYTICS_EVENT_ATTRIBUTES.id]                  : string,
  [ANALYTICS_EVENT_ATTRIBUTES.points]              : number,
  [ANALYTICS_EVENT_ATTRIBUTES.provider_id]         : number,
  [ANALYTICS_EVENT_ATTRIBUTES.loi]                 : number,
  [ANALYTICS_EVENT_ATTRIBUTES.position]            : number,
  [ANALYTICS_EVENT_ATTRIBUTES.sub_type]            : ANALYTICS_EVENT_SUB_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.has_prequalification]: boolean,
  [ANALYTICS_EVENT_ATTRIBUTES.survey_count]        : number,
  [ANALYTICS_EVENT_ATTRIBUTES.survey_load_status]  : string,
}

/**
 * Allows tracking of activity clicks. Currently, they are only surveys.
 * @param activityList - list of surveys
 */
export const analyticsTrackActivityClick = (activityClick: ActivityClickEvent): void => {
  // Create the view_item_list event
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]: ANALYTICS_EVENT_TYPE.survey,
      ...activityClick
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.survey_clicks, [event]);
};
