import React, { useCallback,useEffect, useRef, useState } from 'react';
import { CONFETTI_TEST_ID } from 'Shared/components/design/confetti/constants';
import { patchPromoUiState } from 'Shared/services/promo/api/promoApi';
import { PromoUiState } from 'Shared/services/promo/app/promoService';

type ConfettiComponentProps = {
  triggerConfetti : boolean;
  promoId        ?: string;
  confettiShown   : boolean;
};

type ConfettiModule = typeof import('canvas-confetti');

// Preload the confetti module asynchronously
const confettiModulePromise: Promise<ConfettiModule> = import('canvas-confetti');

/**
 * Renders a confetti animation on the screen
 * library used: https://github.com/catdad/canvas-confetti?tab=readme-ov-file
 * @param param0
 * @returns
 */
const ConfettiComponent: React.FC<ConfettiComponentProps> = ({ triggerConfetti, promoId, confettiShown }) => {
  const confettiCanvasRef = useRef<HTMLCanvasElement>(null);
  const animationTimeoutRef = useRef<NodeJS.Timeout>();
  const [isAnimating, setIsAnimating] = useState(false);

  // Memoize the confetti animation function to prevent recreating it on each render
  const triggerConfettiAnimation = useCallback(() => {
    const canvas = confettiCanvasRef.current;
    if (!canvas) {
      return;
    }

    setIsAnimating(true);

    confettiModulePromise.then((confettiModule) => {
      const myConfetti = confettiModule.default.create(canvas, {
        resize: true,
      });

      myConfetti({
        particleCount: 600,
        spread       : 200,
        origin       : { x: 0.5, y: 0.6 },
        colors       : ['#ff0000', '#00ff00', '#0000ff'],
      });
    });

    // Clear any existing timeout
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }

    // Set a new timeout
    animationTimeoutRef.current = setTimeout(() => {
      setIsAnimating(false);
    }, 4000);
  }, []);

  // Handle confetti animation
  useEffect(() => {
    if (triggerConfetti) {
      triggerConfettiAnimation();
    }

    // Cleanup function
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [triggerConfetti, triggerConfettiAnimation]);

  /**
   * Patch the promoUiState to set the promoConfettiShown to true
   */
  useEffect(() => {
    if (triggerConfetti && !confettiShown && promoId) {
      const patchConfettiShown: PromoUiState = {
        confettiShown: true,
      };

      patchPromoUiState(promoId, patchConfettiShown);
    }
  }, [confettiShown, promoId, triggerConfetti]);

  // Use React.memo for the canvas element to prevent unnecessary re-renders
  const canvasStyle = {
    position     : 'absolute' as const,
    top          : 0,
    left         : 0,
    width        : '100%',
    height       : '100%',
    zIndex       : isAnimating ? 9999  : -1,
    pointerEvents: isAnimating ? 'auto' as const : 'none' as const,
  };

  return (
    <canvas
      ref={confettiCanvasRef}
      data-testid={CONFETTI_TEST_ID}
      style={canvasStyle}
    />
  );
};

// Wrap the component with React.memo to prevent unnecessary re-renders
export default React.memo(ConfettiComponent);
