import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import { Link,useNavigate  } from 'react-router-dom';
import { RemoveGlobalOverflowStyle } from 'App/components/style';
import { PASSWORD_MAXIMUM_CHARACTERS,PASSWORD_MINIMUM_CHARACTERS } from 'App/constants/password';
import {MEMBER_FORGOT_PASSWORD_URL} from 'App/routes/constants';
import { logError } from 'App/services/coralogixService';
import { getAccessTokenCookie, purgeAuthCookies } from 'App/services/idpTokenService';
import SignUpLink from 'Pages/forgotPassword/components/signUpLink/SignUpLink';
import {BASIC_PROFILE_QUESTION_IDS} from "Pages/help/constants";
import {EXPIRED_PASSWORD_BANNER_TEST_ID, SIGN_IN_FORM_TEST_ID, SIGN_IN_GENERIC_ERROR_TEST_ID} from 'Pages/help/testId';
import Header from "Pages/signIn/components/header/Header";
import SSOButtons from 'Pages/signIn/components/SSOButtons/SSOButtons';
import { NOTIFICATION_CODE_QUERY_PARAM } from 'Pages/signIn/constants/queryParams';
import {
  ContentContainer,
  CtaContainer,
  RightAlignedDiv,
  StyledAnchor,
  TextFieldContainer
} from 'Pages/signIn/style';
import { Banner, BannerContextTypes } from 'Shared/components/design/banner/Banner';
import { Button, ButtonSizes, ButtonWidth } from "Shared/components/design/button/Button";
import {BUTTON_TEST_IDS} from "Shared/components/design/button/constants";
import Hr from 'Shared/components/design/Hr/Hr';
import {Modal} from "Shared/components/design/modal/Modal";
import PasswordInput from "Shared/components/design/passwordInput/PasswordInput";
import {TextField, TextFieldTypes} from "Shared/components/design/textField/TextField";
import { ICON_IDS } from 'Shared/components/icons/constants';
import useInterval from 'Shared/hooks/useInterval';
import {useKeyDown} from 'Shared/hooks/useKeyDown';
import { useQueryParam } from 'Shared/hooks/useQueryParam';
import { ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { getUserPointsFromACT } from 'Shared/services/act/app/actService';
import {initAnalytics} from 'Shared/services/analytics/config';
import {ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_LOGIN_ERROR_TYPES, ANALYTICS_RESPONSE_CODES} from 'Shared/services/analytics/constants';
import { analyticsTrackLoginEnd } from 'Shared/services/analytics/events/loginEnd';
import { analyticsTrackLoginStart } from 'Shared/services/analytics/events/loginStart';
import {isValidEmail, isValidPassword} from 'Shared/services/inputValidationService';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import {
  navigateToContactUsPage,
  navigateToMemberPage,
  navigateToUnrecognizedDevicePage,
  useNavigateToForgotPasswordPage,
  useNavigateToSignUpPage
} from 'Shared/services/routingService';
import {
  getAuthTokensUsingCredentials,
  getUserInfo,
} from 'Shared/services/userActivation/api/idpApiService';
import { IDP_ERROR_CODE_GENERIC, IDP_ERROR_CODE_PASSWORD_EXPIRED, IDP_ERROR_UNKNOWN_DEVICE_ID } from 'Shared/services/userActivation/app/idpService';
import { getQuestionAnswers } from 'Shared/services/userActivation/app/questionService';

type InputState = {
  fieldType  : TextFieldTypes;
  helperText?: string;
};

export const SSO_TOKEN_POLL_INTERVAL_MS = 500;

function SignInForm(): JSX.Element {
  const {t}                                     = useTranslation('signIn');
  const presetErrorCode                         = useQueryParam(NOTIFICATION_CODE_QUERY_PARAM);
  const [inputEmail, setEmailAddress]           = useState<string>('');
  const [inputPassword, setInputPassword]       = useState<string>('');
  const [emailState, setEmailState]             = useState<InputState>({fieldType: TextFieldTypes.DEFAULT});
  const [passwordState, setPasswordState]       = useState<InputState>({fieldType: TextFieldTypes.DEFAULT});
  const [signInErrorCode, setSignInErrorCode]   = useState<number | undefined>(presetErrorCode ? parseInt(presetErrorCode) : undefined);
  const [signInError, setSignInError]           = useState<boolean>(false);
  const [validEmail, setValidEmail]             = useState<boolean>(false);
  const [validPassword, setValidPassword]       = useState<boolean>(false);
  const [isLoading, setIsLoading]               = useState<boolean>(false);
  const navigate                                = useNavigate();
  const navigateToForgotPasswordPage            = useNavigateToForgotPasswordPage();
  const navigateToSignUpPage                    = useNavigateToSignUpPage();

  useEffect(() => {
    // If the user lands on the login page
    // then purge their auth cookies
    // so they can login without any issues
    purgeAuthCookies();
  }, []);

  // SSO Polling state
  const [loggedInSSO, setLoggedInSSO]     = useState(false);
  const [shouldPollSSO, setShouldPollSSO] = useState(false);

  // Polling for login cookie if SSO login
  const pollDelay = shouldPollSSO && !loggedInSSO ? SSO_TOKEN_POLL_INTERVAL_MS : null;
  useInterval(() => {
    const token = getAccessTokenCookie();
    if (token) {
      setLoggedInSSO(true);
      setShouldPollSSO(false);
      navigateToMemberPage(navigate);
    }
  }, pollDelay);


  /**
   * Detecting Enter key press to submit login form
   */
  useKeyDown(() => {
    if ((validEmail && validPassword)) {
      submitLogInForm();
    }
  });

  /**
   * Submits the login form
   */
  const submitLogInForm = () => {
    analyticsTrackLoginStart(ANALYTICS_EVENT_SUB_TYPE.login_page);
    setSignInErrorCode(undefined);
    setSignInError(false);
    setIsLoading(true);

    getAuthTokensUsingCredentials(inputEmail, inputPassword).then(() => {
      // If the user hasn't finished their basic profile, redirect to basic profile
      getQuestionAnswers(BASIC_PROFILE_QUESTION_IDS).then((response) => {
        if (response.length < BASIC_PROFILE_QUESTION_IDS.length) {
          initAnalytics({}, true);
          analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_page, ANALYTICS_LOGIN_ERROR_TYPES.partial_registration);
          navigateToSignUpPage();
          return;
        }

        Promise.all([
          getUserInfo(),
          getUserPointsFromACT()
        ]).then(responses => {
          initializeSplitTests(SplitTestInitializationLocation.SIGNIN);
          const [userInfoResponse, userPoints] = responses;
          let pointData: ACTUserPointsResponse = {} as ACTUserPointsResponse;
          if (userPoints !== null) {
            pointData = userPoints;
          }

          // Update GA4 Since Log In
          initAnalytics({
            id             : userInfoResponse.data.id,
            current_points : pointData.currentPoints,
            affiliate      : userInfoResponse.data.affiliate,
            lifetime_points: pointData.lifetimePoints,
            signup_source  : userInfoResponse.data.signupSource
          });

          analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_page);
          setIsLoading(false);
          navigateToMemberPage(navigate);
        });
      });
    }).catch((error) => {
      const errorCodes = error.response?.data?.errorCodes || [];

      analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_page, errorCodes.join(',') || ANALYTICS_RESPONSE_CODES.error);
      setIsLoading(false);

      if (errorCodes.includes(IDP_ERROR_UNKNOWN_DEVICE_ID)) {
        const authCode = error.response.data.sendDeviceRequestAuth;
        navigateToUnrecognizedDevicePage(inputEmail, authCode);
      } else if (errorCodes.length) {
        setSignInErrorCode(errorCodes[0]);
      } else {
        setSignInError(true);
      }
      logError('getAuthTokensUsingCredentials', error);
    });
  };

  /**
   * Sets the email to the state if valid
   * @param newEmail
   */
  const changeEmail = (newEmail: string) => {
    if (isValidEmail(newEmail)) {
      setEmailState({fieldType: TextFieldTypes.DEFAULT});
      setValidEmail(true);
    }

    setEmailAddress(newEmail);
  };

  /**
   * Validates the email on blur
   * @param newEmail
   */
  const validateEmail = (newEmail: string) => {
    // Only validate if the person has entered something
    if (newEmail.length > 0) {
      if (!isValidEmail(newEmail)) {
        setEmailState({fieldType: TextFieldTypes.ERROR, helperText: t('pleaseEnterValidEmailAddress')});
        setValidEmail(false);
      }
    } else {
      setEmailState({fieldType: TextFieldTypes.DEFAULT});
      setValidEmail(false);
    }
  };

  /**
   * Sets the password to the state if valid
   * @param newPassword
   */
  const changePassword = (newPassword: string) => {
    if (isValidPassword(newPassword)) {
      setPasswordState({fieldType: TextFieldTypes.DEFAULT});
      setValidPassword(true);
    }

    setInputPassword(newPassword);
  };

  /**
   * Validates the password on blur
   * @param newPassword
   */
  const validatePassword = (newPassword: string) => {
    // Only validate if the person has entered something
    if (newPassword.length > 0) {
      if (!isValidPassword(newPassword)) {
        setPasswordState({
          fieldType : TextFieldTypes.ERROR,
          helperText: t('mustBeAtLeastXCharactersNoSpaces', {minimum: PASSWORD_MINIMUM_CHARACTERS, maximum: PASSWORD_MAXIMUM_CHARACTERS})
        });
        setValidPassword(false);
      }
    } else {
      setPasswordState({fieldType: TextFieldTypes.DEFAULT});
      setValidPassword(false);
    }
  };

  const navigateToForgotPasswordFlow = () => {
    if (isValidEmail(inputEmail)) {
      navigateToForgotPasswordPage(inputEmail);
    } else {
      navigateToForgotPasswordPage();
    }
  };

  /**
   * Returns the log in error if it should
   */
  const getSignInError = (): JSX.Element | undefined => {
    switch (signInErrorCode) {
      case IDP_ERROR_CODE_PASSWORD_EXPIRED:
        return (
          <Banner
            context     = {BannerContextTypes.LIGHT_NEGATIVE}
            icon        = {ICON_IDS.INFO_FILL}
            text        = {<Trans
              t={t}
              i18nKey='yourPasswordHasExpiredPleaseUseTheFollowingLinkToResetYourPassword'
            >
              Your password has expired. Please use the <Link to={MEMBER_FORGOT_PASSWORD_URL}>following link</Link> to reset your password.
            </Trans>}
            fillParent
            data-testid = {EXPIRED_PASSWORD_BANNER_TEST_ID}
          />
        );
      case IDP_ERROR_CODE_GENERIC:
        return (
          <Banner
            context     = {BannerContextTypes.LIGHT_NEGATIVE}
            icon        = {ICON_IDS.INFO_FILL}
            text        = {t('genericErrorBanner')}
            fillParent
            data-testid = {SIGN_IN_GENERIC_ERROR_TEST_ID}
          />
        );
    }

    if (!signInError) {
      return;
    }

    return <Modal
      title              = {t('hmmm')}
      text               = {t('thatEmailAndPasswordCombinationIsIncorrectPleaseTryAgain')}
      confirmButtonText  = {t('ok')}
      showCloseIcon      = {false}
      cancelButtonText   = {t('forgotPassword')}
      confirmButtonClick = {() => setSignInError(false)}
      cancelButtonClick  = {() => navigateToForgotPasswordFlow()}
    />;
  };

  return (
    <>
      <RemoveGlobalOverflowStyle />
      <div data-testid = {SIGN_IN_FORM_TEST_ID}>
        <Header
          questionCallback = {() => navigateToContactUsPage()}
        />
        <ContentContainer>
          {getSignInError()}
          <SSOButtons onClick={() => setShouldPollSSO(true)}/>
          <Hr text={t('SignInForm--hr--text')} />
          <TextFieldContainer>
            <TextField
              label          = {t('emailAddress')}
              changeCallback = {changeEmail}
              blurCallback   = {validateEmail}
              fieldType      = {emailState.fieldType}
              helperText     = {emailState.helperText}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <PasswordInput
              label          = {t('password')}
              changeCallback = {changePassword}
              blurCallback   = {validatePassword}
              fieldType      = {passwordState.fieldType}
              helperText     = {passwordState.helperText}
            />
          </TextFieldContainer>
          <RightAlignedDiv>
            <StyledAnchor to={MEMBER_FORGOT_PASSWORD_URL} state={{inputEmail: isValidEmail(inputEmail) ? inputEmail : ''}}>{t('forgotPassword')}</StyledAnchor>
          </RightAlignedDiv>
          <CtaContainer>
            <Button
              width         = {ButtonWidth.FULL}
              size          = {ButtonSizes.LARGE}
              isLoading     = {isLoading}
              interactable  = {!isLoading}
              disabled      = {!(validEmail && validPassword)} onClick = {() => submitLogInForm()}
              data-testid   = {BUTTON_TEST_IDS.BUTTON_TEST}
            >
              {t('signIn--button')}
            </Button>
          </CtaContainer>
          <SignUpLink />
        </ContentContainer>
      </div>
    </>
  );
}

export default SignInForm;