import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { useBannerHeight, useFullHeight } from 'Shared/context/bannerHeight';
import { DEVICE_BREAKPOINT, MOBILE_HEADER_HEIGHT } from 'Shared/styles/designConstants';
import styled, {createGlobalStyle} from 'styled-components';

export const getBannerHeight = () => document.getElementById('notifications-banner-root')?.clientHeight ?? 0;
export const LEFT_CONTAINER_SIZE          = '88px';
export const RIGHT_CONTENT_CONTAINER_SIZE = '1085px';
export const RIGHT_CONTENT_LG_CONTAINER_SIZE = '1495px';

type RightContentContainerProp = {
  isFullWidth   : boolean;
  isThreeColumn?: boolean;
}

type GlobalStyleProp = {
  canRedeem      : boolean;
  isPointsLoading: boolean;
}

type AppBackground = {
  background: string;
  gradient  : string;
}

const getAppBackground = (isLoading: boolean, canRedeem: boolean): AppBackground => {
  if (isLoading || window.location.href.includes(MENU_ROUTES.ACCOUNT_HOME)) {
    return {
      background: DESIGN_TOKENS.color.background.primary.value,
      gradient  : DESIGN_TOKENS.color.background.primary.value,
    };
  } else if (canRedeem) {
    const canRedeemedStartColor = '#D6FBFF';
    return {
      background: canRedeemedStartColor,
      gradient  : `linear-gradient(180deg, rgba(255,255,255,0.2) 8.7%, rgba(255,255,255,1) 54.65%), linear-gradient(90deg, rgba(255,220,2,0.5) 0%, rgba(224,77,217,0.3) 60%, rgba(0,183,204,0.25) 79%)`
    };
  } else {
    const cannotRedeemedStartColor = '#FFF5D1';
    return {
      background: cannotRedeemedStartColor,
      gradient  : `linear-gradient(180deg,${ cannotRedeemedStartColor } 8.7%,rgba(255,255,255,1) 44.65%)`
    };
  }
};

export const MainContainer = styled.div`
  display: block;
  width  : 100%;
`;

export const RemoveGlobalOverflowStyle = createGlobalStyle`
  #app-root {
    overflow: visible;
  }
`;

export const PointsBackground = styled.div<GlobalStyleProp>`
  background           : ${ props => getAppBackground(props.isPointsLoading, props.canRedeem).gradient };
  background-color     : #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

export const AppContainer = styled.div`
  display  : flex;
  flex-grow: 1;
  position : relative;

  @media ${ DEVICE_BREAKPOINT.maxMobile } {
    display: block;
  }
`;

export const LeftContainer = styled.div`
  position: sticky;
  top     : ${() => useBannerHeight()};
  left    : 0;
  width   : ${LEFT_CONTAINER_SIZE};
  height  : ${() => useFullHeight()};
  z-index : 11;

  @media ${ DEVICE_BREAKPOINT.maxMobile } {
    display: block;
    height : ${MOBILE_HEADER_HEIGHT};
    width  : 100%;
  }
`;

export const RightContainer = styled.div`
  flex-grow: 1;
`;

/**
 * Get the width of the right container
 * @param isFullWidth
 * @param isThreeColumn
 */
const getRightContainerWidth = (isFullWidth: boolean, isThreeColumn: boolean): string => {
  if (isFullWidth) {
    return '100%';
  }

  if (isThreeColumn) {
    return RIGHT_CONTENT_LG_CONTAINER_SIZE;
  }

  return RIGHT_CONTENT_CONTAINER_SIZE;
};

export const RightContentContainer = styled.div<RightContentContainerProp>`
  max-width: ${ props => getRightContainerWidth(props.isFullWidth, !!props.isThreeColumn)};
  margin   : 0 auto;
  display  : flex;

  @media ${ DEVICE_BREAKPOINT.maxThreeColumn } {
    max-width: ${ props => props.isFullWidth ? 'none' : RIGHT_CONTENT_CONTAINER_SIZE };
  }

  @media ${ DEVICE_BREAKPOINT.minThirdRail } {
    max-width: ${ props => props.isFullWidth ? 'none' : '775px' };
  }
`;

export const RightContentBottomContainer = styled.div`
  display         : flex;
  background-color: #F4F4F5;

  @media ${ DEVICE_BREAKPOINT.maxTablet } {
    display: none;
  }
`;

