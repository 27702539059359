import { MEMBER_PANELS_URL, PAGE_MEMBER } from 'App/routes/constants';
import {
  initGetSurveys,
  resetSurveyRequest,
  SURVEY_REQUEST_REFERRER
} from 'Pages/surveyExperience/services/surveyService';
import { shouldImmediatelyRefreshSurveys } from 'Pages/surveyExperience/services/surveysLoading';
import { UspApiGetModel } from 'Shared/services/usp/models/UspApiGetModel';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';

/**
 * Re-fetches the survey list or redirects to the panel page if the user is eligible
 * @param surveyList
 * @param response
 */
export const refetchOrRedirectToPanelPageIfEligible = (() => {
  const maxRefetchAmount = 1;
  let refetchCounter     = 0;

  return (surveyList: UspSurveysModel[], response: UspApiGetModel) => {
    const isOnHomePage = window.location.pathname === PAGE_MEMBER;
    if (surveyList.length === 0) {
      if (isOnHomePage && !response.hasMoreSurveys && shouldImmediatelyRefreshSurveys()) {
        if (refetchCounter < maxRefetchAmount) {
          refetchCounter++;
          resetSurveyRequest();
          initGetSurveys(SURVEY_REQUEST_REFERRER.DASHBOARD);
        } else {
          refetchCounter = 0;
          window.location.href = MEMBER_PANELS_URL;
        }
      }
    } else {
      refetchCounter = 0;
    }
  };
})();