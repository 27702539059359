import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

/**
 * Triggered when a user signs up and does paste on at least one of these:
 * firstName
 * lastName
 * zipcode
 * @returns void
 */
export const analyticsTrackPastedBasicProfileEnd = (responseMessage: string): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]            : ANALYTICS_EVENT_TYPE.other,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : ANALYTICS_EVENT_SUB_TYPE.paste,
      [ANALYTICS_EVENT_ATTRIBUTES.response_message]: responseMessage,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.security, [event]);
};