import { getUpmCategoryIds } from 'Pages/help/constants';
import { SURVEY_MEDLEY_QUESTION_COUNT } from 'Pages/surveyExperience/services/constants';
import { isKeyCustomQuestionKey } from 'Pages/surveyExperience/services/surveyService';
import { QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { QuestionnaireEventProps } from 'Shared/components/design/questionnairePopup/QuestionnaireEventModels';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';
import { SurveyQualificationDetails } from 'Shared/services/localStorageTypes';
import { getSurveyQualificationTestData } from 'Shared/services/opa/app/surveyQualificationSplitTest';
import { mapDynamicScreenerQuestionsToUnansweredQuestions } from 'Shared/services/userActivation/app/dynamicScreenerService';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';
import { UspUnansweredQuestions } from 'Shared/services/usp/models/UspUnansweredQuestionsModel';

/**
 * Constants
 */
export const TOP_SURVEYS_CUTOFF = 30;

/**
 * Types
 */
export interface SurveyQuestionIdsWithQueueParams {
  maxQuestions?             : number,
  questionKeysQueue         : string[],
  surveyQuestionKeys        : string[],
}

export interface SurveyQualificationCacheParams {
  survey?             : UspSurveysModel,
  maxQuestions?       : number,
  questionKeysQueue   : string[],
  unansweredQuestions : UspUnansweredQuestions[],
  isSurveyMedley?     : boolean,
  isDynamicScreener  ?: boolean,
}

/**
 * Get cached survey qualification details
 */
export const getCachedSurveyQualificationDetails = () => getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_QUAL_DETAILS, true);

/**
 * Delete cached survey qualification details
 */
export const deleteCachedSurveyQualificationDetails = () => deleteLocalStorage(LOCAL_STORAGE_IDS.SURVEY_QUAL_DETAILS);

/**
 * Used for generating the survey qualification cache for a single survey
 * as part of the survey qualification test(MEW-1062).
 * @param survey
 * @param unansweredQuestions
 * @returns
 */
const prepareSingleSurveyQualificationCache = (survey: UspSurveysModel, unansweredQuestions: UspUnansweredQuestions[]) => {

  const filterOutNonSurveyQuestions = unansweredQuestions.filter(question => survey.unansweredQuestions.includes(question.key));

  const questions = filterOutNonSurveyQuestions.map(question => ({
    key: question.key,
    multiValue: question.multiValue,
    sortOrder: question.sortOrder,
    categoryId: question.categoryId,
    data: [question]
  }));

  const questionnaireDetails: QuestionnaireEventProps = {
    points: 0,
    categoryId: getUpmCategoryIds().SURVEY_MEDLEY,
    questions: questions,
    surveyUrl: survey?.internalUrl,
    isSurveyQualification: true,
  };

  return {
    questionnaireDetails: questionnaireDetails,
    surveyPoints: survey?.points || 0
  };
};

/**
 * Prepare dynamic screener survey qualification cache
 * @param survey
 * @param unansweredQuestions
 * @returns
 */
const prepareDynamicScreenerSurveyQualificationCache = (survey: UspSurveysModel): SurveyQualificationDetails => {
  //map provider dynamic screening questions UPM format
  const mappedDynamicQuestions = survey.unansweredDynamicQuestionIds.length > 0 ? mapDynamicScreenerQuestionsToUnansweredQuestions(survey) : [];

  const questions = mappedDynamicQuestions.map(question => ({
    key: question.key,
    multiValue: question.multiValue,
    sortOrder: question.sortOrder,
    categoryId: question.categoryId,
    data: [question]
  }));

  const questionnaireDetails: QuestionnaireEventProps = {
    points: 0,
    categoryId: getUpmCategoryIds().SURVEY_MEDLEY,
    questions: questions,
    surveyUrl: survey?.internalUrl,
    isSurveyQualification: true,
  };

  return {
    questionnaireDetails: questionnaireDetails,
    surveyPoints: survey?.points || 0
  };
};


/**
 * Generating the "surveyQualificationCache" object and including the questions queue if applicable
 */
export const prepareSurveyQualificationCacheWithQueue = ({
  isSurveyMedley = false,
  survey,
  maxQuestions = SURVEY_MEDLEY_QUESTION_COUNT,
  questionKeysQueue = [],
  unansweredQuestions,
  isDynamicScreener = false
}: SurveyQualificationCacheParams) => {
  const isUserInSurveyQualTest  = getSurveyQualificationTestData();

  if (survey && isDynamicScreener) {
    const dynamicScreenerQualificationCache = prepareDynamicScreenerSurveyQualificationCache(survey);
    return dynamicScreenerQualificationCache;
  }

  // If user is in the survey qualification test, return only survey pertaining questions
  if (survey && isUserInSurveyQualTest.enabled && isUserInSurveyQualTest.providerIds.includes(survey?.providerId || 0)) {
    const singleSurveyQualificationCache = prepareSingleSurveyQualificationCache(survey, unansweredQuestions);
    return singleSurveyQualificationCache;
  }

  // Attaching questions to survey from the questions queue if applicable.
  const surveyQuestionsWithQueue = getSurveyQuestionsWithQueue({
    maxQuestions,
    questionKeysQueue,
    surveyQuestionKeys: survey?.unansweredQuestions || [],
    unansweredQuestions
  });

  // Creating surveyQualificationCache with queued questions
  const questions: QuestionPopupModel[] = surveyQuestionsWithQueue.map(question => ({
    multiValue: question.multiValue,
    sortOrder: question.sortOrder,
    key: question.key,
    categoryId: question.categoryId,
    data: [question]
  }));

  const questionnaireDetails: QuestionnaireEventProps = {
    points: isSurveyMedley ? questions.length : 0,
    categoryId: getUpmCategoryIds().SURVEY_MEDLEY,
    questions: questions,
    surveyUrl: survey?.internalUrl,
    isSurveyQualification: isSurveyMedley ? false : true,
    isSurveyMedley: isSurveyMedley,
  };

  return {
    questionnaireDetails: questionnaireDetails,
    surveyPoints: survey?.points || 0
  };
};

/**
 * Prepare and store survey qualification cache in localStorage
 */
export const prepareAndStoreSurveyQualificationCache = ({
  isSurveyMedley = false,
  survey,
  maxQuestions = SURVEY_MEDLEY_QUESTION_COUNT,
  questionKeysQueue,
  unansweredQuestions,
  isDynamicScreener = false
}: SurveyQualificationCacheParams): void => {
  // Prepare survey qualification cache with question queue
  const surveyQualificationCache = prepareSurveyQualificationCacheWithQueue({
    isSurveyMedley,
    survey,
    maxQuestions,
    questionKeysQueue,
    unansweredQuestions,
    isDynamicScreener
  });

  // Updating local storage with new survey qualification cache
  setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_QUAL_DETAILS, surveyQualificationCache, true);
};

/**
 * Generating a queue of question keys based on the top surveys
 * "We ask questions because of emotions" - Nej & Daniel
 */
export const generateQuestionKeysQueue = (surveys: UspSurveysModel[], maxQuestions: number = SURVEY_MEDLEY_QUESTION_COUNT): string[] => {
  const getUniqueQuestionKeys = (questionKeys: string[]) => {
    return Array.from(new Set([...questionKeys]));
  };

  // Mapping a unique list of questions by popularity (repetition)
  const getTopQuestionKeysFromSurveyList = (surveyList: UspSurveysModel[]) => {
    const questionPopularityMap: Record<string, number> = {};

    surveyList.forEach((survey: UspSurveysModel) => {
      const unansweredQuestions = survey.unansweredQuestions || [];
      unansweredQuestions.forEach((questionKey) => {
        questionPopularityMap[questionKey] = (questionPopularityMap[questionKey] || 0) + 1;
      });
    });

    return Object.keys(questionPopularityMap)
      .sort((a, b) => questionPopularityMap[b] - questionPopularityMap[a]);
  };

  const topQuestionKeysFromTopSurveys = getTopQuestionKeysFromSurveyList(surveys.slice(0,TOP_SURVEYS_CUTOFF));

  // Adding top surveys questions to queue
  let questionKeysToAsk = getUniqueQuestionKeys(topQuestionKeysFromTopSurveys);

  if (questionKeysToAsk.length >= maxQuestions) {
    return questionKeysToAsk.slice(0, maxQuestions);
  }

  const topQuestionIdsFromAllSurveys = getTopQuestionKeysFromSurveyList(surveys);

  questionKeysToAsk = getUniqueQuestionKeys([...questionKeysToAsk, ...topQuestionKeysFromTopSurveys, ...topQuestionIdsFromAllSurveys]);

  return questionKeysToAsk.slice(0, maxQuestions);
};

/**
 * Appending top question keys from the queue to the survey unansweredQuestions,
 * Only if there's a gap between SURVEY_MEDLEY_QUESTION_COUNT and the survey unansweredQuestions.
 */
export const getSurveyQuestionKeysWithQueue = ({
  maxQuestions = SURVEY_MEDLEY_QUESTION_COUNT,
  questionKeysQueue,
  surveyQuestionKeys
}: SurveyQuestionIdsWithQueueParams) => {
  const diff = maxQuestions - surveyQuestionKeys.length;

  if (diff <= 0) {
    return [...surveyQuestionKeys];
  }

  return Array.from(new Set([
    ...surveyQuestionKeys,
    ...questionKeysQueue
  ])).slice(0, maxQuestions);
};

/**
 * Getting the combined survey and queue questions metadata.
 */
export const getSurveyQuestionsWithQueue = ({
  maxQuestions,
  questionKeysQueue,
  surveyQuestionKeys,
  unansweredQuestions
}: SurveyQuestionIdsWithQueueParams & { unansweredQuestions: UspUnansweredQuestions[] }) => {
  const questionIdsWithQueue = getSurveyQuestionKeysWithQueue({
    maxQuestions,
    questionKeysQueue,
    surveyQuestionKeys
  });

  return questionIdsWithQueue.reduce((acc: UspUnansweredQuestions[], questionKey: string) => {
    const question = unansweredQuestions.find((question: UspUnansweredQuestions) => question.key === questionKey);
    if (question) {
      acc.push(question);
    }

    return acc;
  }, []);
};

/**
 * Sort extended profile questions before custom questions
 * @param questions
 * @returns string[]
 */
const sortExtendedProfileQuestionsFirst = (questions: string[]): string[] => {
  return questions.sort((questionKeyOne: string, questionKeyTwo: string) => {
    // If question A is not a custom question and question B is, place question A before question B
    if (!isKeyCustomQuestionKey(questionKeyOne) && isKeyCustomQuestionKey(questionKeyTwo)) {
      return -1;
    }

    // If question B is not a custom question and question A is, place question B before question A
    if (isKeyCustomQuestionKey(questionKeyOne) && !isKeyCustomQuestionKey(questionKeyTwo)) {
      return 1;
    }

    return 0;
  });
};

/**
 * Finds the most common strings across multiple string arrays, ensuring that at
 * most one occurrence of each string from every array is considered in the final
 * count. This function is designed to return a list of strings that are the most
 * frequent across all provided arrays, with the constraint that each string is
 * counted at most once per array to ensure uniqueness in the count.
 * @param questionKeys
 * @returns string[]
 */
const findMostCommonButUniqueQuestionKeys = (questionKeys: string[][]): string[] => {
  const countMap = new Map();

  // Count all string occurrences while ensuring uniqueness within each array
  questionKeys.forEach((array: string[]) => {
    new Set(array).forEach((item: string) => {
      if (countMap.has(item)) {
        countMap.set(item, countMap.get(item) + 1);
      } else {
        countMap.set(item, 1);
      }
    });
  });

  // Sort items by their frequency
  const sortedItems: string[][] = Array.from(countMap).sort((a, b) => b[1] - a[1]);

  const result: string[] = [];
  const added = new Set(); // Tracks which arrays have contributed to the result

  sortedItems.forEach(([item]) => {
    questionKeys.forEach((array, index) => {
      if (array.includes(item) && !added.has(index)) {
        // This ensures we pick the most common, yet unique items across all arrays
        added.add(index);

        if (!result.includes(item)) {
          result.push(item);
        }
      }
    });
  });

  // Ensure the result contains items up to the limit of unique arrays
  return result.slice(0, questionKeys.length);
};

/**
 * Returns a list of top survey medley questions to ask
 * @param surveys
 * @returns string[]
 */
export const sortExtendedProfileUnansweredQuestionsFirst = (surveys: UspSurveysModel[]): string[] => {
  const questionKeys: string[][] = surveys.reduce((accumulator: string[][], survey: UspSurveysModel) => {
    const unansweredQuestions: string[] = survey.unansweredQuestions || [];

    if (Array.isArray(unansweredQuestions) && unansweredQuestions.length > 0) {
      accumulator.push(unansweredQuestions);
    }

    return accumulator;
  }, []);

  const commonButUniqueQuestionKeys: string[] = findMostCommonButUniqueQuestionKeys(questionKeys);
  const finalQuestionKeys: string[]           = sortExtendedProfileQuestionsFirst(commonButUniqueQuestionKeys);

  return finalQuestionKeys;
};