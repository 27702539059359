import React, { HTMLAttributes, useState } from 'react';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {IconContainer} from 'Shared/components/design/passwordInput/style';
import { NEW_PASSWORD, NO_VISIBILITY_ICON, VISIBILITY_ICON } from 'Shared/components/design/passwordInput/testId';
import {TextHelper, TextInput, TextLabel} from 'Shared/components/design/textField/style';
import {TextFieldTypes} from "Shared/components/design/textField/TextField";
import {ICON_IDS} from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

interface PasswordInputProps extends HTMLAttributes<HTMLInputElement> {
  changeCallback  : (input: string) => void;
  blurCallback?   : (input: string) => void;
  label?          : string;
  helperText?     : string;
  fieldType?      : TextFieldTypes;
  initialValue?   : string;
}

/**
 * Renders <PasswordInput /> component
 * @param changeCallback
 * @param blurCallback
 * @param label
 * @param helperText
 * @param fieldType
 * @param ariaLabel
 * @param initialValue
 */
function PasswordInput({
  changeCallback,
  blurCallback,
  label,
  helperText,
  fieldType = TextFieldTypes.DEFAULT,
  initialValue,
  ...rest
}: PasswordInputProps,): JSX.Element {
  const [password, setPassword]         = useState<string>(initialValue || '');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  /**
   * Conditionally render label
   */
  const renderLabel = (): JSX.Element | undefined => {
    if (label) {
      return (
        <TextLabel isRequiredField={false} fieldType={fieldType}>
          {label}
        </TextLabel>
      );
    }
  };

  /**
   * Conditionally render helper text
   */
  const renderHelperText = (): JSX.Element | undefined => {
    if (helperText) {
      return (
        <TextHelper fieldType={fieldType}>
          {helperText}
        </TextHelper>
      );
    }
  };

  /**
   * Conditionally renders toggle password visibility icon
   */
  const renderVisibilityIcon = (): JSX.Element => {
    return (
      <>
        <IconContainer onClick={() => setShowPassword(!showPassword)}>
          {showPassword ?
            <Icon
              iconId={ICON_IDS.VISIBILITY}
              testId={VISIBILITY_ICON}
              width={20}
              height={16}
              color={DESIGN_TOKENS.color.text.normal.value}
            />
            :
            <Icon
              iconId={ICON_IDS.NO_VISIBILITY}
              testId={NO_VISIBILITY_ICON}
              width={20}
              height={16}
              color={DESIGN_TOKENS.color.text.normal.value}
            />
          }
        </IconContainer>
      </>
    );
  };

  /**
   * Trigger callback and set state on input changes
   * @param event
   */
  const inputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setPassword(event.target.value);
    changeCallback(event.target.value);
  };

  /**
   * Trigger callback and set state on input blur
   * @param event
   */
  const inputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (blurCallback && typeof blurCallback === 'function') {
      blurCallback(event.target.value);
    }
  };

  return (
    <div>
      {renderLabel()}
      <TextInput
        data-testid  = {NEW_PASSWORD}
        type         = {showPassword ? 'text' : 'password'}
        onChange     = {inputChange}
        onBlur       = {inputBlur}
        value        = {password}
        autoComplete = 'new-password'
        fieldType    = {fieldType}
        isPreFilled  = {false}
        aria-label   = {label}
        {...rest}
      />
      {renderVisibilityIcon()}
      {renderHelperText()}
    </div>
  );
}

export default PasswordInput;
