import { DEVICE_BREAKPOINT, MOBILE_HEADER_HEIGHT, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type SlideOutContainerProps = {
  isOpen: boolean;
};

export const SlideOutContainer = styled.div<SlideOutContainerProps>`
  display: none;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display         : block;
    position        : absolute;
    top             : ${(props) => (props.isOpen ? MOBILE_HEADER_HEIGHT : `calc(-100vh + ${MOBILE_HEADER_HEIGHT})`)};
    left            : 0;
    width           : 100%;
    height          : calc(100vh - ${MOBILE_HEADER_HEIGHT});
    background-color: white;
    color           : black;
    transition      : top 0.5s ease-in-out;
    padding         : 0 16px;
    overflow-y      : auto;
    margin-bottom   : 40px;
  }
`;

export const Title = styled.h1`
  ${TITLES_NORMAL.FT_01}
  margin-top: 24px;
`;