import { hasViewedGamesPage } from 'App/services/brazeService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getLocalStorage } from 'Shared/services/localStorageService';

type GamesCacheConfig  = {
  partnerId            : string,
  partnerIdIncentivized: string
}

const GAMES_NONINCENTIVIZED = '0';
const GAMES_INCENTIVIZED    = '1';
/**
 * Gets the games partner id cache
 * @returns string | null
 */
export const getGamesConfigFromCache = (): GamesCacheConfig => {
  const config: string                     = getLocalStorage(LOCAL_STORAGE_IDS.GAMES_PARTNER_ID) || '';
  const [partnerId, partnerIdIncentivized] = config.split('|');

  return {
    partnerId            : partnerId             || '',
    partnerIdIncentivized: partnerIdIncentivized || GAMES_NONINCENTIVIZED
  };
};

/**
 * Gets the games partner id cache
 * @returns string | null
 */
export const getGamesPartnerIdCache = (): string => {
  return getGamesConfigFromCache().partnerId;
};

export const isGamesIncentivized = (): boolean => {
  return getGamesConfigFromCache().partnerIdIncentivized === GAMES_INCENTIVIZED;
};

/**
 * Returns true if member has viewed the games page
 * @returns boolean
 */
export const viewedGamesPage = (): boolean => {
  return hasViewedGamesPage();
};
