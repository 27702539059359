import React from "react";
import { HR_TEST_IDS } from "Shared/components/design/Hr/constants";
import { StyledHr } from "Shared/components/design/Hr/style";

// Note: the background color prop is left optional,
// But should be defined when used on a non-white background.
// Otherwise, the <hr/> text background won't match the parent background.
const Hr = ({ text, backgroundColor }: { text: string; backgroundColor?: string }): JSX.Element => {
  return <StyledHr data-testid={HR_TEST_IDS.CONTAINER} text={text} backgroundColor={backgroundColor}/>;
};

export default Hr;