import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonWidth } from 'Shared/components/design/button/Button';
import { Blurb, BottomButtonWrapper, RedirectingContentWrapper } from 'Shared/components/design/email/commonStyle';
import { REDIRECTING } from 'Shared/constants/email/testId';

type RedirectingReason = 'too-long' | 'universal-link';

interface Props {
  reason: RedirectingReason
}

const ONE_SECOND = 1000;

/**
 * Renders the EmailRedirecting component
 */
function AERedirecting({ reason }: Props): JSX.Element {
  const { t }    = useTranslation('app');
  const navigate = useNavigate();

  function renderBlurb () {
    switch (reason) {
      case 'too-long':
        return (
          <Blurb>
            <div>{t('Email--too-long-blurb-1')}</div>
            <div>{t('Email--too-long-blurb-2')}</div>
          </Blurb>
        );
      case 'universal-link':
        return (
          <Blurb>
            <div>{t('Email--universal-link-blurb-1')}</div>
            <div>{t('Email--universal-link-blurb-2')}</div>
          </Blurb>
        );
    }
  }

  const followRedirect = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(followRedirect, ONE_SECOND);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [followRedirect]);

  return (
    <RedirectingContentWrapper data-testid={REDIRECTING}>
      {renderBlurb()}
      <BottomButtonWrapper>
        <Button
          isLoading
          stillLoadingMessage = {t('Email--redirecting-button-text')}
          width               = {ButtonWidth.FULL}
          onClick             = {followRedirect}
        />
      </BottomButtonWrapper>
    </RedirectingContentWrapper>
  );
}

export default AERedirecting;
