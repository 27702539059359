import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Center,
  ContentContainer,
  CtaContainer,
  CurrentPoints,
  Description,
  StepperContainer,
  StepperTitle,
  Title
} from "Pages/createAccount/components/confirmation/style";
import { LOADING_SPINNER_TEST_ID } from 'Pages/createAccount/constants/testIds';
import { getUpmCategoryIds } from "Pages/help/constants";
import { BASIC_PROFILE_CONFIRMATION_TEST_ID } from 'Pages/help/testId';
import { Button, ButtonSizes, ButtonWidth } from "Shared/components/design/button/Button";
import LoadingSpinner from "Shared/components/design/loadingSpinner/LoadingSpinner";
import Stepper, { StepperState, StepperType } from 'Shared/components/design/stepper/Stepper';
import {ICON_IDS} from "Shared/components/icons/constants";
import { BASIC_PROFILE_POINTS_REWARD, GENERAL_PROFILE_POINTS_REWARD } from 'Shared/constants';
import { useKeyDown } from 'Shared/hooks/useKeyDown';
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_SUB_TYPE } from "Shared/services/analytics/constants";
import { analyticsTrackTransitionEnd } from "Shared/services/analytics/events/transitionEnd";
import { analyticsTrackTransitionStart } from "Shared/services/analytics/events/transitionStart";
import { navigateToMemberPage } from 'Shared/services/routingService';
import { useGetCategoryData } from "Shared/services/userActivation/app/questionService";

/**
 * Renders <Confirmation /> component
 */
function Confirmation(): JSX.Element {
  const { t }                                              = useTranslation("createAccount");
  const { data: gpCategoryData, isLoading: gpDataLoading } = useGetCategoryData(getUpmCategoryIds().GENERAL_PROFILE);
  const navigate = useNavigate();

  /**
   * Detecting enter key press on Confirmation screen
   */
  useKeyDown(() => {
    basicProfileCompleted();
  });

  /**
   * Track the transition to the next step
   */
  useEffect(() => {
    analyticsTrackTransitionStart({
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.basic_profile,
    });
  }, []);

  /**
   * set setSignupState on basic profile complete
   */
  const basicProfileCompleted = (): void => {
    analyticsTrackTransitionEnd({
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.basic_profile
    });

    navigateToMemberPage(navigate);
  };

  /**
   * Returns the title for Stepper component
   * @param title
   * @param points
   * @returns
   */
  const getTitle = (title: string, points: number): JSX.Element => {
    return(
      <StepperTitle>
        <span>{title}</span>
        <span>{points} {t('BasicProfile--confirmation--points-abbreviation')}</span>
      </StepperTitle>
    );
  };

  /**
   * Renders Confirmation Stepper component
   * @returns
   */
  const renderContent = (): JSX.Element => {
    if (gpDataLoading) {
      return (
        <Center>
          <LoadingSpinner heightWidth='36px' data-testid={LOADING_SPINNER_TEST_ID} />
        </Center>
      );
    }

    return(
      <ContentContainer>
        <CurrentPoints>
          {BASIC_PROFILE_POINTS_REWARD}
        </CurrentPoints>
        <Title tabIndex={0}>
          <Trans i18nKey={'BasicProfile--confirmation--title'}>
            You’ve earned <span>{{BASIC_PROFILE_POINTS_REWARD}}</span> points for becoming a member
          </Trans>
        </Title>
        <Description tabIndex={0}>
          {t('BasicProfile--confirmation--description')}
        </Description>
        <StepperContainer>
          <Stepper
            steps = {[{
              title      : getTitle(t('BasicProfile--confirmation--stepper-stepOne-title'), BASIC_PROFILE_POINTS_REWARD),
              stateStart : StepperState.DEFAULT,
              stateEnd   : StepperState.ACTIVE,
              typeStart  : StepperType.NUMERIC,
              typeEnd    : StepperType.CHECK,
            },{
              title        : getTitle(t('BasicProfile--confirmation--stepper-stepTwo-title'), gpCategoryData?.points ?? GENERAL_PROFILE_POINTS_REWARD),
              description  : t('BasicProfile--confirmation--stepper-stepTwo-description'),
              stateStart   : StepperState.DEFAULT,
              stateEnd     : StepperState.ACTIVE,
              typeStart    : StepperType.NUMERIC,
              typeEnd      : StepperType.NUMERIC,
              onActiveClick: basicProfileCompleted
            },{
              title      : t('BasicProfile--confirmation--stepper-stepThree-title'),
              stateStart : StepperState.DEFAULT,
              stateEnd   : StepperState.DEFAULT,
              typeStart  : StepperType.NUMERIC,
              typeEnd    : StepperType.NUMERIC,
            }]
          }
          activeStep    = {2}
          animateToStep = {true}
        />
        </StepperContainer>
        <CtaContainer>
          <Button
            size      = {ButtonSizes.LARGE}
            width     = {ButtonWidth.FULL}
            iconRight = {ICON_IDS.ARROW_RIGHT}
            onClick   = {basicProfileCompleted}
          >
            {t('BasicProfile--confirmation--button-text')}
          </Button>
        </CtaContainer>
      </ContentContainer>
    );
  };

  return (
    <div data-testid = {BASIC_PROFILE_CONFIRMATION_TEST_ID}>
      {renderContent()}
    </div>
  );
}

export default Confirmation;
