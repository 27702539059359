import React from 'react';
import {HEADER_TEST_ID} from 'Pages/help/testId';
import {HeaderContainer, LeftIconContainer, RightIconContainer, TitleContainer} from 'Shared/components/design/authHeader/style';
import { IconId } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

export type HeaderIcon = {
  visible  : boolean;
  callback : null | (() => any);
  iconId   : IconId;
  color    : string;
}

export type HeaderProps = {
  rightIcon?: HeaderIcon;
  leftIcon ?: HeaderIcon;
  title     : string;
}

function AuthHeader({rightIcon, leftIcon, title}: HeaderProps): JSX.Element {
  /**
   * Renders the left icon
   * @returns JSX.Element
   */
  const renderLeftIcon = (): JSX.Element => {
    if (!leftIcon) {
      return <></>;
    }

    return (
      <LeftIconContainer onClick={() => leftIcon.callback ? leftIcon.callback() : ''} isVisible={leftIcon.visible}>
        { leftIcon.iconId ? <Icon iconId={leftIcon.iconId} color={leftIcon.color} /> : '' }
      </LeftIconContainer>
    );
  };

  /**
   * Renders the right icon
   * @returns JSX.Element
   */
  const renderRightIcon = (): JSX.Element => {
    if (!rightIcon) {
      return <></>;
    }

    return (
      <RightIconContainer onClick={() => rightIcon.callback ? rightIcon.callback() : ''} isVisible={rightIcon.visible}>
        { rightIcon.iconId ? <Icon iconId={rightIcon.iconId} color={rightIcon.color} /> : '' }
      </RightIconContainer>
    );
  };

  return (
    <HeaderContainer data-testid={HEADER_TEST_ID}>
      { renderLeftIcon() }
      <TitleContainer>
        {title}
      </TitleContainer>
      { renderRightIcon() }
    </HeaderContainer>
  );
}

export default AuthHeader;
