import styled from 'styled-components';

type ActiveProps = {
  isActive: boolean;
};

export const PromoButtonContainer = styled.button`
  background     : none;
  color          : inherit;
  border         : none;
  padding        : 0;
  font           : inherit;
  cursor         : pointer;
  outline        : inherit;
  margin-right   : 6px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  position       : relative;
  height         : 28px;
  width          : 28px;

  svg {
    height: 24px;
    width : 24px;
  }
`;

export const ActiveIndicator = styled.div<ActiveProps>`
  position: absolute;
  top     : 10px;
  left    : 18px;
  display : ${props => props.isActive ? 'block' : 'none'};
`;