import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

/**
 * Triggered when the relevant ID process completes.
 * @returns void
 */
export const analyticsTrackRelevantIdEnd = (responseMessage: string, startTimestamp: number): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]            : ANALYTICS_EVENT_TYPE.other,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : ANALYTICS_EVENT_SUB_TYPE.rvid,
      [ANALYTICS_EVENT_ATTRIBUTES.response_message]: responseMessage,
      [ANALYTICS_EVENT_ATTRIBUTES.total_load_time] : startTimestamp ? Math.round((Date.now() - startTimestamp)/1000): 0,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.security, [event]);
};
