
const USPAnswerType = {
  TEXT  : 'TEXT',
  NUMBER: 'NUMBER',
  DATE  : 'DATE'
} as const;

type USPAnswerType = typeof USPAnswerType[keyof typeof USPAnswerType]

type USPPredefinedAnswers = {
  id         : number,
  questionId : number,
  label      : string,
  sortOrder  : number,
  enabled    : boolean,
  answerValue: number,
  solo       : boolean
}

type UspUnansweredQuestions = {
  id                  : number,
  typeId              : number,
  categoryId          : number,
  categoryName        : string,
  key                 : string,
  question            : string,
  answerType          : USPAnswerType,
  multiValue          : boolean,
  hasPredefinedAnswers: boolean,
  enabled             : boolean,
  sortOrder           : number,
  inputTypeId?        : number,
  criteriaKey?        : string,
  predefinedAnswers   : USPPredefinedAnswers[],
  required            : boolean,
  questionProviderId? : number
}

export {
  USPAnswerType,
  USPPredefinedAnswers,
  UspUnansweredQuestions
};