import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from 'App/components/loading';
import { LoginModalOpenEventProps } from 'Shared/components/design/loginModal/models/eventModels';
import { addLoginModalOpenEventListener } from 'Shared/components/design/loginModal/routes/addEvents';
import { ModalBackdrop, ModalContainer } from 'Shared/components/design/loginModal/style';
import { IDPSignupSource } from 'Shared/services/userActivation/api/consts';
import { useGetUserInfo } from 'Shared/services/userActivation/api/idpApiService';

import ForgotPasswordConfirm from './components/forgotPasswordConfirm';
import ForgotPasswordForm from './components/forgotPasswordForm';
import LoginForm from './components/loginForm';

/**
 * Renders PartialLoginModal component
 * TODO: revisit this component and make sure it's not rendered unless visible.
 */
const LoginModal = () => {
  const location                                      = useLocation();
  const [isOpen, setIsOpen]                           = useState(false);
  const [showModal, setShowModal]                     = useState(false);
  const [showForgotPassword, setShowForgotPassword]   = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [password, setPassword]                       = useState('');
  const [confirmEmail, setConfirmEmail]               = useState('');

  const {
    data,
    isLoading,
  } = useGetUserInfo();

  const renderLoginForm = () => {
    return <>
      <LoginForm
        password           = {password}
        closeModal         = {() => setIsOpen(false)}
        setPassword        = {setPassword}
        signupSource       = {data?.signupSource as IDPSignupSource}
        showForgotPassword = {() => setShowForgotPassword(true)}
      />
      <ForgotPasswordForm
        show       = {showForgotPassword}
        goBack     = {goBackToLogin}
        goToConfirm = {(email) => {
          setShowPasswordConfirm(true);
          setConfirmEmail(email);
        }}
      />
      <ForgotPasswordConfirm
        show                  = {showPasswordConfirm}
        email                 = {confirmEmail}
        goBackToLogin         = {goBackToLogin}
        returnToForotPassword = {() => setShowPasswordConfirm(false)}
      />
    </>;
  };

  function goBackToLogin () {
    setShowForgotPassword(false);
    setShowPasswordConfirm(false);
    setPassword('');
  }

  useEffect(() => {
    const modalOpenListener = ({ open }: LoginModalOpenEventProps) => {
      if (!isOpen) {
        goBackToLogin();
      }

      if (open) {
        setShowModal(true);
      }

      setIsOpen(open);
    };

    const { remove } = addLoginModalOpenEventListener(modalOpenListener);

    return () => {
      remove();
    };
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [location, setIsOpen]);

  function handleTransitionEnd () {
    if (isOpen) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }

  return (
    <ModalBackdrop data-testid='modal-backdrop' open={isOpen} onTransitionEnd={handleTransitionEnd}>
      <ModalContainer open={showModal}>
        {isLoading ? <Loading /> : renderLoginForm()}
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default LoginModal;
