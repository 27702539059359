import type { Value as RemoteConfigValue } from "firebase/remote-config";
import { getFirebaseConfig, getValueFn } from 'Shared/services/firebase';
import { getFirebaseRemoteConfigCacheKey } from "Shared/services/swr/swrKeyService";
import useSWR from 'swr';

type configTypes = 'boolean' | 'string' | 'number';

/**
 * Hook to get the value of a firebase remote config key
 * @param key - The key of the remote config value to get
 * @param type - The type of the remote config value to get
 * @returns The value of the remote config key
 */
const useFirebaseRemoteConfig = (key: string, type: configTypes) => {
  const swrKey = getFirebaseRemoteConfigCacheKey(key);
  return useSWR(swrKey, async () => {
    const value = await getFirebaseConfigValue(key, type);
    return value;
  });
};

/**
 * Get the value of a firebase remote config key
 * @param key - The key of the remote config value to get
 * @param type - The type of the remote config value to get
 * @returns The value of the remote config key
 */
const getFirebaseConfigValue = async (key: string, type: configTypes) => {
  const config = await getFirebaseConfig();
  if (!config || !getValueFn) {
    return getDefaultValue(type);
  }

  const value = getValueFn(config, key);
  if (!value) {
    return getDefaultValue(type);
  }

  return getTypedValue(value, type);
};

const getDefaultValue = (type: configTypes) => {
  if (type === 'boolean') {
    return { value: false };
  } else if (type === 'string') {
    return { value: '' };
  } else if (type === 'number') {
    return { value: 0 };
  }
  return { value: false };
};

const getTypedValue = (value: RemoteConfigValue, type: configTypes) => {
  if (type === 'boolean') {
    return { value: value.asBoolean() };
  } else if (type === 'string') {
    return { value: value.asString() };
  } else if (type === 'number') {
    return { value: value.asNumber() };
  }
  return { value: value.asBoolean() };
};

export {
  getFirebaseConfigValue,
  useFirebaseRemoteConfig,
};
