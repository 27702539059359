import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type TransitionStartEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE,
}

/**
 * Sends analytic event and attributes for Basic Profile to Google Analytics
 * @param TransitionStartEvent
 * @param eventType
 * @returns void
 */
export const analyticsTrackTransitionStart = (transitionStartEvent: TransitionStartEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      ...transitionStartEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]: ANALYTICS_EVENT_TYPE.transition,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
};