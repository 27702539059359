import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import App from 'App/components/App';
import InitializeUserActivation from 'App/components/initializeUserActivation/InitializeUserActivation';
import Loading from 'App/components/loading';
import PopupQueue from 'App/components/popupQueue/PopupQueue';
import { RefreshAccessToken } from 'App/components/refreshAccessToken/RefreshAccessToken';
import { ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN, MENU_ROUTES } from 'App/components/sidebar/constants';
import { MainContainer } from 'App/components/style';
import { TitleChanger } from 'App/components/titleChanger/TitleChanger';
import {
  MEMBER_FORGOT_PASSWORD_URL,
  MEMBER_PANELS_URL,
  MEMBER_SIGN_IN_URL,
  MEMBER_SIGN_UP_URL,
  MEMBER_SIGN_UP_URL_NO_AUTH,
  MEMBER_WELCOME_URL,
  PAGE_NOT_FOUND,
  PINNING_INSTRUCTIONS,
  STE_LEARN_MORE,
  UNINSTALL,
  UNRECOGNIZED_DEVICE_URL,
  VERIFY_DEVICE_URL} from 'App/routes/constants';
import { hasAutoLoginParams } from 'App/services/autoEmailService';
import { getRefreshTokenCookie, useIsLoggedIn } from 'App/services/idpTokenService';
import { isSiteShutdown, useShutdownRedirection } from 'App/services/siteShutDownService';
import AutoEmail from 'Pages/autoEmail';
import AutoLogin from 'Pages/autoLogin';
import CreateAccount from 'Pages/createAccount/index';
import SignInForm from 'Pages/signIn/SignInForm';
import SurveyCallback from 'Pages/surveyCallback/SurveyCallback';
import { COUNTRY_CODE_UK } from 'Shared/constants';
import useMaintenanceMode from 'Shared/hooks/useMaintenanceMode';
import { useMobileStoreRedirection } from 'Shared/hooks/useMobileStoreRedirection';
import useSetupCoralogixUser from 'Shared/hooks/useSetupCoralogixUser';
import { initAnalytics } from 'Shared/services/analytics/config';
import { getIsLimitedScopeToken } from 'Shared/services/apiInterface';
import { isInMobileApp } from 'Shared/services/mobileAppMessenger';

const DirectSurvey                   = lazy(() => import('Pages/directSurvey'));
const Rewards                        = lazy(() => import('Pages/rewards/index'));
const HowOnfidoWorks                 = lazy(() => import('Pages/howOnfidoWorks/HowOnfidoWorks'));
const OnfidoPI                       = lazy(() => import('Pages/onfidoReviewYourPersonalInfo/OnfidoReviewYourPersonalInfo'));
const SurveyExperience               = lazy(() => import('Pages/surveyExperience/index'));
const EmailSubmit                    = lazy(() => import('Pages/emailSubmit/SignupEmailSubmit'));
const ForgotPassword                 = lazy(() => import('Pages/forgotPassword/ForgotPassword'));
const PaypalTransfer                 = lazy(() => import('Pages/rewards/components/paypalTransfer/PaypalTransfer'));
const UserActivation                 = lazy(() => import('Pages/help/index'));
const Faq                            = lazy(() => import('Pages/help/subPages/faq'));
const PersonalInfo                   = lazy(() => import('Pages/help/subPages/personalInfo/PersonalInfo'));
const AccountLeaderboard             = lazy(() => import('Pages/help/subPages/accountLeaderboard/AccountLeaderboard'));
const Permissions                    = lazy(() => import('Pages/help/subPages/permissions/Permissions'));
const ContactUs                      = lazy(() => import('Pages/help/subPages/contactUs'));
const TermsAndConditions             = lazy(() => import('Pages/help/subPages/termsAndConditions/TermsAndConditions'));
const InviteTerms                    = lazy(() => import('Pages/help/subPages/inviteTerms/InviteTerms'));
const PrivacyPolicy                  = lazy(() => import('Pages/help/subPages/privacyPolicy/PrivacyPolicy'));
const ProfileQuestions               = lazy(() => import('Pages/help/subPages/profileQuestions/ProfileQuestions'));
const ProfileQuestionDetail          = lazy(() => import('Pages/help/subPages/profileQuestionDetail/ProfileQuestionDetail'));
const PinningInstruction             = lazy(() => import('Pages/pinningInstructions/PinningInstructions'));
const SurveyQualification            = lazy(() => import('Pages/surveyQualification'));
const SurveyMedley                   = lazy(() => import('Pages/surveyMedley'));
const UnrecognizedDevice             = lazy(() => import('Pages/unrecognizedDevice'));
const VerifyDevice                   = lazy(() => import('Pages/verifyDevice'));
const Uninstall                      = lazy(() => import('Pages/uninstall/Uninstall'));
const Welcome                        = lazy(() => import('Pages/welcome/Welcome'));
const Invite                         = lazy(() => import('Pages/help/subPages/invite/Invite'));
const SteLearnMore                   = lazy(() => import('Pages/steLearnMore/SteLearnMore'));
const SiteShutDown                   = lazy(() => import('Pages/siteShutDown/SiteShutDown'));
const SurveyShutdownNotice           = lazy(() => import('App/components/surveyShutdownNotice/SurveyShutdownNotice'));
const SteEmailInstructions           = lazy(() => import('Pages/surveyExperience/components/steEmailInstructions/SteEmailInstructions'));
const StePreInstallInstructions      = lazy(() => import('Pages/surveyExperience/components/stePreInstallInstructions/StePreInstallInstructions'));
const Webview                        = lazy(() => import('Pages/webview/Webview'));
const Games                          = lazy(() => import('Pages/games/Games'));
const Panels                         = lazy(() => import('Pages/panels/Panels'));
const PageNotFound                   = lazy(() => import('Pages/pageNotFound/PageNotFound'));
const SiteMaintenance                = lazy(() => import('Pages/maintenance'));

/**
 * Private/Protected route wrapper
 */
const PrivateRoute: FC = ({ children }) => {
  // Hook to handle mobile store redirection
  useMobileStoreRedirection();

  return useIsLoggedIn() ? (
    <>
      { children }
      <App/>
    </>
  ) : (
    <Navigate to={ MEMBER_SIGN_IN_URL } replace/>
  );
};

/**
 * Hybrid route wrapper
 */
const HybridRoute: FC = ({ children }) => {
  return useIsLoggedIn() ? (
    <>
      { children }
      <App/>
    </>
  ) : (
    <MainContainer>
      { children }
      <Outlet/>
    </MainContainer>
  );
};

/**
 * Public route wrapper
 */
const PublicRoute: FC = ({ children }) => {
  return (
    <MainContainer>
      { children }
      <Outlet/>
    </MainContainer>
  );
};

/**
 * Site Shutdown route wrapper
 */
const SiteShutDownRoute: FC = () => {
  const { isUserOnShutdownPages } = useShutdownRedirection();

  return (!isUserOnShutdownPages()) ? (
    <Navigate to="/" replace />
  ) : (
    <Outlet />
  );
};

/**
 * Renders the main <Index/> element
 */
function Router(): JSX.Element {
  useSetupCoralogixUser();
  const isLoggedIn = useIsLoggedIn();
  const {
    enabled: isUnderMaintenanceMode,
    message: maintenanceMessage
  } = useMaintenanceMode();

  if (
    !isLoggedIn &&
    !!getRefreshTokenCookie() &&
    !getIsLimitedScopeToken() &&
    !hasAutoLoginParams() &&
    !isInMobileApp() &&
    process.env.ENABLE_EXPIRED_ACCESS_TOKEN_REFRESH
  ) {
    return <RefreshAccessToken />;
  }

  // Init GA4 Analytics
  initAnalytics({}, true);

  if (isSiteShutdown()) {
    return (
      <BrowserRouter>
        <TitleChanger />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/*' element={<SiteShutDownRoute />}>
              <Route path="" element={<SiteShutDown />}/>
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="terms" element={<TermsAndConditions />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }

  if (isUnderMaintenanceMode) {
    return (
      <BrowserRouter>
        <TitleChanger />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='callback/survey' element={<SurveyCallback />}/>
            <Route path='/*' element={<Navigate to="/" replace />} />
            <Route path="" element={<SiteMaintenance message={maintenanceMessage || ''} />}/>
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }

  return (
    <>
      <PopupQueue />
      <BrowserRouter>
        <TitleChanger />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/' element={<PrivateRoute />}>
              <Route path={ MEMBER_WELCOME_URL } element={<Welcome />}/>
              <Route path='/' element={
                process.env.COUNTRY_BUILD === COUNTRY_CODE_UK
                  ? <SurveyShutdownNotice />
                  : <>
                      <InitializeUserActivation />
                      <Suspense fallback={<Loading />}>
                        <StePreInstallInstructions />
                        <SteEmailInstructions/>
                        <SurveyExperience />
                      </Suspense>
                    </>
              } />
              <Route path={ MEMBER_PANELS_URL } element={<Panels />}/>
              <Route path='rewards/*' element={<><Rewards /><Outlet /></>}>
                <Route path='redeem' element={<PaypalTransfer />}/>
              </Route>
              <Route path='how-onfido-works' element={<HowOnfidoWorks />}/>
              <Route path='onfido-review-personal-info' element={<OnfidoPI />}/>
              <Route path='survey-qualification' element={<SurveyQualification />} />
              <Route path='survey-medley' element={<SurveyMedley />} />
              <Route path='webview' element={<Webview />} />
              <Route path={ MENU_ROUTES.GAMES } element={<Games />} />
              <Route path='*' element={<Navigate to={PAGE_NOT_FOUND} replace />}/>
            </Route>
            <Route path='/' element={<HybridRoute/>}>
              <Route path='ae' element={<AutoEmail />} />
              <Route path='al/*' element={<AutoLogin />} />
              <Route path='ds' element={<DirectSurvey />} />
              <Route path='help' element={<UserActivation isLoggedIn={isLoggedIn} />}>
                <Route path='support' element={<Faq />} />
                <Route path='contact-us' element={<ContactUs />} />
                <Route path='terms' element={<TermsAndConditions />} />
                <Route path='privacy' element={<PrivacyPolicy />} />
                {
                  isLoggedIn && (
                    <>
                      <Route path='profile-questions' element={<ProfileQuestions />} />
                      <Route path='profile-questions/:id' element={<ProfileQuestionDetail />} />
                      <Route path='invite' element={<Invite />} />
                      <Route path='invite-terms' element={<InviteTerms />} />
                      <Route path='personal-info' element={<PersonalInfo />} />
                      <Route path='personal-info/edit-name' element={<PersonalInfo />} />
                      <Route path='personal-info/edit-code' element={<PersonalInfo />} />
                      <Route path='leaderboard' element={<AccountLeaderboard />} />
                      <Route path='permissions' element={<Permissions />} />
                      <Route path='account' element={<>Profile Page</>} />
                    </>
                  )
                }
                <Route path='*' element={<Navigate to={MENU_ROUTES.ACCOUNT_CONTACT_US + ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN} />} />
              </Route>
              <Route path='*' element={<Navigate to={PAGE_NOT_FOUND} replace />}/>
            </Route>
            <Route path='/' element={<PublicRoute/>}>
              { process.env.ENABLE_NO_AUTH_SIGNUP && <Route path={ MEMBER_SIGN_UP_URL_NO_AUTH } element={ <EmailSubmit /> }/> }
              <Route path={ MEMBER_SIGN_UP_URL } element={ <CreateAccount /> }/>
              <Route path={ MEMBER_SIGN_IN_URL } element={ <SignInForm /> }/>
              <Route path={ MEMBER_FORGOT_PASSWORD_URL } element={ <ForgotPassword /> }/>
              <Route path={ PINNING_INSTRUCTIONS } element={ <PinningInstruction /> }/>
              <Route path={ STE_LEARN_MORE } element={ <SteLearnMore /> }/>
              <Route path={ UNINSTALL } element={ <Uninstall /> }/>
              <Route path={ UNRECOGNIZED_DEVICE_URL } element={<UnrecognizedDevice />} />
              <Route path={ VERIFY_DEVICE_URL } element={<VerifyDevice />} />
              <Route path={ PAGE_NOT_FOUND } element={<PageNotFound />} />
              <Route path='*' element={<Navigate to={PAGE_NOT_FOUND} replace />}/>
            </Route>
            <Route path='callback/survey' element={<SurveyCallback />}/>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default Router;
