import React from 'react';
import { useTranslation } from "react-i18next";
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {
  Divider,
  Name,
  Quote,
  Redeemed,
  StarsContainer,
UserContainer,    UserDetails,
UserImage} from 'Pages/createAccount/components/testimonial/style';
import testimony from 'Shared/assets/images/testimonial/testimony.png';
import {ICON_IDS} from "Shared/components/icons/constants";
import Icon from 'Shared/components/icons/icon';

/**
 * Returns Testimonial component
*/
function CATestimonial(): JSX.Element {
  const { t } = useTranslation('createAccount');

  /**
   * Renders filled star icon
   * @returns
  */
  const renderStarIcon = (): JSX.Element => {
    return (
      <Icon
        iconId = {ICON_IDS.STAR_FILL}
        height = {24}
        width  = {24}
        color  = {DESIGN_TOKENS.color.background.warning.value}
      />
    );
  };
  
  return (
    <>
      <Divider />
      <StarsContainer>
        {renderStarIcon()}
        {renderStarIcon()}
        {renderStarIcon()}
        {renderStarIcon()}
        {renderStarIcon()}
      </StarsContainer>
      <Quote>
        {t('CreateAccount--Testimonial-quote')}
      </Quote>
      <UserContainer>
        <UserImage src={testimony} />
        <UserDetails>
          <Name>{t('CreateAccount--Testimonial-name')}</Name>
          <Redeemed>{t('CreateAccount--Testimonial-redeemed')}</Redeemed>
        </UserDetails>
      </UserContainer>
    </>
  );
}

export default CATestimonial;