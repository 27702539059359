import { AnalyticsEvent } from "Shared/services/analytics/config";
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SOURCE,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

type ReferralBonusStartEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.sub_type] ?: ANALYTICS_EVENT_SUB_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.source]    : ANALYTICS_EVENT_SOURCE,
}

/**
 * Sends analytic event and attributes for start of referral bonus program to Google Analytics
 * @returns void
 */
export const analyticsTrackReferralBonusStart = (referralBonusStartEvent: ReferralBonusStartEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      ...referralBonusStartEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]: ANALYTICS_EVENT_TYPE.referral,
    }
  };

trackEventConditionally(GA4_EVENT_CATEGORY.other, [event]);
};