import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

/**
 * Track surveys load cache event
 */
export const analyticsTrackSurveysLoadCache = (): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]    : ANALYTICS_EVENT_TYPE.survey,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.survey_load_cache,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.survey_load, [event]);
};
