import React, { useEffect, useState } from 'react';
import { Trans, TranslationKey, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QrDownloadApp } from 'App/components/qrDownloadApp/QrDownloadApp';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { SURVEY_MODAL_TEST_IDS } from 'Pages/surveyCallback/constants';
import { shouldShowShortText } from 'Pages/surveyCallback/helpers';
import { SurveyCallbackStatuses } from 'Pages/surveyCallback/models/SurveyCallbackModels';
import {
  Border,
  EligibleIcon,
  EmojiIcon,
  PointsPill,
  QualifiedText,
  StepperContainer,
  SurveyEligibilityText,
  SurveyModalButtonContainer,
  SurveyModalCloseIcon,
  SurveyModalContainer,
  SurveyModalContentContainer,
  SurveyModalQrContainer,
  SurveyModalText,
  SurveyModalTitle
} from 'Pages/surveyCallback/style';
import { removeSurveyById } from 'Pages/surveyExperience/services/surveyService';
import { Button, ButtonSizes, ContextTypes } from 'Shared/components/design/button/Button';
import { SurveyEligibilityStatus } from 'Shared/components/design/questionnairePopup/QuestionnairePopup';
import Stepper, { StepperState, StepperType } from 'Shared/components/design/stepper/Stepper';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { navigateToExternalSurvey } from 'Shared/services/routingService';

type SurveyModalProps = {
  title                   : TranslationKey<'surveyCallback'>,
  text                    : TranslationKey<'surveyCallback'>,
  points?                 : number,
  buttonText?             : TranslationKey<'surveyCallback'>,
  reportIssue?            : boolean,
  emojiElement            : string,
  buttonContextType      ?: ContextTypes,
  callbackStatus          : number,
  centered?               : boolean,
  emojiComponent?         : React.FunctionComponent,
  surveyId                : string | null,
  modalCloseCallback     ?: () => void,
  surveyEligibilityStatus?: SurveyEligibilityStatus,
  surveyUrl?              : string
}

enum StepperTitles {
  FIRST,
  SECOND,
  THIRD
}

/**
 * Renders user survey redirect modal
 * @param param0
 * @returns
 */
function SurveyModal ({
  title,
  text,
  points,
  buttonText,
  emojiElement,
  callbackStatus,
  reportIssue = false,
  centered = false,
  emojiComponent: EmojiComponent,
  surveyId,
  modalCloseCallback,
  surveyEligibilityStatus = SurveyEligibilityStatus.NO_STATUS,
  surveyUrl
}: SurveyModalProps) {
  const { t }                                   = useTranslation('surveyCallback');
  const navigate                                = useNavigate();
  const [displayComponent, setDisplayComponent] = useState<boolean>(true);
  const showStepper                             = callbackStatus === SurveyCallbackStatuses.REJECTED || callbackStatus === SurveyCallbackStatuses.CLIENT_REJECTED;
  const [showShortText, setShowShortText]       = useState<boolean>(true);

  useEffect(() => {
    // Determine if we should show the short text
    const result = shouldShowShortText(SurveyCallbackStatuses.REJECTED);
    setShowShortText(result);
  }, []);

  /**
   * Go to url and close modal
   * @param url - URL to navigate to
   */
  const navigateTo = (url: string): void => {
    setDisplayComponent(false);
    navigate(url, { replace: true });
  };

    /**
   * Close survey modal
   * In case of first or second recommendation, set the recommended survey as complete
   */
  const closeSurveyModal = (): void => {
    if (modalCloseCallback) {
      modalCloseCallback();
      setDisplayComponent(false);
    } else {
      navigateTo(MENU_ROUTES.HOME);
    }
  };

  /**
   * Conditionally render the close button if close is set to true
   */
  const renderCloseIcon = (): JSX.Element => {
    return (
      <SurveyModalCloseIcon
        onClick     = {() => closeSurveyModal()}
        data-testid = {SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CLOSE_ICON}
      >
        <Icon iconId = {ICON_IDS.CLOSE} />
      </SurveyModalCloseIcon>
    );
  };

  /**
   * Renders points pill
   * @returns
   */
  const renderPointsPill = (): JSX.Element | undefined => {
    if (points) {
      return (
        <PointsPill>
          <span>
            {points}
          </span>
        </PointsPill>
      );
    }
  };

  /**
   * Renders emoji icon
   * @returns
   */
  const renderEmojiIcon = (): JSX.Element => {
    if (EmojiComponent) {
      return (
        <EmojiComponent />
      );
    }

    return(
      <EmojiIcon>
        {emojiElement}
      </EmojiIcon>
    );
  };

  /**
   * Returns the stepper content based on the status
   * @param titleNumber
   * @returns
   */
  const getStepperContent  = (titleNumber: StepperTitles): string => {
    switch (titleNumber) {
      case StepperTitles.FIRST:
        return showShortText ? t('SurveyCallback--stepper-rejected-one-short') : t('SurveyCallback--stepper-rejected-one');
      case StepperTitles.SECOND:
        return showShortText ? t('SurveyCallback--stepper-rejected-two-short') : t('SurveyCallback--stepper-rejected-two');
      case StepperTitles.THIRD:
        return showShortText ? t('SurveyCallback--stepper-rejected-three-short') : t('SurveyCallback--stepper-rejected-three');
    }
  };

  /**
   * Conditionally render stepper on rejected status callback
   * @returns
   */
  const renderStepper = (): JSX.Element | undefined => {
    if (showStepper) {
      return(
        <StepperContainer>
          <Stepper
            steps = {[{
                title     : getStepperContent(StepperTitles.FIRST),
                stateStart: StepperState.DEFAULT,
                stateEnd  : StepperState.ACTIVE,
                typeStart : StepperType.CHECK,
                typeEnd   : StepperType.ICON,
                iconEnd   : ICON_IDS.CHECK_FILL,
              },{
                title     : getStepperContent(StepperTitles.SECOND),
                stateStart: StepperState.DEFAULT,
                stateEnd  : StepperState.ACTIVE,
                typeStart : StepperType.CHECK,
                typeEnd   : StepperType.ICON,
                iconEnd   : ICON_IDS.CHECK_FILL,
              },{
                title     : getStepperContent(StepperTitles.THIRD),
                stateStart: StepperState.DEFAULT,
                stateEnd  : StepperState.ACTIVE,
                typeStart : StepperType.CHECK,
                typeEnd   : StepperType.ICON,
                iconEnd   : ICON_IDS.CHECK_FILL,
              }]}
            activeStep    = {2}
            animateToStep = {true}
          />
        </StepperContainer>
      );
    }
};

  /**
   * Conditionally render title
   */
  const renderTitle = (): JSX.Element => {
    return (
      <SurveyModalTitle centered={centered}>
        <Trans
          t          = {t}
          i18nKey    = {title}
          components = {{ br: <br />}}
        />
      </SurveyModalTitle>
    );
  };

  /**
   * Conditionally render text
   */
  const renderText = (): JSX.Element => {
    return (
      <SurveyModalText showStepper = {showStepper} centered={centered}>
        <Trans
          t          = {t}
          i18nKey    = {text}
          values     = {{ points: points }}
          components = {{ b: <strong />}}
        />
      </SurveyModalText>
    );
  };

  /**
   * Handle continue button click
   */
  const handleContinueButtonClick = (): void => {
    if (modalCloseCallback) {
      // If the user is in modal and eligible for survey, navigate to the survey URL
      if (surveyEligibilityStatus === SurveyEligibilityStatus.ELIGIBLE && surveyUrl) {
        navigateToExternalSurvey(surveyUrl, true, true);
        if (surveyId) {
          removeSurveyById(surveyId);
        }
      }

      modalCloseCallback();
    }

    navigateTo(MENU_ROUTES.HOME);
  };

  /**
   * Render 'Take more surveys' button
   */
  const renderContinueButton = (): JSX.Element => {
    return (
      <Button
        size    = {ButtonSizes.LARGE}
        context = {ContextTypes.PRIMARY}
        onClick = {handleContinueButtonClick}
        testId  = {SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CONFIRM_BUTTON}
      >
        {buttonText ? t(buttonText) : t('SurveyCallback--button--continue')}
      </Button>
    );
  };

  /**
   * Conditionally render 'report issue' button
   */
  const renderReportIssueButton = (): JSX.Element | undefined => {
    if (reportIssue) {
      return (
        <Button
          size    = {ButtonSizes.LARGE}
          context = {ContextTypes.SECONDARY}
          onClick = {() => navigateTo(MENU_ROUTES.ACCOUNT_CONTACT_US)}
          testId  = {SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CANCEL_BUTTON}
        >
          {t('SurveyCallback--button--reportIssue')}
        </Button>
      );
    }
  };

  /**
   * Render the standard survey modal
   */
  const renderSurveyCallbackContent= (): JSX.Element | undefined => {
    return (
      <>
        {renderEmojiIcon()}
        {renderTitle()}
        {renderText()}
        {renderStepper()}
        <SurveyModalButtonContainer>
          {renderContinueButton()}
          {renderReportIssueButton()}
        </SurveyModalButtonContainer>
      </>
    );
  };

  /**
   * Renders Regular survey redirect modal or Survey Recommendation Modal
   * @returns
   */
  const renderModalContainer = (): JSX.Element | undefined => {
    if (surveyEligibilityStatus === SurveyEligibilityStatus.ELIGIBLE) {

      return (
        <SurveyModalContainer data-testid={SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CONTAINER}>
          {renderCloseIcon()}
          <Border show={!centered} />
          <SurveyEligibilityText>
            <EligibleIcon>
              {'\ud83c\udf89'}
            </EligibleIcon>
            <span>
              Good news!
            </span>
            <QualifiedText>
              You qualified to enter the survey.
            </QualifiedText>
          </SurveyEligibilityText>
          <SurveyModalButtonContainer>
            <Button
              size    = {ButtonSizes.LARGE}
              context = {ContextTypes.PRIMARY}
              onClick = {handleContinueButtonClick}
              testId  = {SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CONFIRM_BUTTON}
              iconRight= {ICON_IDS.ARROW_RIGHT}
            >
              Start Survey
            </Button>
          </SurveyModalButtonContainer>
        </SurveyModalContainer>
      );
    }

    return(
      <SurveyModalContainer data-testid={SURVEY_MODAL_TEST_IDS.SURVEY_MODAL_TEST_CONTAINER}>
        {renderPointsPill()}
        {renderCloseIcon()}
        <Border show={!centered} />
        <SurveyModalContentContainer centered={centered}>
          {renderSurveyCallbackContent()}
          { callbackStatus === SurveyCallbackStatuses.COMPLETE ?
            <SurveyModalQrContainer>
              <QrDownloadApp inverse={true} isOnSurveyCallback={true} titleMaxWidth={310} />
            </SurveyModalQrContainer>
            : null }
        </SurveyModalContentContainer>
      </SurveyModalContainer>
    );
  };

  return (
    <>
      { displayComponent ? renderModalContainer() : '' }
    </>
  );
}

export default SurveyModal;
