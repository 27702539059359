import {
  BATCH_MAX_WAIT_SECONDS,
  BATCH_MIN_PROVIDERS,
  BATCH_MIN_TPA_PROVIDERS,
  BATCH_MIN_WAIT_SECONDS,
  SURVEY_CACHE_HIGH_WATER_MARK,
  SURVEY_CACHE_LOW_WATER_MARK
} from 'Pages/surveyExperience/services/constants';
import {
  getSurveyCacheCreatedTime,
  getSurveysShown,
  isFirstSurveyRequest,
  isSurveyCacheEmpty
} from 'Pages/surveyExperience/services/surveyService';
import { uniqueProviderIdsSize } from 'Shared/services/helperService';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';

/**
 * Returns whether the survey cache is stale based on when it was created
 */
export const shouldImmediatelyRefreshSurveys = (cacheCreatedTime = getSurveyCacheCreatedTime()): boolean => {
  const cacheAgeInSeconds = (Date.now() - cacheCreatedTime) / 1000;
  if (isFirstSurveyRequest() && !getSurveysShown()) {
    return cacheAgeInSeconds > SURVEY_CACHE_LOW_WATER_MARK;
  } else {
    return cacheAgeInSeconds > SURVEY_CACHE_HIGH_WATER_MARK;
  }
};

export const isSurveyCacheStale = (cacheCreatedTime = getSurveyCacheCreatedTime()) => {
  const cacheAgeInSeconds = (Date.now() - cacheCreatedTime) / 1000;
  return cacheAgeInSeconds > SURVEY_CACHE_HIGH_WATER_MARK;
};

/**
 * Returns true if the survey cache can be set
 * @param lastRequestStartTime
 * @param lastCompletedRequestTime
 * @param hasMoreSurveys
 * @param surveys
 */
export const isEligibleToSetSurveyCache = (lastRequestStartTime: number, lastCompletedRequestTime: number, surveys: UspSurveysModel[], hasMoreSurveys: boolean): boolean => {
  const TPA_PROVIDER_IDS    = [5, 8, 10, 16, 18, 21, 23, 29, 290, 291];

  // We want to set an empty local storage
  // if the request is completed and the member
  // doesn't have a survey list local storage or it does have local storage but it's empty
  // since we use the local storage to know
  // when to show no surveys message.
  if (!hasMoreSurveys && isSurveyCacheEmpty()) {
    return true;
  }

  // We don't want to set the local storage if
  // there are no surveys to store.
  if (surveys.length === 0) {
    return false;
  }

  // If the request has completed then set the local storage.
  // This comes after "surveys.length" because
  // we don't want to set an empty local storage if there are surveys
  // in the local storage.
  if (!hasMoreSurveys) {
    return true;
  }

  // Allow adding items to the local storage if any of the following is true:
  // - The survey cache is stale
  // - The local storage is empty or doesn't exist
  if (shouldImmediatelyRefreshSurveys(lastCompletedRequestTime) || isSurveyCacheEmpty()) {
    // Time since the request started in seconds
    const timeSinceRequestStart = Math.floor((Date.now() - lastRequestStartTime)/1000);

    if (timeSinceRequestStart < BATCH_MIN_WAIT_SECONDS) {
      return false;
    }

    // If the number of unique providers is greater or equal than the minimum, show the surveys
    if (
      uniqueProviderIdsSize(surveys) >= BATCH_MIN_PROVIDERS &&
      uniqueProviderIdsSize(surveys, TPA_PROVIDER_IDS) >= BATCH_MIN_TPA_PROVIDERS
    ) {
      return true;
    }

    // If the timeout is met, show the surveys
    if (timeSinceRequestStart >= BATCH_MAX_WAIT_SECONDS) {
      return true;
    }
  }

  return false;
};
