
/**
 * RED MS Routes
 */
export const API_CONFIG_RED_MS      = '/v1/users';
export const RED_PAYMENT_METHODS    = '/payment-methods';
export const RED_REDEEM             = '/redeem';
export const RED_REDEMPTION_OPTIONS = '/redemption-options';
export const RED_REDEMPTION_STATUS  = '/redemption-status';

/**
 * ACT MS Routes
 */
export const API_CONFIG_ACT_MS           = '/v1/users';
export const ACT_CONFIG_V1               = '/v1';
export const ACT_POINTS                  = '/points';
export const ACT_ACTIVITIES              = '/activities';
export const ACT_SEARCH_ACTIVITIES       = '/search';

/**
 * Redemption Verification Code Routes
 */
export const REDEMPTION_STATUS     = '/verify/status';
export const REDEEM_EMAIL_CODE     = '/redemption/code/email';
export const REDEEM_VERIFY_CODE    = '/redemption/code/verify';
export const REDEEM_EMAIL_CODE_MS  = '/send-redemption-code';
export const REDEEM_VERIFY_CODE_MS = '/redemption-code';