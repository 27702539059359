import React  from 'react';
import { useTranslation } from 'react-i18next';
import PromoWidgets from 'Shared/components/design//promoWidgets/PromoWidgets';
import { SlideOutContainer, Title } from 'Shared/components/design/promoSlideOut/style';
import useScrollStop from 'Shared/hooks/useScrollStop';

type PromoSlideOutProps = {
  isOpen: boolean;
};

/**
 * Component that renders a slide-out panel in mobile view
 * @param param0
 * @returns
 */
function PromoSlideOut({isOpen}: PromoSlideOutProps): JSX.Element {
  const { t } = useTranslation('app');

  useScrollStop(isOpen);

  return (
    <SlideOutContainer isOpen={isOpen}>
      <Title>
        {t('PromoWidget--mobile-slideOut-title')}
      </Title>
      <PromoWidgets />
    </SlideOutContainer>
  );
}

export default PromoSlideOut;
