import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const transitionSeconds = 0.5;

interface ModalProps {
  open: boolean;
}

export const ModalBackdrop = styled.div<ModalProps>`
  position        : fixed;
  inset           : 0;
  display         : flex;
  align-items     : center;
  justify-content : center;
  background-color: ${DESIGN_TOKENS.color.background.overlay.value};
  opacity         : ${({ open }) => open ? 1 : 0};
  transition      : opacity ${transitionSeconds}s;
  pointer-events  : ${({ open }) => open ? 'auto' : 'none'};
  z-index         : 10000;
`;

export const ModalContainer = styled.div<ModalProps>`
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  color           : ${DESIGN_TOKENS.color.text.normal.value};
  border-radius   : ${DESIGN_TOKENS.borderRadius.md.value};
  position        : relative;
  display         : ${({ open }) => open ? 'flex' : 'none'};
  flex-direction  : column;
  align-items     : center;
  overflow        : hidden;
  width           : 512px;
  max-width       : 100vw;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    border-radius: 0;
    width        : 100vw;
    height       : 100%;
  }
`;
