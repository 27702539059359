import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type QuestionnaireStepStartEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.sub_type]    : ANALYTICS_EVENT_SUB_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : number,
  [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : string,
  [ANALYTICS_EVENT_ATTRIBUTES.current_step]: number,
}

/**
 * Sends analytic event and attributes for Questionnaire Step to Google Analytics
 * @param questionnaireStepStartEvent
 * @returns void
 */
export const analyticsTrackQuestionnaireStepStart = (questionnaireStepStartEvent: QuestionnaireStepStartEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      ...questionnaireStepStartEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]: ANALYTICS_EVENT_TYPE.questionnaire,
    }
  };

  switch (questionnaireStepStartEvent[ANALYTICS_EVENT_ATTRIBUTES.sub_type]) {
    case ANALYTICS_EVENT_SUB_TYPE.survey_qualification_step:
      trackEventConditionally(GA4_EVENT_CATEGORY.survey_qualification, [event]);
      break;
    case ANALYTICS_EVENT_SUB_TYPE.survey_medley_step:
      trackEventConditionally(GA4_EVENT_CATEGORY.survey_medley, [event]);
      break;
    default:
      trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
      break;
  }
};