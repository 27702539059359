import { COOKIE_IDS, INSTALLATION_TOKEN_DATA_EXPIRY_IN_DAYS } from 'App/constants';
import { logError } from 'App/services/coralogixService';
import { hasAuthToken } from 'App/services/idpTokenService';
import { setPassiveMeteringDataTokenCookie } from 'App/services/pisTokenService';
import { DeviceInfoModel, getWurfl } from 'App/services/wurflService';
import { APP_INSTALL_STAGES, APP_TYPES } from 'Shared/constants';
import {
  ANALYTICS_EVENT_TYPE,
  ANALYTICS_RESPONSE_CODES,
  ANALYTICS_RESPONSE_MESSAGE
} from 'Shared/services/analytics/constants';
import { analyticsTrackSteInstallationEnd } from 'Shared/services/analytics/events/steInstallationEnd';
import { setOptInEventInBss } from 'Shared/services/bss/api/bssAPI';
import { bssDataCollector } from 'Shared/services/bss/services/bssService';
import { deleteCookie, getCookie, setCookie } from 'Shared/services/cookieService';
import {
  getPassiveMeteringData,
  getPlatformInstallationData,
  putPlatformInstallationSuccess
} from 'Shared/services/pis/api/pisAPI';
import { PLATFORM_NAMINGS } from 'Shared/services/pis/constants';
import {
  EligibleAppType,
  UninstallMutatedSurveyType,
  UninstallSurveyType
} from 'Shared/services/pis/types/installationFlowCheckTypes';
import { getEnvVar } from 'Shared/utils/envUtils';

/**
 * Mutating wurfl namings
 */
const pisDeviceDataMutator = (data: DeviceInfoModel) => {
  data.advertised_device_os = data.advertised_device_os === PLATFORM_NAMINGS.macOS
    ? PLATFORM_NAMINGS.MAC_OS : data.advertised_device_os?.toUpperCase();
  data.advertised_browser   = data.advertised_browser?.toUpperCase();
  return data;
};

const setIntegrationServiceInstallationTokenCookie = (appDownloadToken: string, platform: string): void => {
  try {
    setCookie(
      (COOKIE_IDS as any)[`${platform}_APP_DOWNLOAD_TOKEN`],
      appDownloadToken,
      INSTALLATION_TOKEN_DATA_EXPIRY_IN_DAYS.ACCESS,
      true
    );
  } catch (error) {
    logError('setIntegrationServiceInstallationTokenCookie', error);
  }
};

/**
 * Returns the installation URL for the given platform.
 * @param resultData
 */
const getInstallationURL = (platform: string, deviceDataFromPis: { availableOptions: Array<EligibleAppType> }) => {
  if (!platform || !deviceDataFromPis) {
    return;
  }

  const installationUrl = deviceDataFromPis?.availableOptions?.find(el => el.type === platform)?.artifactUrl;

  if (!installationUrl) {
    return;
  }

  return installationUrl;
};

/**
 * Validates if the device data from pis is available
 * @param deviceDataFromPis
 * @returns
 */
const isDeviceDataFromPisAvailable = (deviceDataFromPis: { availableOptions: Array<EligibleAppType> }): boolean => {
  return deviceDataFromPis?.availableOptions?.length !== undefined && deviceDataFromPis.availableOptions.length > 0;
};

/**
 * Get installation url
 * @param generatedParams
 * @param platform
 */
export const getSetDownloadToken = (generatedParams: string, platform: string) => {
  getPlatformInstallationData(generatedParams).then(res => {
    const resultData = res && res.data || {};
    if (resultData.downloadToken) {
      setIntegrationServiceInstallationTokenCookie(resultData.downloadToken, platform);
    }
  }).catch(error => {
    logError('getSetDownloadToken', error);
  });
};

/**
 * When install button is clicked send collected device data to bss (Behavioral Signals Service)
 * and get the download URL and token from pis (pulse integration service) and set to cookie
 * @param platform
 * @param deviceDataFromPis
 * @returns {Promise}
 */
export const handleInstallationStart = (platform: string, deviceDataFromPis: { availableOptions: Array<EligibleAppType> }): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      if (!platform) {
        return reject(false);
      }

      const generatedParams = deviceDataFromPis?.availableOptions?.find(el => el.type === platform)?.url.split('?')[1];
      if (!generatedParams) {
        return reject(false);
      }

      /**
       * There is no functionality with response data, even if this fails the app process should not be stopped
       */
      setOptInEventInBss(bssDataCollector(platform, APP_INSTALL_STAGES.PRE), platform).then().catch(error => {
        logError('setOptInEventInBss', error);
      });

      /**
       * Get installation url
       */
      getSetDownloadToken(generatedParams, platform);

      // Refresh the pmdata cookie incase it's not there
      passiveMeteringDataTokenVerification(0, true);

      return resolve(true);
    } catch (e) {
      logError('handleInstallationStart', e);
      reject(false);
    }
  });
};

/**
 * Check if the user was redirected from the desktop app or extension
 * @param searchParams
 */
const checkAppInstallationAction = (searchParams: URLSearchParams) => {
  let platform      = '';
  let downloadToken = '';

  try {
    // If the passive metering is disabled, then return
    if (!getEnvVar('PASSIVE_METERING_ENABLED')) {
      return;
    }

    // If there is no params, then return false and do not check
    if (searchParams.get('ei') !== 'true' && searchParams.get('appi') !== 'true' ) {
      return false;
    }

    // Check the param from extension
    if (searchParams.get('ei') === 'true') {
      platform      = APP_TYPES.EXTENSION;
      downloadToken = getCookie(COOKIE_IDS.EXTENSION_APP_DOWNLOAD_TOKEN);
    }

    // Check the param from desktop
    if (searchParams.get('appi') === 'true') {
      platform      = APP_TYPES.DESKTOP;
      downloadToken = getCookie(COOKIE_IDS.DESKTOP_APP_DOWNLOAD_TOKEN);
    }

    if (downloadToken) {
      analyticsTrackSteInstallationEnd(platform, ANALYTICS_EVENT_TYPE.ste_install, ANALYTICS_RESPONSE_CODES.success, ANALYTICS_RESPONSE_MESSAGE.success);

      putPlatformInstallationSuccess(downloadToken).then(() => {
        return platform === APP_TYPES.EXTENSION ? deleteCookie(COOKIE_IDS.EXTENSION_APP_DOWNLOAD_TOKEN) : deleteCookie(COOKIE_IDS.DESKTOP_APP_DOWNLOAD_TOKEN);
      }).catch(error => {
        logError('putPlatformInstallationSuccess', error);
      });
    }
  } catch (error) {
    logError('checkAppInstallationAction', error);
  }

  if (hasAuthToken()) {
    setOptInEventInBss(bssDataCollector(platform, APP_INSTALL_STAGES.AFTER), platform).then().catch(error => {
      logError('setOptInEventInBss', error);
    });
  }
};

/**
 * Verify pmdata (passive metering data) token from cookie
 * compare with the logged-in user id
 * or get from pis (pulse integration service)
 */
let passiveMeteringDataTokenVerificationInterval: ReturnType<typeof setTimeout>;
const passiveMeteringDataTokenVerification = async (retryCount = 0, withInterval = false) => {
  if (!getEnvVar('PASSIVE_METERING_ENABLED') || !hasAuthToken() || getWurfl().is_mobile) {
    return false;
  }

  const maxRetries = 5;

  await getPassiveMeteringData().then(response => {
    if (response.data) {
      setPassiveMeteringDataTokenCookie(response.data);
    }

    // Call the API every 5 seconds during installation flow so that
    // we can make sure the cookie is always fresh.
    if (withInterval) {
      clearTimeout(passiveMeteringDataTokenVerificationInterval);
      passiveMeteringDataTokenVerificationInterval = setTimeout(() => {
        passiveMeteringDataTokenVerification(retryCount, true);
      }, 5000);
    }
  }).catch(error => {
    // Retry failed API calls up to 5 times before giving up.
    if (retryCount < maxRetries) {
      setTimeout(() => {
        passiveMeteringDataTokenVerification(retryCount + 1, withInterval);
      }, 1000);
    } else {
      logError('getPassiveMeteringData', error);
    }
  });
};

/**
 * Mutating data for uninstallation feedback
 */
const pisUninstallationFeedbackDataMutator = (data: Array<UninstallSurveyType>, description?: string) => {
  const mutatedDataToSend: Array<UninstallMutatedSurveyType> = [];

  data.forEach((item: UninstallSurveyType) => {
    const getSingleItemParams: UninstallMutatedSurveyType = {
      answer    : !item.isCustom ? 'true': description || '',
      questionId: item.id,
    };
    mutatedDataToSend.push(getSingleItemParams);
  });

  return mutatedDataToSend;
};


export {
  checkAppInstallationAction,
  getInstallationURL,
  isDeviceDataFromPisAvailable,
  passiveMeteringDataTokenVerification,
  pisDeviceDataMutator,
  pisUninstallationFeedbackDataMutator,
  setIntegrationServiceInstallationTokenCookie,
};
