import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, INTER_FONT, LABEL_LARGE, LABEL_SMALL, PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled, { keyframes } from 'styled-components';

export type SurveyModalProps = {
  showStepper : boolean;
  centered   ?: boolean;
};

const SURVEY_MODAL_WIDTH = 480;

const slideAnimation = (transformY: string) => keyframes`
  0% {
    transform: translateY(${transformY});
  }

  100% {
    visibility: visible;
    transform : translate(0);
  }
`;

export const SurveyModalContainer = styled.div`
  z-index   : 100;
  position  : absolute;
  top       : 0;
  right     : 0;
  left      : 0;
  bottom    : 0;
  background: ${DESIGN_TOKENS.color.background.primary.value};
`;

export const SurveyModalContentContainer = styled.div<{ centered?: boolean }>`
  max-width   : ${SURVEY_MODAL_WIDTH}px;
  margin-left : auto;
  margin-right: auto;
  padding     : 40px 24px 24px;

  ${(props) => props.centered && `
    display       : flex;
    flex-direction: column;
    align-items   : center;
  `}

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    padding: 10px 16px 24px;
  }
`;

export const SurveyModalCloseIcon = styled.div`
  position: absolute;
  cursor  : pointer;
  right   : 8px;
  top     : 8px;
  padding : 8px;

  svg {
    width : 24px;
    height: 24px;
  }
`;

export const SurveyModalText = styled.div<SurveyModalProps>`
  ${(props) => props.centered ? INTER_FONT.PARAGRAPH : PARAGRAPH.DEFAULT}
  margin-bottom      : ${$props => $props.showStepper ? '12px' : '80px'};
  color              : ${DESIGN_TOKENS.color.text.secondary.value};
  visibility         : hidden;
  --transform-start  : -20rem;
  animation          : 250ms ${slideAnimation('-3rem')};
  animation-delay    : 1s;
  animation-fill-mode: forwards;

  strong {
    color: ${DESIGN_TOKENS.color.text.normal.value};
    ${PARAGRAPH.BOLD}
  }

  ${(props) => props.centered && `
    font-size  : 16px;
    line-height: 24px;
    text-align : center;
    color      : #6F6F6F;
    width      : 220px;
    margin-top : 8px;
  `}
`;

export const SurveyModalTitle = styled.div<{ centered?: boolean }>`
  ${ TITLES_NORMAL.FT_04 }
  margin-top         : 16px;
  margin-bottom      : 16px;
  color              : ${DESIGN_TOKENS.color.text.normal.value};
  visibility         : hidden;
  animation          : 250ms ${slideAnimation('-10rem')};
  animation-fill-mode: forwards;

  ${(props) => props.centered && `
    ${ TITLES_NORMAL.FT_01 };
    font-size  : 28px;
    line-height: 36px;
    text-align : center;
  `}
`;

export const SurveyModalQrContainer = styled.div`
  padding       : 12px 24px;
  display       : flex;
  flex-direction: column;
  gap           : 16px;
  position      : absolute;
  bottom        : 112px;
  left          : 0;
  right         : 0;
  margin        : 0 auto;
  max-width     : ${SURVEY_MODAL_WIDTH}px;
  border        : 1px solid ${DESIGN_TOKENS.color.border.normal.value};
  border-radius : 12px;
  background    : ${DESIGN_TOKENS.color.background.secondary.value};

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    max-width: calc(100% - 48px);
  }
`;

export const SurveyModalButtonContainer = styled.div`
  padding       : 24px;
  display       : flex;
  flex-direction: column;
  gap           : 16px;
  position      : absolute;
  bottom        : 0;
  left          : 0;
  right         : 0;
  margin        : 0 auto;
  border-top    : 1px solid ${DESIGN_TOKENS.color.border.light.value};
  max-width     : ${SURVEY_MODAL_WIDTH}px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    max-width: calc(100% - 48px);
    padding  : 24px 0;
  }
`;

export const PointsPill = styled.div`
  position       : absolute;
  border-radius  : 16px;
  right          : 48px;
  top            : 14px;
  padding        : 4px 12px 4px 12px;
  background     : ${DESIGN_TOKENS.color.primitive.yellow['200'].value};
  display        : flex;
  align-items    : center;
  justify-content: center;

  span {
    ${LABEL_SMALL.MONOSPACE}
  }
`;

export const EmojiIcon = styled.div`
  ${TITLES_NORMAL.FT_04}
  margin-top         : 64px;
  visibility         : hidden;
  animation          : 250ms ${slideAnimation('-10rem')};
  animation-fill-mode: forwards;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    margin-top: 40px;
  }
`;

export const Border = styled.div<{ show: boolean }>`
  display   : ${(props) => props.show ? 'block' : 'none'};
  margin-top: 54px;
  border-top: 1px solid ${DESIGN_TOKENS.color.border.light.value};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
`;

export const StepperContainer = styled.div`
  margin-bottom: 24px;
`;

export const SurveyRecommendationModalContainer = styled.div`
  max-width : 384px;
  margin-top: 24px;
  padding   : 0px 16px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    padding       : 0;
    display       : flex;
    flex-direction: column;
    align-items   : center;
  }
`;

export const SurveyEligibilityText = styled.div`
  ${TITLES_NORMAL.FT_02}
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  color          : ${DESIGN_TOKENS.color.text.secondary.value};
  text-align     : center;
  height         : 75%;

  span {
    ${TITLES_NORMAL.FT_04}
    color: ${DESIGN_TOKENS.color.text.normal.value};
    line-height: 60px;
  }
`;

export const EligibleIcon = styled.div`
  font-size          : 64px;
  visibility         : hidden;
  animation          : 250ms ${slideAnimation('-10rem')};
  animation-fill-mode: forwards;
  margin-bottom      : 16px;
  line-height        : 1;
`;

export const QualifiedText = styled.div`
  ${LABEL_LARGE.DEFAULT};
`;