import { COOKIE_IDS } from 'App/constants';
import { getSessionId } from 'App/services/idpTokenService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getCookie, setCookie } from 'Shared/services/cookieService';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';

const DAYS_TO_SHOW_DYNAMIC_SURVEY_MEDLEY = 1;
const VISIT_COOKIE_RENEWAL_PERIOD        = 30;

/**
 * This processor is used to handle the popup logic for the survey medley
 */
export const surveyMedleyPopupManager = (() => {
  let hasTriggeredGeneralProfilePopup = false;

  return {
    /**
     * This function is used to set the flag that the user has completed the general profile
     */
    completeGeneralProfile: (): void => {
      hasTriggeredGeneralProfilePopup = true;
    },

    /**
     * This function is used to check if the user has completed the general profile
     * @returns {boolean}
     */
    hasCompletedGeneralProfile: (): boolean => hasTriggeredGeneralProfilePopup,

    /**
     * This function is used to close the popup and set the current session as the last session
     */
    closePopup: (): void => {
      const currentSessionId = getSessionId();
      setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_MEDLEY_SESSION_TRIGGER, currentSessionId, true);
    },

    /**
     * This function is used to check if the current session is the first session
     * @returns {boolean}
     */
    isFirstPopupSession: (): boolean => {
      const currentSessionId       = getSessionId();
      const lastSessionIdTriggered = getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_MEDLEY_SESSION_TRIGGER, true);

      if (currentSessionId != lastSessionIdTriggered || !lastSessionIdTriggered) {
        return true;
      }

      return false;
    },

    /**
     * Checks the user's last visit date from a cookie and renews the visit cookie if needed.
     * If the user has not visited the site for a specified number of days, it will show a popup again for one day
     * and renew the visit cookie.
     *
     * @returns {void}
     */
    manageVisitCookie: (): void => {
      const lastVisit = getCookie(COOKIE_IDS.USER_VISIT_DATE);

      const currentDate = new Date();

      if (lastVisit) {
        const lastVisitDate = new Date(lastVisit);
        const timeDifference = Math.abs(currentDate.getTime() - lastVisitDate.getTime());

        // Calculate the number of days since the user's last visit
        const daysSinceLastVisit = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        if (daysSinceLastVisit >= VISIT_COOKIE_RENEWAL_PERIOD) {
          // If the user has not visited the site for 30 days, we will show the popup again for one day
          setCookie(COOKIE_IDS.DYNAMIC_SURVEY_MEDLEY_ENABLED, currentDate.toISOString(), DAYS_TO_SHOW_DYNAMIC_SURVEY_MEDLEY);

          // Renew the visit cookie
          setCookie(COOKIE_IDS.USER_VISIT_DATE, currentDate.toISOString(), 90);
        }
      } else {
        // If the user has not visited the site before, we will initialize the visit cookie
        setCookie(COOKIE_IDS.USER_VISIT_DATE, currentDate.toISOString(), 90);
      }
    },

    /**
     * Checks if the dynamic survey medley is enabled by verifying the presence of a specific cookie.
     *
     * @returns {boolean} - Returns true if the dynamic survey medley is enabled, otherwise false.
     */
    isDynamicSurveyMedleyEnabled: (): boolean => {
      return !!getCookie(COOKIE_IDS.DYNAMIC_SURVEY_MEDLEY_ENABLED);
    }
  };
})();