import { LoaderEventProps } from 'Pages/surveyExperience/components/surveysStreamLoader/LoaderEventModels';
import { StePreinstallModalEventProps, SurveyPromptEventProps } from 'Pages/surveyExperience/models/EventModels';
import { removeEventListener } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * RemoveEventListener: window.mfEvents.surveyExperience.surveyPrompt
 * @param callback
 */
export const removeSurveyPromptEvent = (callback: (props: SurveyPromptEventProps) => void): void => {
  removeEventListener<SurveyPromptEventProps>(MF_EVENTS.surveyExperience.surveyPrompt, callback);
};

/**
 * Remove STE app preinstall modal event
 * @param callback
 */
export const removeStePreInstallModalEvent = (callback: (props: StePreinstallModalEventProps) => void): void => {
  removeEventListener(MF_EVENTS.app.stePreinstallModal, callback );
};

/**
 * Remove STE email confirmation modal event
 * @param callback
 */
export const removeSteEmailModalEvent = (callback: () => void): void => {
  removeEventListener(MF_EVENTS.app.stePreinstallModal, callback );
};

/**
 * RemoveEventListener: window.mfEvents.surveysLoading.action
 * @param callback
 */
export const removeSurveysLoadingEvent = (callback: (props: LoaderEventProps) => void): void => {
  removeEventListener<LoaderEventProps>(MF_EVENTS.surveysLoading.action, callback);
};

/**
 * RemoveEventListener: window.MF_EVENTS.promotions.optinStart
 * @param callback
 */
export const removePromoOptinStartEvent = (callback: () => void): void => {
  removeEventListener(MF_EVENTS.promotions.optinStart, callback);
};