export enum CakeInitLocation {
  BASIC_PROFILE = 'BASIC_PROFILE',
}

/**
 * All cake pixels being triggered on the defined step
 */
export const initializeCake = (location: CakeInitLocation, cakeRequestId: string | undefined): void => {
  switch (location) {
    case CakeInitLocation.BASIC_PROFILE:
      fireCakePixels(cakeRequestId);
      break;
    default:
      break;
  }
};

/**
 * Render Cake pixel
 * @param pixelUrl
 * @param iframeId
 * @returns
 */
const renderPixel = (pixelUrl: string, iframeId: string) => {
  if (document.getElementById(iframeId)) {
    return;
  }

  const iframe               = document.createElement("iframe");
        iframe.src           = pixelUrl;
        iframe.width         = "0";
        iframe.height        = "0";
        iframe.style.border  = "none";
        iframe.style.display = "none";
        iframe.id            = iframeId;
  document.body.appendChild(iframe);
};

/**
 * Fire Cake Pixels if a valid "cakeRequestId" (crid) is provided
 */
export const fireCakePixels = (cakeRequestId: string | undefined): void => {
  if (!cakeRequestId) {
    return;
  }

  const timestamp           = Date.now();
  const cakePixelEvent3Url  = `https://bmv.biz/p.ashx?o=1&e=3&t=${timestamp}&r=${cakeRequestId}`;
  const cakePixelEvent97Url = `https://bmv.biz/p.ashx?o=1&e=97&t=${timestamp}&r=${cakeRequestId}`;

  renderPixel(cakePixelEvent3Url, "cake-sign-up-iframe-3");
  renderPixel(cakePixelEvent97Url, "cake-sign-up-iframe-97");
};

