import { LabelVariantType } from 'Shared/components/design/label/Label';
import { INTER_FONT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

/**
 * Get the color for the label variant
 * @param variant
 */
const getVariantColor = (variant: keyof typeof LabelVariantType): string => {
  switch (variant) {
    case LabelVariantType.positive:
      return '#037551';
    case LabelVariantType.negative:
      return '#E21C18';
    case LabelVariantType.neutral:
      return '#505050';
    case LabelVariantType.notice:
      return '#353100';
    case LabelVariantType.info:
      return '#0E3FD4';
    default:
      return '#505050';
  }
};

/**
 * Get the background color for the label variant
 * @param variant
 */
const getVariantBackground = (variant: keyof typeof LabelVariantType): string => {
  switch (variant) {
    case LabelVariantType.positive:
      return '#D9FFF3';
    case LabelVariantType.negative:
      return '#FCF0EF';
    case LabelVariantType.neutral:
      return '#EBEBEB';
    case LabelVariantType.notice:
      return '#FCFFDF';
    case LabelVariantType.info:
      return '#F1F4FE';
    default:
      return '#EBEBEB';
  }
};

/**
 * Get the background color for the label variant
 * @param variant
 */
const getVariantBackgroundHover = (variant: keyof typeof LabelVariantType): string => {
  switch (variant) {
    case LabelVariantType.positive:
      return '#c5ebdf';
    case LabelVariantType.negative:
      return '#e8dcdb';
    case LabelVariantType.neutral:
      return '#d7d7d7';
    case LabelVariantType.notice:
      return '#e7ebcb';
    case LabelVariantType.info:
      return '#dde0ea';
    default:
      return '#d7d7d7';
  }
};

/**
 * Get the accent color for the label variant
 * @param variant
 */
const getVariantAccent = (variant: keyof typeof LabelVariantType): string => {
  switch (variant) {
    case LabelVariantType.positive:
      return '#037551';
    case LabelVariantType.negative:
      return '#E21C18';
    case LabelVariantType.neutral:
      return 'rgba(20, 18, 18, 0.48)';
    case LabelVariantType.notice:
      return '#BEB002';
    case LabelVariantType.info:
      return '#0E3FD4';
    default:
      return 'rgba(20, 18, 18, 0.48)';
  }
};

export const LabelRoot = styled.span<{ variant: keyof typeof LabelVariantType, clickable: boolean }>`
  ${INTER_FONT.PARAGRAPH}
  display      : inline-flex;
  align-items  : center;
  font-weight  : 600;
  border-radius: 24px;
  color        : ${({ variant }) => getVariantColor(variant)};
  background   : ${({ variant }) => getVariantBackground(variant)};
  cursor       : ${({ clickable }) => clickable ? 'pointer' : 'default'};
  padding      : 1px 8px;
  transition   : background 0.2s ease-in-out;
    
  &:before {
    content      : '';
    display      : inline-block;
    background   : ${({ variant }) => getVariantAccent(variant)};
    width        : 8px;
    height       : 8px;
    border-radius: 50%;
    margin-right : 4px;
  }

  ${({ clickable, variant }) => clickable && `
    &:hover {
      background: ${getVariantBackgroundHover(variant)};
    }
  `}
`;
