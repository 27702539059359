import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled, { css, keyframes } from 'styled-components';

const animationDuration = '0.2s';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleUp = keyframes`
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(50px);
  }
`;

/**
 * Get vertical align style
 * @param verticalAlign
 */
const getDialogVerticalAlign = (verticalAlign: 'center' | 'top' | 'bottom') => {
  if (verticalAlign === 'center') {
    return css`
      justify-content: center;
    `;
  }
  if (verticalAlign === 'top') {
    return css`
      justify-content: flex-start;
    `;
  }
  return css`
    justify-content: flex-end;
  `;
};


export const DialogContainer = styled.div<{ zIndex?: number }>`
  position       : fixed;
  top            : 0;
  left           : 0;
  z-index        : ${props => props.zIndex ?? 301};
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 100%;
  height         : 100vh;
`;

export const DialogRoot = styled.div<{
  show?                   : boolean,
  isAnimating?            : boolean,
  allowAnimation          : boolean,
  maxWidth?               : number,
  mobileHorizontalSpacing?: number
}>`
  position       : relative;
  z-index        : 101;
  max-width      : ${props => props.maxWidth ? `${props.maxWidth}px` : '600px'};
  width          : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;

  ${props => props.allowAnimation && (props.isAnimating && !props.show ? css`
    animation: ${fadeOut} ${animationDuration} ease-out forwards, ${scaleDown} ${animationDuration} ease-out forwards;
  ` : css`
    animation: ${fadeIn} ${animationDuration} ease-out forwards, ${scaleUp} ${animationDuration} ease-out forwards;
  `)}

  // This block applies a media query for mobile devices. If the 'mobileHorizontalSpacing' prop is provided,
  // it adjusts the width and margins of the component based on this value. This creates a dynamic horizontal spacing
  // on mobile devices.
  ${props => props.mobileHorizontalSpacing && css`
    @media ${ DEVICE_BREAKPOINT.maxMobile } {
      width       : calc(100% - ${props.mobileHorizontalSpacing * 2}px);
      margin-left : ${props.mobileHorizontalSpacing}px;
      margin-right: ${props.mobileHorizontalSpacing}px;
    }
  `}
`;

export const DialogContentWrapper = styled.div<{ verticalAlign: 'center' | 'top' | 'bottom' }>`
  position       : relative;
  width          : 100%;
  height         : 100%;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  ${props => getDialogVerticalAlign(props.verticalAlign)}
`;

export const Backdrop = styled.div<{ show?: boolean, isAnimating?: boolean }>`
  position  : fixed;
  top       : 0;
  left      : 0;
  z-index   : 100;
  width     : 100%;
  height    : 100%;
  background: rgba(0, 0, 0, 0.32);

  ${props => props.isAnimating && !props.show ? css`
    animation: ${fadeOut} ${animationDuration} ease-out forwards;
  ` : css`
    animation: ${fadeIn} ${animationDuration} ease-out forwards;
  `}
`;
