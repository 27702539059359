import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, LABEL_LARGE, LABEL_XL, MOBILE_HEADER_HEIGHT, PARAGRAPH, TITLES_NORMAL } from "Shared/styles/designConstants";
import styled from "styled-components";

export const CtaContainer = styled.div`
  width: 100%;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    padding-top: 0px;
  }
`;

export const Title = styled.h2`
  ${TITLES_NORMAL.FT_03}
  text-align: center;
  max-width : 412px;

  span {
    ${LABEL_XL.MONOSPACE}
  }
`;

export const Description = styled.span`
  ${PARAGRAPH.DEFAULT}
  max-width    : 411px;
  text-align   : center;
  margin-bottom: 30px;
  color        : ${DESIGN_TOKENS.color.text.secondary.value};
`;

export const IconContainer = styled.svg`
  width   : 24px;
  height  : 24px;
  position: absolute;
  top     : 24px;
  right   : 24px;
  cursor  : pointer;
`;

export const StepperContainer = styled.div`
  max-width : 414px;
  min-height: 230px;
`;

export const StepperTitle = styled.div`
  span:nth-child(2) {
    float: right;
    ${PARAGRAPH.MONOSPACE}
  }
`;

export const CurrentPoints = styled.div`
  position        : absolute;
  top             : 24px;
  right           : 65px;
  width           : 53px !important;
  background-color: ${DESIGN_TOKENS.color.primitive.yellow['200'].value};
  border-radius   : 100px;
  color           : ${DESIGN_TOKENS.color.text.normal.value};
  text-align      : center;
  ${LABEL_LARGE.MONOSPACE}
`;

export const ContentContainer = styled.div`
  max-width     : 414px;
  margin        : auto;
  vertical-align: middle;
  display       : flex;
  flex-direction: column;
  gap           : 24px;
  align-items   : center;
  padding-top   : 40px;
  overflow      : auto;
  height        : 100%;

  ${PARAGRAPH.DEFAULT}

  & > div {
    width: 100%;
  }

  & [data-testid="text-field"] {
    padding-bottom: 16px;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    padding   : 0 16px 40px;
    margin-top: ${MOBILE_HEADER_HEIGHT};

    & [data-testid="text-field"] {
      padding-bottom: 0px;
    }
  }
`;

export const Center = styled.div`
  display        : flex;
  justify-content: center;
  align-items    : center;
`;
