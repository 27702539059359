import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MINIMUM_POINTS_PER_MINUTE_TO_DISPLAY } from 'Pages/surveyExperience/components/surveyCard/constants';
import {
  CardContainer,
  CardCTA,
  CardDataDuration,
  CardDataPoints,
  CardDataPointsAmount,
  CardDataPointsPerMinute,
  CardDataSurveyId,
  CardDataWrapper,
  SteContent,
  SteFlatImage,
  SteImageContainer,
  SteOverflowHider,
  SurveyIdContainer,
  SurveyLink
} from 'Pages/surveyExperience/components/surveyCard/style';
import { SurveyButton, SurveyButtonDesign } from 'Pages/surveyExperience/components/surveyCard/SurveyButton';
import {
  SURVEY_CARD_TEST,
  SURVEY_EXCLUSIVE_CARD_TEST,
  SURVEY_LOCKED_CARD_TEST,
  SURVEY_POINTS_PER_MINUTE
} from 'Pages/surveyExperience/components/surveyCard/testId';
import { SurveyPromptEventProps } from 'Pages/surveyExperience/models/EventModels';
import { SurveyModel } from 'Pages/surveyExperience/models/SurveyModel';
import { dispatchQuestionnaireStartEvent, dispatchSurveyPromptEvent, dispatchSurveyRemoveEvent  } from 'Pages/surveyExperience/routes/dispatchEvents';
import {
  FirstPromptStateTypes,
  getSurveyPromptStatus,
  setViewedRouted
} from 'Pages/surveyExperience/services/surveyPromptService';
import {
  getLocalSurveyCache,
  redirectToSurveyQualification
} from 'Pages/surveyExperience/services/surveyService';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_SURVEY_LOAD_STATUSES } from 'Shared/services/analytics/constants';
import { analyticsTrackActivityClick } from 'Shared/services/analytics/events/surveyClick';
import { getValidatedSurveyId } from 'Shared/services/helperService';
import { SurveyQualificationDetails } from 'Shared/services/localStorageTypes';
import { getDynamicScreenerTestData } from 'Shared/services/opa/app/dynamicScreenerSplitTest';
import { getSurveyQualificationTestData } from 'Shared/services/opa/app/surveyQualificationSplitTest';
import { navigateToExternalSurvey } from 'Shared/services/routingService';
import { getOutgoingSurveyUrl } from 'Shared/services/surveyRouting/app/surveyRoutingService';
import { UspUnansweredQuestions } from 'Shared/services/usp/models/UspUnansweredQuestionsModel';
import { deleteCachedSurveyQualificationDetails, getCachedSurveyQualificationDetails, prepareAndStoreSurveyQualificationCache } from 'Shared/utils/unansweredQuestionsQueue';

type surveyCardProps = {
  survey                        : SurveyModel;
  locked?                       : boolean;
  onClick?                      : () => void;
  position                      : number;
  highlight?                    : boolean;
  className?                    : string;
  buttonText?                   : string;
  questionKeysQueue             : string[];
  unansweredQuestions?          : UspUnansweredQuestions[];
  requestInProgress?            : boolean;
  autoConfirmLegalPrompt       ?: boolean;
  surveyQualificationTestActive?: boolean;
  surveyQualificationTestPIds?  : number[];
}

function isNonEmptyArray (array: any[] | null | undefined): array is any[] {
  return array ? array.length > 0 : false;
}

/**
 * Show survey prompt if survey description is present or if its a first survey click
 * @returns boolean
 */
const showSurveyPrompt = (): boolean => {
  return getSurveyPromptStatus() !== FirstPromptStateTypes.VIEWED_ROUTED;
};

/**
 * Validates if survey description is present
 * @param surveyDescription
 * @returns
 */
const showSurveyDescription = (survey: SurveyModel): boolean => {
  return (survey.description !== null && survey.description.length > 0 && survey.isDescVisible === true);
};

/**
 * Survey Card component
 * Logic to be controlled by a parent component
 * @param survey - Survey object.
 * @param cta - Main Button text.
 * @param locked - If survey is locked
 */
function SurveyCard({
  survey,
  onClick,
  position,
  className,
  buttonText,
  questionKeysQueue = [],
  unansweredQuestions,
  autoConfirmLegalPrompt = false,
  requestInProgress,
  surveyQualificationTestActive = false,
  surveyQualificationTestPIds = []
}: surveyCardProps) {
  const {
    id,
    displayId,
    points,
    duration,
    attributes,
    internalUrl,
    providerId,
    locked
  } = survey;
  const { t }                            = useTranslation('surveyExperience');
  const hasUnansweredQuestions           = isNonEmptyArray(unansweredQuestions);
  const isADM                            = attributes?.isAdm ?? false;
  const isExclusive                      = attributes?.isExclusive ?? false;
  const isADMStyle                       = isADM || isExclusive;
  const showSurveyRelatedQuestionsOnly   = surveyQualificationTestActive && surveyQualificationTestPIds.includes(survey.providerId);
  const isSurveyMedleyActive             = hasUnansweredQuestions && questionKeysQueue.length > 0;
  const surveyLoadStatus                 = requestInProgress ? ANALYTICS_SURVEY_LOAD_STATUSES.in_progress : ANALYTICS_SURVEY_LOAD_STATUSES.complete;
  const surveyDynamicScreenerTest        = getDynamicScreenerTestData();
  const showDynamicScreenerQuestionnaire = surveyDynamicScreenerTest.enabled && surveyDynamicScreenerTest.providerIds.includes(survey.providerId);

  /**
   * Checks if survey click should follow control logic or test logic for survey qualification
   * - 1. User in test and Survey provider id is in the list of test providerIds (i.e.PureSpectrum survey) with only regular `unansweredQuestions` (no `unansweredDynamicQuestionIds`) :
   *    - Do not show questionnaire.
   * - 2. Survey from a provider *not* in the `enabledProviders` list, but the user is part of the test:
   *    - If that survey has any `unansweredQuestions`, then the **questionnaire is shown** (the “control flow” behavior).
   *    - Keep in mind that this flow will also depend on the SurveyQualification in modal test
   * - 3. User in test and Survey provider id is in the list of test providerIds (i.e.PureSpectrum survey) and survey has both `unansweredQuestions` and `unansweredDynamicQuestionIds`:
   *    - Only the dynamic questions (`unansweredDynamicQuestionIds`) are presented.
   *    - The regular `unansweredQuestions` are not shown in the pre-survey questionnaire screen.
   * @returns
   */
  const isQuestionnaireAvailable = useCallback((): boolean => {
    if (showDynamicScreenerQuestionnaire) {
      return survey.unansweredDynamicQuestionIds && survey.unansweredDynamicQuestionIds.length > 0;
    }

    if (!showSurveyRelatedQuestionsOnly) {
      return isSurveyMedleyActive;
    }

    return Array.isArray(survey.unansweredQuestions) && survey.unansweredQuestions.length > 0 && !surveyDynamicScreenerTest.providerIds.includes(survey.providerId);
  },[showDynamicScreenerQuestionnaire, showSurveyRelatedQuestionsOnly, survey.unansweredQuestions, survey.providerId, survey.unansweredDynamicQuestionIds, surveyDynamicScreenerTest.providerIds, isSurveyMedleyActive]);

  /**
   * Renders survey id / other top left content of survey card
   * @returns
   */
  const renderTopLeftContent = (): JSX.Element => {
    if (isADMStyle) {
      return (
        <SteImageContainer>
          <SteOverflowHider>
            <SteContent>
              <SteFlatImage />
              <SurveyIdContainer>
                {renderSurveyId()}
              </SurveyIdContainer>
            </SteContent>
          </SteOverflowHider>
        </SteImageContainer>
      );
    }

    return renderSurveyId();
  };

  /**
   * Renders survey id component of survey card
   * @returns
   */
  const renderSurveyId = (): JSX.Element => {
    return (
      <CardDataSurveyId>
        <span>{t('SurveyCard--element-surveyIdLabel')}</span>
        {getValidatedSurveyId(displayId)}
      </CardDataSurveyId>
    );
  };

  /**
   * Render card content
   * Shows only message is message passed
   */
  const renderCardContent = (): JSX.Element | undefined => {
    const pointsPerMinute = points / duration;

    // check if pointsPerMinute is neither infinity or NaN
    const isFinitePointsPerMinute = Number.isFinite(pointsPerMinute);

    return (
      <CardDataWrapper>
        {renderTopLeftContent()}
        <CardDataPoints>
          <CardDataPointsAmount>
            <span>{points}</span> {t('SurveyCard--element-pointsSuffix')}
          </CardDataPointsAmount>
          <CardDataPointsPerMinute>
            {isFinitePointsPerMinute && pointsPerMinute >= MINIMUM_POINTS_PER_MINUTE_TO_DISPLAY && (
              <>
                <span data-testid={SURVEY_POINTS_PER_MINUTE}>
                  {Math.round(pointsPerMinute)}
                </span>
                {t('SurveyCard--element-pointsPerMin')}
              </>
            )}
          </CardDataPointsPerMinute>
        </CardDataPoints>
        <CardDataDuration>
          <Icon iconId={ICON_IDS.TIMER_OUTLINE} />
          {t('SurveyCard--element-duration', {minutes: duration})}
        </CardDataDuration>
      </CardDataWrapper>
    );
  };

  /**
   * Returns button design
   * @returns
   */
  const getButtonDesign = (): SurveyButtonDesign => {
    if (locked) {
      return SurveyButtonDesign.LOCKED;
    } else if (isADMStyle) {
      return SurveyButtonDesign.ADM;
    } else {
      return SurveyButtonDesign.DEFAULT;
    }
  };

  /**
   * Opens the survey qualification modal or navigates to survey qualification page
   */
  const openModalOrNavigateToSurveyQualification = useCallback((): void => {
    const isUserInSurveyQualTest = getSurveyQualificationTestData();

    if (showDynamicScreenerQuestionnaire) {
      const details: SurveyQualificationDetails | null = getCachedSurveyQualificationDetails();

      if (!details) {
        navigateToExternalSurvey(internalUrl, true, true);
        return;
      }

      dispatchQuestionnaireStartEvent({
        ...details.questionnaireDetails,
        isModal: true,
        surveyId: survey.id,
        surveyProviderId: survey.providerId,
        surveyUrl: internalUrl,
        isDynamicScreener: true
      });
      deleteCachedSurveyQualificationDetails();
      return;
    } else if (isUserInSurveyQualTest.enabled) {
      const details: SurveyQualificationDetails | null = getCachedSurveyQualificationDetails();
      if (!details) {
        navigateToExternalSurvey(internalUrl, true, true);
        return;
      }

      dispatchQuestionnaireStartEvent({
        ...details.questionnaireDetails,
        isModal: true,
        surveyId: survey.id,
        surveyProviderId: survey.providerId,
        surveyUrl: internalUrl
      });
      deleteCachedSurveyQualificationDetails();
    } else {
      // redirecting user to survey qualification page
      redirectToSurveyQualification(id, providerId);
    }
  }, [id, internalUrl, showDynamicScreenerQuestionnaire, providerId, survey.id, survey.providerId]);

  /**
   * Opens the survey medley questionnaire
   */
  const openQuestionnaire = useCallback(() => {
    try {

      if (!surveyQualificationTestActive && !showDynamicScreenerQuestionnaire) {
        /**
         * Dispatch an event to update client survey list and
         * remove clicked survey from survey list
         */
        dispatchSurveyRemoveEvent({
          surveyId: id
        });
      }

      const surveyQualificationUnansweredQuestions = unansweredQuestions || [];

      // Storing survey qualification cache with question queue
      prepareAndStoreSurveyQualificationCache({
        survey,
        questionKeysQueue,
        unansweredQuestions: surveyQualificationUnansweredQuestions,
        isDynamicScreener: showDynamicScreenerQuestionnaire
      });

      // Redirecting user to survey qualification page or open survey qualification modal based on
      openModalOrNavigateToSurveyQualification();
    } catch {
      navigateToExternalSurvey(internalUrl, true, true);
    }
  }, [id, internalUrl, showDynamicScreenerQuestionnaire, openModalOrNavigateToSurveyQualification, questionKeysQueue, survey, surveyQualificationTestActive, unansweredQuestions]);

  /**
   * Handles survey tile click if there are unanswered questions
   * 1: Dispatches the event to show first survey click prompt
   * Or
   * 2: Dispatches the event to remove survey from local cache and redirects user to survey qualification page
   */
  const handleClick = useCallback((): void => {
    if (locked) {
      return;
    }

    // Auto confirm legal prompt
    if (autoConfirmLegalPrompt) {
      setViewedRouted();
    }

    //show survey prompt if survey description is not null or if its a first survey click
    if (showSurveyPrompt() || showSurveyDescription(survey)) {
      const surveyPromptProps: SurveyPromptEventProps = {
        url               : internalUrl,
        surveyId          : id,
        surveyPoints      : points,
        surveyLOI         : duration,
        surveyProviderId  : providerId,
        surveyPosition    : position,
        surveyLoadStatus  : surveyLoadStatus,
        description       : survey.description,
        descriptionVisible: survey.isDescVisible
      };

      if (isQuestionnaireAvailable()) {
        surveyPromptProps.onAccept = openQuestionnaire;
      }

      if (onClick) {
        const oldOnAccept = surveyPromptProps.onAccept;
        surveyPromptProps.onAccept = () => {
          if (oldOnAccept) {
            oldOnAccept();
          } else {
            navigateToExternalSurvey(internalUrl, true, true);
          }
          onClick();
        };
      }

      /**
       * Dispatch an event to show survey legal prompt prior to redirecting to
       * user to survey provider
       */
      dispatchSurveyPromptEvent(surveyPromptProps);
    } else if (isQuestionnaireAvailable()) {
      openQuestionnaire();
      onClick?.();
    } else {
      const removeSurveyEventProps = {
        surveyId: id
      };

      /**
       * Dispatch an event to update client survey list and
       * remove clicked survey from survey lits
       */
      dispatchSurveyRemoveEvent(removeSurveyEventProps);
      onClick?.();
    }
  }, [locked, autoConfirmLegalPrompt, survey, isQuestionnaireAvailable, internalUrl, id, points, duration, providerId, position, surveyLoadStatus, onClick, openQuestionnaire]);

  /**
   * Tracks outgoing survey click to ga4
   */
  const trackSurveyClick = useCallback((): void => {
    analyticsTrackActivityClick({
      id                  : id,
      points              : points,
      provider_id         : providerId,
      loi                 : duration,
      position            : position,
      sub_type            : ANALYTICS_EVENT_SUB_TYPE.survey_click,
      has_prequalification: !!isSurveyMedleyActive,
      survey_count        : getLocalSurveyCache().length,
      survey_load_status  : surveyLoadStatus
    });
  }, [id, points, providerId, duration, position, isSurveyMedleyActive, surveyLoadStatus]);

  /**
   * TRSJSPA-162
   * Prevents right click on survey card
   */
  const preventRightClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
  }, []);

  /**
   * Render call-to-action (CTA) button
   */
  const renderSurveyButton = (): JSX.Element => {
    return(
      <SurveyButton design = {getButtonDesign()}>
        {buttonText}
      </SurveyButton>
    );
  };

  const renderCardContainer = (): JSX.Element => {
    return (
      <CardContainer
        data-testid   = {getCardTestId()}
        locked        = {locked}
        onContextMenu = {preventRightClick}
        className     = {className}
        onClick       = {handleClick}
      >
        {renderCardContent()}
        <CardCTA>
          {renderSurveyButton()}
        </CardCTA>
      </CardContainer>
    );
  };

  /**
   * Conditionally renders survey card with or without survey link <a> tag wrapper
   * @returns
   */
  const renderSurveyCard = (): JSX.Element => {
    if (showSurveyPrompt() || showSurveyDescription(survey) || isQuestionnaireAvailable() || locked) {
      return renderCardContainer();
    }

    return(
      <SurveyLink
        href    = {getOutgoingSurveyUrl(internalUrl, true, !!survey.attributes.isAutoEmail)} target = '_blank' rel = "noreferrer"
        onClick = {trackSurveyClick}
      >
        {renderCardContainer()}
      </SurveyLink>
    );
  };

  const getCardTestId = (): string => {
    if (locked) {
      return SURVEY_LOCKED_CARD_TEST;
    } else if (isADMStyle) {
      return SURVEY_EXCLUSIVE_CARD_TEST;
    }

    return SURVEY_CARD_TEST;
  };

  return (
    <>
      {renderSurveyCard()}
    </>
  );
}

export default SurveyCard;