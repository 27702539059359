import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import { QualificationPromptContainer, QualificationPromptEmoji } from 'Shared/components/design/questionnairePopup/qualificationPrompt/style';
import { QUAL_PROMPT_BUTTON_TEST_ID, QUAL_PROMPT_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import { ICON_IDS } from 'Shared/components/icons/constants';

type QualificationPromptProps = {
  onContinue   : () => void
  questionCount: number
}

/**
 * Returns Qualification Prompt Component
 */
function QualificationPrompt ({
  onContinue,
  questionCount
}: QualificationPromptProps): JSX.Element {
  const { t } = useTranslation('questionnairePopup');

  return (
    <QualificationPromptContainer
      data-testid = {QUAL_PROMPT_TEST_ID}
    >
      <QualificationPromptEmoji>🎲</QualificationPromptEmoji>
      <h1>{t('Questionnaire--qualificationPrompt-title')}</h1>
      <p>{t('Questionnaire--qualificationPrompt-body', { count: questionCount })}</p>
      <Button
        width     = {ButtonWidth.FULL}
        size      = {ButtonSizes.LARGE}
        iconRight = {ICON_IDS.ARROW_RIGHT}
        testId    = {QUAL_PROMPT_BUTTON_TEST_ID}
        onClick   = {onContinue}
      >
        {t('Questionnaire--qualificationPrompt-cta')}
      </Button>
    </QualificationPromptContainer>
  );
}

export default QualificationPrompt;
