import { QuestionnaireEventProps } from 'Shared/components/design/questionnairePopup/QuestionnaireEventModels';
import { addEventListener } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * AddEventListener: window.mfEvents.Popup.start
 * @param callback
 */
export const addQuestionnaireStartEvent = (callback: (props: QuestionnaireEventProps) => void): void => {
  addEventListener<QuestionnaireEventProps>(MF_EVENTS.userActivation.questionnaireStart, callback);
};
