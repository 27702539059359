
import { getUserId } from 'App/services/idpTokenService';
import * as routes from 'Pages/rewards/routes/constants';
import { ACTGetActivitiesResponse, ACTSearchCompletedActivityResponse, ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';
import { getCompletedActivitiesCacheKey } from 'Shared/services/swr/swrKeyService';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

/**
 * Generates specific ACT MS endpoint URL
 * @param route
 */
const getActURL = (route: string): string | undefined=> {
  const userId = getUserId();

  switch(route) {
    case routes.ACT_POINTS:
      return `${routes.API_CONFIG_ACT_MS}/${userId}${routes.ACT_POINTS}`;
    case routes.ACT_ACTIVITIES:
      return  `${routes.API_CONFIG_ACT_MS}/${userId}${routes.ACT_ACTIVITIES}`;
    case routes.ACT_SEARCH_ACTIVITIES:
      return `${routes.ACT_CONFIG_V1}${routes.ACT_SEARCH_ACTIVITIES}`;
  }
};

/**
 * GET User points from ACT MS
 * @param userId
 */
const getUserPoints = (): Promise<APIResponse<ACTUserPointsResponse>> => {
  const baseURL = process.env.REACT_APP_ACT_BASE_URL;
  const url     = getActURL(routes.ACT_POINTS);

  return MS_API({
    method : 'GET',
    baseURL: baseURL,
    url    : url,
  });
};

/**
 * Get User Completed activities
 * @param dateFrom
 * @returns
 */
const getUserCompletedActivities = (dateFrom: string, dateTo?: string):  Promise<APIResponse<ACTGetActivitiesResponse>> => {
  const baseURL = process.env.REACT_APP_ACT_BASE_URL;
  const url     = getActURL(routes.ACT_ACTIVITIES);

  const params = {
    dateFrom,
    dateTo
  };

  return MS_API({
    method : 'GET',
    baseURL: baseURL,
    url    : url,
    params : params
  });
};

/**
 * Search Completed Activities
 * @param dateFrom
 * @param dateTo
 * @param pageSize
 * @returns
 */
const searchCompletedActivities = (dateFrom: string, dateTo: string, pageSize: number, currentPage = 1, formatDescription: boolean): Promise<APIResponse<ACTSearchCompletedActivityResponse>> => {
  const baseURL          = process.env.REACT_APP_ACT_BASE_URL;
  const url              = getActURL(routes.ACT_SEARCH_ACTIVITIES);
  const userId           = parseInt(getUserId());
  const searchEntityType = 'COMPLETED_ACTIVITY_V1';

  const data = {
    searchEntityType,
    dateFrom,
    dateTo,
    pageSize,
    userIds : [userId],
    currentPage,
    formatDescription
  };

  return MS_API({
    method : 'POST',
    baseURL: baseURL,
    url    : url,
    data   : data
  });
};

/**
 * The number of days per "page" of data retrieved from ACT by `useInfiniteCompletedActivities`
 */
const COMPLETED_ACTIVITIES_DATE_SIZE = 45;

/**
 * A custom hook that fetches completed activities using infinite scrolling between specified dates.
 *
 * @param dateFrom - The starting `Date` from which to fetch activities. Should be a stable reference to prevent unnecessary refetches.
 * @param dateTo - The ending `Date` up to which to fetch activities.
 * @param pageSize - Optional. The number of activities to fetch per page. Defaults to 45.
 * @returns An `SWRInfiniteResponse` object containing:
 * - `data`: An array of arrays of `ACTSearchCompletedActivityResponse` objects (each inner array corresponds to a page).
 * - `error`: Any error encountered during fetching.
 * - `isValidating`: A boolean indicating if a request is in progress.
 * - `size`: The current number of pages that have been loaded.
 * - `setSize`: A function to set the number of pages to load.
 * - `mutate`: A function to manually trigger revalidation.
 *
 * This hook is configured to:
 * - Fetch data only once per page (`revalidateFirstPage: false`).
 * - Persist the number of pages across re-renders (`persistSize: true`).
 * - Avoid refetching on focus or reconnect.
 *
 * Ensure that `dateFrom` and `dateTo` are stable references (i.e, using useMemo, useRef)
 * to prevent unnecessary refetches caused by changing parameters on each render.
 */
const useInfiniteCompletedActivities = (
  dateFrom: Date,
  dateTo: Date,
  pageSize = 45
): SWRInfiniteResponse<ACTSearchCompletedActivityResponse[]> => {
  const formattedDateFrom = dateFrom.toISOString();
  const formattedDateTo   = dateTo.toISOString();

  return useSWRInfinite(
    (pageIndex) => [
      getCompletedActivitiesCacheKey(
        formattedDateFrom,
        formattedDateTo,
        pageSize,
        pageIndex + 1
      ),
      formattedDateFrom,
      formattedDateTo,
      pageSize,
      pageIndex + 1,
    ],
    async ([, dateFrom, dateTo, pageSize, currentPage]) => {
      const response = await searchCompletedActivities(
        dateFrom,
        dateTo,
        pageSize,
        currentPage,
        true
      );

      return response.data.activities ? [response.data] : [];
    },
    {
      revalidateOnFocus    : false,
      revalidateOnReconnect: false,
      persistSize          : true,
      revalidateFirstPage  : false,   //ensures that the first page is fetched on initial render only
    }
  );
};

export {
  COMPLETED_ACTIVITIES_DATE_SIZE,
  getUserCompletedActivities,
  getUserPoints,
  searchCompletedActivities,
  useInfiniteCompletedActivities
};