import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE, ANALYTICS_RESPONSE_MESSAGE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type BasicProfileAgeConfirmationEndEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.response_message]: ANALYTICS_RESPONSE_MESSAGE,
}

/**
 * Sends analytic event and attributes for Basic Profile Age Confirmation to Google Analytics
 * @param BasicProfileAgeConfirmationEnd
 * @returns void
 */
export const analyticsTrackBasicProfileAgeConfirmationEnd = (basicProfileAgeConfirmationEndEvent: BasicProfileAgeConfirmationEndEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      ...basicProfileAgeConfirmationEndEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]    : ANALYTICS_EVENT_TYPE.prompt,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.under_age
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
};