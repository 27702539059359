import { useLocation } from 'react-router-dom';

function useQueryParam(name: string, keepPlus = false) {
  const location = useLocation();
  let   search   = location.search;

  if (keepPlus) {
    search = search.replace(/\+/g, '%2B');
  }

  const params = new URLSearchParams(search);

  return params.get(name);
}

export {
  useQueryParam
};
