import React, { useEffect, useState } from 'react';
import { CheckboxGroup, SingleCheckboxDataModel } from 'Shared/components/design/checkbox/CheckboxGroup';
import { AnswerModel } from 'Shared/components/design/questionnairePopup/AnswerModel';
import { SEARCH_THRESHOLD } from 'Shared/components/design/questionnairePopup/constants';
import { QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { QuestionTitle, SearchContainer } from 'Shared/components/design/questionnairePopup/style';
import { MULTI_SELECT_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import { TextField, TextFieldTypes } from 'Shared/components/design/textField/TextField';
import { UPMPredefinedAnswer } from 'Shared/models/swagger/upm';

type MultiSelectProps = {
  question              : QuestionPopupModel;
  existingAnswer?       : AnswerModel;
  selectedAnswerCallback: (x: AnswerModel) => void;
  changeFilter?         : (x: (number | string)[]) => (number | string)[];
}

/**
 * Multi Select Component
 */
function MultiSelect ({question, existingAnswer, selectedAnswerCallback, changeFilter}: MultiSelectProps): JSX.Element {
  const [inputText, setInputText]     = useState<string>('');
  const [reRenderKey, setReRenderKey] = useState<number>(Date.now());
  const showSearchBar                 = question?.data?.[0]?.predefinedAnswers?.length >= SEARCH_THRESHOLD;

  useEffect(() => {
    setInputText('');
    setReRenderKey(Date.now());
  }, [question]);

  /**
   * Send the changed value back to the parent component
   * @param changedValue
   */
  const handleChange = (changedValue: (number | string)[]): void => {
    const newAnswer = {
      questionKey: question.key,
      value      : changedValue
    };

    selectedAnswerCallback(newAnswer);
  };

  /**
   * Provide any filtering before an answer is checked
   * @returns number[]
   */
  const handleChangeFilter = (answers: (number | string)[]): (number | string)[] => {
    if (changeFilter) {
      return changeFilter(answers);
    } else {
      return answers;
    }
  };

  /**
   * Sorts answers for a given question so that answers with a solo property are sorted last
   * @param a - answer to be sorted
   * @param b - answer to be sorted
   * @returns number
   */
  const soloAnswerSort = (a: UPMPredefinedAnswer, b: UPMPredefinedAnswer) => {
    if (a.solo && !b.solo) {
      return 1;
    } else if (!a.solo && b.solo) {
      return -1;
    } else {
      return a.sortOrder - b.sortOrder;
    }
  };

  /**
   * Generates array with input for checkbox buttons components
   * @param predefinedAnswers - array of predefined answers
   */
  const generateCheckboxes = (predefinedAnswers: UPMPredefinedAnswer[]): SingleCheckboxDataModel<any>[] => {
    return predefinedAnswers.map((value) => {
      let previouslySelected = false;

      if (existingAnswer && existingAnswer.value.includes(value.answerValue)) {
        previouslySelected = true;
      }

      return {
        answerValue: value.answerValue,
        label      : value.label,
        selected   : previouslySelected
      };
    });
  };

  const valueType = typeof question.data[0].predefinedAnswers[0].answerValue === 'number'
    ? 'number'
    : 'string';

  const filteredAnswers = (): SingleCheckboxDataModel<any>[] => {
    return generateCheckboxes(question.data[0].predefinedAnswers.sort(soloAnswerSort)).filter((answer) => {
      return answer.label.toLowerCase().includes(inputText.toLowerCase());
    });
  };

  return (
    <div data-testid={MULTI_SELECT_TEST_ID}>
      <QuestionTitle>
        {question.data[0].question}
      </QuestionTitle>
      { showSearchBar ? (
        <SearchContainer>
          <TextField
            key            = {reRenderKey}
            placeholder    = {'Search'}
            fieldType      = {TextFieldTypes.DEFAULT}
            changeCallback = {setInputText}
            isSearchBar    = {true}
          />
        </SearchContainer>
      ) : null }
      <CheckboxGroup
        id                = {`questionnaire-${question.key}`}
        key               = {question.key}
        checkBoxes        = {filteredAnswers()}
        changeCallback    = {handleChange}
        changeFilter      = {handleChangeFilter}
        isColorBackground = {true}
        groupName         = {question.key}
        disabled          = {false}
        valueType         = {valueType}
      />
    </div>
  );
}

export default MultiSelect;
