import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import {
  MEMBER_FORGOT_PASSWORD_URL,
  MEMBER_SIGN_IN_URL,
  MEMBER_SIGN_UP_URL,
  MEMBER_WELCOME_URL,
  PAGE_MEMBER,
  PINNING_INSTRUCTIONS,
  REWARDS_PAGE,
  STE_LEARN_MORE,
  SURVEY_MEDLEY_URL,
  SURVEY_QUALIFICATION_ROUTE,
  UNRECOGNIZED_DEVICE_URL,
  VERIFY_DEVICE_URL,
} from 'App/routes/constants';

interface TitleRecord {
  route: string | RegExp
  title: string
}

function startsWith(path: string) {
  return new RegExp(`^${path}$|^${path}/`, 'i');
}

const titleRecords: TitleRecord[] = [
  {
    route: MEMBER_SIGN_IN_URL,
    title: 'Log in to Survey Junkie',
  },
  {
    route: MEMBER_FORGOT_PASSWORD_URL,
    title: 'Forgot my Survey Junkie password',
  },
  {
    route: MEMBER_SIGN_UP_URL,
    title: 'Become a member',
  },
  {
    route: PAGE_MEMBER,
    title: 'Take Surveys Get Paid',
  },
  {
    route: REWARDS_PAGE,
    title: 'Rewards from Survey Junkie',
  },
  {
    route: MEMBER_WELCOME_URL,
    title: 'Welcome to the new Survey Junkie'
  },
  {
    route: VERIFY_DEVICE_URL,
    title: 'Verify your device',
  },
  {
    route: UNRECOGNIZED_DEVICE_URL,
    title: 'Unrecognized device',
  },
  {
    route: PINNING_INSTRUCTIONS,
    title: 'Pin the Survey Junkie extension',
  },
  {
    route: STE_LEARN_MORE,
    title: 'Learn more about Surf to Earn',
  },
  {
    route: SURVEY_MEDLEY_URL,
    title: 'Improve your Survey Junkie profile',
  },
  {
    route: SURVEY_QUALIFICATION_ROUTE,
    title: 'Survey Pre-qualification',
  },
  {
    route: MENU_ROUTES.ACCOUNT_SUPPORT,
    title: 'Need help with Survey Junkie',
  },
  {
    route: MENU_ROUTES.ACCOUNT_CONTACT_US,
    title: 'Contact Survey Junkie',
  },
  {
    route: MENU_ROUTES.ACCOUNT_TERMS,
    title: 'Survey Junkie Terms & Conditions',
  },
  {
    route: MENU_ROUTES.ACCOUNT_PRIVACY,
    title: 'Survey Junkie Privacy Policy',
  },
  {
    route: MENU_ROUTES.ACCOUNT_INVITE,
    title: 'Invite Friends to Survey Junkie',
  },
  {
    route: MENU_ROUTES.ACCOUNT_INVITE_TERMS,
    title: 'Invite Friends to Survey Junkie',
  },
  {
    route: MENU_ROUTES.REVIEW_ONFIDO_PERSONAL_INFO,
    title: 'Review your information',
  },
  {
    route: MENU_ROUTES.HOW_ONFIDO_WORKS,
    title: 'How Onfido works',
  },
  {
    route: startsWith(MENU_ROUTES.ACCOUNT_PERSONAL_INFO),
    title: 'My Survey Junkie account',
  },
  {
    route: startsWith(MENU_ROUTES.ACCOUNT_PROFILE_QUESTIONS),
    title: 'My Survey Junkie profile',
  },
  {
    route: startsWith(MENU_ROUTES.ACCOUNT_HOME),
    title: 'Survey Junkie Help'
  },
  {
    route: MENU_ROUTES.GAMES,
    title: 'Survey Junkie Games',
  }
];

export const TitleChanger = () => {
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    if (path.length > 1 && path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }

    const record = titleRecords.find(({ route }) => {
      if (typeof route === 'string') {
        return path === route;
      } else {
        return route.test(path);
      }
    });

    if (record) {
      document.title = record.title;
    } else {
      document.title = 'Survey Junkie';
    }
  }, [location.pathname]);

  return null;
};
