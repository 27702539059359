import { PopupStartEventProps } from 'App/models/eventModels';
import { QuestionnaireEventProps } from 'Shared/components/design/questionnairePopup/QuestionnaireEventModels';
import { dispatchEvent } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

export const dispatchQuestionnaireStartEvent = (eventProps: QuestionnaireEventProps): void => {
  const eventModel: PopupStartEventProps = {
    id   : MF_EVENTS.userActivation.questionnaireStart,
    props: eventProps
  };

  dispatchEvent<PopupStartEventProps>(MF_EVENTS.app.popup.start, eventModel);

  // The GP questionnaire editing is a special case where the questionnaire popup should not be shown
  if (eventProps.isGPQuestionnaireEdit) {
    dispatchEvent<PopupStartEventProps>(MF_EVENTS.app.popup.exit, eventModel);
  }
};

export const dispatchQuestionnaireExitEvent = (): void => {
  dispatchEvent(MF_EVENTS.app.popup.exit);
};

export const dispatchQuestionnaireCompleteEvent = (): void => {
  dispatchEvent(MF_EVENTS.app.questionnaireComplete);
};