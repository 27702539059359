import React, { useState } from 'react';
import {
  CardContainer,
  CardCTA,
  CardDataDuration,
  CardDataPoints,
  CardDataPointsPerMinute,
  CardDataSurveyId,
  CardDataWrapper,
  CardLoadingPointsContainer,
  LOADING_POINT_COUNT
} from 'Pages/surveyExperience/components/surveyCard/style';
import { SurveyButton } from 'Pages/surveyExperience/components/surveyCard/SurveyButton';
import { SURVEY_LOADING_CARD_TEST } from 'Pages/surveyExperience/components/surveyCard/testId';

/**
 * Generates a random number between 5 and 50 which is divisible by 5
 * @returns the generated number
 */
function getRandomNumberDivisibleByFive () {
  return Math.floor(Math.random() * 10) * 5 + 5;
}

/**
 * Generates a random array of numbers between 5 and 50 which are divisible by 5
 * @returns the array of numbers
 */
function generateRandomPoints () {
  const result: number[] = [];
  let prev = -1;
  for (let i = 0; i < LOADING_POINT_COUNT; i++) {
    let next = getRandomNumberDivisibleByFive();

    while (
      // Ensure no two consecutive numbers are the same
      prev === next ||

      // Ensure the last and first elements are not the same
      (i === LOADING_POINT_COUNT - 1 && result[0] === next)
    ) {
      next = getRandomNumberDivisibleByFive();
    }

    result.push(next);
    prev = next;
  }
  return result;
}

type LoadingCardProps = {
  buttonText: string;
  className?: string;
}

/**
 * Survey Loading Card component
 * @description Renders a card similar to a Survey Card, but in a loading state
 */
function LoadingCard({ buttonText, className }: LoadingCardProps) {
  const [points] = useState(generateRandomPoints());

  /**
   * Render card content
   * Shows only message is message passed
   */
  const renderCardContent = (): JSX.Element | undefined =>  {
    return (
      <CardDataWrapper>
          <CardDataSurveyId>
            <span>&nbsp;</span>
            &nbsp;
          </CardDataSurveyId>
          <CardDataPoints>
            <CardLoadingPointsContainer>
              <div>
                {
                  points.map((point, i) => (
                    <div key={i}>{point}</div>
                  ))
                }
                {
                  points.map((point, i) => (
                    <div key={i}>{point}</div>
                  ))
                }
                <div>{points[0]}</div>
              </div>
            </CardLoadingPointsContainer>
            <CardDataPointsPerMinute>
              &nbsp;
            </CardDataPointsPerMinute>
          </CardDataPoints>
          <CardDataDuration>
            &nbsp;
          </CardDataDuration>
      </CardDataWrapper>
    );
  };

  return (
    <CardContainer data-testid={SURVEY_LOADING_CARD_TEST} className={className}>
      {renderCardContent()}
      <CardCTA>
        <SurveyButton isLoading loadingMessage={buttonText} />
      </CardCTA>
    </CardContainer>
  );
}

export default LoadingCard;
