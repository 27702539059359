import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { ButtonContainer, LinkContainer,SideMenuContainer } from "App/components/sidebar/mobileMenu/sideMenu/style";
import { ActiveItem } from "App/components/sidebar/Sidebar";
import { MOBILE_MENU } from "App/components/sidebar/testId";
import {ICON_IDS} from "Shared/components/icons/constants";
import Icon from "Shared/components/icons/icon";
import { useSwipe } from "Shared/hooks/useSwipe";
import { viewedGamesPage } from "Shared/services/opa/app/gamesSplitTest";

type SideMenuProp = {
  show          : boolean;
  changeCallback: () => void;
}

/**
 * Renders </SideMenu> component
 */
function SideMenu({ show, changeCallback }: SideMenuProp): JSX.Element {
  const location                    = useLocation();
  const [activeItem, setActiveItem] = useState<number>(ActiveItem.NONE);
  const { t }                       = useTranslation('app');
  const navigateTo                  = useNavigate();

  /**
   * Handles menu item click navigation
   * @param url
   */
  const navigate = (url: string): void => {
    changeCallback();
    navigateTo(url);
  };

  /**
   * Used for rendering diff icons on "active" url
   */
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveItem(ActiveItem.HOME);
    } else if (location.pathname.includes(MENU_ROUTES.REWARDS)) {
      setActiveItem(ActiveItem.REWARDS);
    } else if (location.pathname.startsWith(MENU_ROUTES.ACCOUNT_HOME)) {
      setActiveItem(ActiveItem.ACCOUNT);
    } else if (location.pathname.startsWith(MENU_ROUTES.GAMES)) {
      setActiveItem(ActiveItem.GAMES);
    } else {
      setActiveItem(ActiveItem.NONE);
    }
  },[location.pathname]);

  /**
   * Renders mobile menu Close button
   */
  const renderCloseButton = (): JSX.Element => {
    return(
      <ButtonContainer onClick={changeCallback}>
        <Icon iconId={ICON_IDS.CLOSE}/>
      </ButtonContainer>
    );
  };

  /**
   * Renders Home menu item
   */
  const renderHome = (): JSX.Element => {
    const iconId = (activeItem === ActiveItem.HOME) ? ICON_IDS.HOUSE_FILL : ICON_IDS.HOUSE_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigate(MENU_ROUTES.HOME)}
        isActive = {location.pathname === "/"}
      >
        <Icon iconId={iconId}/>
        <h1>{t('mobileMenuButtonHome')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders Games menu item
   */
  const renderGames = (): JSX.Element => {
    if (!viewedGamesPage()) {
      return <></>;
    }

    const iconId = (activeItem === ActiveItem.GAMES) ? ICON_IDS.GAMES_FILL : ICON_IDS.GAMES;

    return(
      <LinkContainer
        onClick  = {() => navigate(MENU_ROUTES.GAMES)}
        isActive = {location.pathname === MENU_ROUTES.GAMES}
      >
        <Icon iconId={iconId}/>
        <h1>{t('mobileMenuButtonGames')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders Rewards menu item
   */
  const renderRewards = (): JSX.Element | undefined=> {
    const iconId = (activeItem === ActiveItem.REWARDS) ? ICON_IDS.COIN_USA : ICON_IDS.COIN_USA_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigate(MENU_ROUTES.REWARDS)}
        isActive = {location.pathname.includes(MENU_ROUTES.REWARDS)}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuRewardsButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders Account menu item
   */
  const renderAccount = (): JSX.Element => {
    const iconId = (activeItem === ActiveItem.ACCOUNT) ? ICON_IDS.AVATAR_FILL : ICON_IDS.AVATAR_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigate(MENU_ROUTES.ACCOUNT_SUPPORT_MOBILE_SIDE_NAV_OPEN)}
        isActive = {location.pathname.includes(MENU_ROUTES.ACCOUNT_HOME)}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuAccountButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  const swipeToClose = useSwipe(() => changeCallback(), { onlyDirection: 'LEFT' });

  return(
    <SideMenuContainer show={show} onTouchStart={swipeToClose} data-testid={MOBILE_MENU}>
      { renderCloseButton() }
      { renderHome() }
      { renderGames() }
      { renderRewards() }
      { renderAccount() }
    </SideMenuContainer>
  );
}

export {
  SideMenu
};
