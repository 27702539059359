import { SurveyCallbackStatuses } from "Pages/surveyCallback/models/SurveyCallbackModels";
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';

const shortTextCache   = new Map();
const oneMonthOldCache = new Map();

/**
 * Check if the saved date is one month old
 * @param savedDate
 * @returns boolean
 */
const isOneMonthOld = (savedDate: number): boolean => {
  if (oneMonthOldCache.has(savedDate)) {
    return oneMonthOldCache.get(savedDate);
  }

  const differenceInMilliseconds = Math.abs(savedDate - Date.now());
  const millisecondsInOneMonth = 30 * 24 * 60 * 60 * 1000;
  const differenceInMonths = differenceInMilliseconds / millisecondsInOneMonth;
  const result = differenceInMonths >= 1;

  oneMonthOldCache.set(savedDate, result);
  return result;
};

/**
 * Set the timestamp for the short text
 * @returns void
 */
const setShortTextTimestamp = (): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.CALLBACK_REJECT_STATUS_TS, Date.now(), true);
};

/**
 * Check if the short text should be shown
 * @param callbackStatus
 * @returns boolean
 */
export const shouldShowShortText = (callbackStatus: number): boolean => {
  // Check if the result is in the cache
  if (shortTextCache.has(callbackStatus)) {
    return shortTextCache.get(callbackStatus);
  }

  let result;
  const callbackStatusData = getLocalStorage(LOCAL_STORAGE_IDS.CALLBACK_REJECT_STATUS_TS, true);

  if (callbackStatusData) {
    if (isOneMonthOld(callbackStatusData)) {
      setShortTextTimestamp();
      result = false;
    } else {
      result = true;
    }
  } else {
    setShortTextTimestamp();
    result = false;
  }

  shortTextCache.set(callbackStatus, result);
  return result;
};

/**
 * Returns if it's a successful survey callback
 */
export const isSurveyStatusComplete = (callbackStatus: SurveyCallbackStatuses): boolean => {
  return callbackStatus === SurveyCallbackStatuses.COMPLETE;
};

/**
 * Returns if it's a successful survey callback
 */
export const isSurveyStatusDisqualified = (callbackStatus: SurveyCallbackStatuses): boolean => {
  return [
    SurveyCallbackStatuses.QUOTA_FULL,
    SurveyCallbackStatuses.TERMINATED,
    SurveyCallbackStatuses.REJECTED,
    SurveyCallbackStatuses.DUPLICATE,
    SurveyCallbackStatuses.SURVEY_NOT_AVAILABLE,
    SurveyCallbackStatuses.NO_SURVEY_AVAILABLE,
    SurveyCallbackStatuses.PRE_SCREEN_OUT,
    SurveyCallbackStatuses.CLIENT_REJECTED,
    SurveyCallbackStatuses.COMPLETE_BUT_INTERNAL_QUOTA_FULL,
    SurveyCallbackStatuses.SJ_PRE_SCREEN_OUT,
    SurveyCallbackStatuses.SJ_USER_PROVIDER_BLOCK,
    SurveyCallbackStatuses.SJ_INCORRECT_DEVICE,
    SurveyCallbackStatuses.SJ_QUOTA_FULL,
    SurveyCallbackStatuses.SJ_SURVEY_NOT_AVAILABLE,
    SurveyCallbackStatuses.SJ_SURVEY_DUPLICATE,
    SurveyCallbackStatuses.SJ_DISPLAY_RULES,
    SurveyCallbackStatuses.SJ_OK,
    SurveyCallbackStatuses.SJ_CLICK_THROTTLED,
    SurveyCallbackStatuses.SJ_PARTNER_REJECTED_CLICK,
    SurveyCallbackStatuses.SJ_SURVEY_INVALID_URL,
    SurveyCallbackStatuses.SJ_SURVEY_URL_MISMATCH,
    SurveyCallbackStatuses.SJ_SURVEY_SURVEY_FILTERED
  ].includes(callbackStatus);
};