import React from 'react';
import { useTranslation } from 'react-i18next';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { ExitContainer, HeaderContainer, HeaderText } from 'Shared/components/design/questionnairePopup/surveyPromptHeader/style';
import { HEADER_CLOSE_TEST_ID, HEADER_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

type SurveyPromptHeaderProps = {
  exitCallback   : () => void;
  isSurveyMedley?: boolean;
}

/**
 * Returns Survey Prompt Header Component
 */
function SurveyPromptHeader ({
  exitCallback,
  isSurveyMedley
}: SurveyPromptHeaderProps): JSX.Element {
  const { t } = useTranslation(['questionnairePopup', 'surveyMedleyPopup']);
  const title = isSurveyMedley ? t('surveyMedleyPopup:SurveyMedleyBanner--popup-title') : t('questionnairePopup:Questionnaire--header-qualification');

  return (
    <HeaderContainer data-testid={HEADER_TEST_ID}>
      <HeaderText>
        {title}
      </HeaderText>
      <ExitContainer data-testid={HEADER_CLOSE_TEST_ID}>
        <button onClick={() => exitCallback()}>
          <Icon
            iconId = {ICON_IDS.CLOSE}
            width  = {25}
            height = {25}
            color  = {DESIGN_TOKENS.color.text.normal.value}
          />
        </button>
      </ExitContainer>
    </HeaderContainer>
  );
}

export default SurveyPromptHeader;
