import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MEMBER_SIGN_IN_URL } from 'App/routes/constants';
import { setAutoEmailSession } from 'App/services/autoEmailService';
import { useIsLoggedIn } from 'App/services/idpTokenService';
import { AutoLoginRoot } from 'Pages/autoLogin/style';
import AELoginGuard from 'Shared/components/design/email/autoLoginGuard';
import { LOGIN_TEST_ID } from 'Shared/constants/email/testId';
import { useQueryParam } from 'Shared/hooks/useQueryParam';
import { extractAutoLoginRedirectionPath } from 'Shared/services/helperService';

/**
 * Reviews the query parameters in the url and removes
 * all parameters which are used for auto login to avoid
 * any conflicts or unintended consequences.
 *
 * @returns string
 */
function extractSafeSearchParams(): string {
  const search: string = window.location.search;
  const searchParams   = new URLSearchParams(search);
  searchParams.delete('userId');
  searchParams.delete('aeT');
  searchParams.delete('autoLoginKey');
  searchParams.delete('loadingText');
  searchParams.delete('umid');
  searchParams.delete('aeCId');
  searchParams.delete('aeId');

  const searchString = searchParams.toString();

  if (searchString) {
    return `?${searchString}`;
  }

  return '';
}

/**
 * Renders the AutoLogin page
 * Example URL http://localhost:8080/al/?aeId=100&aeCId=2&userId=767&aeT=2023-03-22T23:36:28Z&autoEmailKey=foo
 */
function AutoLogin(): JSX.Element {
  const userId          = useQueryParam('userId') || '';
  const aeT             = useQueryParam('aeT', true) || '';
  const autoLoginKey    = useQueryParam('autoLoginKey') || '';
  const loadingText     = useQueryParam('loadingText') || '';
  const stillLoading    = false;
  const isLoggedIn      = useIsLoggedIn();
  const navigate        = useNavigate();
  const location        = useLocation();
  const pathToRedirect  = extractAutoLoginRedirectionPath(location.pathname);
  const shouldAutoLogin = useMemo(() => userId && aeT && autoLoginKey, [aeT, autoLoginKey, userId]);

  /**
   * Set the auto email session
   */
  useEffect(() => {
    setAutoEmailSession('', '', aeT);
  }, [aeT]);

  useEffect(() => {
    if (isLoggedIn && pathToRedirect) {
      const searchParams = extractSafeSearchParams();
      navigate({
        pathname: pathToRedirect,
        search: searchParams
      }, {
        replace: true,
      });
    } else if (!shouldAutoLogin) {
      navigate(MEMBER_SIGN_IN_URL);
    }
  }, [isLoggedIn, navigate, pathToRedirect, shouldAutoLogin]);

  if (!shouldAutoLogin) {
    return <></>;
  }

  return (
    <AutoLoginRoot data-testid={LOGIN_TEST_ID}>
      <AELoginGuard
        userId                 = {userId}
        aeT                    = {aeT}
        autoLoginKey           = {autoLoginKey}
        stillLoading           = {stillLoading}
        isAutoLogin
        loadingText            = {loadingText}
        fallbackRedirectionURL = {MEMBER_SIGN_IN_URL}
      >
        <></>
      </AELoginGuard>
    </AutoLoginRoot>
  );
}

export default AutoLogin;
