import { logError } from 'App/services/coralogixService';
import { SURVEY_REQUEST_REFERRER } from 'Pages/surveyExperience/services/surveyService';
import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";
import { UspApiGetModel } from 'Shared/services/usp/models/UspApiGetModel';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';

export type SurveyLoadEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.first_batch_survey_count]: number,
  [ANALYTICS_EVENT_ATTRIBUTES.first_batch_load_time]   : number,
  [ANALYTICS_EVENT_ATTRIBUTES.first_batch_provider_ids]: string,
  [ANALYTICS_EVENT_ATTRIBUTES.total_survey_count]      : number,
  [ANALYTICS_EVENT_ATTRIBUTES.total_load_time]         : number,
  [ANALYTICS_EVENT_ATTRIBUTES.total_provider_ids]      : string,
}

/**
 * Triggered when a user completes the survey request
 * @returns void
 */
export const analyticsTrackSurveyLoadEnd = (referrer: SURVEY_REQUEST_REFERRER) => {
  // Track the survey load end event
  const trackEvent = (surveyLoadEvent: SurveyLoadEvent) => {
    const event: AnalyticsEvent = {
      event     : ANALYTICS_EVENT_NAMES.end,
      attributes: {
        [ANALYTICS_EVENT_ATTRIBUTES.type]                    : ANALYTICS_EVENT_TYPE.survey,
        [ANALYTICS_EVENT_ATTRIBUTES.sub_type]                : ANALYTICS_EVENT_SUB_TYPE.survey_load,
        [ANALYTICS_EVENT_ATTRIBUTES.referrer]                : referrer                                                             || '',
        [ANALYTICS_EVENT_ATTRIBUTES.first_batch_survey_count]: surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.first_batch_survey_count] || '',
        [ANALYTICS_EVENT_ATTRIBUTES.first_batch_load_time]   : surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.first_batch_load_time]    || '',
        [ANALYTICS_EVENT_ATTRIBUTES.first_batch_provider_ids]: surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.first_batch_provider_ids] || '',
        [ANALYTICS_EVENT_ATTRIBUTES.total_survey_count]      : surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.total_survey_count]       || '',
        [ANALYTICS_EVENT_ATTRIBUTES.total_load_time]         : surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.total_load_time]          || '',
        [ANALYTICS_EVENT_ATTRIBUTES.total_provider_ids]      : surveyLoadEvent[ANALYTICS_EVENT_ATTRIBUTES.total_provider_ids]       || '',
      }
    };

    trackEventConditionally(GA4_EVENT_CATEGORY.survey_load, [event]);
  };

  // Extract unique provider ids from the survey list
  const extractProviderIds = (surveyList: UspSurveysModel[]): string => {
    return surveyList.reduce((acc: string[], survey: UspSurveysModel) => {
      const providerId = survey.providerId + '';

      if (!acc.includes(providerId)) {
        acc.push(providerId);
      }

      return acc;
    }, []).join(',');
  };

  // Defaults
  let startTime             = 0;
  let firstBatchEndTime     = 0;
  let firstBatchSurveyCount = 0;
  let firstBatchProviderIds = '';
  let totalBatchEndTime     = 0;
  let totalBatchSurveyCount = 0;
  let totalBatchProviderIds = '';

  return {
    // Track the start
    start: () => {
      startTime = Date.now();
    },
    // Process each batch
    processBatch: (response: UspApiGetModel) => {
      try {
        if (response.surveys.length > 0 && !firstBatchEndTime) {
          firstBatchEndTime     = Date.now();
          firstBatchSurveyCount = response.surveys.length;
          firstBatchProviderIds = extractProviderIds(response.surveys);
        }

        if (!response.hasMoreSurveys) {
          totalBatchEndTime     = Date.now();
          firstBatchEndTime     = firstBatchEndTime || totalBatchEndTime;
          totalBatchSurveyCount = response.surveys?.length;
          firstBatchSurveyCount = firstBatchSurveyCount || totalBatchSurveyCount;
          totalBatchProviderIds = extractProviderIds(response.surveys);

          trackEvent({
            [ANALYTICS_EVENT_ATTRIBUTES.first_batch_survey_count] : firstBatchSurveyCount,
            [ANALYTICS_EVENT_ATTRIBUTES.first_batch_load_time]    : Math.round((firstBatchEndTime - startTime)/1000),
            [ANALYTICS_EVENT_ATTRIBUTES.first_batch_provider_ids] : firstBatchProviderIds,
            [ANALYTICS_EVENT_ATTRIBUTES.total_survey_count]       : totalBatchSurveyCount,
            [ANALYTICS_EVENT_ATTRIBUTES.total_load_time]          : Math.round((totalBatchEndTime - startTime)/1000),
            [ANALYTICS_EVENT_ATTRIBUTES.total_provider_ids]       : totalBatchProviderIds,
          });
        }
      } catch (error) {
        logError('extractProviderIds', error);
      }
    }
  };
};
