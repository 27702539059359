import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { checkAndSetSurveyCallbackStatus } from 'App/services/steService';
import { NO_POINTS, SURVEY_CALLBACK_SCREENS } from 'Pages/surveyCallback/constants';
import { SurveyCallbackParams, SurveyCallbackStatuses } from 'Pages/surveyCallback/models/SurveyCallbackModels';
import SurveyModal from 'Pages/surveyCallback/SurveyModal';

/**
 * Survey Callback component
 * It renders modal on user redirect from survey provider
 * @returns
 */
function SurveyCallback(): JSX.Element {
  const [searchParams] = useSearchParams();

  /**
   *   Gets value from url param
   */
  const processParam = (param: SurveyCallbackParams): number | null => {
    const paramValue = searchParams.get(param);
    if (paramValue == null || isNaN(Number(paramValue))) {
      return null;
    } else {
      return parseInt(paramValue);
    }
  };

  const callbackStatus        = processParam(SurveyCallbackParams.STATUS_PARAM);
  const callbackPoints        = processParam(SurveyCallbackParams.POINTS_PARAM);
  const message               = searchParams.get(SurveyCallbackParams.MESSAGE_PARAM);
  const surveyId              = searchParams.get(SurveyCallbackParams.ID_PARAM);

  /**
   * Call desktop app install modal check function depending on callback status
   */
  checkAndSetSurveyCallbackStatus(callbackStatus || null);

  /**
   * Renders the modal
   */
  const renderModal = (): JSX.Element | void => {
    if (callbackStatus != null) {
      if (callbackStatus in SurveyCallbackStatuses) {
        const screen = SURVEY_CALLBACK_SCREENS[callbackStatus as SurveyCallbackStatuses];
        if (screen) {
          const title          = screen.title;
          const text           = screen.text;
          const points         = callbackPoints || NO_POINTS;
          const buttonText     = 'buttonText' in screen ? screen.buttonText  : undefined;
          const reportIssue    = 'reportIssue' in screen ? screen.reportIssue: undefined;
          const emojiElement   = screen.emojiElement;
          const centered       = screen.centered;
          const emojiComponent = screen.emojiComponent;

          return (
            <SurveyModal
              title             = {title}
              text              = {text}
              centered          = {centered}
              points            = {points}
              buttonText        = {buttonText}
              reportIssue       = {reportIssue}
              emojiElement      = {emojiElement}
              emojiComponent    = {emojiComponent}
              callbackStatus    = {callbackStatus}
              surveyId          = {surveyId}
            />
          );
        }
      } else {
        const thinkingFaceEmoji = "\uD83E\uDD14";
        return (
          // Following screen is a fallback for statuses without designs
          <SurveyModal
            title          = {`Status: ${callbackStatus}` as any} // TODO: add actual i18next key & remove `as any`
            text           = {`Message: ${message}<br/> Points: ${callbackPoints}` as any} // TODO: add actual i18next key & remove `as any`
            points         = {NO_POINTS}
            reportIssue    = {true}
            emojiElement   = {thinkingFaceEmoji}
            callbackStatus = {callbackStatus || SurveyCallbackStatuses.UNEXPECTED_ERROR}
            surveyId       = {surveyId}
          />
        );
      }
    }
  };

  /**
   * Renders the modal
   */
  return (
    <>
      {renderModal()}
    </>
  );
}

export default SurveyCallback;
