import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEMBER_FORGOT_PASSWORD_URL, MEMBER_SIGN_IN_URL } from 'App/routes/constants';
import { logError } from 'App/services/coralogixService';
import { SSO_TOKEN_POLL_INTERVAL_MS } from "Pages/signIn/SignInForm";
import { Button, ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import {
  CustomLink,
  ForgotPasswordWrapper,
  Gap,
  LoginFormContainer,
  LoginFormContainerInner,
  ModalForm,
  ModalHeader,
  PasswordField,
  PasswordWrapper,
  ShowPasswordIcon,
  UserAvatar,
  UserEmail,
  UserFullName,
  UserInfo
} from 'Shared/components/design/loginModal/components/loginForm/style';
import { onLogin } from 'Shared/components/design/loginModal/routes/waitForLogin';
import { SSOProvider } from 'Shared/components/design/SSOButton/constants';
import SSOButton from 'Shared/components/design/SSOButton/SSOButton';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import useInterval from 'Shared/hooks/useInterval';
import { ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_RESPONSE_CODES } from 'Shared/services/analytics/constants';
import { analyticsTrackLoginEnd } from 'Shared/services/analytics/events/loginEnd';
import { analyticsTrackLoginStart } from 'Shared/services/analytics/events/loginStart';
import { getIsFullScopeToken } from 'Shared/services/apiInterface';
import { IDPSignupSource } from 'Shared/services/userActivation/api/consts';
import { useAuthTokensUsingCredentials, useGetUserInfo } from 'Shared/services/userActivation/api/idpApiService';

interface LoginFormProps {
  password          : string;
  setPassword       : (value: string) => void;
  closeModal        : () => void;
  signupSource?     : IDPSignupSource;
  showForgotPassword: () => void;
}

/**
 * Renders PartialLoginModal component
 */
const LoginForm = ({ password, setPassword, closeModal, showForgotPassword }: LoginFormProps) => {
  const { t }                             = useTranslation('app');
  const [showPassword, setShowPassword]   = useState(false);
  const [isValid, setIsValid]             = useState(false);
  const passwordInputRef                  = useRef<any>();
  const formRef                           = useRef<HTMLFormElement>(null);
  const { data: userInfo }                = useGetUserInfo();
  const {
    isMutating: isSubmitting,
    trigger: login
  }                                       = useAuthTokensUsingCredentials();
  const firstInitial                      = userInfo?.firstName?.substring(0, 1) ?? '?';
  const lastInitial                       = userInfo?.lastName?.substring(0, 1) ?? '?';
  const initals                           = `${firstInitial}${lastInitial}`;

  // Triggered after successful login
  const handleLoginSuccess = (): void => {
    analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_modal);
    closeModal();
    onLogin();
  };

  // Starts listening to authCookie write
  const handleSSOButtonClick = (): void => {
    analyticsTrackLoginStart(ANALYTICS_EVENT_SUB_TYPE.login_modal);
    setStartPolling(true);
  };

  // Full scope login poling state
  const isFullScopeToken                  = getIsFullScopeToken();
  const [startPolling, setStartPolling]   = useState(false);

  // Determine if should poll for SSO re-auth
  const signupSource      = userInfo?.signupSource;
  const isSignupSourceSSO = [SSOProvider.FACEBOOK.toUpperCase(), SSOProvider.GOOGLE.toUpperCase()].some((source) => source === signupSource);

  // Polling for fully logged in token if needed
  const pollDelay = (startPolling && isSignupSourceSSO) ? SSO_TOKEN_POLL_INTERVAL_MS : null;
  useInterval(() => {
    if (isFullScopeToken) {
      setStartPolling(false);
      // User fully logged in with SSO.
      handleLoginSuccess();
    }
    // user is not fully logged in, keep polling
  }, pollDelay);

  function postData () {
    if (!userInfo || !isValid) {
      return;
    }

    passwordInputRef.current?.setCustomValidity('');
    analyticsTrackLoginStart(ANALYTICS_EVENT_SUB_TYPE.login_modal);

    login({
      userName: userInfo.email ?? '',
      password
    }).then(() => {
      handleLoginSuccess();
    }).catch(error => {
      const errorCodes = error.response?.data?.errorCodes || [];
      analyticsTrackLoginEnd(ANALYTICS_EVENT_SUB_TYPE.login_modal, errorCodes.join(',') || ANALYTICS_RESPONSE_CODES.error);
      passwordInputRef.current?.setCustomValidity(t('LoginModal--login-error'));
      logError('login', error);
    });
  }

  function onSubmit (event: FormEvent) {
    event.preventDefault();
    postData();
  }

  /**
   * Sets whether the form is valid every time `password` changes
   */
  useEffect(() => {
    (async () => {
      // Wait for the password input to be fully initialized
      let done = false;
      while (!done) {
        done = await passwordInputRef.current?.updateComplete ?? true;
      }

      // Set isValid based on whether the form is valid
      setIsValid(formRef.current?.checkValidity() ?? false);
    })();
  }, [password, formRef, passwordInputRef, setIsValid]);

  const renderBySignupSource = () => {
    switch (signupSource) {
      case IDPSignupSource.FACEBOOK:
        return <SSOButton provider={SSOProvider.FACEBOOK} onClick={handleSSOButtonClick} />;
      case IDPSignupSource.GOOGLE:
        return <SSOButton provider={SSOProvider.GOOGLE} onClick={handleSSOButtonClick} />;
      default:
        return renderDirect();
    }
  };

  const renderDirect = () => {
    return <ModalForm onSubmit={onSubmit} ref={formRef}>
      <PasswordWrapper>
        <PasswordField
          ref          = {passwordInputRef}
          type         = {showPassword ? 'text' : 'password'}
          value        = {password}
          label        = {t('LoginModal--login-label')}
          name         = 'password'
          autocomplete = 'current-password'
          onVdsInput   = {(event: KeyboardEvent<HTMLInputElement>) => {
            setPassword(event.currentTarget.value);
            passwordInputRef.current?.setCustomValidity('');
          }}
          required
        />
        <ShowPasswordIcon type='button' onClick={() => setShowPassword(show => !show)}>
          <Icon
            iconId={showPassword ? ICON_IDS.VISIBILITY : ICON_IDS.NO_VISIBILITY}
            width={20}
            height={20}
          />
        </ShowPasswordIcon>
      </PasswordWrapper>
      <ForgotPasswordWrapper>
        <CustomLink to={MEMBER_FORGOT_PASSWORD_URL} onClick={(ev) => {
          ev.preventDefault();
          showForgotPassword();
        }}>
          {t('LoginModal--login-forgotPassword')}
        </CustomLink>
      </ForgotPasswordWrapper>
      <Gap />
      <Button
        onClick   = {postData}
        size      = {ButtonSizes.LARGE}
        width     = {ButtonWidth.FULL}
        isLoading = {isSubmitting}
        disabled  = {!isValid}
      >
        {t('LoginModal--login-submit')}
      </Button>
    </ModalForm>;
  };

  return (
    <>
      <ModalHeader>{t('LoginModal--login-title')}</ModalHeader>
        <LoginFormContainer>
          <UserInfo>
            <UserAvatar>{initals}</UserAvatar>
            <UserFullName>{userInfo?.firstName} {userInfo?.lastName}</UserFullName>
            <UserEmail>{userInfo?.email}</UserEmail>
          </UserInfo>
          <LoginFormContainerInner>
            {renderBySignupSource()}
            <CustomLink to={MEMBER_SIGN_IN_URL}>
              {t('LoginModal--login-notYou')}
            </CustomLink>
          </LoginFormContainerInner>
        </LoginFormContainer>
    </>
  );
};

export default LoginForm;
