import { RouteQueryParam, SURVEY_QUALIFICATION_ROUTE } from 'App/routes/constants';
import { sendBrazeSurveyLoadEvent } from 'App/services/brazeService';
import { logError } from 'App/services/coralogixService';
import { refetchOrRedirectToPanelPageIfEligible } from 'Pages/panels/panelPageService';
import { SURVEY_STREAM_LOADING_STATUS } from 'Pages/surveyExperience/components/surveysStreamLoader/constants';
import {
  MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_DESKTOP,
  MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_MOBILE,
  MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_DESKTOP,
  MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_MOBILE,
  MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_DESKTOP,
  MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_MOBILE,
  MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_DESKTOP,
  MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_MOBILE,
  MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_DESKTOP,
  MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_MOBILE,
  MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_DESKTOP,
  MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_MOBILE
} from 'Pages/surveyExperience/constants';
import {SurveyModel} from 'Pages/surveyExperience/models/SurveyModel';
import { dispatchSurveysLoadingEvent } from 'Pages/surveyExperience/routes/dispatchEvents';
import { MIN_INCOMPLETE_SURVEY_COUNT, NO_SURVEY_RETRY_INTERVAL_SECONDS, SURVEY_LOAD_INTERVAL_SECONDS, SURVEY_UI_RENDER_INTERVAL_SECONDS } from 'Pages/surveyExperience/services/constants';
import { filterVerifyRepData } from 'Pages/surveyExperience/services/repdataService';
import { isEligibleToSetSurveyCache, isSurveyCacheStale, shouldImmediatelyRefreshSurveys } from 'Pages/surveyExperience/services/surveysLoading';
import { addSurveyIdToSurveySuppressionList, removeSuppressedSurveysById } from 'Pages/surveyExperience/services/surveySuppressionService';
import {LOCAL_STORAGE_IDS} from 'Shared/constants';
import { analyticsTrackSurveyImpressionsIfAbsent } from 'Shared/services/analytics/events/surveyImpression';
import { analyticsTrackSurveyLoadEnd } from 'Shared/services/analytics/events/surveyLoad';
import { analyticsTrackSurveysLoadCache } from 'Shared/services/analytics/events/surveysLoadCacheEnd';
import {isMobile} from 'Shared/services/deviceService';
import {deleteLocalStorage, getLocalStorage, hasLocalStorage, setLocalStorage} from 'Shared/services/localStorageService';
import { loginToMonolith } from 'Shared/services/monolith/api/monolithApiService';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import {getUSPSurveysStream} from 'Shared/services/usp/api/uspApiService';
import {UspApiGetModel} from 'Shared/services/usp/models/UspApiGetModel';
import {UspSurveysModel} from 'Shared/services/usp/models/UspSurveysModel';
import {UspUnansweredQuestions} from 'Shared/services/usp/models/UspUnansweredQuestionsModel';
import { getCachedSurveyQualificationDetails } from 'Shared/utils/unansweredQuestionsQueue';
import useSWR, { useSWRConfig } from 'swr';

// Load this asynchronously
import('Shared/vendors/fingerprint-rfg.js').then(module => {
  module.initRFGFingerprint();
});

export enum SURVEY_REQUEST_REFERRER {
  GENERAL_PROFILE      = 'general_profile',
  GENERAL_PROFILE_EDIT = 'general_profile_edit',
  DASHBOARD            = 'dashboard',
  WELCOME_V3           = 'welcome_v3',
  AUTO_EMAIL           = 'auto_email',
  DIRECT_SURVEY        = 'direct_survey',
  SURVEY_CALLBACK      = 'survey_callback',
  SURVEY_MEDLEY        = 'survey_medley',
  SURVEY_QUALIFICATION = 'survey_qualification',
  OTHER                = 'other',
}

let lastRequestStartTime = 0;
let isFirstRequest       = true;
let surveysShown         = false;

const isFirstSurveyRequest = () => isFirstRequest;
const getSurveysShown      = () => surveysShown;
const setSurveysShown      = (shown: boolean) => surveysShown = shown;

/**
 * Gets SortingGroups
 */
const getSortingGroups = (): string => {
  const IS_MOBILE = isMobile() ? 'MOBILE,' : '';
  return IS_MOBILE + process.env.USP_SORTING_GROUPS;
};

/**
 * Returns the time the survey cache was created
 */
const getSurveyCacheCreatedTime = (): number => {
  const surveyCache = getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST, true);

  if (surveyCache?.createdAt) {
    return surveyCache?.createdAt;
  }

  return 0;
};

/**
 * Reset survey request
 */
const resetSurveyRequest = (): void => {
  lastRequestStartTime = 0;
  isFirstRequest       = true;
  deleteLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST);
};

/**
 * Gets whether the current survey cache is an incomplete batch (request hasn't finished yet)
 */
const getSurveyListIncomplete = (): boolean => {
  const surveyCache = getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST, true);
  return surveyCache?.incomplete ?? true;
};

/**
 * Gets the user's qualified surveys and replenishes the list quietly
 * every minute.
 */
const initGetSurveys = (() => {
  const requestInterval          = SURVEY_LOAD_INTERVAL_SECONDS * 1000;
  const noSurveysRequestInterval = NO_SURVEY_RETRY_INTERVAL_SECONDS * 1000;
  let   requestInProgress        = false;

  // Returns whether the user is eligible for a survey request.
  const eligibleForSurveyRequest = (noSurveys: boolean, lastCompletedRequestTime: number): boolean => {
    // If there is already a request in progress, do not request again.
    if (requestInProgress) {
      return false;
    }

    if (shouldImmediatelyRefreshSurveys()) {
      return true;
    }

    const FIFTEEN_SECONDS_AGO = Date.now() - 15 * 1000;
    // Don't make requests more than once every 15 seconds
    if (lastRequestStartTime > FIFTEEN_SECONDS_AGO) {
      return false;
    }

    // If there is no survey cache then request surveys immediately.
    if (!hasLocalSurveyCache()) {
      return true;
    }

    // Return true for scenarios where the survey list in the cache is from an incomplete load and the member hasn't
    // requested any surveys since the page was loaded.
    if (getSurveyListIncomplete() && isFirstRequest) {
      return true;
    }

    // If there is a survey cache but the cache is empty then apply special rules
    if (noSurveys) {
      const noSurveyDelayTimestamp = Date.now() - noSurveysRequestInterval;

      // If the last request was done less than 15 seconds ago, do not request again.
      if (lastCompletedRequestTime > noSurveyDelayTimestamp) {
        return false;
      } else {
        return true;
      }
    }

    // If the survey list is newer than 1 minute then don't request again
    const staleSurveyListTimestamp = Date.now() - requestInterval;
    if (lastCompletedRequestTime > staleSurveyListTimestamp) {
      return false;
    }

    // Request surveys for all other cases
    return true;
  };

  return (surveyRequestReferrer: SURVEY_REQUEST_REFERRER): Promise<UspApiGetModel> => {
    return new Promise<UspApiGetModel>((resolve) => {
      const surveyCacheList                                  = getLocalSurveyCache();
      const unansweredQuestionsCacheList                     = getLocalUnansweredQuestionsCache();
      const repDataSurveyList                                = filterVerifyRepData(surveyCacheList);
      const { filteredSurveys, filteredUnansweredQuestions } = filterUnansweredQuestions(unansweredQuestionsCacheList, repDataSurveyList);
      const lastCompletedRequestTime                         = getSurveyListIncomplete() ? 0 : getSurveyCacheCreatedTime();

      resolve({
        surveys                 : filteredSurveys,
        unansweredQuestions     : filteredUnansweredQuestions,
        hasMoreSurveys          : false,
        lastRequestStartTime    : lastRequestStartTime,
        lastCompletedRequestTime: lastCompletedRequestTime,
      });

      const surveyCacheEmpty = filteredSurveys.length === 0;

      if (!eligibleForSurveyRequest(surveyCacheEmpty, lastCompletedRequestTime)) {
        return;
      }

      // Set to true to notify that there is a request in progress
      requestInProgress = true;

      // Notify the UI of loading
      dispatchSurveysLoadingEvent({ action: SURVEY_STREAM_LOADING_STATUS.LOADING });

      // Login to the monolith
      loginToMonolith();

      /**
       * Combines survey and unanswered questions into a single list then
       * stores them in the local storage.
       * @param response
       */
      const surveyProcessor = (response: UspApiGetModel) => {
        //filter out already clicked surveys
        let surveys                                            = removeSuppressedSurveysById(response.surveys || []);
            surveys                                            = removeDuplicateSurveys(surveys);
        const unansweredQuestions                              = (response.unansweredQuestions || []);
        const { filteredSurveys, filteredUnansweredQuestions } = filterUnansweredQuestions(unansweredQuestions, surveys);
        const hasMoreSurveys                                   = response.hasMoreSurveys;

        // Process and track each batch
        analyticsTrackRequestLoad.processBatch(response);

        if (isEligibleToSetSurveyCache(lastRequestStartTime, lastCompletedRequestTime, filteredSurveys, hasMoreSurveys)) {
          // Store surveys in local storage
          setLocalSurveyCache(filteredSurveys, { incomplete: hasMoreSurveys });

          // Store unanswered questions in local storage
          setLocalUnansweredQuestionsCache(filteredUnansweredQuestions);
        }

        if (!hasMoreSurveys) {
          // Notify the UI that survey loading was completed
          dispatchSurveysLoadingEvent({ action: SURVEY_STREAM_LOADING_STATUS.COMPLETED });

          // send the event to Braze
          sendBrazeSurveyLoadEvent(filteredSurveys.length);

          // Mark request as completed
          requestInProgress = false;
          isFirstRequest    = false;

          // Track instances when there are no surveys
          analyticsTrackSurveyImpressionsIfAbsent(filteredSurveys.length, hasMoreSurveys);

          // Trigger a GA4 event when there are no surveys available but there are surveys in the cache
          if (filteredSurveys.length === 0 && !isSurveyCacheEmpty()) {
            analyticsTrackSurveysLoadCache();
          }

          // MEW-410: Redirect user to Panel Page if filteredSurveys is empty and user is the panel page test group
          refetchOrRedirectToPanelPageIfEligible(filteredSurveys, response);
        }
      };

      lastRequestStartTime = Date.now();

      const analyticsTrackRequestLoad = analyticsTrackSurveyLoadEnd(surveyRequestReferrer);
      analyticsTrackRequestLoad.start();

      getSurveyPageUSPSurveysStream((response: UspApiGetModel) => {
        surveyProcessor(response);

        if (response.hasMoreSurveys) {
          dispatchSurveysLoadingEvent({ action: SURVEY_STREAM_LOADING_STATUS.LOADING });
        }
      });
    });
  };
})();

/**
 * Get the USP surveys stream
 * @param streamCallback - callback for each time the stream has returned a message
 */
const getSurveyPageUSPSurveysStream = (streamCallback: (response: UspApiGetModel) => void) => {
  const providerIds                                   = Array.isArray(process.env.SURVEY_PROVIDER_IDS) ? process.env.SURVEY_PROVIDER_IDS : [];
  const sortingGroups                                 = getSortingGroups();
  const surveys: UspSurveysModel[]                    = [];
  const unansweredQuestions: UspUnansweredQuestions[] = [];

  getUSPSurveysStream((data: string, hasMoreSurveys: boolean) => {
    try {
      const surveysResult = JSON.parse(data);

      if (surveysResult.surveys) {
        surveysResult.surveys.forEach((survey: UspSurveysModel) => {
          for (const currentSurvey of surveys) {
            if (currentSurvey.id === survey.id) {
              return;
            }
          }

          surveys.push(survey);
        });
      }

      if (surveysResult.unansweredQuestions) {
        surveysResult.unansweredQuestions.forEach((unansweredQuestion: UspUnansweredQuestions) => {
          for (const currentQuestion of unansweredQuestions) {
            if (currentQuestion.key === unansweredQuestion.key) {
              return;
            }
          }

          unansweredQuestions.push(unansweredQuestion);
        });
      }

      streamCallback({
        surveys            : surveys,
        unansweredQuestions: unansweredQuestions,
        hasMoreSurveys     : hasMoreSurveys,
      });
    } catch (error) {
      logError('getSurveyPageUSPSurveysStream', error);
      streamCallback({
        surveys            : surveys,
        unansweredQuestions: unansweredQuestions,
        hasMoreSurveys     : hasMoreSurveys,
      });
    }
  }, providerIds.join(','), sortingGroups);
};

/**
 * Returns true if cache is empty or doesn't exist
 * @returns {boolean}
 */
const isSurveyCacheEmpty = (): boolean => {
  return !hasLocalSurveyCache() || getLocalSurveyCache().length === 0;
};

/**
 * Check if the local survey cache exists
 * @returns {boolean}
 */
const hasLocalSurveyCache = (): boolean => {
  return !!hasLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST);
};

/**
 * Gets survey list from local storage
 * @returns
 */
const getLocalSurveyCache = (): UspSurveysModel[] => {
  const surveyCache = getLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST, true);

  if (!surveyCache?.createdAt) {
    return [];
  }

  if (isSurveyCacheStale(surveyCache.createdAt)) {
    return [];
  }

  if (getSurveyListIncomplete() && isFirstRequest && surveyCache.surveys.length < MIN_INCOMPLETE_SURVEY_COUNT) {
    return [];
  }

  return surveyCache.surveys;
};

interface LocalSurveyCacheOpts {
  incomplete?: boolean
  createdAt? : number
}

/**
 * Sets survey list to local storage
 * If createdAt is passed, it will be used as the createdAt timestamp
 * (used to apply existing timestamp when just removing a survey from the list)
 * @param createdAt
 * @param surveyList
 * @returns
 */
const setLocalSurveyCache = (surveyList: UspSurveysModel[], {
  incomplete = getSurveyListIncomplete(),
  createdAt = Date.now(),
}: LocalSurveyCacheOpts = {}): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.SURVEY_LIST, {
    createdAt: createdAt,
    incomplete: incomplete,
    surveys: surveyList
  }, true);
};

/**
 * Removes specified survey from survey list by id and sets it in
 * local storage replacing the existing survey list
 * @param surveyList
 * @param surveyId
 * @returns
 */
const removeSurveyById = (surveyId: string) => {
  const surveyList: UspSurveysModel[] = getLocalSurveyCache();
  const createdAtTime                 = getSurveyCacheCreatedTime();
  const currentCreatedAt              = createdAtTime !== 0 ? createdAtTime : undefined;
  const updatedSurveyList             = surveyList.filter((survey: UspSurveysModel) => survey.id !== surveyId);

  setLocalSurveyCache(updatedSurveyList, { createdAt: currentCreatedAt });
  addSurveyIdToSurveySuppressionList(surveyId);
};

/**
 * Get surveys total count
 * @returns {number}
 */
const getTotalSurveysAmount = (isMemberBeyondNewMemberThreshold: boolean): number => {
  if (isMemberBeyondNewMemberThreshold) {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_MOBILE : MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  } else {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_MOBILE : MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  }
};

/**
 * Get surveys featured count
 * @returns {number}
 */
const getFeaturedSurveysAmount = (isMemberBeyondNewMemberThreshold: boolean): number => {
  if (isMemberBeyondNewMemberThreshold) {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_MOBILE : MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  } else {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_MOBILE : MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  }
};

/**
 * Get surveys priority count
 * @returns {number}
 */
const getPrioritySurveysAmount = (isMemberBeyondNewMemberThreshold: boolean): number => {
  if (isMemberBeyondNewMemberThreshold) {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_MOBILE : MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  } else {
    const featuredSurveyCount = isMobile() ? MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_MOBILE : MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_DESKTOP;
    return featuredSurveyCount;
  }
};

/**
 * Limits max number of surveys displayed in US ONLY
 * @param surveys
 * @returns
 */
const getMaxSurveys = (surveys: SurveyModel[], isMemberBeyondNewMemberThreshold: boolean): SurveyModel[] => {
  return surveys.slice(0, getTotalSurveysAmount(isMemberBeyondNewMemberThreshold));
};

/**
 * Gets survey list from local storage
 * @returns
 */
const getLocalUnansweredQuestionsCache = (): UspUnansweredQuestions[] => {
  const surveyCache = getLocalStorage(LOCAL_STORAGE_IDS.UNANSWERED_QUESTIONS, true);

  if (!surveyCache?.createdAt) {
    return [];
  }

  if (isSurveyCacheStale(surveyCache.createdAt)) {
    return [];
  }

  return surveyCache.unansweredQuestions || [];
};

/**
 * Sets survey list to local storage
 * @returns
 * @param unansweredQuestions
 */
const setLocalUnansweredQuestionsCache = (unansweredQuestions: UspUnansweredQuestions[]): void => {
  setLocalStorage(LOCAL_STORAGE_IDS.UNANSWERED_QUESTIONS, {
    createdAt: Date.now(),
    unansweredQuestions: unansweredQuestions
  }, true);
};

/**
 * Removes specified survey from survey list by id and sets it in
 * local storage replacing the existing survey list
 * @returns
 * @param unansweredQuestionKey
 */
const removeUnansweredQuestionByKey = (unansweredQuestionKey: string) => {
  const unansweredQuestions = getLocalUnansweredQuestionsCache();
  const updatedSurveyList   = unansweredQuestions.filter((question) => question.key !== unansweredQuestionKey);

  setLocalUnansweredQuestionsCache(updatedSurveyList);
  addAnsweredQuestionToSuppressionList(unansweredQuestionKey);
};

const customQuestionPrefixes = [
  'AMQ',
  'am.',
  'amq.',
  'LUCID',
  'pid'
];

/**
 * Check whether a given question key belongs to an Active Measure (Custom) question.
 */
const isKeyCustomQuestionKey = (key: string): boolean => {
  return customQuestionPrefixes.some(prefix => key.startsWith(prefix));
};

/**
 * Add unanswered question id to question suppression list
 * and store the last 50
 * @param questionId
 */
const addAnsweredQuestionToSuppressionList = (questionKey: string) => {
  const questionSuppressionList = getLocalStorage(LOCAL_STORAGE_IDS.QUESTION_SUPPRESSION_LIST, true);
  let questionKeys = questionSuppressionList?.questionKeys || [];

  // Add the question key to the front of the array
  questionKeys.unshift(questionKey);

  // Cut so that it's the last 50 question key
  questionKeys = questionKeys.slice(0, 50);

  // Update the survey cache
  setLocalStorage(LOCAL_STORAGE_IDS.QUESTION_SUPPRESSION_LIST, {
    questionKeys: questionKeys
  }, true);
};

/**
 * Filters the unanswered questions list so that
 * 1. Removes duplicates
 * 2. Removes the questions which are in the question suppression list
 * @param surveyList
 * @returns filteredUnansweredQuestionsList
 */
const filterUnansweredQuestions = (unansweredQuestionsList: UspUnansweredQuestions[], surveyList: UspSurveysModel[]): { filteredSurveys: UspSurveysModel[], filteredUnansweredQuestions: UspUnansweredQuestions[] } => {
  const questionSuppressionList = getLocalStorage(LOCAL_STORAGE_IDS.QUESTION_SUPPRESSION_LIST, true);

  if (!questionSuppressionList?.questionKeys || questionSuppressionList?.questionKeys?.length === 0) {
    return {
      filteredUnansweredQuestions: unansweredQuestionsList || [],
      filteredSurveys: surveyList || []
    };
  }

  const questionKeys: string[]         = questionSuppressionList.questionKeys;
  const includedQuestionKeys: string[] = [];

  // Filters the survey question keys to remove already answered questions.
  const filteredSurveys = surveyList.map((survey: UspSurveysModel) => {
    survey.unansweredQuestions = (survey.unansweredQuestions || []).filter((questionKey: string) => {
      if (questionKeys.includes(questionKey)) {
        return false;
      } else {
        return true;
      }
    });

    return survey;
  });

  // Filters the main questions list to remove already answered questions.
  const filteredUnansweredQuestions: UspUnansweredQuestions[] = unansweredQuestionsList.filter((unansweredQuestion: UspUnansweredQuestions) => {
    if (includedQuestionKeys.includes(unansweredQuestion.key)) {
      return false;
    } else {
      // adds the question key to the found questions
      includedQuestionKeys.push(unansweredQuestion.key);
    }

    return !questionKeys.includes(unansweredQuestion.key);
  });

  return {
    filteredSurveys: filteredSurveys || [],
    filteredUnansweredQuestions: filteredUnansweredQuestions || []
  };
};

const useGetSurveys = (surveyRequestReferrer: SURVEY_REQUEST_REFERRER) => {
  // Refresh every 5 seconds so that the surveys
  // are up to date with the cache across all tabs.
  return useSWR(SwrKeys.GetSurveys, () => initGetSurveys(surveyRequestReferrer), {
    refreshInterval: SURVEY_UI_RENDER_INTERVAL_SECONDS * 1000
  });
};

const useRefetchSurveys = () => {
  const { mutate } = useSWRConfig();

  return (surveyRequestReferrer: SURVEY_REQUEST_REFERRER) => {
    resetSurveyRequest();
    initGetSurveys(surveyRequestReferrer);
    mutate<UspApiGetModel>(SwrKeys.GetSurveys, () => undefined);
  };
};

/**
 * Returns unanswered question keys from local storage
 * @returns {boolean}
 */
const getUnansweredQuestionKeys = (): string => {
  const surveyQualificationDetails = getCachedSurveyQualificationDetails();
  const questionList               = surveyQualificationDetails?.questionnaireDetails?.questions || [];
  const questionIds                = questionList.map(
    question => question.data.map(data => data.id)
  ).flat().join(',');

  return questionIds;
};

/**
 * Generates a URL and redirects to the survey qualification page
 * @param surveyId
 * @param providerId
 */
const redirectToSurveyQualification = (surveyId: string, providerId: number): void => {
  const questionIds = getUnansweredQuestionKeys();

  window.open(`${SURVEY_QUALIFICATION_ROUTE}?${RouteQueryParam.SURVEY_ID_QUERY_PARAM}=${surveyId}&${RouteQueryParam.PROVIDER_ID_QUERY_PARAM}=${providerId}&${RouteQueryParam.QUESTION_IDS_QUERY_PARAM}=${questionIds}`);
};

/**
 * Remove potential duplicate surveys from the list
 * @param surveys
 * @returns
 */
const removeDuplicateSurveys = (surveys: UspSurveysModel[]): UspSurveysModel[] => {
  const uniqueSurveys = new Map<number, Set<string>>();

  return (surveys || []).filter(survey => {
    const { providerId } = survey;
    const surveyId       = ((survey.id || '') + '').toLowerCase();

    //check if there is already a set of ids for this providerId
    let ids = uniqueSurveys.get(providerId);

    //if no set exists create a new one and add survey id
    if (!ids) {
      ids = new Set<string>();
      ids.add(surveyId);
      uniqueSurveys.set(providerId, ids);
      return true;
    }

    //if surveyId already exists in the set, its a duplicate and return false to be filtered out
    if (ids.has(surveyId)) {
      return false;
    }

    //add surveyId to the set and include this survey in the list
    ids.add(surveyId);
    return true;
  });
};

export {
  addAnsweredQuestionToSuppressionList,
  filterUnansweredQuestions,
  getFeaturedSurveysAmount,
  getLocalSurveyCache,
  getLocalUnansweredQuestionsCache,
  getMaxSurveys,
  getPrioritySurveysAmount,
  getSurveyCacheCreatedTime,
  getSurveyPageUSPSurveysStream,
  getSurveysShown,
  getTotalSurveysAmount,
  getUnansweredQuestionKeys,
  hasLocalSurveyCache,
  initGetSurveys,
  isFirstSurveyRequest,
  isKeyCustomQuestionKey,
  isSurveyCacheEmpty,
  redirectToSurveyQualification,
  removeDuplicateSurveys,
  removeSurveyById,
  removeUnansweredQuestionByKey,
  resetSurveyRequest,
  setLocalUnansweredQuestionsCache,
  setSurveysShown,
  useGetSurveys,
  useRefetchSurveys
};
