import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppDownloadBadge,
  AppDownloadBadgeButton,
  DownloadCtaContainer,
  DownloadCtaTitle,
  MobileAppBadgeContainer,
  QrCodeContainer,
  QrCodeImage,
  QrContainer,
  TrustPilotImage
} from 'App/components/qrDownloadApp/style';
import { APP_BADGE_TEST_ID, QR_CODE_TEST_ID } from 'App/components/qrDownloadApp/testId';
import { APP_ENVIRONMENTS } from 'App/constants';
import appStore from 'Shared/assets/images/mobileApp/app-store-badge.svg';
import playMarket from 'Shared/assets/images/mobileApp/play-market-badge.svg';
import qrCode from 'Shared/assets/images/qr-code.png';
import qrCodeTesting from 'Shared/assets/images/qr-code-testing.png';
import trustpilot from 'Shared/assets/images/social/trustpilot.png';
import { isAndroidDevice, isIosDevice, isMobile } from 'Shared/services/deviceService';

interface QrDownloadAppType {
  inverse           ?: boolean,
  isOnSurveyCallback?: boolean,
  titleMaxWidth     ?: number
}

const iosAppEnabled     = process.env.IOS_APP_ENABLED;
const androidAppEnabled = process.env.ANDROID_APP_ENABLED;

/**
 * Renders the QR code image based on the application environment.
 *
 * @returns {JSX.Element} - A JSX element representing the QR code image.
 */
const renderQrCode = (): JSX.Element => {
  if (process.env.APP_ENV === APP_ENVIRONMENTS.DEVELOPMENT || process.env.APP_ENV === APP_ENVIRONMENTS.TESTING) {
    return <QrCodeImage src={qrCodeTesting} data-testid={QR_CODE_TEST_ID} />;
  }

  return <QrCodeImage src={qrCode} data-testid={QR_CODE_TEST_ID} />;
};

/**
 * Redirects the user to the appropriate app store URL based on the device type.
 *
 * @param {boolean} isIos - A boolean indicating if the device is an iOS device.
 * @returns {void} - This function does not return a value.
 */
const redirectToStore = (isIos: boolean): void => {
  if (isIos) {
    window.location.href = process.env.MOBILE_APP_APPSTORE_URL as string;
  } else {
    window.location.href = process.env.MOBILE_APP_PLAYMARKET_URL as string;
  }
};

// Render mobile app store badge based on device
const renderAppBadge = (): JSX.Element => {
  if (isIosDevice() && iosAppEnabled) {
    return (
      <AppDownloadBadgeButton onClick={() => redirectToStore(true)}>
        <AppDownloadBadge src={appStore} data-testid={APP_BADGE_TEST_ID} />
      </AppDownloadBadgeButton>
    );
  } else if (isAndroidDevice() && androidAppEnabled) {
    return (
      <AppDownloadBadgeButton onClick={() => redirectToStore(false)}>
        <AppDownloadBadge src={playMarket} data-testid={APP_BADGE_TEST_ID} />
      </AppDownloadBadgeButton>
    );
  }
  return <></>;
};

export const QrDownloadApp = ({ isOnSurveyCallback = false, inverse, titleMaxWidth }: QrDownloadAppType) => {
  const { t } = useTranslation('qrDownloadApp');

  // Determine the title based on the app availability
  const getQrDownloadAppTitle = (): string => {
    if (!iosAppEnabled) {
      return t('QrDownloadApp--title_android');
    }

    if (!androidAppEnabled) {
      return t('QrDownloadApp--title_ios');
    }

    return t('QrDownloadApp--title');
  };

  // Determine the title when on survey callback
  const getTitleOnSurveyCallback = (): string => {
    if (!iosAppEnabled) {
      return t('QrDownloadApp--MoreSurveys_android');
    }

    if (!androidAppEnabled) {
      return t('QrDownloadApp--MoreSurveys_ios');
    }

    return t('QrDownloadApp--MoreSurveys');
  };

  // check if the app is enabled for the respective platforms before rendering
  if ((iosAppEnabled && isIosDevice()) || (!androidAppEnabled && isAndroidDevice())) {
    return <></>;
  }

  return (
    <QrContainer inverse={inverse}>
      {isMobile() ?
        <MobileAppBadgeContainer>
          { renderAppBadge() }
        </MobileAppBadgeContainer>
      :
        <QrCodeContainer>
          { renderQrCode() }
        </QrCodeContainer>
      }
      <DownloadCtaContainer>
        <DownloadCtaTitle titleMaxWidth={titleMaxWidth}>
          {isOnSurveyCallback ? getTitleOnSurveyCallback() : getQrDownloadAppTitle()}
        </DownloadCtaTitle>
        <TrustPilotImage src={trustpilot} />
      </DownloadCtaContainer>
    </QrContainer>
  );
};
