import React, { useEffect,useState } from 'react';
import { PopupQueueModel } from 'App/components/popupQueue/models/PopupQueueModel';
import { PopupStartEventProps } from 'App/models/eventModels';
import { addPopupExitEvent, addPopupStartEvent } from 'App/routes/addEvents';
import useScrollStop from 'Shared/hooks/useScrollStop';
import { dispatchEvent } from 'Shared/services/eventsInterface';

/**
 * Renders the popup backdrop
 * - Initializes event listeners for triggering popups
 * - Initializes popup controller
 */
function PopupQueue(): JSX.Element {
  // Show/hides popup background
  const [showPopup, setShowPopup] = useState<boolean>(false);
  // Popup currently being shown (index by string name)
  const [activePopup, setActivePopup] = useState<PopupQueueModel | null>(null);
  // Queue containing list of popups triggered in the order they are triggered
  const [popupQueue, setPopupQueue] = useState<(PopupQueueModel | null)[]>([]);
  // Indicates which popup flow is being actively triggered
  const [startNewPopup, setStartNewPopup] = useState<PopupQueueModel | null>(null);
  // Indicates a popup was closed (currently doesn't care which popup was closed)
  const [exitedPopup, setExitedPopup] = useState<boolean>(false);

  useScrollStop(showPopup);

  /**
   * React hook for setting up start/exit event listeners for popups
   */
  useEffect(() => {
    /**
     * Callback for the popup start event listener
     * @param event
     */
    const popupStartEventCallback = (event: PopupStartEventProps): void => {
      const queueModel = {
        id   : event.id,
        props: event.props
      };

      setStartNewPopup(queueModel);
    };

    /**
     * Callback for the popup exit event listener
     */
    const popupExitEventCallback = (): void => {
      setExitedPopup(true);
    };

    addPopupStartEvent(popupStartEventCallback);
    addPopupExitEvent(popupExitEventCallback);
  }, []);

  /**
   * Sets the appropriate popup items in the state when startPopup is triggered by an event listener
   * - adds popup to queue if a popup is already active
   * - sets active popup in component
   */
  useEffect(() => {
    if (startNewPopup === null) {
      setShowPopup(false);
    }

    // Used to prevent any changes if the active popup has already been set or it's set to empty
    if (activePopup?.id === startNewPopup?.id) {
      return;
    }

    // If a popup is already active, add to queue
    if (!!activePopup && activePopup?.id !== '') {
      setPopupQueue(popupQueue => [...popupQueue, startNewPopup]);
      return;
    }

    // If all other checks pass, initiate the popup
    setActivePopup(startNewPopup);
    setShowPopup(true);

    if (startNewPopup !== null) {
      const popupName  = startNewPopup.id;
      const popupProps = startNewPopup.props;

      // trigger the popup
      dispatchEvent(popupName, popupProps);
    }

    setPopupQueue(popupQueue => popupQueue.filter(item => item !== startNewPopup));
  }, [startNewPopup, activePopup]);

  /**
   * Hook triggered when popup is exited
   * - Removes active popup
   * - triggers next one in queue
   */
  useEffect(() => {
    // Effect should run only when the event listener triggers the popup
    if (!exitedPopup) {
      return;
    }

    setExitedPopup(false);
    setShowPopup(false);
    setActivePopup(null);

    // Start next popup from queue
    if (popupQueue.length) {
      setStartNewPopup(popupQueue[0]);
    } else {
      setStartNewPopup(null);
    }
  }, [popupQueue, exitedPopup]);

  return (
    <></>
  );
}

export default PopupQueue;
