import { SplitTest } from 'Shared/services/opa/api/opaApi';
import { getActiveDecisionVariables, getSplitTestConfigs } from 'Shared/services/opa/app/splitTestService';
import { OPA_FLAG, OPA_VARIABLE } from 'Shared/services/opa/constants';

export interface SurveysSortSplitTest extends SplitTest {
  variables: {
    [OPA_VARIABLE.SURVEYS_SORT_ENABLED]: boolean | undefined,
  }
}

/**
 * Calls OPA to get information about the surveys sort split test.
 * @returns The surveys sort split test data.
 */
export const surveysSortSplitTest = (): SurveysSortSplitTest => {
  const decisions = getSplitTestConfigs([OPA_FLAG.SURVEYS_SORT]);
  const variables = getActiveDecisionVariables(OPA_FLAG.SURVEYS_SORT, decisions);

  return {
    enabled: !!variables,
    variables: {
      [OPA_VARIABLE.SURVEYS_SORT_ENABLED]: variables?.[OPA_VARIABLE.SURVEYS_SORT_ENABLED],
    }
  };
};
