import { AnalyticsEvent } from "Shared/services/analytics/config";
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_NAMES,ANALYTICS_EVENT_SUB_TYPE,ANALYTICS_EVENT_TYPE, ANALYTICS_RESPONSE_CODES, ANALYTICS_RESPONSE_MESSAGE } from "Shared/services/analytics/constants";
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

export type QuestionnaireEndEvent = {
  [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : ANALYTICS_EVENT_SUB_TYPE,
  [ANALYTICS_EVENT_ATTRIBUTES.total_steps]     : number,
  [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : ANALYTICS_RESPONSE_CODES,
  [ANALYTICS_EVENT_ATTRIBUTES.response_message]: ANALYTICS_RESPONSE_MESSAGE,
}

/**
 * Sends analytic event and attributes for Questionnaire to Google Analytics
 * @param questionnaireEndEvent
 * @returns void
 */
export const analyticsTrackQuestionnaireEnd = (questionnaireEndEvent: QuestionnaireEndEvent): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      ...questionnaireEndEvent,
      [ANALYTICS_EVENT_ATTRIBUTES.type]: ANALYTICS_EVENT_TYPE.questionnaire,
    }
  };

  switch (questionnaireEndEvent[ANALYTICS_EVENT_ATTRIBUTES.sub_type]) {
    case ANALYTICS_EVENT_SUB_TYPE.survey_qualification:
      trackEventConditionally(GA4_EVENT_CATEGORY.survey_qualification, [event]);
      break;
    case ANALYTICS_EVENT_SUB_TYPE.survey_medley:
      trackEventConditionally(GA4_EVENT_CATEGORY.survey_medley, [event]);
      break;
    default:
      trackEventConditionally(GA4_EVENT_CATEGORY.aquisition, [event]);
      break;
  }
};