import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const Divider = styled.hr`
  width        : 100%;
  margin       : 0 0 24px 0;
  border       : none;
  border-top   : 1px solid ${DESIGN_TOKENS.color.border.normal.value};
`;

export const StarsContainer = styled.div`
  margin-bottom: 12px;
`;

export const Quote = styled.p`
  ${LABEL_SMALL.DEFAULT}
  color        : ${DESIGN_TOKENS.color.text.secondary.value};
  margin-bottom: 16px;
`;

export const UserContainer = styled.div`
  display: flex;
`;

export const UserImage = styled.img`
  width : 40px;
  height: 40px;
`;

export const UserDetails = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  ${LABEL_SMALL.BOLD}
  color: ${DESIGN_TOKENS.color.text.normal.value};
`;

export const Redeemed = styled.div`
  ${LABEL_SMALL.DEFAULT}
  color: ${DESIGN_TOKENS.color.text.secondary.value};
`;
