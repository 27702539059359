import { useSearchParams } from 'react-router-dom';
import { isAndroidDevice, isIosDevice, isMobile } from 'Shared/services/deviceService';

/**
 * Opens download page in Play Market or App Store by device OS
 * @returns
 */
export const useMobileStoreRedirection = () => {
  const OPEN_STORE_PARAM_KEY   = 'openApp';
  const OPEN_STORE_PARAM_VALUE = 'true';
  const iosAppEnabled          = process.env.IOS_APP_ENABLED;
  const androidAppEnabled      = process.env.ANDROID_APP_ENABLED;

  const [searchParams] = useSearchParams();
  const shouldRedirect = searchParams.get(OPEN_STORE_PARAM_KEY) === OPEN_STORE_PARAM_VALUE;

  if (!shouldRedirect || !isMobile()) {
    return;
  }

  if (isIosDevice() && iosAppEnabled) {
    window.location.href = process.env.MOBILE_APP_APPSTORE_URL as string;
  } else if (isAndroidDevice() && androidAppEnabled) {
    window.location.href = process.env.MOBILE_APP_PLAYMARKET_URL as string;
  }
};
