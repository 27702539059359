import { useEffect, useState } from 'react';
import { COOKIE_IDS, PERSISTED_COOKIE_IDS } from 'App/constants';
import { logError } from 'App/services/coralogixService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { ANALYTICS_EVENT_SOURCE } from 'Shared/services/analytics/constants';
import { analyticsTrackLogoutStart } from 'Shared/services/analytics/events/logoutStart';
import { deleteCookie, getCookie, setCookie } from 'Shared/services/cookieService';
import { convertMillisecondsToDays, convertSecondsToMilliseconds } from 'Shared/services/helperService';
import { deleteLocalStorage } from 'Shared/services/localStorageService';
import { isInMobileApp, MobileAppErrors, MobileAppEvent, sendAppMessage } from 'Shared/services/mobileAppMessenger';
import { loginToMonolith, logoutFromMonolith } from 'Shared/services/monolith/api/monolithApiService';
import { navigateToSignInPage } from 'Shared/services/routingService';
import { initSessionCache } from 'Shared/services/sessionCacheService';
import { getAccessTokenUsingRefresh } from 'Shared/services/userActivation/api/idpApiService';

const AUTH_TOKEN_CHANGE_EVENT = 'auth-token-change';

class AuthTokenTarget extends EventTarget {}

const authTokenTarget = new AuthTokenTarget();

/**
 * Decoded IDP access token
 * @see https://wiki.disqo.us/display/MEG/Survey+Junkie+Identity+Provider+Service+%28SJ-IDP%29+v2.0+Technical+Specifications+Document#SurveyJunkieIdentityProviderService(SJIDP)v2.0TechnicalSpecificationsDocument-TokenSchema
 */
type DecodedAccessTokenModel = {
  ver: string,
  uid: number,
  cid: string,
  sub: string,
  iat: number,
  exp: number,
  iss: string,
  aud: string,
  scp: string[]
}

/**
 * Decoded IDP refresh token
 * @see https://wiki.disqo.us/display/MEG/Survey+Junkie+Identity+Provider+Service+%28SJ-IDP%29+v2.0+Technical+Specifications+Document#SurveyJunkieIdentityProviderService(SJIDP)v2.0TechnicalSpecificationsDocument-TokenSchema
 */
type DecodedRefreshTokenModel = {
  ver: string,
  uid: number,
  cid: string,
  sub: string,
  iat: number,
  exp: number,
  iss: string,
  aud: string,
  sid: string,
  scp: string[]
}

let accessTokenPromise:Promise<void> | null = null;

/**
 * Decodes the JWT Token
 * @param token
 * @returns
 */
const decodeJwt = (token: string) => {
  if (!token) {
    return null;
  }

  try {
    const base64Url   = token.split('.')[1];
    const base64      = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    logError('decodeJwt', error);
    return null;
  }
};

/**
 * Purge auth cookies
 */
const purgeAuthCookies = (): void => {
  deleteAccessTokenCookie();
  deleteRefreshTokenCookie();
  deleteUserIdCookie();
  deleteMonolithCookie();
  logoutFromMonolith();
  purgeAppLocalStorage();
};

/**
 * Purge all cookies
 */
const purgeAppCookies = (): void => {
  // Clear all unpersisted the application cookies
  Object.values(COOKIE_IDS)
  .filter(cookieId => !PERSISTED_COOKIE_IDS.includes(cookieId))
  .forEach(cookie => {
    deleteCookie(cookie, true);
  });

  recheckLoginStatus();
};

/**
 * Purge selected keys from local storage
 */
const purgeAppLocalStorage = (): void => {
  // Clear selected keys from local storage
  const keysToRemove = [
    LOCAL_STORAGE_IDS.SURVEY_LIST,
    LOCAL_STORAGE_IDS.UNANSWERED_QUESTIONS,
    LOCAL_STORAGE_IDS.SPLIT_TEST_CACHE,
    LOCAL_STORAGE_IDS.SURVEY_QUAL_DETAILS,
    LOCAL_STORAGE_IDS.LAST_SURVEY_REQUEST_TIME,
    LOCAL_STORAGE_IDS.RELEVANT_ID_STATUS,
    LOCAL_STORAGE_IDS.QUESTION_SUPPRESSION_LIST,
    LOCAL_STORAGE_IDS.AUTO_EMAIL_SESSION_ID,
    LOCAL_STORAGE_IDS.SURVEY_MEDLEY_SESSION_TRIGGER,
    LOCAL_STORAGE_IDS.EMAIL_CONFIRMATION_REQUEST
  ];

  keysToRemove.forEach(key => {
    deleteLocalStorage(key);
  });
};

/**
 * Logs user out by deleting IDP access/refresh
 * tokens and redirects user back to homepage
 */
const logUserOut = (autoLogout: boolean, authError = false, errorCode?: number, eventSource?: string): void => {
  analyticsTrackLogoutStart(autoLogout, errorCode, eventSource);

  if (authError) {
    sendAppMessage({
      event     : MobileAppEvent.error,
      error_code: MobileAppErrors.invalidAuth
    });
  }

  // Stopping all outgoing pending requests
  window.stop();

  // Clear cookies
  purgeAppCookies();

  // Clear keys from the local storage
  purgeAppLocalStorage();

  // Redirect to sign in page
  navigateToSignInPage(errorCode);
};

/**
 * Logs UnderAge(below 18 years) user out by deleting IDP access/refresh
 */
const logUnderAgeUserOut = (): void => {
  purgeAppCookies();
};

/**
 * Retrieve access token via either cookie or call to IDP
 */
const renewAccessToken = (): Promise<void> => {
  if (accessTokenPromise) {
    return accessTokenPromise;
  }

  if (isInMobileApp()) {
    return Promise.resolve();
  }

  accessTokenPromise = new Promise<void>((resolve, reject) => {
    const cookieRefreshToken = getRefreshTokenCookie();

    // If the refresh token doesn't exist then
    // there isn't much to do
    if (cookieRefreshToken === '') {
      reject(null);
      return;
    }

    // Try to get access token from IDP
    getAccessTokenUsingRefresh(cookieRefreshToken).then((response) => {
      const data         = response.data;
      const accessToken  = data.access_token;
      const refreshToken = data.refresh_token;

      if (accessToken) {
        setAccessTokenCookie(accessToken);
      }

      if (refreshToken) {
        setRefreshTokenCookie(refreshToken);
      }

      loginToMonolith();

      resolve();
    })
    .catch((error) => {
      // Log error and then when it completes, log the user out
      logError('getAccessTokenUsingRefresh', error);
      logUserOut(true, true, undefined, ANALYTICS_EVENT_SOURCE.expired_token);
      reject(error);
    }).finally(() => {
      accessTokenPromise = null;
    });
  });

  return accessTokenPromise;
};


/**
* Checks whether the access token cookie exists in the cookies
*/
const hasAuthToken = (): boolean => {
  return !!getAccessTokenCookie() && !!getRefreshTokenCookie();
};

/*
* Checks whether the refresh token cookie exists in the cookies
*/
const hasRefreshToken = (): boolean => {
  return !!getRefreshTokenCookie();
};

/**
 * Sets the refresh token
 */
const setRefreshTokenCookie = (refreshToken: string): void => {
  const decodedRefreshToken: DecodedRefreshTokenModel = decodeJwt(refreshToken);

  if (!decodedRefreshToken) {
    return;
  }

  const refreshTokenExpiration = convertMillisecondsToDays((convertSecondsToMilliseconds(decodedRefreshToken.exp)) - Date.now());

  setCookie(COOKIE_IDS.IDP_REFRESH, refreshToken, refreshTokenExpiration, true);

  // Initialize the session cache with the new session id from the refresh token
  initSessionCache();

  recheckLoginStatus();
};

/**
 * Gets the refresh token
 */
const getRefreshTokenCookie = (): string => {
  return getCookie(COOKIE_IDS.IDP_REFRESH);
};

/**
 * Delete the refresh token
 */
const deleteRefreshTokenCookie = (): void => {
  deleteCookie(COOKIE_IDS.IDP_REFRESH, true);
  recheckLoginStatus();
};

/**
 * Sets the access token
 */
const setAccessTokenCookie = (accessToken: string): void => {
  const decodedAccessToken: DecodedAccessTokenModel = decodeJwt(accessToken);

  if (!decodedAccessToken) {
    return;
  }

  const accessTokenExpiration = convertMillisecondsToDays((convertSecondsToMilliseconds(decodedAccessToken.exp)) - Date.now());

  setCookie(COOKIE_IDS.IDP_ACCESS, accessToken, accessTokenExpiration, true);
  recheckLoginStatus();
};

/**
 * Gets the access token
 */
const getAccessTokenCookie = (): string => {
  return getCookie(COOKIE_IDS.IDP_ACCESS);
};

/**
 * Delete the access token
 */
const deleteAccessTokenCookie = (): void => {
  deleteCookie(COOKIE_IDS.IDP_ACCESS, true);
  recheckLoginStatus();
};

/**
 * Gets the monolith cookie
 */
const getMonolithCookie = (): string => {
  return getCookie(COOKIE_IDS.MONOLITH_LOGIN);
};

/**
 * Assigns the monolith cookie to the current user ID
 */
const setMonolithCookie = (): void => {
  const ONE_MINUTES_IN_DAY = 0.00069444; // 3 / 60 / 24
  setCookie(COOKIE_IDS.MONOLITH_LOGIN, getUserId(), ONE_MINUTES_IN_DAY, true);
};

/**
 * Delete the user id
 */
const deleteMonolithCookie = (): void => {
  deleteCookie(COOKIE_IDS.MONOLITH_LOGIN, true);
};

const getAccessTokenPayload = (optional = false): DecodedAccessTokenModel | null => {
  const accessToken = getAccessTokenCookie();

  if (!accessToken) {
    if (!optional) {
      logUserOut(true, true, undefined, ANALYTICS_EVENT_SOURCE.missing_auth);
    }

    return null;
  }

  const decodedAccessToken: DecodedAccessTokenModel = decodeJwt(accessToken);

  return decodedAccessToken;
};

const getRefreshTokenPayload = (optional = false): DecodedRefreshTokenModel | null => {
  const refreshToken = getRefreshTokenCookie();

  if (!refreshToken) {
    if (!optional) {
      logUserOut(true, true, undefined, ANALYTICS_EVENT_SOURCE.missing_auth);
    }

    return null;
  }

  const decodedRefreshToken: DecodedRefreshTokenModel = decodeJwt(refreshToken);

  return decodedRefreshToken;
};

/**
 * Gets the user id
 */
const getUserId = (optional = false): string => {
  const decodedAccessToken = getAccessTokenPayload(optional);

  if (decodedAccessToken && decodedAccessToken.uid) {
    return decodedAccessToken.uid + '';
  } else {
    if (!optional) {
      logUserOut(true, true, undefined, ANALYTICS_EVENT_SOURCE.missing_auth);
    }

    return '';
  }
};

/**
 * Delete the user id
 */
const deleteUserIdCookie = (): void => {
  deleteCookie(COOKIE_IDS.USER_ID, true);
};

/**
 * Renew the access token if it was
 * issued more than 30 minutes ago
 */
const renewAccessTokenPreemptively = () => {
  // Don't renew the access token
  // for mobile app since they handle
  // their own access tokens.
  if (isInMobileApp()) {
    return;
  }

  const accessToken = getAccessTokenCookie();

  if (!accessToken) {
    return;
  }

  const decodedAccessToken: DecodedAccessTokenModel = decodeJwt(accessToken);

  if (!decodedAccessToken) {
    return;
  }

  const timeSinceTokenIssued        = Date.now() - convertSecondsToMilliseconds(decodedAccessToken.iat);
  const thirtyMinutesInMilliseconds = 30 * 60 * 1000;

  if (timeSinceTokenIssued > thirtyMinutesInMilliseconds) {
    renewAccessToken();
  }
};

/**
 * Get the session id
 */
const getSessionId = (): string => {
  const refreshTokenPayload = getRefreshTokenPayload(true);

  if (!refreshTokenPayload) {
    return '';
  }

  return refreshTokenPayload.sid || '';
};

function recheckLoginStatus () {
  if (typeof CustomEvent !== 'undefined') {
    authTokenTarget.dispatchEvent(new CustomEvent(AUTH_TOKEN_CHANGE_EVENT));
  }
}

// Triggers the login check in case the
// cookie was updated in the background.
const loginCheckInterval: ReturnType<typeof setInterval> = setInterval(recheckLoginStatus, 250);

// Function to clear the login check interval
const clearLoginCheckInterval = (): void => {
  if (loginCheckInterval) {
    clearInterval(loginCheckInterval);
  }
};

const useIsLoggedIn = (): boolean => {
  const [isLoggedIn, setIsLoggedIn] = useState(hasAuthToken());

  useEffect(() => {
    const updateLoginStatus = () => setIsLoggedIn(hasAuthToken());

    authTokenTarget.addEventListener(AUTH_TOKEN_CHANGE_EVENT, updateLoginStatus);

    return () => {
      authTokenTarget.removeEventListener(AUTH_TOKEN_CHANGE_EVENT, updateLoginStatus);
    };
  }, []);

  return isLoggedIn;
};

export {
  clearLoginCheckInterval,
  DecodedAccessTokenModel,
  DecodedRefreshTokenModel,
  decodeJwt,
  deleteAccessTokenCookie,
  deleteRefreshTokenCookie,
  deleteUserIdCookie,
  getAccessTokenCookie,
  getAccessTokenPayload,
  getMonolithCookie,
  getRefreshTokenCookie,
  getRefreshTokenPayload,
  getSessionId,
  getUserId,
  hasAuthToken,
  hasRefreshToken,
  logUnderAgeUserOut,
  logUserOut,
  purgeAppLocalStorage,
  purgeAuthCookies,
  renewAccessToken,
  renewAccessTokenPreemptively,
  setAccessTokenCookie,
  setMonolithCookie,
  setRefreshTokenCookie,
  useIsLoggedIn,
};
