import { logError } from 'App/services/coralogixService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';
import { getDynamicScreenerTestData } from 'Shared/services/opa/app/dynamicScreenerSplitTest';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import { getDynamicScreeningQuestions } from 'Shared/services/usp/api/uspApiService';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';
import { USPAnswerType,UspUnansweredQuestions } from 'Shared/services/usp/models/UspUnansweredQuestionsModel';
import useSWR from 'swr';

import { DYNAMIC_SCREENER_QUESTION_KEY } from './questionService';

export interface DynamicScreenerQuestion {
  id         : string;
  question   : string;
  displayMode: {
    type: 'SINGLE_CHOICE' | 'MULTI_CHOICE';
  };
  answers: Array<{
    id   : string;
    label: string;
  }>;
}

interface DynamicScreenerCache {
  updatedAt: number;
  questions: {
    [providerId: number]: DynamicScreenerQuestion[];
  };
}

const CACHE_INTERVAL = 10 * 60 * 1000; // 10 minutes in milliseconds

/**
 * Checks if the cache is stale (older than CACHE_INTERVAL)
 */
export const isCacheStale = (cache: DynamicScreenerCache | null): boolean => {
  if (!cache?.updatedAt) {
    return true;
  }

  return Date.now() - cache.updatedAt > CACHE_INTERVAL;
};

/**
 * Fetches dynamic screening questions for all enabled providers
 */
export const getDynamicScreenerQuestions = async (): Promise<void> => {
  const { enabled, providerIds } = getDynamicScreenerTestData();

  if (!enabled || providerIds.length === 0) {
    return;
  }

  const cache = getLocalStorage<DynamicScreenerCache>(LOCAL_STORAGE_IDS.DYNAMIC_SCREENER_QUESTIONS, true);

  if (!isCacheStale(cache)) {
    return;
  }

  const newCache: DynamicScreenerCache = {
    updatedAt: Date.now(),
    questions: {}
  };

  await Promise.all(
    providerIds.map(async (providerId) => {
      try {
        const response = await getDynamicScreeningQuestions(providerId);
        if (response.data?.questions) {
          newCache.questions[providerId] = response.data.questions;
        }
      } catch (error: unknown) {
        logError('getDynamicScreenerQuestions', error);
      }
    })
  );

  setLocalStorage(LOCAL_STORAGE_IDS.DYNAMIC_SCREENER_QUESTIONS, newCache, true);
};

/**
 * Gets cached dynamic screener questions for a specific provider
 */
export const getCachedDynamicScreenerQuestions = (providerId: number): DynamicScreenerQuestion[] | null => {
  const cache = getLocalStorage<DynamicScreenerCache>(LOCAL_STORAGE_IDS.DYNAMIC_SCREENER_QUESTIONS, true);
  return cache?.questions?.[providerId] || null;
};

/**
 * Hook to fetch dynamic screener questions
 */
const fetcher = async () => {
  await getDynamicScreenerQuestions();
};

export const useGetDynamicScreenerQuestions = () => {
  const { data, error } = useSWR(SwrKeys.DynamicScreenerQuestions, fetcher, {
    revalidateOnFocus: true,
    refreshInterval  : CACHE_INTERVAL,
  });

  return {
    isLoading: !error && !data,
    isError: error,
  };
};

/**
 * Maps dynamic screener questions to USP format
 */
export const mapDynamicScreenerQuestions = (
  questions: DynamicScreenerQuestion[],
  providerId: number
): UspUnansweredQuestions[] => {
  return questions
    .filter(q => q.answers && q.answers.length > 0)
    .map(question => ({
      id                  : Number(question.id),
      inputTypeId         : 1,
      key                 : `${DYNAMIC_SCREENER_QUESTION_KEY}${question.id}`,
      question            : question.question,
      answerType          : USPAnswerType.TEXT,
      multiValue          : question.displayMode.type.toUpperCase() === 'MULTI_CHOICE',
      hasPredefinedAnswers: true,
      enabled             : true,
      sortOrder           : 100,
      predefinedAnswers   : question.answers.map(answer => ({
        id         : Number(answer.id),
        questionId : Number(question.id),
        label      : answer.label,
        sortOrder  : Number(answer.id),
        enabled    : true,
        answerValue: Number(answer.id),
        solo       : false
      })),
      //for dynamic screener questions, im using categoryId to store the providerID which is later needed to save the answers
      categoryId  : Number(`${providerId}`),
      required    : true,
      typeId      : 0,
      categoryName: '',
      criteriaKey : undefined
    }));
};

/**
 * Maps dynamic screener questions to unanswered questions format
 * @param survey
 * @returns
 */
export const mapDynamicScreenerQuestionsToUnansweredQuestions = (
  survey: UspSurveysModel
): UspUnansweredQuestions[] => {

  //exit early if the test is disabled, no provider IDs are set,
  //or the survey has no unansweredDynamicQuestionIds
  if (!survey.unansweredDynamicQuestionIds || survey.unansweredDynamicQuestionIds.length === 0) {
    return [];
  }

  //retrieve dynamic screener questions for this provider
  const dynamicQuestions = getCachedDynamicScreenerQuestions(survey.providerId) || [];

  //filter only the questions that are still unanswered
  const filteredDynamicQuestions = dynamicQuestions.filter(question =>
    survey.unansweredDynamicQuestionIds.includes(question.id)
  );

  if (filteredDynamicQuestions.length === 0) {
    return [];
  }

  //map questions to UPM/USP format needed for the questionnaire popup
  return mapDynamicScreenerQuestions(filteredDynamicQuestions, survey.providerId);
};
