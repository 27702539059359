import React from 'react';
import { useTranslation } from 'react-i18next';
import { COOKIE_IDS } from 'App/constants';
import { ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import {
  SSO_BUTTON_TEST_IDS,
  SSOButtonOptionsByProvider,
  SSOButtonProps
} from 'Shared/components/design/SSOButton/constants';
import { SSOButtonBtn } from 'Shared/components/design/SSOButton/style';
import { setCookie } from 'Shared/services/cookieService';
import { openSSOProviderWindow } from 'Shared/utils/SSOUtils';

/**
 * SSO Button component
 */
const SSOButton: React.FC<SSOButtonProps> = ({
  provider,
  onClick,
  ...props
}) => {
  const { t } = useTranslation('signIn');

  // Setting default values
  const {
    width = ButtonWidth.FULL,
    size  = ButtonSizes.LARGE,
  } = props;

  // Getting provider options
  const {
    name: providerName,
    logoUri,
    ...options
  } = SSOButtonOptionsByProvider[provider];

  /**
   * Click handler
   */
  const handleClick = (): void => {
    if (onClick) {
      onClick?.();
    }

    // Setting forceV3SignUp cookie with an expiration date of 1 day
    setCookie(COOKIE_IDS.FORCE_TO_V3_FOR_NEW_SIGNUP, '1', 1, true);

    // Opening login popup
    openSSOProviderWindow(provider);
  };

  return <SSOButtonBtn
    size       ={size}
    width      ={width}
    options    ={options}
    onClick    ={handleClick}
    data-testid={SSO_BUTTON_TEST_IDS.CONTAINER}
    {...props}
  >
    <img src={logoUri} alt={providerName} />
    {t('SignInForm--SSOButton--text', { providerName })}
  </SSOButtonBtn>;
};

export default SSOButton;