import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinnerContainer, LoadingWrapper } from 'App/components/loading/style';
import { APP_LOADING_TESTID } from 'App/components/testId';
import LoadingSpinner from 'Shared/components/design/loadingSpinner/LoadingSpinner';

interface Props {
  className   ?: string
  loadingText ?: string,
}

function Loading ({ className, loadingText }: Props): JSX.Element {
  const {t} = useTranslation('app');

  return (
    <LoadingWrapper className={className} data-testid={APP_LOADING_TESTID}>
      <LoadingSpinnerContainer>
        <LoadingSpinner speed={1.5} />
      </LoadingSpinnerContainer>
      {loadingText || t('loading--loading-text')}
    </LoadingWrapper>
  );
}

export default Loading;
