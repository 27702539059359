import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PAGE_MEMBER, RouteQueryParam } from 'App/routes/constants';
import { sendGpCompleteBrazeEvent } from 'App/services/brazeService';
import { logError } from 'App/services/coralogixService';
import { CUSTOM_QUESTION_KEYS, getUpmCategoryIds, MAX_NUMBER_OF_CHILDREN, NUMBER_OF_CHILDREN_QUESTION_KEY } from 'Pages/help/constants';
import { addQuestionnaireStartEvent } from 'Pages/help/routes/addEvents';
import { dispatchQuestionnaireCompleteEvent, dispatchQuestionnaireExitEvent } from 'Pages/help/routes/dispatchEvents';
import { SURVEY_CALLBACK_SCREENS } from 'Pages/surveyCallback/constants';
import { SurveyCallbackStatuses } from 'Pages/surveyCallback/models/SurveyCallbackModels';
import SurveyModal from 'Pages/surveyCallback/SurveyModal';
import { removeSurveyById, removeUnansweredQuestionByKey, SURVEY_REQUEST_REFERRER, useRefetchSurveys} from 'Pages/surveyExperience/services/surveyService';
import { Button, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import LoadingSpinner from 'Shared/components/design/loadingSpinner/LoadingSpinner';
import { AnswerModel } from 'Shared/components/design/questionnairePopup/AnswerModel';
import ChildrenQuestion from 'Shared/components/design/questionnairePopup/childrenQuestion/ChildrenQuestion';
import { QuestionnaireContextTypes } from 'Shared/components/design/questionnairePopup/constants';
import Header from 'Shared/components/design/questionnairePopup/header/Header';
import MultiSelect from 'Shared/components/design/questionnairePopup/multiSelect/MultiSelect';
import NavButtons from 'Shared/components/design/questionnairePopup/navButtons/NavButtons';
import QualificationPrompt from 'Shared/components/design/questionnairePopup/qualificationPrompt/QualificationPrompt';
import { QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { QuestionnaireEventProps } from 'Shared/components/design/questionnairePopup/QuestionnaireEventModels';
import QuestionTracker from 'Shared/components/design/questionnairePopup/questionTracker/QuestionTracker';
import SingleSelect from 'Shared/components/design/questionnairePopup/singleSelect/SingleSelect';
import {
  ButtonsContainer,
  ContentContainer,
  CurrentPoints,
  Description,
  Divider,
  LoadingSpinnerContainer,
  ModalBackdrop,
  ModalContent,
  PopupBackground,
  QuestionnaireCompleteContainer,
  QuestionnaireModalContainer,
  QuestionsContainer,
  StepperContainer,
  StepperTitle,
  SuccessIconContainer,
  Title
} from 'Shared/components/design/questionnairePopup/style';
import SurveyPromptHeader from 'Shared/components/design/questionnairePopup/surveyPromptHeader/SurveyPromptHeader';
import { LOADING_SPINNER_TEST_ID, QUESTIONNAIRE_POPUP_TEST_ID, QUESTIONNAIRE_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import Stepper, { StepperState, StepperType } from 'Shared/components/design/stepper/Stepper';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { BASIC_PROFILE_POINTS_REWARD } from 'Shared/constants';
import usePopup from 'Shared/hooks/usePopup';
import useScrollStop from 'Shared/hooks/useScrollStop';
import { UPMPredefinedAnswer } from 'Shared/models/swagger/upm';
import { ANALYTICS_EVENT_ATTRIBUTES, ANALYTICS_EVENT_SUB_TYPE, ANALYTICS_RESPONSE_CODES, ANALYTICS_RESPONSE_MESSAGE } from 'Shared/services/analytics/constants';
import { analyticsTrackQuestionnaireEnd } from 'Shared/services/analytics/events/questionnaireEnd';
import { analyticsTrackQuestionnaireStart } from 'Shared/services/analytics/events/questionnaireStart';
import { analyticsTrackQuestionnaireStepEnd } from 'Shared/services/analytics/events/questionnaireStepEnd';
import { analyticsTrackQuestionnaireStepStart } from 'Shared/services/analytics/events/questionnaireStepStart';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { navigateToExternalSurvey, navigateToMemberPage } from 'Shared/services/routingService';
import { getOutgoingSurveyUrl } from 'Shared/services/surveyRouting/app/surveyRoutingService';
import { addExpectingChildAnswer, canSkipChildrenQuestion, getNumberOfChildrenAnswer, postQuestionAnswers, useGetCategoryData } from 'Shared/services/userActivation/app/questionService';
import { getIsQualifiedForSurvey, getSurveyEligibility, triggerSurveyURLSilently } from 'Shared/services/usp/api/uspApiService';

interface QuestionnairePopupProps {
  initialAnswers ?: AnswerModel[];
  onSave         ?: () => void;
  onError        ?: () => void;
  onInit         ?: () => void;
}

export enum SurveyEligibilityStatus {
  NO_STATUS,
  ELIGIBLE,
  NOT_ELIGIBLE
}

/**
 * Sets up eventListener and renders the Questionnaire Popup
 */
function QuestionnairePopup ({ initialAnswers, onSave, onError, onInit }: QuestionnairePopupProps): JSX.Element {
  // Hooks
  const navigate = useNavigate();

  // State
  const [popupActive, setPopupActive]                                            = useState<boolean>(false);
  const [questionnaireComplete, setQuestionnaireComplete]                        = useState<boolean>(false);
  const [inputQuestions, setInputQuestions]                                      = useState<QuestionPopupModel[]>([]);
  const [initialInputQuestions, setInitialInputQuestions]                        = useState<QuestionPopupModel[]>([]);
  const [savedAnswers, setSavedAnswers]                                          = useState<AnswerModel[]>(initialAnswers || []);
  const [currentAnswer, setCurrentAnswer]                                        = useState<AnswerModel | null>(null);
  const {activeSlide, nextSlide, previousSlide, setMaxSlides, forceSlide, reset} = usePopup(0);
  const [lastQuestionKey, setLastQuestionKey]                                    = useState<string>('');
  const [currentCount, setCurrentCount]                                          = useState<number>(activeSlide);
  const QUESTION_CONTAINER                                                       = 'question-container';
  const popupBackgroundRef                                                       = useRef<HTMLDivElement>(null);
  const [surveyUrl, setSurveyUrl]                                                = useState<string | null>(null);
  const [isQualificationPromptShown, setIsQualificationPromptShown]              = useState<boolean>(false);
  const { t }                                                                    = useTranslation('questionnairePopup');
  const [isSurveyMedley, setIsSurveyMedley]                                      = useState<boolean>(false);
  const [isGPEditingQuestionnaire, setIsGPEditingQuestionnaire]                  = useState<boolean>(false);
  const [isSurveyQualification, setIsSurveyQualification]                        = useState<boolean>(false);
  const [isModal, setIsModal]                                                    = useState<boolean>(false);
  const [isGeneralProfile, setIsGeneralProfile]                                  = useState<boolean>(false);
  const [showQuestionTracker, setShowQuestionTracker]                            = useState<boolean>(true);
  const [isProcessingAnswers, setIsProcessingAnswers]                            = useState<boolean>(false);
  const [surveyId, setSurveyId]                                                  = useState<string>('');
  const [surveyProviderId, setSurveyProviderId]                                  = useState<number | null>(null);
  const [surveyStatus, setSurveyStatus]                                          = useState<SurveyCallbackStatuses>(SurveyCallbackStatuses.UNEXPECTED_ERROR);
  const [surveyEligibilityStatus, setSurveyEligibilityStatus]                    = useState<SurveyEligibilityStatus>(SurveyEligibilityStatus.NO_STATUS);
  const [isSilentAttemptTriggered, setIsSilentAttemptTriggered]                  = useState(false);
  const [isCheckingEligibility, setIsCheckingEligibility]                        = useState<boolean>(false);
  const [hideHeaderInModal, setHideHeaderInModal]                                = useState<boolean>(false);
  const [isDynamicScreener, setIsDynamicScreener]                                = useState<boolean>(false);
  const analyticsQuestionnaireSubType                                            = (isSurveyQualification && ANALYTICS_EVENT_SUB_TYPE.survey_qualification)      || (isSurveyMedley && ANALYTICS_EVENT_SUB_TYPE.survey_medley)       || ANALYTICS_EVENT_SUB_TYPE.general_profile;
  const analyticsQuestionnaireStepSubType                                        = (isSurveyQualification && ANALYTICS_EVENT_SUB_TYPE.survey_qualification_step) || (isSurveyMedley && ANALYTICS_EVENT_SUB_TYPE.survey_medley_step)  || ANALYTICS_EVENT_SUB_TYPE.general_profile_step;
  const shouldRefetchSurveys                                                     = isSurveyMedley || isSurveyQualification;
  const refetchSurveys                                                           = useRefetchSurveys();
  const { data: gpCategoryData, isLoading: gpDataLoading }                       = useGetCategoryData(getUpmCategoryIds().GENERAL_PROFILE);
  // if its a modal and survey url is present, check survey eligibility when questionnaire is complete
  const surveyEligibilityEnabled                                                 = isModal && surveyUrl;
  // disable scroll when popup is active and its modal
  useScrollStop(isModal);

  /**
   * Reset Component States on mount/unmount
   */
  const resetStates = useCallback(() => {
    // reset only when popup is closed
    if (!popupActive) {
      reset();// resets usePopup hook state
      setInputQuestions([]);
      setInitialInputQuestions([]);
      setSavedAnswers([]);
      setSurveyUrl(null);
      setSurveyId('');
      setCurrentAnswer(null);
      setIsQualificationPromptShown(false);
      setIsSurveyMedley(false);
      setIsGPEditingQuestionnaire(false);
      setIsSurveyQualification(false);
      setSurveyEligibilityStatus(SurveyEligibilityStatus.NO_STATUS);
      setSurveyProviderId(null);
      setIsModal(false);
      setPopupActive(false);
      setHideHeaderInModal(false);
      setSurveyStatus(SurveyCallbackStatuses.UNEXPECTED_ERROR);
      setIsDynamicScreener(false);
    }
  },[popupActive, reset]);

  /**
   * React hook to set up event listener
   */
  useLayoutEffect(() => {
    const eventListenerCallback = async (event: QuestionnaireEventProps) => {

      // reset states on mount, since the questionnaire can be triggered multiple times without page reload
      resetStates();

      const redirectOnError = (): void => {
        // Go to the survey if there's an error and they are
        // here for survey qualification.
        if (event.isSurveyQualification && event.surveyUrl) {
          navigateToExternalSurvey(event.surveyUrl, false, true);
          if (isModal) {
            if (surveyUrl && surveyId !== '') {
              removeSurveyById(surveyId);
            }

            resetStates();
          }
        } else {
          // we are doing a hard refresh here by not passing
          // navigate to navigateToMemberPage so that
          // we are able to do a fresh reload of the page
          // in case it was caused by any errors.
          navigateToMemberPage();
        }
      };

      try {
        const questions: QuestionPopupModel[] = event.questions;

        if (!questions || questions.length === 0) {
          // In case of no questions, redirect to the home page
          redirectOnError();
          return;
        }

        setInputQuestions(event.questions);
        setInitialInputQuestions(event.questions);
        setMaxSlides(event.questions.length);
        setLastQuestionKey(event.questions[event.questions.length - 1].key);
        setSurveyUrl(event.surveyUrl ?? null);
        setSurveyId(event.surveyId ?? '');
        setIsSurveyQualification(event.isSurveyQualification ?? false);
        setIsQualificationPromptShown(event.isSurveyQualification ?? false);
        setIsModal(event.isModal ?? false);
        setIsGeneralProfile(event.isGeneralProfile ?? false);
        analyticsTrackQuestionnaireStart({
          [ANALYTICS_EVENT_ATTRIBUTES.sub_type]   : (event.isSurveyQualification && ANALYTICS_EVENT_SUB_TYPE.survey_qualification) || (event.isSurveyMedley && ANALYTICS_EVENT_SUB_TYPE.survey_medley) || ANALYTICS_EVENT_SUB_TYPE.general_profile,
          [ANALYTICS_EVENT_ATTRIBUTES.total_steps]: event.questions.length
        });
        setIsSurveyMedley(!!event.isSurveyMedley);
        setIsGPEditingQuestionnaire(!!event.isGPQuestionnaireEdit);
        setShowQuestionTracker(event.showQuestionTracker ?? true);
        setQuestionnaireComplete(false);
        setSurveyProviderId(event.surveyProviderId ?? null);
        setIsDynamicScreener(event.isDynamicScreener ?? false);
        if (!event.isGPQuestionnaireEdit) {
          setPopupActive(true);
        }

        if (event.isModal) {
          setIsQualificationPromptShown(false);
        }
      } catch (error) {
        // In case of an error redirecting to the home page
        redirectOnError();
        logError('QuestionnaireStartEvent', error);
      }
    };

    addQuestionnaireStartEvent(eventListenerCallback);

    if (onInit && typeof onInit === 'function') {
      onInit();
    }
  }, [setMaxSlides, onInit, activeSlide, nextSlide, previousSlide, forceSlide, reset, isModal, resetStates, surveyUrl, surveyId]);

  /**
   * Trigger silently only after eligibility is checked
   */
  const handleSilentSurveyAttempt = useCallback((surveyUrl) => {
    if (!isSilentAttemptTriggered ) {
      triggerSurveyURLSilently(surveyUrl);
      setIsSilentAttemptTriggered(true);
    }
  }, [isSilentAttemptTriggered]);

  /**
   * Normalize the count of questions shown
   */
  useEffect(() => {
    const childQuestionIndex = inputQuestions.findIndex(question => question.key == CUSTOM_QUESTION_KEYS.CHILDREN);

    if (activeSlide >= childQuestionIndex) {
      setCurrentCount(activeSlide);
    }
    else {
      setCurrentCount(activeSlide + 1);
    }

    let numberOfQuestions = inputQuestions.length;
    if (numberOfQuestions && analyticsQuestionnaireStepSubType) {
      if (analyticsQuestionnaireStepSubType !== ANALYTICS_EVENT_SUB_TYPE.survey_medley_step) {
        numberOfQuestions = inputQuestions.length - 1;
      }

      if (activeSlide > 0) {
        analyticsTrackQuestionnaireStepEnd({
          [ANALYTICS_EVENT_ATTRIBUTES.sub_type]    : analyticsQuestionnaireStepSubType,
          [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : numberOfQuestions,
          [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : inputQuestions[activeSlide - 1]?.key,
          [ANALYTICS_EVENT_ATTRIBUTES.current_step]: activeSlide
        });
      }

      analyticsTrackQuestionnaireStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.sub_type]    : analyticsQuestionnaireStepSubType,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : numberOfQuestions,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : inputQuestions[activeSlide]?.key,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: activeSlide + 1
      });
    }
  }, [activeSlide, inputQuestions, analyticsQuestionnaireStepSubType]);

  // Handle the case where the user selects number of children in GP edit questionnaire
  useEffect(() => {
    if (!isGPEditingQuestionnaire || currentAnswer) {
      return;
    }

    // Check if the active question is not the one regarding the number of children, and exit if so.
    if (inputQuestions[activeSlide].key !== NUMBER_OF_CHILDREN_QUESTION_KEY) {
      return;
    }

    // Find the answer related to the number of children from the initial answers array.
    const selectedNumberOfChildrenAnswer = initialAnswers?.find((answer) => answer.questionKey === NUMBER_OF_CHILDREN_QUESTION_KEY);
    if (!selectedNumberOfChildrenAnswer) {
      return;
    }

    // Set the current answer to the selected number of children.
    setCurrentAnswer(selectedNumberOfChildrenAnswer);

    // Find the answer related to children's gender and age from the initial answers array.
    const childrenGenderAndAgeAnswers = initialAnswers?.find((answer) => answer.questionKey === CUSTOM_QUESTION_KEYS.CHILDREN);

    // Actual number of children selected by the user.
    const numberOfChildren = getNumberOfChildrenAnswer([selectedNumberOfChildrenAnswer]) || 0;

    // If the number of children is not valid or exceeds the maximum allowed, reset to the initial question.
    if (!numberOfChildren || numberOfChildren > MAX_NUMBER_OF_CHILDREN) {
      setSavedAnswers([selectedNumberOfChildrenAnswer]);
      setInputQuestions([inputQuestions[0]]);
      return;
    }

    // If an answer for children's gender and age exists, update it and set the saved answers.
    if (childrenGenderAndAgeAnswers) {
      childrenGenderAndAgeAnswers.multiValue = [];
      setSavedAnswers([selectedNumberOfChildrenAnswer, childrenGenderAndAgeAnswers]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGPEditingQuestionnaire]);

  /**
   * Detect scrolling
   */
  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const target = document.getElementById(QUESTION_CONTAINER);
          target?.scrollTo(window.scrollX, window.scrollY);
          ticking = false;
        });

        ticking = true;
      }
    };

    //listening for scroll event with throttling logic
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * Track questionnaire end event
   */
  const trackQuestionnaireEnd = useCallback((responseCode: ANALYTICS_RESPONSE_CODES, responseMessage: ANALYTICS_RESPONSE_MESSAGE) => {
    analyticsTrackQuestionnaireEnd({
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : analyticsQuestionnaireSubType,
      [ANALYTICS_EVENT_ATTRIBUTES.total_steps]     : inputQuestions.length,
      [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : responseCode,
      [ANALYTICS_EVENT_ATTRIBUTES.response_message]: responseMessage
    });

    let numberOfQuestions = inputQuestions.length;
    if (numberOfQuestions) {
      if (analyticsQuestionnaireStepSubType !== ANALYTICS_EVENT_SUB_TYPE.survey_medley_step) {
        numberOfQuestions = inputQuestions.length - 1;
      }

      analyticsTrackQuestionnaireStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.sub_type]    : analyticsQuestionnaireStepSubType,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : numberOfQuestions,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : inputQuestions[activeSlide].key,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: activeSlide
      });
    }
  },[activeSlide, analyticsQuestionnaireStepSubType, analyticsQuestionnaireSubType, inputQuestions]);

  /**
   * Get question answers
   */
  const filterAnsweredQuestions = (savedAnswers: AnswerModel[]): AnswerModel[] => {
    return savedAnswers.filter((answer: AnswerModel) => answer?.value?.length);
  };

  /**
   * Called ONLY in Survey Medley and Survey Qualification
   * Saves answers (if there are any) on early Survey Medley or Survey Qualification exit
   */
  const submitAnyAnswers = (skipRedirect = false): void => {
    const filteredAnswers = filterAnsweredQuestions(savedAnswers);
    if (filteredAnswers.length) {
      processPostQuestionAnswers(filteredAnswers, skipRedirect);
    }
  };

  /**
   * Dispatches event listener for exiting popup
   */
  const handlePopupExit = (): void => {
    trackQuestionnaireEnd(ANALYTICS_RESPONSE_CODES.success, ANALYTICS_RESPONSE_MESSAGE.abandoned);

    if (isSurveyMedley) {
      submitAnyAnswers();
      navigate(getSurveyMedleyRedirectUrl());
      dispatchQuestionnaireExitEvent();
      // if its survey medley in a modal, close the popup
      if (isModal) {
        setPopupActive(false);
        setQuestionnaireComplete(false);
      }
    } else if (surveyUrl) {
      if (!isModal) {
        submitAnyAnswers(true);
        location.href = getOutgoingSurveyUrl(surveyUrl, true);
      }
      // in case of a survey qualification popup test close the popup
      if (isModal) {
        setPopupActive(false);
        setQuestionnaireComplete(false);
        dispatchQuestionnaireExitEvent();
      }
    } else {
      setPopupActive(false);
      reset();
      dispatchQuestionnaireExitEvent();
    }

    resetStates();
  };

  /**
   * Does solo answer check whenever an answer is submitted
   * @returns number[]
   */
  const handleChangeFilter = (answers: (number | string)[]): (number | string)[] => {
    // These are answers which can only have a single input selection, example "Don't own any pets"
    const soloPredefinedAnswers = inputQuestions[activeSlide].data[0].predefinedAnswers.reduce(
      (soloPredefinedAnswers, predefinedAnswer: UPMPredefinedAnswer) => {
        if (predefinedAnswer.solo) {
          soloPredefinedAnswers.push(predefinedAnswer.answerValue);
        }

        return soloPredefinedAnswers;
      }
    , [] as (number | string)[]);

    const hasSoloAnswersSelected = answers.some((answerValue) => {
      return soloPredefinedAnswers.includes(answerValue);
    });

    if (hasSoloAnswersSelected) {
      const lastAnswerSubmitted = answers[answers.length - 1];

      if (soloPredefinedAnswers.includes(lastAnswerSubmitted)) {
        answers = [lastAnswerSubmitted];
      } else {
        answers = answers.filter((answerValue) => {
          return !soloPredefinedAnswers.includes(answerValue);
        });
      }
    }

    return answers;
  };

  /**
   * Returns previously answered question
   */
  const getAnsweredQuestion = useCallback((nextSlide: boolean): AnswerModel | null => {
    const slide = nextSlide ? activeSlide + 1 : activeSlide;
    const activeQuestionKey = inputQuestions[slide]?.key;
    const savedAnswer = savedAnswers.filter((answerModel: AnswerModel | null) => {
      return answerModel && answerModel.questionKey === activeQuestionKey;
    })[0];

    return savedAnswer || null;
  }, [activeSlide, inputQuestions, savedAnswers]);

  /**
   * Returns whether or not the user has answered the current slide question
   * @returns boolean
   */
  const hasAnsweredCurrentSlide = useCallback((): boolean => {
    if (!currentAnswer && inputQuestions[activeSlide]) {
      const savedAnswer = getAnsweredQuestion(false);
      if (savedAnswer?.value?.length || savedAnswer?.multiValue?.length) {
        return true;
      }
    }

    return !!(currentAnswer && (currentAnswer?.value.length || currentAnswer?.multiValue?.length));
  }, [currentAnswer, inputQuestions, activeSlide, getAnsweredQuestion]);

  /**
   * Checks if user answered all available
   * @returns
   */
  const hasAnsweredAllQuestions = useCallback((): boolean => {
    return !inputQuestions[activeSlide + 1] && hasAnsweredCurrentSlide();
  }, [activeSlide, inputQuestions, hasAnsweredCurrentSlide]);

  /**
   * Returns the redirect url
   * @returns boolean
   */
  const getSurveyMedleyRedirectUrl = useCallback((): string => {
    const filteredAnswers = filterAnsweredQuestions(savedAnswers);
    if (!filteredAnswers.length) {
      return PAGE_MEMBER;
    } else {
      return `${PAGE_MEMBER}?${RouteQueryParam.SURVEY_MEDLEY_COMPLETE}=true&${RouteQueryParam.SURVEY_MEDLEY_QUESTION_COUNT}=${savedAnswers.length}`;
    }
  }, [savedAnswers]);

  /**
   * Handles the completion of the survey qualification
   */
  const handleSurveyQualificationCompletion = useCallback(() => {
    // if survey eligibility is enabled, we will not redirect until we get the eligibility response after answers are submitted
    if (surveyEligibilityEnabled) {
      setHideHeaderInModal(true);
      return;
    }

    navigateToExternalSurvey(surveyUrl || '', false, true);
  },[surveyEligibilityEnabled, surveyUrl]);

  /**
  * Redirect on complete
  */
  const redirectAfterCompletion = useCallback((success: boolean): void => {
    if (isSurveyMedley) {
      if (success) {
        dispatchQuestionnaireExitEvent();
        navigate(getSurveyMedleyRedirectUrl());
      } else {
        navigate(PAGE_MEMBER);
      }
    } else if (isSurveyQualification) {
      handleSurveyQualificationCompletion();
    } else if (!success) {
      // we are doing a hard refresh here by not passing
      // navigate to navigateToMemberPage so that
      // we are able to do a fresh reload of the page
      // in case it was caused by any errors.
      navigateToMemberPage();
    }
  },[isSurveyMedley, isSurveyQualification, navigate, getSurveyMedleyRedirectUrl, handleSurveyQualificationCompletion]);

  /**
   * Process PostQuestionAnswer and trackGA4QuestionnaireEvent
   * @param answersArray
   * @param skipRedirect used to determine if user has answered all questions in survey medley or has it exited early
   */
  const processPostQuestionAnswers = useCallback((answersArray: AnswerModel[], skipRedirect = false) => {
    setIsProcessingAnswers(true);

    /**
     * this is used to check if user selected "expecting" for children question,
     * in which case we add ChildGenderAge answer as Child_9_DOb
     */
    const processedAnswers = addExpectingChildAnswer(answersArray);
    postQuestionAnswers({
      answers        : processedAnswers,
      isRewardable   : isSurveyMedley,
      inputQuestions : inputQuestions
    }).then(() => {
      // Trigger split tests after general profile completion
      if (isGeneralProfile) {
        sendGpCompleteBrazeEvent();
        initializeSplitTests(SplitTestInitializationLocation.AFTER_GP);
      }

      // add answered questions to suppression list
      inputQuestions.slice(0, savedAnswers.length).forEach(question => {
        question.data.forEach(datum => {
          removeUnansweredQuestionByKey(datum.key);
        });
      });

      if (shouldRefetchSurveys) {
        // refetch surveys only if in survey medley or in survey qualification
        let surveyRequestReferrer: SURVEY_REQUEST_REFERRER = SURVEY_REQUEST_REFERRER.OTHER;

        if (isSurveyMedley) {
          surveyRequestReferrer = SURVEY_REQUEST_REFERRER.SURVEY_MEDLEY;
        } else if (isSurveyQualification) {
          surveyRequestReferrer = SURVEY_REQUEST_REFERRER.SURVEY_QUALIFICATION;
        } else if (isGeneralProfile) {
          surveyRequestReferrer = SURVEY_REQUEST_REFERRER.GENERAL_PROFILE;
        } else if (isGPEditingQuestionnaire) {
          surveyRequestReferrer = SURVEY_REQUEST_REFERRER.GENERAL_PROFILE_EDIT;
        }

        refetchSurveys(surveyRequestReferrer);
      }

      // trigger this only at the end of GP
      trackQuestionnaireEnd(ANALYTICS_RESPONSE_CODES.success, ANALYTICS_RESPONSE_MESSAGE.success);

      if (!skipRedirect) {
        redirectAfterCompletion(true);
      }

      if (isModal && isSurveyQualification && hasAnsweredAllQuestions()) {
        setIsCheckingEligibility(true);

        if (isDynamicScreener) {
          getIsQualifiedForSurvey(surveyId, surveyProviderId || 0).then((response: any) => {
            if (response?.data.match === true) {
              setSurveyEligibilityStatus(SurveyEligibilityStatus.ELIGIBLE);
              dispatchQuestionnaireExitEvent();
              resetStates();
            } else {
              //If survey is not a good match I have to hardcode the pre screen out Survey Status since the response is
              // not returning the actual status code (unlike the eligibility check endpoint below)
              setSurveyStatus(SurveyCallbackStatuses.SJ_PRE_SCREEN_OUT);
              //silently trigger the survey url if user is not eligible
              if (surveyUrl) {
                handleSilentSurveyAttempt(surveyUrl);
              }
              removeSurveyById(surveyId);
              setSurveyEligibilityStatus(SurveyEligibilityStatus.NOT_ELIGIBLE);
              setQuestionnaireComplete(true);
            }
          }).catch((error: any) => {
            setSurveyEligibilityStatus(SurveyEligibilityStatus.ELIGIBLE);
            logError('getIsQualifiedForSurvey', error);
            resetStates();
          }).finally(() => {
            setIsCheckingEligibility(false);
          });
        } else {
          getSurveyEligibility(surveyId, surveyProviderId || 0).then((response: any) => {
            if (response?.data.status.status === "SUCCESS") {
              setSurveyEligibilityStatus(SurveyEligibilityStatus.ELIGIBLE);
              dispatchQuestionnaireExitEvent();
              resetStates();
            } else {
              setSurveyStatus(response.data.status.statusCode);
              //silently trigger the survey url if user is not eligible
              if (surveyUrl) {
                handleSilentSurveyAttempt(surveyUrl);
              }
              setSurveyEligibilityStatus(SurveyEligibilityStatus.NOT_ELIGIBLE);
              setQuestionnaireComplete(true);
            }
          }).catch((error: any) => {
            setSurveyEligibilityStatus(SurveyEligibilityStatus.ELIGIBLE);
            logError('getSurveyEligibility', error);
            resetStates();
          }).finally(() => {
            setIsCheckingEligibility(false);
          });
        }
      } else if (isModal) {
        setPopupActive(false);
        setQuestionnaireComplete(false);
      }

      dispatchQuestionnaireCompleteEvent();

      if (onSave) {
        onSave();
      }
    }).catch(error => {
        trackQuestionnaireEnd(ANALYTICS_RESPONSE_CODES.error, ANALYTICS_RESPONSE_MESSAGE.error);
        if (!skipRedirect) {
          redirectAfterCompletion(false);
        }

      if (onError) {
        onError();
      }
      logError('postQuestionAnswers', error);
    }).finally(() => {
      setIsProcessingAnswers(false);
    });
  },[isSurveyMedley, inputQuestions, isGeneralProfile, savedAnswers.length, shouldRefetchSurveys, trackQuestionnaireEnd, isModal, isSurveyQualification, hasAnsweredAllQuestions, onSave, isGPEditingQuestionnaire, refetchSurveys, redirectAfterCompletion, isDynamicScreener, surveyId, surveyProviderId, resetStates, surveyUrl, handleSilentSurveyAttempt, onError]);

  /**
   * Scroll to the top of popup frame after
   * the user navigates between pages.
   */
  const scrollToTop = (): void => {
    requestAnimationFrame(() => {
      if (popupBackgroundRef.current) {
        popupBackgroundRef.current.scrollTo(0, 0);
      }
    });
  };

  /**
   * Handles clicking of `next` button
   */
  const handleNextClick = (): void => {
    if (!currentAnswer) {
      return;
    }

    const newArray        = [...savedAnswers];
    newArray[activeSlide] = currentAnswer;
    setSavedAnswers(newArray);
    const nextQuestionPositionIdx = currentAnswer?.questionKey === NUMBER_OF_CHILDREN_QUESTION_KEY && canSkipChildrenQuestion(savedAnswers) ? 2 : 1;

    if (newArray[activeSlide + nextQuestionPositionIdx] !== undefined) {
      setCurrentAnswer(newArray[activeSlide + nextQuestionPositionIdx]);
    } else {
      setCurrentAnswer(null);
    }

    // Save the answers if it's the last slide
    if (!inputQuestions[activeSlide + 1]) {
      // post question answers and set questionnaire complete for regular questions
      processPostQuestionAnswers(newArray);
      if (!isModal) {
        setQuestionnaireComplete(true);
      }

      return;
    }

    // Check whether if the person can skip the child question
    if (inputQuestions[activeSlide + 1].key === CUSTOM_QUESTION_KEYS.CHILDREN && canSkipChildrenQuestion(newArray)) {
      setCurrentAnswer(savedAnswers[activeSlide + 2]);
      forceSlide(activeSlide + 2);
      return;
    }

    nextSlide();
    scrollToTop();
  };

  /**
   * Handles clicking of `previous` button
   */
  const handlePreviousClick = (): void => {
    // Check whether if the person can skip the child question
    if (inputQuestions[activeSlide - 1].key === CUSTOM_QUESTION_KEYS.CHILDREN && canSkipChildrenQuestion(savedAnswers)) {
      setCurrentAnswer(savedAnswers[activeSlide - 2]);
      forceSlide(activeSlide - 2);
    } else {
      setCurrentAnswer(savedAnswers[activeSlide - 1]);
      previousSlide();
    }
    scrollToTop();
  };

  /**
   * Sets the currently selected answer for saving when progressing to the next question
   * @param input
   */
  const selectAnswerCallback = (input: AnswerModel | null): void => {
    if (!input) {
      delete savedAnswers[activeSlide];
    } else {
      savedAnswers[activeSlide] = input;
    }

    setCurrentAnswer(input);
  };

  /**
   * Auto save answers
   */
  const autoSaveAnswers = (): void => {
    if (isSurveyMedley || isGPEditingQuestionnaire || isSurveyQualification) {
      return;
    }

    if (!inputQuestions[activeSlide + 1] && savedAnswers[activeSlide]) {
      processPostQuestionAnswers(savedAnswers);
      if (!isModal) {
        setQuestionnaireComplete(true);
      }

      // if the questionnaire is in a modal, close the modal and dispatch the event since survey redirect opens in a new tab
      if (isModal) {
        setPopupActive(false);
        dispatchQuestionnaireCompleteEvent();
      }
    }
  };

  /**
   * Single Select: sets the currently selected answer for saving and also automatically proceeds to the next slide
   * @param input
   */
  const singleSelectAnswerCallback = (input: AnswerModel | null): void => {
    if (!input) {
      delete savedAnswers[activeSlide];
    } else {
      savedAnswers[activeSlide] = input;
    }

    setCurrentAnswer(input);

    // Handle the case where the user selects number of children
    if (isGPEditingQuestionnaire) {
      if (input?.questionKey === NUMBER_OF_CHILDREN_QUESTION_KEY) {
        const numberOfChildren = getNumberOfChildrenAnswer([input]) || 0;

        // If user selects 0 children
        if (!numberOfChildren || numberOfChildren > MAX_NUMBER_OF_CHILDREN) {
          setCurrentAnswer(input);
          setInputQuestions([inputQuestions[0]]);
          setSavedAnswers([input]);
          return;
        } else {
          const [, ...initialInputChildrenQuestions] = initialInputQuestions;

          setCurrentAnswer(input);
          setInputQuestions([inputQuestions[0], ...initialInputChildrenQuestions]);
          setSavedAnswers([input]);

          nextSlide();
          setCurrentAnswer(null);
        }
      }
    }

    /**
     * If user selects O for how many children question, skip "ChildrenAge" question and set current answer to null
     * to ensure that next button on "Education" is disabled until user selects an answer
     * (logic excluded from GP editing questionnaire as all questions are handled individually and there is no need to skip anything)
    **/
    if (!isGPEditingQuestionnaire) {
      if (inputQuestions[activeSlide].key !== lastQuestionKey && inputQuestions[activeSlide + 1].key === CUSTOM_QUESTION_KEYS.CHILDREN && canSkipChildrenQuestion(savedAnswers) ) {
        if (savedAnswers[activeSlide + 2]) {
          setCurrentAnswer(savedAnswers[activeSlide + 2]);
        } else {
          setCurrentAnswer(null);
        }

        forceSlide(activeSlide + 2);
        return;
      }
    }

    if (inputQuestions[activeSlide].key !== lastQuestionKey) {
      nextSlide();
      scrollToTop();
      const previouslyStoredAnswer = getAnsweredQuestion(true);

      if (previouslyStoredAnswer) {
        setCurrentAnswer(previouslyStoredAnswer);
      } else {
        setCurrentAnswer(null);
      }
    } else {
      autoSaveAnswers();
    }
  };

  /**
   * This function determines if the user changed the answer for number of children, if it did, it will reset the children questions and user will be prompted to answer them again
   * @param existingAnswer
   * @param numberOfChildren
   * @returns
   */
  const getChildrenExistingAnswer = (existingAnswer: AnswerModel | undefined, numberOfChildren: number): AnswerModel | undefined => {
    if (!existingAnswer) {
      return undefined;
    }
    // existing answer will have 2 values per child, hence division by 2 to get the number of children user previously selected
    if (existingAnswer.multiValue && existingAnswer.multiValue.length/2 === numberOfChildren) {
      return existingAnswer;
    }

    return undefined;
  };

  /**
   * Conditionally renders a question depending on the type of input question
   * (will need to be updated with the various types of questions)
   */
  const renderQuestion = (): JSX.Element => {
    const currentQuestion = inputQuestions[activeSlide];

    // Render Children Question
    if (currentQuestion.key === CUSTOM_QUESTION_KEYS.CHILDREN) {
      const numberOfChildren = getNumberOfChildrenAnswer(savedAnswers) || 0;
      //see function its calling for description for details
      const existingAnswer   = getChildrenExistingAnswer(savedAnswers[activeSlide], numberOfChildren);

      return (
        <ChildrenQuestion
          question               = {currentQuestion}
          numberOfChildren       = {numberOfChildren}
          existingAnswer         = {existingAnswer}
          selectedAnswerCallback = {selectAnswerCallback}
          resetOnUnMount         = {isGPEditingQuestionnaire || !!existingAnswer}
        />
      );
    }

    // Render multi-value question
    if (currentQuestion.multiValue) {
      return (
        <MultiSelect
          question               = {currentQuestion}
          existingAnswer         = {savedAnswers[activeSlide]}
          selectedAnswerCallback = {selectAnswerCallback}
          changeFilter           = {handleChangeFilter}
        />
      );
    }

    // Render single select question
    return (
      <SingleSelect
        question               = {currentQuestion}
        existingAnswer         = {savedAnswers[activeSlide]}
        selectedAnswerCallback = {singleSelectAnswerCallback}
      />
    );
  };

  /**
   * Returns the title for Stepper component
   * @param title
   * @param points
   * @returns
   */
  const getTitle = (title: string, points: number): JSX.Element => {
    return(
      <StepperTitle>
        <span>{title}</span>
        <span>{points} {t('Questionnaire--gp-complete-stepper-pointsAbbreviation')}</span>
      </StepperTitle>
    );
  };

  /**
   * Render questionnaire complete stepper page
   */
  const renderQuestionnaireComplete = (): JSX.Element => {
    if (gpDataLoading) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner heightWidth='36px' data-testid={LOADING_SPINNER_TEST_ID} />
        </LoadingSpinnerContainer>
      );
    }

    if (isGPEditingQuestionnaire) {
      return(
        <></>
      );
    }

    const GP_COMPLETE_PLACEHOLDER_REWARD = 50;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const gpPoints = gpCategoryData!.points || GP_COMPLETE_PLACEHOLDER_REWARD;

    return(
      <>
        <SuccessIconContainer onClick={handlePopupExit}>
          <Icon iconId  = {ICON_IDS.CLOSE} width = {24} height = {24} clickable = {true} />
        </SuccessIconContainer>
        <CurrentPoints>
          {BASIC_PROFILE_POINTS_REWARD + gpPoints}
        </CurrentPoints>
        <QuestionnaireCompleteContainer>
          <Title>
            <Trans i18nKey={'Questionnaire--gp-complete-title'}>
              You’ve earned <span>{{points: gpPoints}}</span> points creating your profile!
            </Trans>
          </Title>
          <Description>
            <Trans i18nKey={'Questionnaire--gp-complete-message'}>
              Your answers allow us to match you with <span>128</span> new surveys from <span>28</span> brands that wanna hear from you.
            </Trans>
          </Description>
          <StepperContainer>
            <Stepper
              steps = {
                [{
                  title      : getTitle(t('Questionnaire--gp-complete-stepper-titleOne'), BASIC_PROFILE_POINTS_REWARD),
                  stateStart : StepperState.DEFAULT,
                  stateEnd   : StepperState.ACTIVE,
                  typeStart  : StepperType.NUMERIC,
                  typeEnd    : StepperType.CHECK,
                },{
                  title      : getTitle(t('Questionnaire--gp-complete-stepper-titleTwo'), gpPoints),
                  stateStart : StepperState.DEFAULT,
                  stateEnd   : StepperState.ACTIVE,
                  typeStart  : StepperType.NUMERIC,
                  typeEnd    : StepperType.CHECK,
                },{
                  title      : t('Questionnaire--gp-complete-stepper-titleThree'),
                  description: t('Questionnaire--gp-complete-stepper-descriptionThree'),
                  stateStart : StepperState.DEFAULT,
                  stateEnd   : StepperState.ACTIVE,
                  typeStart  : StepperType.NUMERIC,
                  typeEnd    : StepperType.NUMERIC,
                }]
              }
              activeStep    = {2}
              animateToStep = {true}
            />
          </StepperContainer>
          <Button
            context = {ContextTypes.PRIMARY}
            size    = {ButtonSizes.LARGE}
            width   = {ButtonWidth.FULL}
            onClick = {handlePopupExit}
            >
              {t('Questionnaire--gp-complete-buttonText')}
          </Button>
        </QuestionnaireCompleteContainer>
      </>
    );
  };

  /**
   * Gets the content of the questionnaire complete page
   */
  const getQuestionnaireComplete = (): JSX.Element => {
    if ((isSurveyQualification || isSurveyMedley) && surveyEligibilityStatus === SurveyEligibilityStatus.NO_STATUS) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner heightWidth='36px' />
        </LoadingSpinnerContainer>
      );
    }

    if (surveyEligibilityEnabled && surveyEligibilityStatus !== SurveyEligibilityStatus.NO_STATUS) {
      const screen = SURVEY_CALLBACK_SCREENS[surveyStatus as SurveyCallbackStatuses];

      return(
        <SurveyModal
          title                   = {screen.title}
          text                    = {screen.text}
          centered                = {screen.centered}
          buttonText              = {screen.buttonText}
          reportIssue             = {screen.reportIssue}
          emojiElement            = {screen.emojiElement}
          emojiComponent          = {screen.emojiComponent}
          callbackStatus          = {surveyStatus}
          surveyId                = {surveyId}
          modalCloseCallback      = {handlePopupExit}
          surveyEligibilityStatus = {surveyEligibilityStatus}
          surveyUrl               = {surveyUrl}
        />
      );
    }

    return renderQuestionnaireComplete();
  };

  /**
   * Gets the question header
   */
  const getQuestionHeader = (): JSX.Element => {
    if (!showQuestionTracker) {
      return <></>;
    }

    if (isSurveyQualification) {
      return (
        <QuestionTracker
          currentIndex = {currentCount + 1}
          total        = {inputQuestions.length}
          isModal      = {isModal}
        />
      );
    }

    return (
      <QuestionTracker
        currentIndex = {currentCount+1}
        total        = {inputQuestions.length}
      />
    );
  };

  /**
   * Checks if the current slide is the last slide in a questionnaire.
   *
   * @returns {boolean} `true` if the current slide is the last slide, `false` otherwise
   */
  const isLastSlide = useMemo((): boolean => {
    if (isGPEditingQuestionnaire) {
      const numberOfChildren = getNumberOfChildrenAnswer(savedAnswers) || 0;

      if (!numberOfChildren || numberOfChildren > MAX_NUMBER_OF_CHILDREN) {
        return true;
      }
    }

    return activeSlide === (inputQuestions.length - 1);
  }, [isGPEditingQuestionnaire, activeSlide, inputQuestions.length, savedAnswers]);

  /**
   * Returns the Nav Buttons at bottom of the questions
   */
  const renderNavButtons = (): JSX.Element => {
    return (
      <NavButtons
        contextType        = {isGPEditingQuestionnaire ? QuestionnaireContextTypes.GP_EDITING : QuestionnaireContextTypes.PRIMARY}
        nextActive         = {hasAnsweredCurrentSlide()}
        showPrevious       = {activeSlide !== 0}
        nextCallback       = {handleNextClick}
        previousCallback   = {handlePreviousClick}
        isLastSlide        = {isLastSlide}
        isDateOfBirthSlide = {false}
        isSingleQuestion   = {inputQuestions.length === 1}
        isLoading          = {isLastSlide && hasAnsweredCurrentSlide() && (isProcessingAnswers || isCheckingEligibility) && isModal}
      />
    );
  };

  /**
   * Returns the content of the current question
   */
  const getQuestion = (): JSX.Element => {
    return (
      <>
        <QuestionsContainer id={QUESTION_CONTAINER}>
          {getQuestionHeader()}
          {renderQuestion()}
        </QuestionsContainer>
        <ButtonsContainer>
          <Divider />
          {renderNavButtons()}
        </ButtonsContainer>
      </>
    );
  };

  /**
   * Renders the qualification prompt before sending the user into the survey medley
   */
  const getQualificationPrompt = (): JSX.Element => (
    <QualificationPrompt
      onContinue={() => {
        setIsQualificationPromptShown(false);
      }}
      questionCount={inputQuestions.length}
    />
  );

  /**
   * Returns the content of the questionnaire
   */
  const getQuestionnaireContent = (): JSX.Element => {
    if (isQualificationPromptShown && !isSurveyMedley && !isGPEditingQuestionnaire && !isModal) {
      return getQualificationPrompt();
    } else if ((questionnaireComplete && !isGPEditingQuestionnaire) || (isProcessingAnswers || isCheckingEligibility) || surveyEligibilityStatus !== SurveyEligibilityStatus.NO_STATUS) {
      return getQuestionnaireComplete();
    } else {
      return getQuestion();
    }
  };

  /**
   * Conditionally renders the questionnaire header
   */
  const renderHeader = (): JSX.Element | undefined => {
    if (isProcessingAnswers && isSurveyQualification) {
      return <></>;
    }

    if ((!questionnaireComplete || (isSurveyMedley && !questionnaireComplete)) && !hideHeaderInModal) {
      return (
        <Header
          exitCallback           = {handlePopupExit}
          totalQuestions         = {inputQuestions.length}
          currentQuestionIndex   = {currentCount + 1}
          isSurveyQualification  = {isSurveyQualification}
          isSurveyMedley         = {isSurveyMedley}
          isModal                = {isModal}
          questionnaireCompleted = {questionnaireComplete}
        />
      );
    }

    if (isSurveyQualification && !isSurveyMedley && (questionnaireComplete || isQualificationPromptShown) && surveyEligibilityStatus === SurveyEligibilityStatus.NO_STATUS) {
      return <SurveyPromptHeader exitCallback={handlePopupExit} isSurveyMedley={isSurveyMedley}/>;
    }
  };

  /**
   * Renders the questions without popup
   */
  const renderQuestionsWithoutPopup = (): JSX.Element => {
    return getQuestionnaireContent();
  };

  /**
   * Renders the full screen or modal popup
   * @returns
   */
  const renderFullScreenOrModal = (): JSX.Element | undefined => {
    if (popupActive && isModal) {
      return(
        <>
          <ModalBackdrop>
            <QuestionnaireModalContainer>
              <ModalContent>
                {renderHeader()}
                <ContentContainer data-testid={QUESTIONNAIRE_TEST_ID}>
                  { getQuestionnaireContent() }
                </ContentContainer>
              </ModalContent>
            </QuestionnaireModalContainer>
          </ModalBackdrop>
        </>
      );
    }

    if (popupActive) {
      return (
        <PopupBackground ref={popupBackgroundRef}>
          {renderHeader()}
          <ContentContainer data-testid={QUESTIONNAIRE_TEST_ID}>
            { getQuestionnaireContent() }
          </ContentContainer>
        </PopupBackground>
      );
    }
  };

  return (
    <div data-testid={QUESTIONNAIRE_POPUP_TEST_ID}>
      {isGPEditingQuestionnaire && renderQuestionsWithoutPopup()}
      {!isGPEditingQuestionnaire && renderFullScreenOrModal()}
    </div>
  );
}

export default QuestionnairePopup;