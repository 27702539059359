import {NavLink} from "react-router-dom";
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {DEVICE_BREAKPOINT, LABEL_SMALL, MOBILE_HEADER_HEIGHT} from "Shared/styles/designConstants";
import styled from 'styled-components';

export const TextFieldContainer = styled.div`
  width: 300px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  ${LABEL_SMALL.DEFAULT}
  max-width     : 414px;
  margin        : auto;
  vertical-align: middle;
  display       : flex;
  flex-direction: column;
  gap           : 20px;
  align-items   : center;
  padding-top   : 40px;
  overflow      : auto;

  & > div {
    width:100%;
  }

  img {
    width       : 23px;
    padding-left: 4px;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    width     : 100%;
    padding   : 40px 16px 0 16px;
    margin-top: ${MOBILE_HEADER_HEIGHT};
  }
`;

export const CtaContainer = styled.div`
  width      : 100%;
  padding-top: 16px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    padding-top: 0;
  }
`;

export const RightAlignedDiv = styled.div`
  text-align:right;
`;

export const CenterAlignedDiv = styled.div`
  text-align    : center;
  padding-bottom: ${DESIGN_TOKENS.spacing.xl.value}
`;


export const StyledAnchor = styled(NavLink)`
  color: ${DESIGN_TOKENS.color.text.link.value};
`;
