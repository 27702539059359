import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { Button, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { ErrorBlurb, ErrorButtonContainer, ErrorCodes, ErrorContentWrapper, ErrorIcon, ErrorTitle } from 'Shared/components/design/email/commonStyle';
import { ERROR, ERROR_CODES } from 'Shared/constants/email/testId';

interface Props {
  errorCodes?: number[];
}

const magnifyingGlassEmoji = '\uD83D\uDD0E';

/**
 * Renders the EmailNotFoundError component
 */
function EmailNotFoundError({ errorCodes }: Props): JSX.Element {
  const { t }    = useTranslation('app');
  const navigate = useNavigate();

  const renderErrorCode = (): JSX.Element => {
    if (!errorCodes?.length) {
      return <></>;
    }

    return (
      <ErrorCodes data-testid={ERROR_CODES}>
        {t('Email--error-error-codes', { count: errorCodes.length, codes: errorCodes.join(', ') })}
      </ErrorCodes>
    );
  };

  return (
    <ErrorContentWrapper data-testid={ERROR}>
      <ErrorIcon>{magnifyingGlassEmoji}</ErrorIcon>
      <ErrorTitle>{t('Email--error-notFound-title')}</ErrorTitle>
      <ErrorBlurb>
        <p>{t('Email--error-notFound-blurb')}</p>
      </ErrorBlurb>
      { renderErrorCode() }
      <ErrorButtonContainer>
        <Button
          size    = {ButtonSizes.LARGE}
          width   = {ButtonWidth.FULL}
          context = {ContextTypes.PRIMARY}
          onClick = {() => navigate(MENU_ROUTES.HOME)}
        >
          {t('Email--error-continue')}
        </Button>
      </ErrorButtonContainer>
    </ErrorContentWrapper>
  );
}

export default EmailNotFoundError;
