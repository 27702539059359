import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerModel } from 'Shared/components/design/questionnairePopup/AnswerModel';
import { ChildQuestionContainer,QuestionSubTitle } from 'Shared/components/design/questionnairePopup/childrenQuestion/style';
import { QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { QuestionTitle } from 'Shared/components/design/questionnairePopup/style';
import { CHILDREN_QUESTION_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import { Select, SelectFieldTypes,SelectOption } from 'Shared/components/design/select/Select';
import { UPMPredefinedAnswer, UPMQuestion } from 'Shared/models/swagger/upm';

// Store all children answers here so it doesn't
// get effected on component updates
let allChildrenAnswers: AnswerModel[] | undefined;

type ChildrenQuestionProps = {
  resetOnUnMount?       : boolean;
  numberOfChildren      : number;
  question              : QuestionPopupModel;
  existingAnswer?       : AnswerModel;
  selectedAnswerCallback: (x: AnswerModel | null) => void;
}

/**
 * Single Select Component
 */
function ChildrenQuestion ({ resetOnUnMount, numberOfChildren, question, existingAnswer, selectedAnswerCallback}: ChildrenQuestionProps): JSX.Element {
  const { t } = useTranslation('questionnairePopup');

  // Filter out all answers to only child related answers
  allChildrenAnswers = allChildrenAnswers || ((existingAnswer && existingAnswer.multiValue) || []).filter((answer: AnswerModel) => {
    if (answer.questionKey.startsWith('Child')) {
      return answer;
    }
  });

  // Reset the allChildrenAnswers if the resetOnUnMount is true
  useEffect(() => {
    return () => {
      if (resetOnUnMount) {
        allChildrenAnswers = undefined;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sorts all the child answers so that child_1, child_3, child_2 becomes child_1, child_2, child_3
   * Then if the person had only selected 2 children, it'll remove all extra to prevent saving extra
   * @param answers
   * @returns
   */
  const prepareAllChildrenAnswers = (answers: AnswerModel[]): AnswerModel[] => {
    return answers.sort((answerModelA: AnswerModel, answerModelB: AnswerModel): number => {
      const answerA: number = parseInt(answerModelA.questionKey.replace(/[^0-9\.]+/g,''));
      const answerB: number = parseInt(answerModelB.questionKey.replace(/[^0-9\.]+/g,''));

      if (answerA > answerB) {
        return 1;
      }

      if (answerB > answerA) {
        return -1;
      }

      return 0;
    }).slice(0, numberOfChildren * 2);
  };

  // Re-evaluate the child answers in a sorted way
  allChildrenAnswers = prepareAllChildrenAnswers(allChildrenAnswers);

  /**
   * Send the changed value back to the parent component
   * @param question
   * @param changedValue
   */
  const handleChange = (question: UPMQuestion, changedValue: string): void => {
    let added = false;

    if (!changedValue) {
      allChildrenAnswers = allChildrenAnswers?.filter(answer => answer.questionKey !== question.key);
      selectedAnswerCallback(null);
      return;
    }

    const answerValue: string[] = [changedValue];

    if (!allChildrenAnswers) {
      return;
    }

    for (let x = 0; x < allChildrenAnswers.length; x++) {
      if (allChildrenAnswers[x].questionKey === question.key) {
        allChildrenAnswers[x].value = answerValue;
        added                       = true;
      }
    }

    if (!added) {
      allChildrenAnswers.push({
        questionKey: question.key,
        value: answerValue
      });
    }

    if (allChildrenAnswers.length === numberOfChildren * 2) {
      const newAnswer: AnswerModel = {
        questionKey: question.key,
        value      : [],
        multiValue : prepareAllChildrenAnswers(allChildrenAnswers)
      };

      selectedAnswerCallback(newAnswer);
    }
  };

  /**
   * Go through the list of answers and find the one for this question
   * @param question
   * @returns answer
   */
  const getExistingAnswer = (question: UPMQuestion): string | number | undefined => {
    if (!allChildrenAnswers) {
      return;
    }

    for (let x = 0; x < allChildrenAnswers.length; x++) {
      if (allChildrenAnswers[x].questionKey === question.key) {
        return allChildrenAnswers[x].value[0];
      }
    }

    return;
  };

  /**
   * Returns the child age selection dropdown
   * @param question
   * @returns
   */
  const getChildrenAgeSelection = (question: UPMQuestion): JSX.Element => {
    const options: SelectOption[] = question.predefinedAnswers.map((predefinedAnswer: UPMPredefinedAnswer) => {
      return {
        label: predefinedAnswer.label,
        value: predefinedAnswer.answerValue
      };
    });

    options.unshift({
      label: t('Questionnaire--children-select-age'),
      value: ''
    });

    return <Select
      label          = { t('Questionnaire--children-age') }
      initialValue   = { getExistingAnswer(question) }
      disabled       = { false }
      options        = { options}
      changeCallback = { (changedValue: string) => handleChange(question, changedValue)}
      fieldType      = {SelectFieldTypes.DEFAULT}
    />;
  };

  /**
   * Returns the child gender selection dropdown
   * @param question
   * @returns
   */
  const getChildrenGenderSelection = (question: UPMQuestion): JSX.Element => {
    const options: SelectOption[] = question.predefinedAnswers.map((predefinedAnswer: UPMPredefinedAnswer) => {
      return {
        label: predefinedAnswer.label,
        value: predefinedAnswer.answerValue
      };
    });

    options.unshift({
      label: t('Questionnaire--children-select-gender'),
      value: ''
    });

    return <Select
      label          = { t('Questionnaire--children-gender') }
      initialValue   = { getExistingAnswer(question) }
      disabled       = { false }
      options        = { options}
      changeCallback = { (changedValue: string) => handleChange(question, changedValue) }
      fieldType      = {SelectFieldTypes.DEFAULT}
    />;
  };

  /**
   * Generates a list of all the children questions
   */
  const getChildrenQuestions = (): JSX.Element[] => {
    const childrenQuestions: JSX.Element[] = [];

    for (let x = 0; x < numberOfChildren; x++) {
      const trueIndex: number          = x * 2;
      const questionOne: UPMQuestion = question.data[trueIndex];
      const questionTwo: UPMQuestion = question.data[trueIndex + 1];

      childrenQuestions.push(
        <QuestionSubTitle key={ `main-question-${  x }` }>
          { t('Questionnaire--children-sub-title', {count: x + 1}) }
        </QuestionSubTitle>
      );

      if (questionOne.key.includes('Gender')) {
        childrenQuestions.push(
          <ChildQuestionContainer key={ questionOne.key }>
            { getChildrenGenderSelection(questionOne) }
          </ChildQuestionContainer>
        );
        childrenQuestions.push(
          <ChildQuestionContainer key={ questionTwo.key }>
            { getChildrenAgeSelection(questionTwo) }
          </ChildQuestionContainer>
        );
      } else {
        childrenQuestions.push(
          <ChildQuestionContainer key={ questionTwo.key }>
            { getChildrenGenderSelection(questionTwo) }
          </ChildQuestionContainer>
        );
        childrenQuestions.push(
          <ChildQuestionContainer key={ questionOne.key }>
            { getChildrenAgeSelection(questionOne) }
          </ChildQuestionContainer>
        );
      }
    }

    return childrenQuestions;
  };

  return (
    <div data-testid={CHILDREN_QUESTION_TEST_ID}>
      <QuestionTitle>
        { t('Questionnaire--children-main-title') }
      </QuestionTitle>
      { getChildrenQuestions() }
    </div>
  );
}

export default ChildrenQuestion;
