import {
  IDP_ERROR_BAD_POST_CODE,
  IDP_ERROR_FIRST_NAME_CANNOT_BE_EMPTY,
  IDP_ERROR_INVALID_DATE_TIME_VALUE,
  IDP_ERROR_LAST_NAME_CANNOT_BE_EMPTY,
  IDP_ERROR_PASSWORD_FAILED_VALIDATION
} from 'Pages/help/constants';

export const SIGNUP_STEPS: {
  [stepName: string] : number
} = {
  INTRO             : 0,
  POSTAL_CODE       : 1,
  DATE_OF_BIRTH     : 2,
  GENDER            : 3,
  NAME              : 4,
  CONFIRMATION      : 5
};

export const IDP_GENDER_VALUES = {
  MALE  : 'MALE',
  FEMALE: 'FEMALE'
};

export const ACTIONABLE_IDP_ERROR_CODES = [
  IDP_ERROR_BAD_POST_CODE,
  IDP_ERROR_PASSWORD_FAILED_VALIDATION ,
  IDP_ERROR_FIRST_NAME_CANNOT_BE_EMPTY,
  IDP_ERROR_LAST_NAME_CANNOT_BE_EMPTY ,
  IDP_ERROR_INVALID_DATE_TIME_VALUE
];

export const STEPS_TOTAL          = 5;
export const SIGNUP_SOURCE_DIRECT = 'DIRECT';

export const TEST_IDS = {
  PHONE_NUMBER       : 'phone-number',
  VERIFICATION_CODE  : 'verification-code'
};
