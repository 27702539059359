import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import styled from 'styled-components';

export const AutoLoginRoot = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  background    : ${DESIGN_TOKENS.color.background.primary.value};
  position      : fixed;
  top           : 0;
  left          : 0;
  right         : 0;
  bottom        : 0;
  z-index       : 100;
`;
