import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { PromoProgress } from 'Shared/components/design/promoProgress/PromoProgress';
import {
  ButtonContainer,
  PointsStepContainer,
  Progress,
  ProgressBarContainer,
  RedeemStepContainer,
  RedemptionDisclaimer,
  StepDescription,
  StepDescriptionIconContainer,
  StepperContainer,
  VerticalLine
} from 'Shared/components/design/promoStepper/style';
import { STEP_ICON_CONTAINER_ID } from 'Shared/components/design/promoWidget/constants';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { PROMOSegment, PROMOStep } from 'Shared/models/swagger/promo';
import { useUserPointsFromACT } from 'Shared/services/act/app/actService';
import {
  COMPLETED_STEP,
  getPromotionProgressPercentage,
  getRedemptionStepState,
  REDEMPTION_STEP_STATE
} from 'Shared/services/promo/app/promoService';
import { navigateToRewardsPage } from 'Shared/services/routingService';

import ActivityProgress from './components/ActivityProgress';

export enum STEP_TYPE {
  IGNORE_BY_FRONTEND,
  POINTS_PROGRESS,
  ACTIVITIES_PROGRESS,
  REDEEM
}

type PromoStepperProps = {
  segment: PROMOSegment[];
};

type GroupedPromoStep = PROMOStep | { state: STEP_TYPE; steps: PROMOStep[] };

function PromoStepper({ segment }: PromoStepperProps): JSX.Element {
  const { t } = useTranslation('promoStepper');
  const {
    data: pointsData,
  } = useUserPointsFromACT();


  /**
   * Returns the progress text for the step
   * @param step
   * @returns
   */
  const getProgressText = (step: PROMOStep): string => {
    if (step.state === STEP_TYPE.POINTS_PROGRESS) {
      return step.pointsCounter ? `${step.pointsCounter} ${t('Promo-stepper--rewardStep-pts')}` : `0 ${t('Promo-stepper--rewardStep-pts')}`;
    }

    return '';
  };

  /**
   * Groups steps with state 2 and returns a new array
   * @param steps
   * @returns
   */
  const groupActivitiesProgressSteps = (steps: PROMOStep[]): GroupedPromoStep[] => {
    const activitiesSteps = steps.filter(step => step.state === STEP_TYPE.ACTIVITIES_PROGRESS);
    const otherSteps: GroupedPromoStep[] = steps.filter(step => step.state !== STEP_TYPE.ACTIVITIES_PROGRESS);

    if (activitiesSteps.length > 0) {
      otherSteps.push({
        state: STEP_TYPE.ACTIVITIES_PROGRESS,
        steps: activitiesSteps, // Explicitly type as PROMOStep[]
      } as GroupedPromoStep);
    }

    return otherSteps;
  };

  /**
   * Returns the icon for the redemption step
   * @returns
   * @param state
   */
  const getRedemptionStepIcon = (state: number ): JSX.Element => {
    if (state === REDEMPTION_STEP_STATE.COMPLETE) {
      return (
        <StepDescriptionIconContainer>
          <Icon iconId={ICON_IDS.CHECKMARK} height={24} width={24} />
        </StepDescriptionIconContainer>
      );
    }

    if (state === REDEMPTION_STEP_STATE.READY_TO_REDEEM) {
      return (
        <StepDescriptionIconContainer>
          <Icon iconId={ICON_IDS.CIRCLE_TWO} height={32} width={32} />
        </StepDescriptionIconContainer>
      );
    }

    if (state === REDEMPTION_STEP_STATE.COMPLETE_NOT_READY_TO_REDEEM) {
      return (
        <StepDescriptionIconContainer>p
          <Icon iconId={ICON_IDS.CIRCLE_TWO} height={32} width={32} />
        </StepDescriptionIconContainer>
      );
    }

    return (
      <StepDescriptionIconContainer
        id      ={STEP_ICON_CONTAINER_ID}
        isLocked={true}
      >
        <Icon iconId={ICON_IDS.LOCK_GRAY} height={24} width={24} />
      </StepDescriptionIconContainer>
    );
  };

  /**
   * Returns the title for the redemption step
   * @param state
   * @returns
   */
  const getRedemptionStepTitle = (state: number): string => {
    if (state === REDEMPTION_STEP_STATE.COMPLETE) {
      return t('Promo-stepper--rewardStep-complete-title');
    }

    if (state === REDEMPTION_STEP_STATE.READY_TO_REDEEM) {
      return t('Promo-stepper--rewardStep-ready-title');
    }

    if (state === REDEMPTION_STEP_STATE.COMPLETE_NOT_READY_TO_REDEEM) {
      return t('Promo-stepper--rewardStep-then-redeem');
    }

    return t('Promo-stepper--rewardStep-then-redeem');
  };

  /**
   * Returns the description for the progress step
   * @param step
   * @returns
   */
  const getProgressStepDescription = (step: PROMOStep) => {
    if (step.state === STEP_TYPE.POINTS_PROGRESS) {
      return t('Promo-stepper--points-progress', { points: step.value });
    }

    return '';
  };

  /**
   * Renders the stepper step based on the step type
   * @param step
   * @param index
   * @returns
   */
  const renderStep = (step: PROMOStep, index: number) => {
    const progressPercentage = getPromotionProgressPercentage(step);
    const redeemStepState    = getRedemptionStepState(step, pointsData);
    switch (step.state) {
      case STEP_TYPE.POINTS_PROGRESS:
        return (
          <PointsStepContainer key={`points-step-${index}`} isVisible={progressPercentage !== COMPLETED_STEP}>
            <StepDescription>
              <StepDescriptionIconContainer>
                {progressPercentage === COMPLETED_STEP
                  ? <Icon iconId={ICON_IDS.CHECKMARK} height={24} width={24} />
                  : <Icon iconId={ICON_IDS.CIRCLE_ONE} height={32} width={32} />
                }
              </StepDescriptionIconContainer>
              <p>{getProgressStepDescription(step)}</p>
            </StepDescription>
            <ProgressBarContainer>
              <VerticalLine isStepCompleted={progressPercentage === COMPLETED_STEP}/>
              <Progress isVisible={progressPercentage !== COMPLETED_STEP}>
                <PromoProgress progress={getPromotionProgressPercentage(step)} startText={t('Promo-stepper--points-progress-startText')} endText={step.value} progressText={getProgressText(step)} animate={true} />
              </Progress>
            </ProgressBarContainer>
          </PointsStepContainer>
        );
      case STEP_TYPE.ACTIVITIES_PROGRESS:
        if ('steps' in step) {
          return (
            <ActivityProgress key={`activities-step-${index}`} steps={step.steps as PROMOStep[]} />
          );
        }
        break;
      case STEP_TYPE.REDEEM:
        return (
          <React.Fragment key={`redeem-step-${index}`}>
            <RedeemStepContainer>
              <StepDescription>
                {getRedemptionStepIcon(redeemStepState)}
                <p>{getRedemptionStepTitle(redeemStepState)}</p>
              </StepDescription>
              {(redeemStepState === REDEMPTION_STEP_STATE.INCOMPLETE || redeemStepState === REDEMPTION_STEP_STATE.COMPLETE_NOT_READY_TO_REDEEM) &&
                <RedemptionDisclaimer>
                  {t('Promo-stepper--rewards-disclaimer')}
                </RedemptionDisclaimer>
              }
            </RedeemStepContainer>
            { redeemStepState !== REDEMPTION_STEP_STATE.COMPLETE &&
              <ButtonContainer>
                <Button
                  size={ButtonSizes.MEDIUM}
                  context={ContextTypes.AUXILIARY}
                  width={ButtonWidth.FULL}
                  onClick={navigateToRewardsPage}
                  disabled={redeemStepState !== REDEMPTION_STEP_STATE.READY_TO_REDEEM}
                >
                  {t('Promo-stepper--rewards-buttonText')}
                </Button>
              </ButtonContainer>
            }
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  // group activities steps if any before rendering
  const groupedSteps = groupActivitiesProgressSteps(segment[0]?.steps || []);

  return (
    <StepperContainer>
      {groupedSteps.map((step, index) => renderStep(step, index))}
    </StepperContainer>
  );
}

export default PromoStepper;
