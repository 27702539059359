
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { BannerContext } from 'Shared/components/design/notificationsBanner/NotificationsBanner';
import {LABEL_XS} from 'Shared/styles/designConstants';
import styled from 'styled-components';

type UserBannerProps = {
  bannerContext: BannerContext;
}

function getBackgroundColor(bannerContext: BannerContext) {
  switch (bannerContext) {
    case BannerContext.DEFAULT:
      return DESIGN_TOKENS.color.background.primary.inverse.value;
    case BannerContext.WARNING:
      return '#E11900';
    default:
      return DESIGN_TOKENS.color.background.primary.inverse.value;
  }
}

export const BannerWrapper = styled.div`
  position        : static;
  display         : block;
  z-index         : 200;
`;

export const ContentContainer = styled.div<UserBannerProps>`
  ${LABEL_XS.DEFAULT}
  width           : 100%;
  padding         : 16px;
  color           : ${DESIGN_TOKENS.color.text.onCritical.value};
  display         : flex;
  background-color: ${props => getBackgroundColor(props.bannerContext)};
  align-items     : center;
  justify-content : center;

  svg {
    fill        : ${DESIGN_TOKENS.color.text.onCritical.value};
    margin-right: 10px;
  }
`;

export const ButtonContainer = styled.button`
  all             : unset;
  text-decoration : none;
  padding         : 4px 16px;
  background-color: ${DESIGN_TOKENS.color.text.onCritical.value};
  border-radius   : 40px;
  color           : black;
  margin-left     : 16px;
  white-space     : nowrap;
  cursor          : pointer;

  &:hover {
    background-color: ${DESIGN_TOKENS.color.button.secondary.hovered.value};
  }
`;

export const IconContainer = styled.div`
  height      : 24px;
  width       : 24px;
  margin-right: 8px;
`;