import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { useFirebaseRemoteConfig } from 'Shared/services/firebase/hooks/useFirebaseRemoteConfig';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';

const REMOTE_CONFIG_ENABLED_KEY = 'sj_maintenance_mode';
const REMOTE_CONFIG_MESSAGE_KEY = 'sj_maintenance_mode_message';
const MAINTENANCE_MODE_OVERRIDE = '1';
const MAINTENANCE_MODE_RESET    = '0';

interface MaintenanceMode {
  message: string;
  enabled: boolean;
  loading: boolean;
}

const useMaintenanceMode = (): MaintenanceMode => {
  const { data, isLoading }                              = useFirebaseRemoteConfig(REMOTE_CONFIG_ENABLED_KEY, 'boolean');
  const { data: message, isLoading: isLoadingMessage }   = useFirebaseRemoteConfig(REMOTE_CONFIG_MESSAGE_KEY, 'string');
  const maintenanceModeOverride                          = (new URLSearchParams(location.search)).get('moo') || '';

  if (maintenanceModeOverride === MAINTENANCE_MODE_OVERRIDE) {
    // If we see moo=1 then don't show the maintenance mode so that
    // we can access SPA even in maintenance mode.
    setLocalStorage(LOCAL_STORAGE_IDS.MAINTENANCE_MODE_OVERRIDE, MAINTENANCE_MODE_OVERRIDE);
  } else if (maintenanceModeOverride === MAINTENANCE_MODE_RESET) {
    // Resets maintenace mode so that it returns to default behavior.
    deleteLocalStorage(LOCAL_STORAGE_IDS.MAINTENANCE_MODE_OVERRIDE);
  }

  // Returns maintenance mode override when detected
  if (getLocalStorage(LOCAL_STORAGE_IDS.MAINTENANCE_MODE_OVERRIDE)) {
    return {
      message: '',
      enabled: false,
      loading: false,
    };
  }

  return {
    message: message?.value as string ?? '',
    enabled: !!data?.value,
    loading: isLoading || isLoadingMessage,
  };
};

export default useMaintenanceMode;