import styled from "styled-components";


export const IconContainer = styled.i`
  position    : relative;
  width       : 15px;
  bottom      : 30px;
  float       : right;
  margin-right: 15px;
`;
