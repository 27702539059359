import { Link } from 'react-router-dom';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { LABEL_XS } from 'Shared/styles/designConstants';
import styled from 'styled-components';

const TOP_CONTENT_VERTICAL_MARGIN = 25;
const TOP_CONTENT_HEIGHT = 27;

export const AutoEmailRoot = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  background    : ${DESIGN_TOKENS.color.background.success.light.value};
  position      : fixed;
  top           : 0;
  left          : 0;
  right         : 0;
  bottom        : 0;
  z-index       : 100;
`;

export const TopContentWrapper = styled.div`
  display        : flex;
  justify-content: space-between;
  width          : calc(100vw - 42px);
  margin         : ${TOP_CONTENT_VERTICAL_MARGIN}px 21px;
  height         : ${TOP_CONTENT_HEIGHT}px;
`;

export const EmailMatch = styled.div`
  display    : flex;
  align-items: center;
  margin-left: 27px;
  color      : ${DESIGN_TOKENS.color.text.secondary.value};
  white-space: pre-wrap;
  ${LABEL_XS.BOLD}
`;

export const EmailName = styled.span`
  color: ${DESIGN_TOKENS.color.text.normal.value};
`;

export const MatchIconCircle = styled.div`
  width          : ${TOP_CONTENT_HEIGHT}px;
  height         : ${TOP_CONTENT_HEIGHT}px;
  border-radius  : ${TOP_CONTENT_HEIGHT}px;
  margin-right   : 16px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  background     : ${DESIGN_TOKENS.color.background.info.value};
  color          : #FFFFFF; // TODO: Use design token Application/Content/contentOnColor
  flex           : 1 0 auto;
`;

export const CloseButton = styled(Link)`
  border     : none;
  background : transparent;
  padding    : 0;
  margin     : 0;
  width      : 24px;
  height     : 24px;
  line-height: 24px;
  cursor     : pointer;
`;
