import { PASSWORD_MAXIMUM_CHARACTERS,PASSWORD_MINIMUM_CHARACTERS } from 'App/constants/password';
import { MINIMUM_NAME_LENGTH } from 'Pages/help/constants';
import { COUNTRY_CODE_AU, COUNTRY_CODE_CA, COUNTRY_CODE_US, PHONE_INPUT_LENGTH_US } from "Shared/constants";

const POST_CODE_PATTERN_AU = /^((0(2|8|9)[0-9]{2})|([1-9][0-9]{3}))$/;
const POST_CODE_PATTERN_CA = /^[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d$/i;
const POST_CODE_PATTERN_US = /^\d{5}(?:-\d{4})?$/;

export const VALID_COUNTRY_CODE   = 1;
export const INVALID_COUNTRY_CODE = 2;
export const EMPTY_POSTAL_CODE    = 3;


/**
 * Determines if a given input is a valid US Postcode
 * @param input
 */
export const isValidUSZipCode = (input: string): boolean => {
  if (!input || input.length === 0) {
    return false;
  }

  return POST_CODE_PATTERN_US.test(input);
};

/**
 * Determines if a given input is a valid AU Postcode
 * @param input
 */
export const isValidAUZipCode = (input: string): boolean => {
  if (!input || input.length === 0) {
    return false;
  }

  return POST_CODE_PATTERN_AU.test(input);
};

/**
 * Determines if a given input is a valid CA Postcode
 * @param input
 */
export const isValidCAZipCode = (input: string): boolean => {
  if (!input || input.length === 0) {
    return false;
  }

  return POST_CODE_PATTERN_CA.test(input);
};

/**
 * Determines if a given input is a valid UK Postcode
 * @param input
 */
export const isValidPostcode = (input: string): number => {
  if (!input || input.length === 0) {
    return EMPTY_POSTAL_CODE;
  }

  switch(process.env.COUNTRY_BUILD) {
    case COUNTRY_CODE_AU:
    case COUNTRY_CODE_CA:
    case COUNTRY_CODE_US:
      if(isValidAUZipCode(input) || isValidCAZipCode(input) || isValidUSZipCode(input)) {
        return VALID_COUNTRY_CODE;
      } else {
        return INVALID_COUNTRY_CODE;
      }
    default:
      throw('Invalid country found');
  }
};

/**
 * Determines the country based on the postcode
 * @param postcode
 * @returns country code
 */
export const getCountryByPostcode = (postcode: string): string => {
  if (isValidAUZipCode(postcode)) {
    return COUNTRY_CODE_AU;
  }
  if (isValidCAZipCode(postcode)) {
    return COUNTRY_CODE_CA;
  }
  if (isValidUSZipCode(postcode)) {
    return COUNTRY_CODE_US;
  }
  return '';
};

/**
 * Validates email input
 * @param emailAddress
 */
export const isValidEmail = (emailAddress: string): boolean => {
  const lowerCaseEmailAddress = (emailAddress || '').toLowerCase();
  const emailPattern          = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;  // RFC-2822 compliant Email regex
  const match                 = lowerCaseEmailAddress.match(emailPattern);
  return !!(match && match.length > 0);
};

/**
 * Returns the age of person based on the date
 * @param date
 * @returns
 */
export const getAgeFromDate = (date: Date, startDate?: Date): number => {
  const today = startDate || new Date();
  let   age   = today.getFullYear() - date.getFullYear();
  const m     = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
};

/**
 * Checks whether the input date is valid
 * @param inputtedDate
 * @param t
 * @returns
 */
export const isValidDate = (inputtedDate: string): boolean => {
  const attemptDate = new Date(inputtedDate);
  return attemptDate.toString() !== 'Invalid Date';
};

/**
 * Checks whether the password is valid
 *
 * @param password
 * @returns
 */
export const isValidPassword = (password: string): boolean => {
  return !!password && password.length >= PASSWORD_MINIMUM_CHARACTERS && password.length <= PASSWORD_MAXIMUM_CHARACTERS && !/\s/.test(password);
};

/**
 * Checks whether the name is valid
 *
 * @param name
 * @returns
 */
export const isValidName = (name: string): boolean => {
  return !!name && name.length >= MINIMUM_NAME_LENGTH ;
};

/**
* Gets the phone input field allowed length depending on country
*/
export const getMaxPhoneInputLength = (): number => {
  return PHONE_INPUT_LENGTH_US;
};

/**
 * Performs a basic validation for routing number
 * checks if its 9 digits long and if there are any non-numeric characters
 * @param routingNumber
 * @returns
 */
export const isValidRoutingNumber = (routingNumber: string) => {
  const numberPattern = /^[0-9]{9}$/;

  return numberPattern.test(routingNumber);
};

/**
 * Performs a basic validation for account number
 * @param accountNumber
 * @returns
 */
export const isValidAccountNumber = (accountNumber: string): boolean => {
  const numberPattern = /^[0-9]+$/;

  return numberPattern.test(accountNumber); // Regular expression to match one or more digits
};

/**
 * Returns current build country code for use in basic profile
 * @returns
 */
export const getNormalizedZipCode = (zipCode: string): string =>{
  if (isValidUSZipCode(zipCode) || isValidAUZipCode(zipCode)) {
    return zipCode;
  }

  if (isValidCAZipCode(zipCode)) {
    // Convert the postal code to upper case and remove any existing spaces
    const normalizedZipCode = zipCode.toUpperCase().replace(/[\s-]+/g, '');
    // Insert a space after the third character
    return normalizedZipCode.slice(0, 3) + ' ' + normalizedZipCode.slice(3);
  }

  return zipCode;
};
