import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { Blurb, BottomButtonWrapper, SurveyContainer, Title } from 'Shared/components/design/email/commonStyle';
import { ShadowLoadingCard } from 'Shared/components/design/email/loading/styles';
import { LOADING } from 'Shared/constants/email/testId';

interface Props {
  stillLoading: boolean;
}

/**
 * Renders the EmailLoading component
 */
function EmailLoading({ stillLoading }: Props): JSX.Element {
  const { t }    = useTranslation('app');
  const navigate = useNavigate();

  const blurb = stillLoading
    ? t('Email--still-loading-blurb')
    : t('Email--loading-blurb');

  const buttonText = stillLoading
    ? t('Email--still-loading-button-text')
    : t('Email--loading-button-text');

  return (
    <div data-testid={LOADING}>
      {/* Leave a space the same height as the title */}
      <Title>&nbsp;</Title>
      <Blurb>
        {blurb}
      </Blurb>
      <SurveyContainer>
        <ShadowLoadingCard buttonText={buttonText} />
      </SurveyContainer>
      {
        stillLoading && (
          <BottomButtonWrapper>
            <Button
              context = {ContextTypes.SECONDARY}
              width   = {ButtonWidth.FULL}
              onClick = {() => navigate('/')}
            >
              {t('Email--loading-close-button-text')}
            </Button>
          </BottomButtonWrapper>
        )
      }
    </div>
  );
}

export default EmailLoading;
