import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useTimeDelta = (date: Date) => {
  const { t } = useTranslation('app');
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const time = date.getTime();

  const isInPast = time - now < 0;
  const deltaMs = Math.abs(time - now);
  const deltaSeconds = Math.floor(deltaMs / 1000);
  const deltaMinutes = Math.floor(deltaSeconds / 60);
  const deltaHours = Math.floor(deltaMinutes / 60);
  const deltaDays = Math.floor(deltaHours / 24);

  let deltaString: string;
  if (deltaDays >= 3) {
    deltaString = `${deltaDays} ${t('TimeDelta--days', { count: deltaDays })}`;
  } else if (deltaHours >= 1) {
    deltaString = `${deltaHours} ${t('TimeDelta--hours', { count: deltaHours })}`;
  } else if (deltaMinutes >= 1) {
    deltaString = `${deltaMinutes} ${t('TimeDelta--minutes', { count: deltaMinutes })}`;
  } else {
    deltaString = `${deltaSeconds} ${t('TimeDelta--seconds', { count: deltaSeconds })}`;
  }

  let deltaStringInAgo: string;
  if (deltaSeconds !== 0) {
    if (isInPast) {
      deltaStringInAgo = t('TimeDelta--ago', { delta: deltaString });
    } else {
      deltaStringInAgo = t('TimeDelta--in', { delta: deltaString });
    }
  } else {
    deltaStringInAgo = t('TimeDelta--now');
  }

  return {
    deltaString,
    deltaStringInAgo,
    isInPast,
    deltaMs,
    deltaSeconds,
    deltaMinutes,
    deltaHours,
    deltaDays,
  };
};
