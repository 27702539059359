import { logError } from 'App/services/coralogixService';
import { getSessionId } from 'App/services/idpTokenService';
import {
  ANSWER_SOURCE_DIRECT,
  CUSTOM_QUESTION_KEYS,
  getUpmCategoryIds,
  NUMBER_OF_CHILDREN_QUESTION_KEY
} from 'Pages/help/constants';
import { dispatchQuestionnaireStartEvent } from 'Pages/help/routes/dispatchEvents';
import { isKeyCustomQuestionKey } from 'Pages/surveyExperience/services/surveyService';
import {
  AnswerModel,
  AnswerResponseModel,
  DynamicAnswer,
  PostAnswerModel,
  PostAnswerModelList
} from 'Shared/components/design/questionnairePopup/AnswerModel';
import { QuestionnaireModel, QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { UPMCategory, UPMQuestion, UPMQuestionsResponse } from 'Shared/models/swagger/upm';
import { APIResponse } from 'Shared/services/apiInterface';
import { getFormFactor, isMobile } from 'Shared/services/deviceService';
import { setLocalStorage } from 'Shared/services/localStorageService';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { getUserCacheData, setUserCacheData, UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { getCategoryDataSwrCacheKey } from 'Shared/services/swr/swrKeyService';
import {
  getAnswers,
  getQuestions,
  getSingleCategory,
  postAnswers,
  postCustomAnswers,
  postDynamicAnswers
} from 'Shared/services/userActivation/api/questionAPIService';
import useSWR from 'swr';

export const POSTAL_CODE_QUESTION_KEY      = "GeoPostalCode";
export const COUNTRY_QUESTION_KEY          = "GeoCountry";
export const DYNAMIC_SCREENER_QUESTION_KEY = "DSQ";

/**
 * Used to group the identify the children question keys
 */
const CHILD_QUESTION_KEYS = [
  'Child_1_Gender',
  'Child_2_Gender',
  'Child_3_Gender',
  'Child_4_Gender',
  'Child_5_Gender',
  'Child_6_Gender',
  'Child_7_Gender',
  'Child_8_Gender',
  'Child_9_Gender',
  'Child_1_age',
  'Child_2_age',
  'Child_3_age',
  'Child_4_age',
  'Child_5_age',
  'Child_6_age',
  'Child_7_age',
  'Child_8_age',
  'Child_1_DOB',
  'Child_2_DOB',
  'Child_3_DOB',
  'Child_4_DOB',
  'Child_5_DOB',
  'Child_6_DOB',
  'Child_7_DOB',
  'Child_8_DOB',
  'Child_9_DOB',
];

/**
 * Retrieves the category data for a given categoryId.
 * @param {number} categoryId - The id of the category to retrieve.
 * @returns {Promise} A promise that either resolves with the processed category data or rejects with an error.
 */
export const getCategoryData = (() => {
  const categoryCache: Map<number, UPMCategory> = new Map<number, UPMCategory>();

  return (categoryId: number): Promise<UPMCategory> => {
    // TODO: 0 values for empty result
    const emptyResult: UPMCategory = {
      categoryId         : categoryId,
      isCompleted        : false,
      hasEverCompleted   : false,
      points             : 30,
      questionCount      : 16,
      answerCount        : 0,
      completedPercentage: 0,
      name               : '',
      duration           : 0,
      needsUpdate        : false
    };

    // Check if the category has already been retrieved
    const cachedCategory = categoryCache.get(categoryId);
    if (cachedCategory && cachedCategory.hasEverCompleted) {
      return Promise.resolve(cachedCategory);
    }

    return new Promise((resolve, reject) => {
      getSingleCategory(categoryId)
        .then((categoryResult) => {
          const processedData = categoryResult;
          resolve(processedData);

          // Store the category in the cache if it's completed
          if (processedData.hasEverCompleted) {
            categoryCache.set(categoryId, processedData);
            setUserCacheData(UserSessionCacheKeys.CATEGORY_DATA , processedData, categoryId.toString());
          }
        })
        .catch(error => {
          logError('getSingleCategory', error);
          reject(emptyResult);
        });
    });
  };
})();

/**
 * @param {number} categoryId - The ID of the category to fetch data for.
 * @returns {SWRResponse<UPMCategory, any>} An object of type SWRResponse that contains the category data or an error message.
 */
export const useGetCategoryData = (categoryId: number | null) => {
  // Construct the key array without null values
  const key = categoryId !== null ? getCategoryDataSwrCacheKey(categoryId) : null;

  const initialData = getUserCacheData(UserSessionCacheKeys.CATEGORY_DATA, String(categoryId));

  const swr = useSWR(key, () => getCategoryData(categoryId as number), {
    fallbackData: initialData
  });

  let isLoading = swr.isLoading;

  if (typeof initialData !== 'undefined') {
    isLoading = false;
  }

  return {
    ...swr,
    isLoading
  };
};

const getCachedQuestions = async (categoryId: number, useSessionCache = false): Promise<UPMQuestionsResponse | null> => {
  const cachedQuestions = getUserCacheData(UserSessionCacheKeys.QUESTIONS_DATA, categoryId);
  if (useSessionCache && cachedQuestions) {
    return cachedQuestions;
  }

  const response = await getQuestions(categoryId);
  if (response.data) {
    setUserCacheData(UserSessionCacheKeys.QUESTIONS_DATA, response.data, categoryId);
    return response.data;
  }

  return null;
};

/**
 * Gets the general profile questions. Returns an empty array if the user has already completed the GP
 */
export const getQuestionnaire = async (categoryId: number, useSessionCache = false): Promise<QuestionnaireModel> => {
  let   gpPointValue                    = 0;
  const emptyResult: QuestionnaireModel = {
    points    : 0,
    questions : [],
    categoryId: categoryId
  };

  try {
    const [categoryResult, questionsResult] = await Promise.all([
      getSingleCategory(categoryId, useSessionCache),
      getCachedQuestions(categoryId, useSessionCache)
    ]);

    // If general profile has already been completed, exit early
    if (categoryResult.hasEverCompleted) {
      return emptyResult;
    }

    // Set point value based on category result
    gpPointValue = categoryResult.points;

    if (questionsResult?.questions) {
      return processQuestions(questionsResult.questions, gpPointValue, categoryId);
    }

    return emptyResult;
  } catch (error) {
    logError('getQuestionnaire', error);
    return emptyResult;
  }
};

/**
 * Check whether an answer is for an Active Measure or Lucid (Custom) question.
 * @param answer
 * @returns {boolean}
 */
const isCustomAnswer = (answer: AnswerModel) => {
  return isKeyCustomQuestionKey(answer.questionKey);
};

type PostQuestionAnswersProps = {
  answers        : AnswerModel[],
  isRewardable  ?: boolean,
  inputQuestions?: QuestionPopupModel[]
};

/**
 * Maps a dynamic screener answer to a DynamicAnswer object for posting to UPM /screening-answers.
 * @param answer
 * @param inputQuestions
 * @returns
 */
export const buildDynamicAnswer = (answer: AnswerModel, inputQuestions: QuestionPopupModel[]): DynamicAnswer | null => {
  //remove DSQ from questionKey since its only used and generated on client side for differentiation purposes
  const questionIdRaw = answer.questionKey.replace(/^DSQ/, '');

  //find the matching inputQuestion by key
  const matchedInputQuestion = inputQuestions.find(
    (iq) => iq.key === answer.questionKey
  );

  if (!matchedInputQuestion || !matchedInputQuestion.data?.length) {
    return null;
  }

  const questionData = matchedInputQuestion.data[0];

  //"providerId" in dynamicAnswers is the categoryId from questionData
  const providerId = String(questionData.categoryId);
  //"questionId" is the ID after removing DSQ
  const finalQuestionId = questionIdRaw;
  //"questionText" is questionData.question
  const questionText = questionData.question;

  //determine if it's multi or single from the matched question’s multiValue
  const isMulti = !!questionData.multiValue;
  // The user's answered values are in `answer.value` which is an array
  const userValues = Array.isArray(answer.value) ? answer.value : [];

  //for SINGLE_CHOICE => there should be exactly 1 item in userValues.
  //for MULTI_CHOICE => userValues can have multiple.
  if (isMulti) {
    // MULTI_CHOICE
    const details = userValues.map((v: number | string) => {
      //match a predefined label
      const matchedPredef = questionData.predefinedAnswers?.find(
        (pa) => String(pa.answerValue) === String(v)
      );
      return {
        answerId  : String(v),
        answerText: matchedPredef?.label ?? String(v)
      };
    });

    return {
      providerId,
      questionId  : finalQuestionId,
      questionText,
      answer: {
        type: 'MULTI_CHOICE',
        answerDetails: details
      }
    };
  } else {
    const singleValue = userValues[0];
    if (singleValue == null) {
      return null;
    }

    //find a matching label
    const matchedPredef = questionData.predefinedAnswers?.find(
      (pa) => String(pa.answerValue) === String(singleValue)
    );

    return {
      providerId,
      questionId  : finalQuestionId,
      questionText,
      answer: {
        type      : 'SINGLE_CHOICE',
        answerId  : String(singleValue),
        answerText: matchedPredef?.label ?? String(singleValue)
      }
    };
  }
};

/**
 * postQuestionAnswers
 *
 * Accepts an array of answers, separates them into:
 * - standard
 * - custom (AMQ, am., amq., LUCID, pid)
 * - dynamic (keys start with 'DSQ', prepended on client side for differentiation purposes)
 *
 * Then it posts:
 * 1) standard -> postAnswers
 * 2) custom -> postCustomAnswers
 * 3) dynamic -> postDynamicAnswers
 *
 * Returns all three promises in a single Promise.all.
 */
export const postQuestionAnswers = async ({
  answers = [],
  isRewardable = false,
  inputQuestions = []
}: PostQuestionAnswersProps): Promise<[APIResponse | null, APIResponse | null, APIResponse | null]> => {

  const standardAnswersList: PostAnswerModelList[] = [];
  const customAnswersList: PostAnswerModelList[]   = [];
  const dynamicAnswersList: DynamicAnswer[]        = [];

  // separate answers into standard/custom or dynamic
  answers.forEach((answer: AnswerModel) => {
    //if DSQ, build the dynamic answer
    if (answer.questionKey.startsWith(DYNAMIC_SCREENER_QUESTION_KEY)) {
      const dynamicAnswer = buildDynamicAnswer(answer, inputQuestions);
      if (dynamicAnswer) {
        dynamicAnswersList.push(dynamicAnswer);
      }
      return;
    }

    //otherwise is standard or custom
    const answerListToUse = isCustomAnswer(answer)
      ? customAnswersList
      : standardAnswersList;

    //if there is multiValue you push them individually. Otherwise, just push a single item.
    if (answer.multiValue && answer.multiValue.length) {
      answer.multiValue.forEach((multiAns: AnswerModel) => {
        answerListToUse.push({
          questionKey: multiAns.questionKey,
          value      : multiAns.value
        });
      });
    } else {
      answerListToUse.push({
        questionKey: answer.questionKey,
        value      : answer.value
      });
    }
  });

  //build the models for standard/custom
  function buildAnswerModel (items: PostAnswerModelList[]): PostAnswerModel {
    return {
      items,
      isMobile  : isMobile(),
      formFactor: getFormFactor(),
      hasFlash  : false,
      cd        : '', // TODO: Insert "cd" from your environment if needed
      source    : ANSWER_SOURCE_DIRECT
    };
  }

  const standardAnswersPromise = standardAnswersList.length > 0
    ? postAnswers(buildAnswerModel(standardAnswersList), isRewardable)
    : null;

  const customAnswersPromise = customAnswersList.length > 0
    ? postCustomAnswers(buildAnswerModel(customAnswersList), isRewardable)
    : null;

  const dynamicAnswersPromise = dynamicAnswersList.length > 0
    ? postDynamicAnswers({ answers: dynamicAnswersList })
    : null;

  return Promise.all([
    standardAnswersPromise,
    customAnswersPromise,
    dynamicAnswersPromise
  ]);
};

/**
 * Used to process the incoming questions by grouping custom questions and sorting the outcome per the sort order
 * @param questionArray
 */
const processQuestions = (questionArray: UPMQuestion[], pointValue: number, categoryId: number): QuestionnaireModel => {
  const questionData  = [];
  const childQuestion = [];

  for (let i = 0; i < questionArray.length; i++) {
    const singleQuestion = questionArray[i];

    if (CHILD_QUESTION_KEYS.indexOf(singleQuestion.key) > -1) {
      childQuestion.push(singleQuestion);
    } else if (singleQuestion.enabled) {
      const newQuestion: QuestionPopupModel = {
        multiValue: singleQuestion.multiValue,
        key       : singleQuestion.key,
        sortOrder : singleQuestion.sortOrder,
        data      : [singleQuestion]
      };

      questionData.push(newQuestion);
    }
  }

  // Add compiled children questions back in
  if (childQuestion.length) {
    const newChildQuestion = {
      multiValue: false,
      key       : CUSTOM_QUESTION_KEYS.CHILDREN,
      sortOrder : childQuestion[0].sortOrder,
      data      : childQuestion
    };

    questionData.push(newChildQuestion);
  }

  // sort questions by sort order
  questionData.sort((q1, q2) => {
    return q1.sortOrder - q2.sortOrder;
  });

  return {
    points    : pointValue,
    questions : questionData,
    categoryId: categoryId
  };
};

/**
 * Used to return children information based on inputted answer
 * @param savedAnswers the answers the member has inputted
 * @returns number of children or null
 */
export const getNumberOfChildrenAnswer = (savedAnswers: AnswerModel[]): number | null => {
  const childrenQuestionAnswer: AnswerModel | undefined = savedAnswers.find((question: AnswerModel) => {
    return question?.questionKey === NUMBER_OF_CHILDREN_QUESTION_KEY;
  });

  if (!childrenQuestionAnswer || !childrenQuestionAnswer.value.length) {
    return null;
  }

  // Children question answer values can be mapped to the actual number by subtracting by 1
  const childrenAnswer = childrenQuestionAnswer.value[0];
  return typeof childrenAnswer === 'number' ? childrenAnswer - 1 : 0;
};

/**
 * Get current user's submitted answers
 * @param filterQuestionIds
 * @returns
 */
export const getQuestionAnswers = async (filterQuestionIds: number[] = []): Promise<AnswerResponseModel[]> => {
  const emptyResult: AnswerResponseModel[] = [];

  try {
    const response = await getAnswers(getUpmCategoryIds().BASIC_PROFILE);
    if (response?.data?.userAnswers) {
      if (filterQuestionIds.length > 0) {
        return response.data.userAnswers.filter((answer: AnswerResponseModel) => filterQuestionIds.includes(answer.questionId));
      }
      return response.data.userAnswers;
    }
    return emptyResult;
  }
  catch (error) {
    logError('getQuestionAnswers', error);
    return emptyResult;
  }
};

/**
 * Returns whether or not the person can skip the children question
 */
export const canSkipChildrenQuestion = (savedAnswers: AnswerModel[]): boolean => {
  const EXPECTING_CHILD          = 9;
  const numberOfChildrenSelected = getNumberOfChildrenAnswer(savedAnswers);

  return numberOfChildrenSelected === 0 || numberOfChildrenSelected === EXPECTING_CHILD;
};

/**
 * Adds the expecting child answer to the answers array if the user has selected expecting for Children question
 * @param answers
 * @returns
 */
export const addExpectingChildAnswer = (answers: AnswerModel[]): AnswerModel[] => {
  const numberOfChildren = getNumberOfChildrenAnswer(answers);

  if (numberOfChildren !== 9) {
    return answers;
  }

  const currentDate = new Date();

  //calculate the date 4.5 months in the future (bussiness logic for expecting child question)
  const futureDate = new Date(currentDate.setMonth(currentDate.getMonth() + 4.5));

  // format the date as YYYY-MM-DDThh:mm:ssZ
  const formattedDate = futureDate.toISOString().split('.')[0] + 'Z';

  //expecting child answer
  const expectingChild = {
    "questionKey": "Child_9_DOB",
    "value"      : [],
    "multiValue" : [
      {
        "questionKey": "Child_9_DOB",
        "value": [
            formattedDate
        ]
      }
    ]
  };

  answers.push(expectingChild);

  return answers;
};

/**
 * Callback function for GP section button
 */
export const triggerGeneralProfilePopup = async (): Promise<QuestionnaireModel> => {
  const response = await getQuestionnaire(getUpmCategoryIds().GENERAL_PROFILE, true);

  if (response.questions.length > 0) {
    initializeSplitTests(SplitTestInitializationLocation.BEFORE_GP);

    // Store the check for the first time the user has seen the GP questionnaire
    const sessionId = getSessionId();
    setLocalStorage(LOCAL_STORAGE_IDS.HAS_SEEN_GP, sessionId, true);
    dispatchQuestionnaireStartEvent({
      ...response,
      isGeneralProfile: true
    });
  }

  return response;
};
