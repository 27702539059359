import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display        : flex;
  justify-content: space-between;
  margin         : 24px;
  border-top     : ${DESIGN_TOKENS.color.border.light.value};

  @media ${DEVICE_BREAKPOINT.maxSmallMobile} {
    margin: 16px;
  }
`;