export const PLATFORM_NAMINGS = {
  MAC_OS : 'MAC_OS',
  MAC    : 'MAC',
  macOS  : 'macOS',
  WINDOWS: 'Windows'
};

export enum BROWSER_TYPES {
  CHROME  = 'CHROME',
  FIREFOX = 'FIREFOX',
  EDGE    = 'EDGE',
}

export enum OS_TYPES {
  MAC_OS  = 'MAC_OS',
  WINDOWS = 'WINDOWS',
}