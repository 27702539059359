import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import { QuestionnaireContextTypes } from 'Shared/components/design/questionnairePopup/constants';
import { ButtonContainer } from 'Shared/components/design/questionnairePopup/navButtons/style';
import { NAV_BUTTONS_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import {ICON_IDS} from "Shared/components/icons/constants";

type NavButtonsProps = {
  contextType?       : QuestionnaireContextTypes;
  nextActive         : boolean;
  showPrevious       : boolean;
  nextCallback       : () => void;
  previousCallback   : () => void;
  isLastSlide        : boolean;
  isDateOfBirthSlide : boolean;
  isSingleQuestion  ?: boolean;
  isLoading         ?: boolean;
}

function NavButtons ({contextType, nextActive, showPrevious, nextCallback, previousCallback, isLastSlide, isDateOfBirthSlide, isSingleQuestion, isLoading}: NavButtonsProps): JSX.Element {
  const { t }      = useTranslation('questionnairePopup');
  const navigateTo = useNavigate();

  /**
   * Renders previous button conditionally
   */
  const renderPrevious = (): JSX.Element | undefined => {
    if (showPrevious) {
      return (
        <Button
          context  = {ContextTypes.SECONDARY}
          size     = {ButtonSizes.LARGE}
          onClick  = {previousCallback}
          disabled = {isLoading}
        >
          { t('Questionnaire--general-previous') }
        </Button>
      );
    }
  };

  /**
   * Renders cancel button conditionally
   */
  const renderCancel = (): JSX.Element | undefined => {
    return (
      <Button
        context = {ContextTypes.SECONDARY}
        size    = {ButtonSizes.LARGE}
        onClick = {() => {
          navigateTo('/help/profile-questions');
        }}
      >
        { t('Questionnaire--general-cancel') }
      </Button>
    );
  };

  /**
   * Renders Finish button
   */
  const renderFinishButton = () : JSX.Element => {
    return (
      <Button
        disabled  = {!nextActive || isLoading}
        iconRight = {ICON_IDS.ARROW_RIGHT}
        context   = {ContextTypes.PRIMARY}
        size      = {ButtonSizes.LARGE}
        width     = {showPrevious ? ButtonWidth.NORMAL : ButtonWidth.FULL}
        onClick   = {nextCallback}
        isLoading = {isLoading}
      >
        {t('Questionnaire--general-finish')}
      </Button>
    );
  };

  /**
   * Renders Finish button
   */
  const renderSaveButton = () : JSX.Element => {
    return (
      <Button
        disabled  = {!nextActive}
        context   = {ContextTypes.PRIMARY}
        size      = {ButtonSizes.LARGE}
        onClick   = {nextCallback}
      >
        {t('Questionnaire--general-save')}
      </Button>
    );
  };

  /**
   * Renders Finish button
   */
  const renderCancelPreviousButton = () : JSX.Element | undefined => {
    if (isSingleQuestion || !isLastSlide) {
      return renderCancel();
    } else if (showPrevious) {
      return renderPrevious();
    }
    return renderCancel();
  };

  /**
   * Renders Next button
   */
  const renderNextButton = (width?: ButtonWidth) : JSX.Element => {
    const btnWidth = width || (showPrevious ? ButtonWidth.NORMAL : ButtonWidth.FULL);
    return (
      <Button
        disabled  = {!nextActive}
        iconRight = {ICON_IDS.ARROW_RIGHT}
        context   = {ContextTypes.PRIMARY}
        size      = {ButtonSizes.LARGE}
        width     = {btnWidth}
        onClick   = {nextCallback}
      >
        {isDateOfBirthSlide ? t('Questionnaire--general-yes-continue') : t('Questionnaire--general-next')}
      </Button>
    );
  };

  /**
   * Renders Next Finish and Yes-Continue buttons conditionally
   */
  const renderNextFinishContinue = (): JSX.Element => {
    return <>
      { isLastSlide ?
        renderFinishButton() :
        renderNextButton()
      }
    </>;
  };

  /**
   * Renders Next and Save buttons conditionally
   */
  const renderNextSave = (): JSX.Element => {
    return <>
      { isLastSlide ?
        renderSaveButton() :
        renderNextButton(ButtonWidth.NORMAL)
      }
    </>;
  };

  const renderButtons = (): JSX.Element => {
    switch (contextType) {
      case QuestionnaireContextTypes.PRIMARY:
        return (
          <>
            {renderPrevious()}
            {renderNextFinishContinue()}
          </>
        );
      case QuestionnaireContextTypes.GP_EDITING:
        return (
          <>
            {renderCancelPreviousButton()}
            {renderNextSave()}
          </>
        );
      default:
        return (
          <>
            {renderPrevious()}
            {renderNextFinishContinue()}
          </>
        );
    }
  };

  return (
    <ButtonContainer data-testid={NAV_BUTTONS_TEST_ID}>
      {renderButtons()}
    </ButtonContainer>
  );
}

export default NavButtons;
