import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { StepperState, StepperTheme, StepperType } from 'Shared/components/design/stepper/Stepper';
import { PARAGRAPH } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type StepperItemProps = {
  isVisible: boolean;
  animateToStep: boolean;
}

type StepperStateProps = {
  isVisible   ?: boolean;
  isIcon      ?: boolean;
  state        : StepperState;
  type        ?: StepperType;
  noBorderIcon?: boolean;
  collapse    ?: boolean;
  theme        : StepperTheme;
}

const getStateBackgroundColor = (state: StepperState, theme: StepperTheme): string => {
  if (theme === StepperTheme.DEFAULT) {
    switch (state) {
      case StepperState.ACTIVE:
        return DESIGN_TOKENS.color.background.success.value;
      case StepperState.DEFAULT:
        return DESIGN_TOKENS.color.primitive.black.value;
      case StepperState.DISABLED:
        return DESIGN_TOKENS.color.border.normal.value;
    }
  } else if (theme === StepperTheme.PROMO) {
    switch (state) {
      case StepperState.ACTIVE:
        return '#8D4EEB';
      case StepperState.DEFAULT:
        return '#B3B3B3';
      case StepperState.DISABLED:
        return '#B3B3B3';
    }
  }
  return '';
};

const getStateLineColor = (state: StepperState, type?: StepperType): string => {
  if (state === StepperState.DISABLED) {
    return DESIGN_TOKENS.color.border.disabled.value;
  }

  if (type === StepperType.CHECK) {
    return DESIGN_TOKENS.color.border.success.light.value;
  } else {
    return DESIGN_TOKENS.color.border.light.value;
  }
};

const getStateIconStyle = (state: StepperState): string => {
  switch (state) {
    case StepperState.ACTIVE:
      return `
        background: ${DESIGN_TOKENS.color.background.success.value};
        border: 2px solid transparent;
        fill: ${DESIGN_TOKENS.color.text.onSuccess.value};
      `;
    case StepperState.DEFAULT:
      return `
        border: 2px solid ${DESIGN_TOKENS.color.primitive.gray['900'].value};
      `;
    case StepperState.DISABLED:
      return `
        border: 2px solid ${DESIGN_TOKENS.color.border.disabled.value};
      `;
  }
  return '';
};

const getStateColor = (state: StepperState): string => {
  switch (state) {
    case StepperState.ACTIVE:
      return DESIGN_TOKENS.color.text.onSuccess.value;
    case StepperState.DEFAULT:
      return DESIGN_TOKENS.color.primitive.black.value;
    case StepperState.DISABLED:
      return DESIGN_TOKENS.color.border.normal.value;
  }
  return '';
};

const getFadeInOut = (isVisible: boolean): string => {
  return `
    animation: ${ isVisible ? 'fadeInAnimation' : 'fadeOutAnimation' } normal forwards ease 0.3s;
    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fadeOutAnimation {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `;
};

export const IconContainer = styled.div`
  position: relative;
  height  : 25px;
  width   : 25px;
  margin  : 6px 0;

  &[role=button] {
    cursor: pointer;
  }
`;

export const IconDonut = styled.div<StepperStateProps>`
  position       : absolute;
  margin-left    : -9px;
  margin-top     : -9px;
  left           : 50%;
  top            : 50%;
  width          : 18px;
  height         : 18px;
  border-radius  : 18px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  background     : ${ ({state, theme}) => getStateBackgroundColor(state, theme) };
  ${ ({isVisible}) => getFadeInOut(!!isVisible) }
`;

export const IconDonutCenter = styled.div`
  width        : 4px;
  height       : 4px;
  border-radius: 8px;
  background   : ${DESIGN_TOKENS.color.primitive.white.value};
`;

export const IconDot = styled.div<StepperStateProps>`
  position     : absolute;
  border-radius: 8px;
  height       : 8px;
  width        : 8px;
  margin-left  : -4px;
  margin-top   : -4px;
  left         : 50%;
  top          : 50%;
  background   : ${ ({state, theme}) => getStateBackgroundColor(state, theme) };
  ${ ({isVisible}) => getFadeInOut(!!isVisible) }
`;

export const IconIcon = styled.div<StepperStateProps>`
  position   : absolute;
  margin-left: ${ ({isIcon}) => isIcon ? '-11px' : '-10px'};
  margin-top : ${ ({isIcon}) => isIcon ? '-11px' : '-10px'};
  left       : 50%;
  top        : 50%;
  height     : ${ ({isIcon}) => isIcon ? '22px' : '20px'};
  width      : ${ ({isIcon}) => isIcon ? '22px' : '20px'};
  ${ ({isVisible}) => getFadeInOut(!!isVisible) }

  svg {
    border-radius: 26px;
    overflow     : hidden;
    fill         : ${ ({state, theme}) => getStateBackgroundColor(state, theme) };
    height       : ${ ({isIcon}) => isIcon ? '22px' : '20px'};
    width        : ${ ({isIcon}) => isIcon ? '22px' : '20px'};
    ${ ({isIcon, state, noBorderIcon}) => isIcon && noBorderIcon ? getStateIconStyle(state) : '' };
  }
`;

export const IconNumeric = styled.div<StepperStateProps>`
  ${PARAGRAPH.DEFAULT}
  ${ ({state}) => getStateIconStyle(state) };
  color        : ${ ({state}) => getStateColor(state) };
  border-radius: 26px;
  line-height  : 23px;
  margin-left  : -12px;
  margin-top   : -12px;
  text-align   : center;
  position     : absolute;
  height       : 26px;
  width        : 26px;
  left         : 50%;
  top          : 50%;
  transition   : color 0.3s ease, background 0.3s ease, border-color 0.3s ease;
  ${ ({isVisible}) => getFadeInOut(!!isVisible) }
`;

export const StepperContainer = styled.div`
  width         : 100%;
  display       : flex;
  flex-direction: column;
`;

export const StepperItem = styled.div<StepperItemProps>`
  display       : flex;
  flex-direction: row;
  visibility    : ${ ({isVisible}) => isVisible ? 'visible' : 'hidden' };
  opacity       : ${ ({isVisible}) => isVisible ? '1' : '0' };
  transition    : ${ ({animateToStep}) => animateToStep ? 'all 0.3s ease' : 'none' };
  transform     : ${ ({isVisible}) => isVisible ? 'translateY(0)' : 'translateY(10px)' };
  max-height    : ${ ({isVisible}) => isVisible ? '400px' : '0' };
  min-height    : ${ ({isVisible}) => isVisible ? '45px' : '0' };
  overflow      : hidden;
`;

export const StepperSidebar = styled.div`
  flex          : 0 1 25px;
  width         : 22.5px;
  display       : flex;
  flex-direction: column;
  align-items   : center;
`;

export const StepperLine = styled.div<StepperStateProps>`
  flex-grow : 1;
  width     : 0;
  border    : 1px solid ${ ({state, type}) => getStateLineColor(state, type) };
  opacity   : ${ ({isVisible}) => isVisible ? '1' : '0' };
  transition: all 0.3s ease;
`;

export const StepperItemContent = styled.div`
  flex       : 1 1 0;
  margin-left: 16px;
`;

export const StepperTitle = styled.div<StepperStateProps>`
  ${PARAGRAPH.BOLD}
  line-height: 24px;
  margin     : 8px 0;
  color      : ${ ({state}) => state === StepperState.DISABLED ? DESIGN_TOKENS.color.text.disabled.value : DESIGN_TOKENS.color.text.normal.value };
  
  &[role=button] {
    cursor: pointer;
  }
`;

export const StepperDescription = styled.div<StepperStateProps>`
  ${PARAGRAPH.DEFAULT}
  color              : ${ ({state}) => state === StepperState.DISABLED ? DESIGN_TOKENS.color.text.disabled.value : DESIGN_TOKENS.color.text.secondary.value };
  padding-bottom     : 12px;
  margin-top         : -8px;
  display            : grid;
  grid-template-rows : ${(props) => props.collapse ? '0fr' : '1fr'};
  transition         : grid-template-rows 0.3s ease-in-out;
  transition-property: grid-template-rows, opacity;
`;

export const StepperDescriptionInner = styled.div`
  overflow: hidden;
`;
