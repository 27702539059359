import {
  surveyMedleyPopupManager
} from 'Pages/surveyMedley/components/surveyMedleyPopup/services/surveyMedleyPopupManager';
import { dynamicScreenerSplitTest } from 'Shared/services/opa/app/dynamicScreenerSplitTest';
import { surveyQualificationSplitTest } from 'Shared/services/opa/app/surveyQualificationSplitTest';
import { surveysSortSplitTest } from 'Shared/services/opa/app/surveysSortSplitTest';

export enum SplitTestInitializationLocation {
  SIGNIN         = 'SIGNIN',
  INIT_SIGNED_IN = 'INIT_SIGNED_IN',
  SIGNUP         = 'SIGNUP',
  BEFORE_GP      = 'BEFORE_GP',
  AFTER_GP       = 'AFTER_GP',
  EMAIL_SUBMIT   = 'EMAIL_SUBMIT',
  AUTOLOGIN      = 'AUTOLOGIN',
  WEBVIEW        = 'WEBVIEW',
  REWARDS_PAGE   = 'REWARDS_PAGE'
}

/**
 * All split tests that need to be initialized on the defined step
 */
const initializeSplitTests = (source: SplitTestInitializationLocation): void => {
  switch (source) {
    case SplitTestInitializationLocation.INIT_SIGNED_IN:
      break;
    case SplitTestInitializationLocation.EMAIL_SUBMIT:
      break;
    case SplitTestInitializationLocation.SIGNIN:
      surveysSortSplitTest();
      surveyMedleyPopupManager.manageVisitCookie();
      surveyQualificationSplitTest();
      dynamicScreenerSplitTest();
      break;
    case SplitTestInitializationLocation.SIGNUP:
      surveysSortSplitTest();
      surveyMedleyPopupManager.manageVisitCookie();
      break;
    case SplitTestInitializationLocation.BEFORE_GP:
      break;
    case SplitTestInitializationLocation.AFTER_GP:
      surveyQualificationSplitTest();
      surveyMedleyPopupManager.completeGeneralProfile();
      dynamicScreenerSplitTest();
      break;
    case SplitTestInitializationLocation.AUTOLOGIN:
      surveyQualificationSplitTest();
      dynamicScreenerSplitTest();
      break;
    case SplitTestInitializationLocation.WEBVIEW:
      break;
    case SplitTestInitializationLocation.REWARDS_PAGE:
      break;
    default:
      break;
  }
};

export default initializeSplitTests;
