import { getUserId } from 'App/services/idpTokenService';
import { getWurfl } from 'App/services/wurflService';
import { APP_INSTALL_STAGES, APP_TYPES } from 'Shared/constants';
import {
  BSS_DESKTOP_SIGNAL_TYPE,
  BSS_EXTENSION_SIGNAL_TYPE
} from 'Shared/services/bss/constants';
import { BssDataType } from 'Shared/services/bss/types/bssSendDataTypes';
import { UninstallSurveyType } from 'Shared/services/pis/types/installationFlowCheckTypes';

/**
 * Behavioral Signals Service data collector
 */
const bssDataCollector = (platform: string, stage: string) => {
  const wurflDeviceData = getWurfl();
  let eventId = 0;

  const userDeviceData: BssDataType = {
    created_internally: false,
    device_id         : generateDeviceUUID(),
    device_platform   : wurflDeviceData.advertised_device_os,
    user_device_agent : navigator.userAgent,
    user_id           : parseInt(getUserId()),
  };

  /**
   * Check the stage of the installation to send the correct event id
   */
  switch (stage) {
    case APP_INSTALL_STAGES.PRE:
      eventId = platform === APP_TYPES.EXTENSION ? BSS_EXTENSION_SIGNAL_TYPE.EXTENSION_TERMS_ACCEPTED : BSS_DESKTOP_SIGNAL_TYPE.DESKTOP_DOWNLOADED;
      break;
    case APP_INSTALL_STAGES.AFTER:
      eventId = platform === APP_TYPES.EXTENSION ? BSS_EXTENSION_SIGNAL_TYPE.EXTENSION_INSTALL : BSS_DESKTOP_SIGNAL_TYPE.DESKTOP_INSTALLED;
      break;
    case APP_INSTALL_STAGES.UNINSTALL:
      eventId = platform === APP_TYPES.EXTENSION ? BSS_EXTENSION_SIGNAL_TYPE.EXTENSION_UNINSTALL : BSS_DESKTOP_SIGNAL_TYPE.DESKTOP_UNINSTALLED;
      break;
    default:
      return eventId = 0;
  }

  if (platform === APP_TYPES.EXTENSION) {
    return {
      ...userDeviceData,
      event_id       : eventId,
      browser        : wurflDeviceData.advertised_browser,
      browser_version: wurflDeviceData.advertised_browser_version,
    };
  }

  if (platform === APP_TYPES.DESKTOP) {
    return {
      ...userDeviceData,
      event_id: eventId,
      version : wurflDeviceData.advertised_device_os_version,
    };
  }

  return {};
};

/**
 * Generate device UUID
 * @return {string}
 */
const generateDeviceUUID = () => {
  const timeStamp    = new Date().getTime();
  const randomNumber = Math.round(Math.random() * 1000);

  return getUserId() + '-' + timeStamp + '-' + randomNumber;
};

const findCheckedSurveyById = (questionsList: Array<UninstallSurveyType>, id?: number) => {
  if (!id) {
    return '';
  }
  return questionsList.find((survey: UninstallSurveyType) => survey.id === id) || '';
};

const findCheckedAnswerByAttribute = (answersList: Array<UninstallSurveyType>, isCustom?: boolean) => {
  if (!isCustom) {
    return false;
  }
  return answersList.find((survey: UninstallSurveyType) => survey.isCustom === isCustom)?.isCustom || false;
};

export {
  bssDataCollector,
  findCheckedAnswerByAttribute,
  findCheckedSurveyById,
  generateDeviceUUID
};
