import { COOKIE_IDS, PASSIVE_METERING_DATA_EXPIRY_IN_DAYS } from 'App/constants';
import { logError } from 'App/services/coralogixService';
import { setCookie } from 'Shared/services/cookieService';

/**
 * Sets the pis (pulse integration service) token object as pmdata (passive metering data)
 * @param passiveMeteringDataToken - data object from pulse integration service
 */
const setPassiveMeteringDataTokenCookie = (passiveMeteringDataToken: object): boolean => {
  try {
    setCookie(
      COOKIE_IDS.PASSIVE_METERING_DATA,
      JSON.stringify(passiveMeteringDataToken),
      PASSIVE_METERING_DATA_EXPIRY_IN_DAYS.ACCESS
    );
  } catch (error) {
    logError('setPassiveMeteringDataTokenCookie', error);
    return false;
  }

  return true;
};

export {
  setPassiveMeteringDataTokenCookie,
};
