import { SplitTest } from 'Shared/services/opa/api/opaApi';
import { getActiveDecisionVariables, getSplitTestConfigs } from 'Shared/services/opa/app/splitTestService';
import { OPA_FLAG, OPA_VARIABLE } from 'Shared/services/opa/constants';

export interface SurveyQualificationSplitTest extends SplitTest {
  variables: {
    [OPA_VARIABLE.SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS]: string | null,
    [OPA_VARIABLE.SURVEY_QUALIFICATION_SURVEY_MEDLEY_ENABLED] : boolean,
  }
}

export interface SurveyQualificationTestData {
  enabled            : boolean,
  providerIds        : number[],
  surveyMedleyEnabled: boolean,
}

/**
 * Parses the provider IDs string test variable into an array of numbers
 * @param pIds
 * @returns
 */
export const parseProviderIds = (pIds: string): number[] => {
  // check if empty string
  if (!pIds.trim()) {
      return [];
  }

  // split by comma and trim
  const numbers = pIds.split(',').map(part => part.trim());

  // check if all numbers
  for (const num of numbers) {
      if (isNaN(Number(num))) {
          return [];
      }
  }

  return numbers.map(Number);
};

/**
 * Buckets and fetches the active decision variation for Survey Qualification Split Test conditional on SSP Dynata migration test
 * @returns
 */
export const surveyQualificationSplitTest = (): SurveyQualificationSplitTest => {
  const testDecision = getSplitTestConfigs([OPA_FLAG.SURVEY_QUALIFICATION_QUESTIONS_WITH_POPUP]);
  const variables    = getActiveDecisionVariables(OPA_FLAG.SURVEY_QUALIFICATION_QUESTIONS_WITH_POPUP, testDecision);

  return {
    enabled: !!variables,
    variables: {
      [OPA_VARIABLE.SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS]: variables?.[OPA_VARIABLE.SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS],
      [OPA_VARIABLE.SURVEY_QUALIFICATION_SURVEY_MEDLEY_ENABLED] : variables?.[OPA_VARIABLE.SURVEY_QUALIFICATION_SURVEY_MEDLEY_ENABLED],
    }
  };
};

/**
 * Returns the properly formatted test data for Survey Qualification Split Test
 * @returns
 */
export const getSurveyQualificationTestData = (): SurveyQualificationTestData  => {
  const testData     = surveyQualificationSplitTest();
  const isUserInTest = testData.enabled && testData.variables[OPA_VARIABLE.SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS] !== '';

  if (!isUserInTest) {
    return {
      enabled            : false,
      providerIds        : [],
      surveyMedleyEnabled: true
    };
  }

  return {
    enabled            : true,
    providerIds        : parseProviderIds(testData.variables[OPA_VARIABLE.SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS] || ''),
    surveyMedleyEnabled: testData.variables[OPA_VARIABLE.SURVEY_QUALIFICATION_SURVEY_MEDLEY_ENABLED],
  };
};