import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfettiComponent from 'Shared/components/design/confetti/Confetti';
import Label from 'Shared/components/design/label/Label';
import PromoLockToolTip from 'Shared/components/design/promoNavButton/promoLockToolTip/PromoLockToolTip';
import PromoToolTip from 'Shared/components/design/promoNavButton/promoToolTip/PromoToolTip';
import { STEP_ICON_CONTAINER_ID } from 'Shared/components/design/promoWidget/constants';
import {
  CelebrationContainer,
  PromoContainer,
  PromoWidgetBackdrop,
  PromoWidgetBadge,
  PromoWidgetChildren,
  PromoWidgetChin,
  PromoWidgetDescription,
  PromoWidgetMainContent,
  PromoWidgetTitle,
  PromoWidgetTitleDescription,
  PromoWidgetTopLine,
  RewardMessage,
  ToolTipContainer
} from 'Shared/components/design/promoWidget/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { useTimeDelta } from 'Shared/hooks/useTimeDelta';
import { convertUTCToLocalTime } from 'Shared/services/helperService';
import { patchPromoTimezone, patchPromoUiState } from 'Shared/services/promo/api/promoApi';
import {
  CustomPROMOPromotion,
  getActivePromoData,
  PromoUiState
} from 'Shared/services/promo/app/promoService';

export type PromoWidgetProps = {
  children : React.ReactNode;
  promotion: CustomPROMOPromotion | null;
};
/**
 * Renders the expiration date of the promo
 * @returns
 */
export const PromoWidgetExpiration: React.FC<{ date: Date }> = ({ date }) => {
  const { t }                     = useTranslation('app');
  const { deltaString, isInPast } = useTimeDelta(date);

  if (isInPast) {
    return <span>{t('PromoWidget--expired')}</span>;
  }

  return <span>{t('PromoWidget--timeLeft', { delta: deltaString })}</span>;
};

/**
 * Renders the label of the promo widget
 * @returns
 */
export const PromoWidgetLabel: React.FC<{ date: Date }> = ({ date }) => {
  const { t }        = useTranslation('app');
  const { isInPast } = useTimeDelta(date);

  return (
    <Label variant={isInPast ? 'negative' : 'positive'}>
      {isInPast ? t('PromoWidget--expired') : t('PromoWidget--active')}
    </Label>
  );
};

/**
 * Renders the updated at timestamp of the promo widget
 * @returns
 */
export const PromoWidgetUpdatedAt: React.FC<{ date: string }> = ({ date }) => {
  const { t } = useTranslation('app');

  if (date === '') {
    return <></>;
  }

  const updatedAt = convertUTCToLocalTime(date);

  return <span>{t('PromoWidget--updatedAt', { time: updatedAt })}</span>;
};

export const PromoWidget: React.FC<PromoWidgetProps> = ({
  children,
  promotion
}) => {
  const { t }                           = useTranslation('app');
  const [isVisible, setIsVisible]       = useState(false);
  const [promoHovered, setPromoHovered] = useState(false);
  const [lockHovered, setLockHovered]   = useState(false);
  const {
    expiration,
    completed: isCompleted,
    completedAt: celebratedAt,
    confettiShown,
    updatedAt } = getActivePromoData(promotion);
  const expirationDate  = new Date(expiration);
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * Closes the widget on promo celebration and sets the timestamp to 2 hours ago to expire the promo
   */
  const handlePromoClose = (): void => {
    setIsVisible(false);
    // set the timestamp to 2 hours ago to expire the promo
    const expirePromoCompleted: PromoUiState = {
      promoCompletedTs: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
    };

    if (promotion?.id) {
      patchPromoUiState(promotion.id, expirePromoCompleted);
    }
  };

  /**
   * Update users timezone if it doesn't match the promo timezone (previously set by the user)
   */
  useEffect(() => {
    if (promotion?.id && (promotion?.timezone !== currentTimezone)) {
      patchPromoTimezone(promotion.id, currentTimezone);
    }
  }, [currentTimezone, promotion?.id, promotion?.timezone]);

  /**
   * If the promo is completed and not celebrated, patch the promoUiState
   */
  useEffect(() => {
    const completionPayload: PromoUiState = {
      promoCompletedTs: new Date().toISOString(),
    };

    if (isCompleted && !celebratedAt && promotion?.id) {
      patchPromoUiState(promotion.id, completionPayload);
    }
  }, [celebratedAt, isCompleted, promotion?.id]);


  /**
   * Set the visibility of the celebration after a 2-second delay
   */
  useEffect(() => {
    if (isCompleted && promotion?.points) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCompleted, promotion?.points]);

  if (!promotion) {
    return <></>;
  }

  /**
   * Renders the celebration animation
   * @returns
   */
  const renderCelebration = (): JSX.Element => {
    if (!isCompleted || !promotion.points) {
      return <></>;
    }

    return (
      <CelebrationContainer isVisible={isVisible} onClick={handlePromoClose}>
        <Icon iconId={ICON_IDS.CLOSE_FILL} height={24} width={24} />
        <RewardMessage fontSize="21px">
          {t('PromoWidget--celebration-title')}
        </RewardMessage>
        <RewardMessage fontSize="21px" style={{ marginBottom: '16px' }}>
          {t('PromoWidget--celebration-subTitle')}
        </RewardMessage>
        <RewardMessage fontSize="60px" style={{ marginBottom: '2px' }}>
          {promotion.points}
        </RewardMessage>
        <RewardMessage fontSize="21px">
          {t('PromoWidget--celebration-denomination')}
        </RewardMessage>
        <ConfettiComponent triggerConfetti={isVisible} promoId={promotion.id} confettiShown={confettiShown} />
      </CelebrationContainer>
    );
  };

  // Function to handle mouse move events by setting the promo hovered state
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>): void => {
    setPromoHovered(true);
    let hoveredElement = document.elementFromPoint(event.clientX, event.clientY) as HTMLElement | null;

    let isStepIconContainer = false;
    while (hoveredElement) {
      if (hoveredElement.id === STEP_ICON_CONTAINER_ID) {
        isStepIconContainer = true;
        break;
      }
      hoveredElement = hoveredElement.parentElement;
    }

    setLockHovered(isStepIconContainer);
  };

  const handleMouseLeave = (): void => {
    setPromoHovered(false);
    setLockHovered(false);
  };

  return (
    <PromoContainer>
      <PromoWidgetBackdrop>
        {renderCelebration()}
        <PromoWidgetMainContent onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
          <PromoWidgetTopLine>
            <PromoWidgetBadge>{t('PromoWidget--badge')}</PromoWidgetBadge>
            <PromoWidgetLabel date={expirationDate} />
          </PromoWidgetTopLine>
          <PromoWidgetTitleDescription>
            <PromoWidgetTitle>{promotion.title}</PromoWidgetTitle>
            <PromoWidgetDescription>{promotion.description}</PromoWidgetDescription>
          </PromoWidgetTitleDescription>
          <PromoWidgetChildren>{children}</PromoWidgetChildren>
        </PromoWidgetMainContent>
        <PromoWidgetChin>
          <PromoWidgetUpdatedAt date={updatedAt}/>
          <PromoWidgetExpiration date={expirationDate} />
        </PromoWidgetChin>
      </PromoWidgetBackdrop>
      <ToolTipContainer>
        <PromoToolTip closeTooltip={false} isMobile={false} showOnHover={promoHovered} />
        <PromoLockToolTip closeTooltip={false} showOnHover={lockHovered} />
      </ToolTipContainer>
    </PromoContainer>
  );
};
