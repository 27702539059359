import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EMAIL_STEP_NAMES,
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

/**
 * Triggered when a user starts the survey request
 * @returns void
 */
export const analyticsTrackEmailSurveyRequestStart = (): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.start,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]     : ANALYTICS_EVENT_TYPE.email,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type] : ANALYTICS_EVENT_SUB_TYPE.auto_email,
      [ANALYTICS_EVENT_ATTRIBUTES.step_name]: ANALYTICS_EMAIL_STEP_NAMES.email_survey_request
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.email, [event]);
};