import { PASSWORD_MAXIMUM_CHARACTERS, PASSWORD_MINIMUM_CHARACTERS } from 'App/constants/password';
import { TFunction } from 'i18next';
import { MINIMUM_NAME_LENGTH } from 'Pages/help/constants';
import { TextFieldTypes } from 'Shared/components/design/textField/TextField';
import { getAgeFromDate, isValidDate, isValidName, isValidPassword } from 'Shared/services/inputValidationService';

export const MAX_VALID_AGE_CONTINUE = 150;
export const MIN_VALID_AGE_CONTINUE = 5;

export type TextFieldAction = {
  type   : 'set';
  payload: string;
} | {
  type   : 'validate';
} | {
  type   : 'setIDPError';
};

export const TEXT_FIELD_INITIAL_STATE: TextFieldState = {
  fieldType: TextFieldTypes.DEFAULT,
  valid    : false,
  error    : null,
  value    : ''
};

export interface TextFieldState {
  fieldType: TextFieldTypes;
  valid    : boolean;
  error    : string | null;
  value    : string;
}

/**
 * TextField reducer
 * @param validator
 * @returns
 */
export const makeTextFieldReducer = (validator: (value: string) => string | null)  => {
  return (state: TextFieldState, action: TextFieldAction): TextFieldState => {
    switch (action.type) {
      case 'set': {
        const value = action.payload;
        const valid = !validator(value);

        return {
          ...state,
          fieldType: valid ? TextFieldTypes.DEFAULT : state.fieldType,
          error: valid ? '' : state.error,
          value,
          valid
        };
      }
      case 'validate': {
        const value = state.value;
        if (value.length > 0) {
          const error = validator(value);
          if (error) {
            return {
              ...state,
              fieldType: TextFieldTypes.ERROR,
              error,
              valid: false
            };
          } else {
            return {
              ...state,
              fieldType: TextFieldTypes.DEFAULT,
              error: null,
              valid: true
            };
          }
        }

        return {
          ...state,
          fieldType: TextFieldTypes.DEFAULT,
          error: null,
          valid: false
        };
      }
      case 'setIDPError': {
        const error = validator(state.value);
        if (error) {
          return {
            ...state,
            fieldType: TextFieldTypes.ERROR,
            error,
            valid: false
          };
        } else {
          return {
            ...state,
            fieldType: TextFieldTypes.DEFAULT,
            error: null,
            valid: true
          };
        }
      }
    }
  };
};

/**
 * Validates date of birth
 * @param value
 * @returns
 */
export const validateDateOfBirth = (value: string) => {
  const date  = new Date(value);
  const age   = getAgeFromDate(date);
  const valid = isValidDate(value) && age <= MAX_VALID_AGE_CONTINUE && age >= MIN_VALID_AGE_CONTINUE;

  return valid;
};

/**
 * Date of birth validator
 * @param t
 * @returns
 */
export const makeDobValidator = (t: TFunction) => {
  return (dob: string): string | null => {
    if (!validateDateOfBirth(dob)) {
      return t('CreateAccount--Input-dobDefaultError');
    }

    return null;
  };
};

/**
 * Name validator
 * @param t
 * @returns
 */
export const makeNameValidator = (t: TFunction) => {
  return (name: string): string | null => {
    if (!isValidName(name)) {
      return t('CreateAccount--Input-mustBeLength', { minimum: MINIMUM_NAME_LENGTH });
    }

    return null;
  };
};

/**
 * Password validator
 * @param t
 * @returns
 */
export const makePasswordValidator = (t: TFunction)=> {
  return (pass: string): string | null => {
    if (!isValidPassword(pass)) {
      return t('CreateAccount--Input-mustBeLengthNoSpaces', { minimum: PASSWORD_MINIMUM_CHARACTERS, maximum: PASSWORD_MAXIMUM_CHARACTERS });
    }

    return null;
  };
};

/**
 * Returns the minimum year for the date of birth
 * @returns
 */
export const getMinimumYear =  (): Date => {
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - MAX_VALID_AGE_CONTINUE);

  return minDate;
};

/**
 * Returns the maximum year for the date of birth
 * @returns
 */
export const getMaximumYear = (): Date => {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());

  return maxDate;
};

/**
 * Converts dob input to age
 * @param dobInput
 * @returns
 */
export const getAgeFromDobInput = (dobInput: string): number => {
  const dobDate = new Date(dobInput);
  const age     = getAgeFromDate(dobDate);

  return age;
};