import React, { ReactNode, useState } from 'react';
import { Button, ButtonSizes, ContextTypes } from 'Shared/components/design/button/Button';
import { MODAL_TEST_IDS } from 'Shared/components/design/modal/constants';
import {
  ModalButtonContainer,
  ModalCloseIcon,
  ModalContainer,
  ModalContentContainer,
  ModalText,
  ModalTitle} from 'Shared/components/design/modal/style';
import {ICON_IDS} from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

type ModalProps = {
  title              : string | ReactNode | ReactNode[];
  text?              : string | ReactNode | ReactNode[];
  showCloseIcon?     : boolean;
  cancelButtonText?  : string;
  confirmButtonText  : string;
  confirmExternalURL?: boolean;
  closeIconClick?    : () => void;
  cancelButtonClick? : () => void;
  confirmButtonClick?: () => void;
}

function Modal ({
  title,
  text,
  showCloseIcon = false,
  cancelButtonText,
  confirmButtonText,
  confirmExternalURL,
  closeIconClick,
  cancelButtonClick,
  confirmButtonClick
}: ModalProps) {
  if (!title) {
    throw new Error(`Modal Component Error: Modal component is missing title`);
  }

  const [displayComponent, setDisplayComponent] = useState<boolean>(true);

  /**
   * Close icon clicked
   */
  const closeIconClicked = (): void => {
    setDisplayComponent(false);

    if (closeIconClick) {
      closeIconClick();
    }
  };

  /**
   * Close icon clicked
   */
  const confirmButtonClicked = (): void => {
    if (confirmButtonClick) {
      confirmButtonClick();
    }

    setDisplayComponent(false);
  };

  /**
   * Close icon clicked
   */
  const cancelButtonClicked = (): void => {
    if (cancelButtonClick) {
      cancelButtonClick();
    }

    setDisplayComponent(false);
  };

  /**
   * Conditionally render the close button if close is set to true
   */
  const renderCloseIcon = (): JSX.Element | undefined => {
    if (showCloseIcon === true) {
      return (
        <ModalCloseIcon onClick={ () => closeIconClicked() } data-testid={MODAL_TEST_IDS.MODAL_TEST_CLOSE_ICON}>
          <Icon iconId={ ICON_IDS.CLOSE_FILL } />
        </ModalCloseIcon>
      );
    }
  };

  /**
   * Conditionally render title
   */
  const renderTitle = (): JSX.Element | undefined => {
    if (title) {
      return (
        <ModalTitle>
          { title }
        </ModalTitle>
      );
    }
  };

  /**
   * Conditionally render text
   */
  const renderText = (): JSX.Element | undefined => {
    if (text) {
      return (
        <ModalText>
          { text }
        </ModalText>
      );
    }
  };

  /**
   * Conditionally render cancel button
   */
  const renderCancelButton = (): JSX.Element | undefined => {
    if (cancelButtonText) {
      return (
        <Button size={ ButtonSizes.LARGE} context={ ContextTypes.SECONDARY } onClick={ () => cancelButtonClicked && cancelButtonClicked() } testId={MODAL_TEST_IDS.MODAL_TEST_CANCEL_BUTTON}>
          { cancelButtonText }
        </Button>
      );
    }
  };

  /**
   * Conditionally render confirm button
   */
  const renderConfirmButton = (): JSX.Element | undefined => {
    if (confirmButtonText) {
      return (
        <Button
          size      = { ButtonSizes.LARGE }
          context   = { ContextTypes.AUXILIARY }
          onClick   = { () => confirmButtonClicked && confirmButtonClicked() }
          testId    = { MODAL_TEST_IDS.MODAL_TEST_CONFIRM_BUTTON }
          iconRight = { confirmExternalURL ? ICON_IDS.EXTERNAL_LINK : undefined }
        >
          { confirmButtonText }
        </Button>
      );
    }
  };

  /**
   * Render the component
   */
  const renderModalContainer = (): JSX.Element | undefined => {
    return (
      <ModalContainer>
        <ModalContentContainer data-testid={MODAL_TEST_IDS.MODAL_TEST_CONTAINER}>
          { renderCloseIcon() }
          { renderTitle() }
          { renderText() }
          <ModalButtonContainer>
            { renderConfirmButton() }
            { renderCancelButton() }
          </ModalButtonContainer>
        </ModalContentContainer>
      </ModalContainer>
    );
  };

  return (
    <>
      { displayComponent ? renderModalContainer() : '' }
    </>
  );
}

export {
  Modal
};
