import React, { useEffect } from 'react';
import { initGetSurveys,SURVEY_REQUEST_REFERRER } from 'Pages/surveyExperience/services/surveyService';
import EmailCompletedError from 'Shared/components/design/email/completedError';
import EmailExpiredError from 'Shared/components/design/email/expiredError';
import EmailGenericError from 'Shared/components/design/email/genericError';
import EmailJwtMismatchError from 'Shared/components/design/email/jwtMismatchError';
import EmailLoading from 'Shared/components/design/email/loading';
import EmailNotFoundError from 'Shared/components/design/email/notFoundError';
import AERedirecting from 'Shared/components/design/email/redirecting';
import EmailSurveyFound from 'Shared/components/design/email/surveyFound';
import { RDT_ERROR_VIEW_SIZE, RdtErrorView, RdtSpecificError } from 'Shared/constants/email/errors';
import { ANALYTICS_RESPONSE_CODES } from 'Shared/services/analytics/constants';
import { analyticsTrackEmailSurveyRequestEnd } from 'Shared/services/analytics/events/emailedSurveyRequestEnd';
import { analyticsTrackEmailSurveyRequestStart } from 'Shared/services/analytics/events/emailedSurveyRequestStart';
import { useAutoEmailSurvey } from 'Shared/services/rdt/app/rdtService';

interface Props {
  aeId          : string;
  aeCId         : string;
  umid          : string;
  aeT           : string;
  autoEmailKey  : string;
  stillLoading  : boolean;
  setDoneLoading: (done: boolean) => void;
}

/**
 * Loads the Auto Email data and renders the survey
 */
function AEDataLoader({ aeId, aeCId, umid, aeT, autoEmailKey, stillLoading, setDoneLoading }: Props): JSX.Element {
  const { data, isLoading, error }                                 = useAutoEmailSurvey({
    aeId,
    aeCId,
    umid,
    aeT,
    autoEmailKey
  });

  useEffect(() => {
    if (!isLoading) {
      setDoneLoading(true);
    } else {
      analyticsTrackEmailSurveyRequestStart();
    }
  }, [isLoading, setDoneLoading]);

  useEffect(() => {
    if (data?.survey) {
      analyticsTrackEmailSurveyRequestEnd();
    } else if (error) {
      const errorCode = (error?.response?.data?.errorCodes ?? [])[0] || ANALYTICS_RESPONSE_CODES.error;
      analyticsTrackEmailSurveyRequestEnd(errorCode);
    }
  }, [data, error]);

  useEffect(() => {
    if (!isLoading) {
      const errorCodes: number[] = error?.response?.data?.errorCodes ?? [];
      if (data?.survey || errorCodes.length) {
        initGetSurveys(SURVEY_REQUEST_REFERRER.AUTO_EMAIL);
      }
    }
  }, [data, error, isLoading]);

  if (data?.survey) {
    return (
      <EmailSurveyFound
        survey={data.survey}
      />
    );
  } else if (error) {
    const errorCodes: number[] = error?.response?.data?.errorCodes ?? [];
    const errorScreen = errorCodes.length > 0 ? Math.floor(errorCodes[0] / RDT_ERROR_VIEW_SIZE) : 4;

    // eslint-disable-next-line no-console
    console.warn({ errorCodes });

    if (errorCodes[0] === RdtSpecificError.JwtRequestUserIdMismatch) {
      return <EmailJwtMismatchError errorCodes={errorCodes} />;
    }

    switch (errorScreen) {
      case RdtErrorView.NotFound:
        return <EmailNotFoundError errorCodes={errorCodes} />;
      case RdtErrorView.Completed:
        return <EmailCompletedError errorCodes={errorCodes} />;
      case RdtErrorView.Expired:
        return <EmailExpiredError errorCodes={errorCodes} />;
      case RdtErrorView.Timeout:
        return <AERedirecting reason='too-long' />;
      default:
        return <EmailGenericError errorCodes={errorCodes} />;
    }
  } else if (isLoading) {
    return <EmailLoading stillLoading={stillLoading} />;
  } else {
    return <EmailGenericError />;
  }
}

export default AEDataLoader;
