import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

const MODAL_WIDTH = 480;

export const Container = styled.div`
  position     : relative;
  box-shadow   : ${ DESIGN_TOKENS.shadow.lg.value };
  width        : ${ MODAL_WIDTH }px;
  max-width    : 100%;
  padding      : 40px 24px 24px;
  background   : ${ DESIGN_TOKENS.color.primitive.purple['100'].value };
  border       : 1px solid ${ DESIGN_TOKENS.color.primitive.gray['200'].value };
  border-radius: 8px;
`;

export const Text = styled.div`
  ${ PARAGRAPH.DEFAULT }
  color: ${ DESIGN_TOKENS.color.text.secondary.value };
`;

export const Title = styled.div`
  ${ TITLES_NORMAL.FT_03 }
  color        : ${ DESIGN_TOKENS.color.text.normal.value };
  margin-bottom: 16px;

  svg {
    width         : 26px;
    height        : 26px;
    fill          : ${ DESIGN_TOKENS.color.text.normal.value };
    vertical-align: text-top;
  }
`;

export const ButtonContainer = styled.div`
  padding-top   : 32px;
  display       : flex;
  gap           : 16px;
  flex-direction: row-reverse;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    flex-direction: column;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  cursor  : pointer;
  right   : 18px;
  top     : 26px;

  svg {
    width : 20px;
    height: 20px;
  }
`;