import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Loading from 'App/components/loading';
import UserApiPatchModel from 'App/models/UserApiPatchModel';
import { logError } from 'App/services/coralogixService';
import { logUnderAgeUserOut } from 'App/services/idpTokenService';
import Confirmation from 'Pages/createAccount/components/confirmation/Confirmation';
import DateField from 'Pages/createAccount/components/dateField/DateField';
import CreateAccountIntroduction from 'Pages/createAccount/components/introduction/Introduction';
import CreateAccountQuestion from 'Pages/createAccount/components/question/Question';
import {
  ACTIONABLE_IDP_ERROR_CODES,
  IDP_GENDER_VALUES,
  SIGNUP_SOURCE_DIRECT,
  SIGNUP_STEPS,
  STEPS_TOTAL
} from 'Pages/createAccount/constants/constants';
import { CREATE_ACCOUNT_TEST_IDS } from 'Pages/createAccount/constants/testIds';
import { trackBasicProfileAgeConfirmationEnd, trackSignupStep, trackUnderageProfileSubmission } from 'Pages/createAccount/service/analytics';
import {
  getAgeFromDobInput,
  getMaximumYear,
  getMinimumYear,
  makeDobValidator,
  makeNameValidator,
  makePasswordValidator,
  makeTextFieldReducer,
  MAX_VALID_AGE_CONTINUE,
  TEXT_FIELD_INITIAL_STATE,
  validateDateOfBirth
} from 'Pages/createAccount/service/inputValidation';
import { getProgressBarState, isSignedUp } from 'Pages/createAccount/service/signupProgress';
import {
  CreateAccountMainWrapper,
  CreateAccountProgressBar,
  CreateAccountProgressFill,
  CreateAccountQuestionBanner,
  CreateAccountStepCounter,
  CreateAccountWrapper,
  InputRow,
  NameInput,
  PopupBackground,
  PopupContentContainer,
  SadFaceEmoji,
  TextSubTitle,
  TextTitle
} from 'Pages/createAccount/style';
import {
  getUpmCategoryIds,
  IDP_ERROR_BAD_POST_CODE,
  IDP_ERROR_FIRST_NAME_CANNOT_BE_EMPTY,
  IDP_ERROR_INVALID_DATE_TIME_VALUE,
  IDP_ERROR_LAST_NAME_CANNOT_BE_EMPTY,
  IDP_ERROR_PASSWORD_FAILED_VALIDATION,
  MINIMUM_AGE
} from 'Pages/help/constants';
import { LAST_NAME_TEST_ID } from 'Pages/help/testId';
import { FIRST_NAME_TEST_ID, POST_CODE_INPUT_TEST_IDS } from 'Pages/signIn/components/header/testId';
import sadFace from 'Shared/assets/images/sadFace.png';
import { BannerContextTypes } from 'Shared/components/design/banner/Banner';
import { Modal } from 'Shared/components/design/modal/Modal';
import PasswordInput from 'Shared/components/design/passwordInput/PasswordInput';
import { NEW_PASSWORD } from 'Shared/components/design/passwordInput/testId';
import { RadioGroup } from 'Shared/components/design/radio/RadioGroup';
import { TextField, TextFieldTypes } from 'Shared/components/design/textField/TextField';
import { ICON_IDS } from 'Shared/components/icons/constants';
import { IDPValidationErrorResponse } from 'Shared/models/swagger/idp';
import {
  ANALYTICS_RESPONSE_MESSAGE
} from 'Shared/services/analytics/constants';
import { analyticsTrackBasicProfileAgeConfirmationStart } from 'Shared/services/analytics/events/basicProfileAgeConfirmationStart';
import { analyticsTrackPastedBasicProfileEnd } from 'Shared/services/analytics/events/pastedBasicProfileEnd';
import { APIResponse } from 'Shared/services/apiInterface';
import { convertDateInputToDateISO } from 'Shared/services/helperService';
import {
  EMPTY_POSTAL_CODE,
  getNormalizedZipCode,
  INVALID_COUNTRY_CODE,
  isValidPostcode,
  VALID_COUNTRY_CODE
} from 'Shared/services/inputValidationService';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { navigateToMemberPage } from 'Shared/services/routingService';
import { useGetUserInfo, usePatchUserInfo } from 'Shared/services/userActivation/api/idpApiService';
import { getQuestionnaire } from 'Shared/services/userActivation/app/questionService';
import { getCountryCodeFromZipCode } from 'Shared/utils/countryCode';

/**
 * Renders the create account/signup flow page
 * @returns
 */
function CreateAccount(): JSX.Element {
  const { t } = useTranslation('createAccount');
  const [currentStep, setCurrentStep]             = useState(SIGNUP_STEPS.INTRO);
  const [postalCode, setPostalCode]               = useState('');
  const [postalCodeError, setPostalCodeError]     = useState('');
  const [gender, setGender]                       = useState<string | null>(null);
  const [firstNameState, firstNameDispatch]       = useReducer(makeTextFieldReducer(makeNameValidator(t)), TEXT_FIELD_INITIAL_STATE);
  const [lastNameState, lastNameDispatch]         = useReducer(makeTextFieldReducer(makeNameValidator(t)), TEXT_FIELD_INITIAL_STATE);
  const [passwordState, passwordDispatch]         = useReducer(makeTextFieldReducer(makePasswordValidator(t)), TEXT_FIELD_INITIAL_STATE);
  const [isAgeLimitVisible, setIsAgeLimitVisible] = useState(false);
  const [dobState, dobStateDispatch]              = useReducer(makeTextFieldReducer(makeDobValidator(t)), TEXT_FIELD_INITIAL_STATE);
  const [showAgePopup, setShowAgePopup]           = useState(false);
  const [pastedContent, setPastedContent]         = useState<string>('');

  const {
    isMutating: isPatching,
    trigger: triggerPatch,
  } = usePatchUserInfo();

  const {
    data: userInfo,
  } = useGetUserInfo();

  const navigate = useNavigate();

  /**
   * Pre-fetch the GP questionnaire so that when the user lands on the survey experience
   * page, it doesn't have to load the data before showing it.
   */
  useEffect(() => {
    getQuestionnaire(getUpmCategoryIds().GENERAL_PROFILE);
  }, []);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    trackSignupStep(userInfo, currentStep, STEPS_TOTAL);
  }, [currentStep, userInfo]);

  /**
   * Handle pasted content:
   * only adds a fieldName if is not already contained in the string
   * @param fieldName
   */
  const handlePaste = (fieldName: string): void => {
    setPastedContent(prev => {
      if (!prev.split(' ').includes(fieldName)) {
        return prev ? `${prev} ${fieldName}` : fieldName;
      }
      return prev;
    });
  };

  async function submitUnderage () {
    const userInfoUpdate: UserApiPatchModel = {
      dateOfBirth: convertDateInputToDateISO(dobState.value)
    };

    // Track that the user did say they are under age
    trackBasicProfileAgeConfirmationEnd(ANALYTICS_RESPONSE_MESSAGE.confirm);

    try {
      await triggerPatch(userInfoUpdate);
    } finally {
      trackUnderageProfileSubmission(userInfo, currentStep, STEPS_TOTAL);
      setIsAgeLimitVisible(true);
      logUnderAgeUserOut();
      setShowAgePopup(false);
    }
    return userInfoUpdate;
  }

  // Hide the under age popup since they
  // said they aren't under age
  function hideUnderagePopup() {
    setShowAgePopup(false);
    trackBasicProfileAgeConfirmationEnd(ANALYTICS_RESPONSE_MESSAGE.cancel);
  }

  // On blur, check if the postal code is valid
  const handleBlurCallback = (val: string) => {
    switch (isValidPostcode(val)) {
      case INVALID_COUNTRY_CODE:
        setPostalCodeError(t('CreateAccount--Input-postalCodeInvalid-country'));
        break;
      case EMPTY_POSTAL_CODE:
        setPostalCodeError(t('CreateAccount--Input-postalCodeInvalid'));
        break;
      default:
        setPostalCodeError('');
    }
  };

  /**
   * Handles a set of error codes from the IDP on user info submit
   * @param errorCode
   */
  const handleIdpErrorCode = (errorCode: number): void => {
    switch (errorCode) {
      case IDP_ERROR_BAD_POST_CODE:
        setCurrentStep(SIGNUP_STEPS.POSTAL_CODE);
        setPostalCodeError(t('CreateAccount--Input-postalCodeInvalid'));
        break;
      case IDP_ERROR_PASSWORD_FAILED_VALIDATION:
        passwordDispatch({ type: 'setIDPError' });
        break;
      case IDP_ERROR_FIRST_NAME_CANNOT_BE_EMPTY:
        firstNameDispatch({ type: 'setIDPError' });
        break;
      case IDP_ERROR_LAST_NAME_CANNOT_BE_EMPTY:
        lastNameDispatch({ type: 'setIDPError' });
        break;
      case IDP_ERROR_INVALID_DATE_TIME_VALUE:
        dobStateDispatch({ type: 'setIDPError' });
        setCurrentStep(SIGNUP_STEPS.DATE_OF_BIRTH);
        break;
    }
  };

  async function submit () {
    const userInfoUpdate: UserApiPatchModel = {
      firstName : firstNameState.value                  || userInfo?.firstName,
      lastName  : lastNameState.value                   || userInfo?.lastName,
      gender    : (gender ? gender : undefined)         || userInfo?.gender,
      postalCode: getNormalizedZipCode(postalCode)      || userInfo?.postalCode,
      country   : getCountryCodeFromZipCode(postalCode) || userInfo?.country,
    };

    // Set the date of birth if we don't have a value
    try {
      userInfoUpdate.dateOfBirth = userInfo?.dateOfBirth ? userInfo.dateOfBirth.toISOString() : convertDateInputToDateISO(dobState.value);
    } catch (error) {
      // do nothing
    }

    if (userInfo?.signupSource === SIGNUP_SOURCE_DIRECT) {
      userInfoUpdate.password = passwordState.value;
    }

    // Initialize split tests
    initializeSplitTests(SplitTestInitializationLocation.SIGNUP);

    // track pasted content
    if (pastedContent !== '') {
      analyticsTrackPastedBasicProfileEnd(pastedContent);
    }

    try {
      await triggerPatch(userInfoUpdate);
      setCurrentStep(SIGNUP_STEPS.CONFIRMATION);
    } catch (error: any) {
      const response = 'response' in error ? error.response as APIResponse<IDPValidationErrorResponse> : null;
      const errorCode = response?.data.errorCodes[0];

      if (errorCode && ACTIONABLE_IDP_ERROR_CODES.includes(errorCode)) {
        handleIdpErrorCode(errorCode);
      }
      logError('createAccount_Submit', error);
    } finally {
      setShowAgePopup(false);
    }
    return userInfoUpdate;
  }
  /**
   * Renders messaging for underage signup attempts
   * @returns
   */
  function renderAgeLimitMessage () {
    if (!isAgeLimitVisible) {
      return;
    }

    return (
      <PopupBackground visible>
        <PopupContentContainer>
          <SadFaceEmoji src={sadFace} alt={t('CreateAccount--AgeLimitMessage-imageDescription')} />
          <TextTitle>
            {t('CreateAccount--AgeLimitMessage-title')}
          </TextTitle>
          <TextSubTitle>
            {t('CreateAccount--AgeLimitMessage-subTitle')}
          </TextSubTitle>
        </PopupContentContainer>
      </PopupBackground>
    );
  }

  /**
   * Renders messaging for user to input information that matches their id
   * @returns {JSX.Element | null}
   */
  function renderMessageBanner (): JSX.Element | null {
    return (
      <CreateAccountQuestionBanner
        context={BannerContextTypes.INFO}
        data-testid={CREATE_ACCOUNT_TEST_IDS.INFO_BANNER}
        icon={ICON_IDS.INFO_FILL}
        text={t('CreateAccount--InfoMatching--description')}
      />
    );
  }

  function renderCurrentStep () {
    switch (currentStep) {
      case SIGNUP_STEPS.INTRO: {
        // Redirect
        if (isSignedUp(userInfo)) {
          navigateToMemberPage(navigate);
          return <Loading />;
        }

        return (
          <CreateAccountIntroduction
            onContinue={() => setCurrentStep(SIGNUP_STEPS.POSTAL_CODE)}
          />
        );
      }
      case SIGNUP_STEPS.POSTAL_CODE: {
        const disable = isValidPostcode(postalCode) !== VALID_COUNTRY_CODE;

        return (
          <CreateAccountQuestion
            onContinue={() => {
              if (disable) { return; }
              setCurrentStep(SIGNUP_STEPS.DATE_OF_BIRTH);
            }}
            question={t('CreateAccount--Question-postalCode')}
            disableButton={disable}
          >
            <TextField
              data-testid={POST_CODE_INPUT_TEST_IDS.POSTCODE_FIELD}
              label={t('CreateAccount--Input-postalCode')}
              value={postalCode}
              changeCallback={setPostalCode}
              blurCallback={handleBlurCallback}
              fieldType={postalCodeError ? TextFieldTypes.ERROR : TextFieldTypes.DEFAULT}
              helperText={postalCodeError}
              onPaste={() => handlePaste(ANALYTICS_RESPONSE_MESSAGE.zipcode)}
            />
          </CreateAccountQuestion>
        );
      }
      case SIGNUP_STEPS.DATE_OF_BIRTH: {
        return (
          <CreateAccountQuestion
            onContinue={() => {
              if (getAgeFromDobInput(dobState.value) < MINIMUM_AGE || getAgeFromDobInput(dobState.value) > MAX_VALID_AGE_CONTINUE) {
                setShowAgePopup(true);
                analyticsTrackBasicProfileAgeConfirmationStart();
                return;
              }

              const valid = validateDateOfBirth(dobState.value);
              dobStateDispatch({ type: 'validate' });

              if (!valid) {
                return;
              }

              setCurrentStep(SIGNUP_STEPS.GENDER);
            }}
            question={t('CreateAccount--Question-dob')}
            disableButton={!dobState.valid}
          >
            {renderMessageBanner()}
            <DateField
              label     = {t('CreateAccount--Input-dob')}
              helpText  = {t('CreateAccount--DoB-popup-text')}
              value     = {dobState.value}
              onChange  = {(value) => dobStateDispatch({ type: 'set', payload: value })}
              onBlur    = {() => dobStateDispatch({ type: 'validate' })}
              error     = {dobState.error ?? ''}
              fieldType = {dobState.fieldType}
              min       = {getMinimumYear()}
              max       = {getMaximumYear()}
            />

            <PopupBackground visible={showAgePopup}>
              {showAgePopup && <Modal
                title              = {t('CreateAccount--DoB-popup-title', { age: getAgeFromDobInput(dobState.value) })}
                text               = {t('CreateAccount--DoB-popup-text')}
                confirmButtonText  = {t('CreateAccount--DoB-popup-yes')}
                cancelButtonText   = {t('CreateAccount--DoB-popup-no')}
                showCloseIcon      = {false}
                confirmButtonClick = {submitUnderage}
                cancelButtonClick  = {hideUnderagePopup}
              />}
            </PopupBackground>
          </CreateAccountQuestion>
        );
      }
      case SIGNUP_STEPS.GENDER: {
        const disable = gender === null;
        return (
          <CreateAccountQuestion
            onContinue={() => {
              if (disable) { return; }
              setCurrentStep(SIGNUP_STEPS.NAME);
            }}
            question={t('CreateAccount--Question-gender')}
            disableButton={disable}
          >
            {renderMessageBanner()}
            <RadioGroup
              radioButtons={[
                {
                  label: 'Male',
                  answerValue: IDP_GENDER_VALUES.MALE,
                  selected: gender === IDP_GENDER_VALUES.MALE
                },
                {
                  label: 'Female',
                  answerValue: IDP_GENDER_VALUES.FEMALE,
                  selected: gender === IDP_GENDER_VALUES.FEMALE
                }
              ]}
              changeCallback    = {setGender}
              isColorBackground = {true}
              groupName         = 'gender'
              valueType         = 'string'
            />
          </CreateAccountQuestion>
        );
      }
      case SIGNUP_STEPS.NAME: {
        const showPasswordField = userInfo?.signupSource === SIGNUP_SOURCE_DIRECT;
        const valid             = firstNameState.valid && lastNameState.valid && (passwordState.valid || !showPasswordField);

        return (
          <CreateAccountQuestion
            onContinue={() => {
              if (!valid) { return; }
              submit();
            }}
            question      = {t('CreateAccount--Question-name')}
            buttonText    = {t('CreateAccount--Question-finish')}
            disableButton = {!valid}
            loading       = {isPatching}
          >
            {renderMessageBanner()}
            <InputRow>
              <NameInput
                data-testid    = {FIRST_NAME_TEST_ID}
                label          = {t('CreateAccount--Input-firstName')}
                value          = {firstNameState.value}
                fieldType      = {firstNameState.fieldType}
                changeCallback = {(payload) => firstNameDispatch({ type: 'set', payload })}
                blurCallback   = {() => firstNameDispatch({ type: 'validate' })}
                helperText     = {firstNameState.error ?? ''}
                onPaste        = {() => handlePaste(ANALYTICS_RESPONSE_MESSAGE.fname)}
              />
              <NameInput
                data-testid    = {LAST_NAME_TEST_ID}
                label          = {t('CreateAccount--Input-lastName')}
                value          = {lastNameState.value}
                fieldType      = {lastNameState.fieldType}
                changeCallback = {(payload) => lastNameDispatch({ type: 'set', payload })}
                blurCallback   = {() => lastNameDispatch({ type: 'validate' })}
                helperText     = {lastNameState.error ?? ''}
                onPaste        = {() => handlePaste(ANALYTICS_RESPONSE_MESSAGE.lname)}
              />
            </InputRow>
            {showPasswordField && <PasswordInput
              data-testid    = {NEW_PASSWORD}
              label          = {t('CreateAccount--Input-password')}
              fieldType      = {passwordState.fieldType}
              changeCallback = {(payload) => passwordDispatch({ type: 'set', payload })}
              blurCallback   = {() => passwordDispatch({ type: 'validate' })}
              helperText     = {passwordState.error ?? ''}
              initialValue   = {passwordState.value}
            />}
          </CreateAccountQuestion>
        );
      }
      case SIGNUP_STEPS.CONFIRMATION: {
        return <Confirmation />;
      }
    }
  }

  return (
    <CreateAccountWrapper data-testid={CREATE_ACCOUNT_TEST_IDS.INDEX}>
      <CreateAccountStepCounter hidden={currentStep === SIGNUP_STEPS.CONFIRMATION}>
        {t('CreateAccount--StepCounter', { current: currentStep === SIGNUP_STEPS.CONFIRMATION ? 5 : currentStep + 1, total: STEPS_TOTAL })}
      </CreateAccountStepCounter>
      <CreateAccountProgressBar hidden={currentStep === SIGNUP_STEPS.CONFIRMATION}>
        <CreateAccountProgressFill progress={getProgressBarState(currentStep)} />
      </CreateAccountProgressBar>
      <CreateAccountMainWrapper>
        {renderCurrentStep()}
      </CreateAccountMainWrapper>
      {renderAgeLimitMessage()}
    </CreateAccountWrapper>
  );
}

export default CreateAccount;
