
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import SurveyCard from 'Pages/surveyExperience/components/surveyCard/SurveyCard';
import styled from 'styled-components';

export const ShadowSurveyCard = styled(SurveyCard)`
  box-shadow: ${DESIGN_TOKENS.shadow.md.value};

  :hover {
    box-shadow: ${DESIGN_TOKENS.shadow.md.value};
  }
`;
