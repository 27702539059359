import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, LABEL_SMALL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface QuestionTrackerProps {
  isModal: boolean;
}

export const QuestionTrackerContainer = styled.div<QuestionTrackerProps>`
  ${LABEL_SMALL.MONOSPACE}
  background   : ${DESIGN_TOKENS.color.background.secondary.value};
  display      : inline-block;
  padding      : 4px;
  border-radius: 5px;
  margin-top   : ${props => props.isModal ? '24px' : '104px'};

  @media ${DEVICE_BREAKPOINT.maxSmallMobile} {
    margin-top: 90px;
  }
`;