import { Link } from 'react-router-dom';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { VdsInput } from '@disqotech/vinyl-design-system/react';
import { DEVICE_BREAKPOINT, LABEL_LARGE, LABEL_SMALL, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const ModalHeader = styled.h1`
  ${TITLES_NORMAL.FT_01};
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  width           : 100%;
  text-align      : center;
  padding         : 19px;
  border-bottom   : 1px solid #EAEAEA;
`;

export const LoginFormContainer = styled.div`
  gap          : 36px;
  width        : 100%;
  display      : flex;
  padding      : 36px 0;
  align-items  : center;
  flex-direction: column;
`;

export const LoginFormContainerInner = styled.div`
  gap          : 16px;
  display      : flex;
  align-items  : center;
  flex-direction: column;
`;

export const UserInfo = styled.div`
  display       : flex;
  align-items   : center;
  flex-direction : column;
`;

export const ModalForm = styled.form`
  display       : flex;
  flex-direction : column;
  align-items   : center;
  width         : 360px;
  flex-grow      : 1;
`;

export const UserAvatar = styled.div`
  ${LABEL_LARGE.DEFAULT}
  width           : 48px;
  height          : 48px;
  border-radius   : 24px;
  background-color: ${DESIGN_TOKENS.color.background.tertiary.alpha.value};
  color           : ${DESIGN_TOKENS.color.text.normal.value};
  display         : flex;
  align-items     : center;
  justify-content : center;
  text-transform  : uppercase;
`;

export const UserFullName = styled.div`
  ${TITLES_NORMAL.FT_01}
  margin-top: 16px;
  text-transform: capitalize;
`;

export const UserEmail = styled.div`
  ${LABEL_SMALL.DEFAULT}
  color     : ${DESIGN_TOKENS.color.text.secondary.value};
`;

export const PasswordWrapper = styled.div`
  position  : relative;
  width     : 100%;
  margin-top: 32px;
`;

export const PasswordField = styled(VdsInput)`
  &::part(base) {
    padding-right: 42px;
  }
`;

export const ForgotPasswordWrapper = styled.div`
  width     : 100%;
  text-align: right;
  margin-top: 8px;
`;

export const ShowPasswordIcon = styled.button`
  cursor    : pointer;
  border    : none;
  background: none;
  height    : 20px;
  width     : 20px;
  position  : absolute;
  top       : 38px;
  right     : 12px;
  padding   : 0;
`;

export const CustomLink = styled(Link)`
  ${LABEL_SMALL.DEFAULT}
  color          : ${DESIGN_TOKENS.color.text.link.value};
  text-decoration: underline;
`;

export const Gap = styled.div`
  height   : 80px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    height : 40px;
  }
`;