import { LABEL_XS } from "Shared/styles/designConstants";
import styled from "styled-components";

export const StyledHr = styled.hr<{ text: string, backgroundColor?: string }>`
  ${LABEL_XS.DEFAULT};
  width      : 100%;
  border     : 0;
  height     : 1.5em;
  margin     : 24px 0;
  position   : relative;
  font-size  : 14px;
  text-align : center;
  line-height: 1em;

  &::before {
    top       : 50%;
    left      : 0;
    width     : 100%;
    height    : 1px;
    content   : "";
    position  : absolute;
    background: #CBCBCB;
  }

  &::after {
    color           : #6E6E6E;
    content         : '${props => props.text}';
    padding         : 36px;
    position        : relative;
    line-height     : 1.5em;
    background-color: ${props => props.backgroundColor ?? '#fff'};
  }
`;