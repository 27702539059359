import React from "react";
import { ButtonContainer } from "App/components/sidebar/mobileMenu/menuToggle/style";
import { MENU_TOGGLE } from 'App/components/sidebar/testId';
import {ICON_IDS} from "Shared/components/icons/constants";
import Icon from "Shared/components/icons/icon";

type MenuToggleProp = {
  changeCallback: () => void;
}

/**
 * Renders <MenuToggle /> component
 * @param changeCallback
 */
function MenuToggle({changeCallback}: MenuToggleProp): JSX.Element {
  return(
    <ButtonContainer onClick={() => changeCallback()} data-testid={MENU_TOGGLE}>
      <Icon iconId={ICON_IDS.HAMBURGER}/>
    </ButtonContainer>
  );
}

export {
  MenuToggle
};
