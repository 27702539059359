import type configType from '../../../config.json';

export type ConfigObj = typeof configType['us']['testing'];
export type ConfigKey = keyof ConfigObj;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ImportMetaEnv extends ConfigObj {
    LD: string
  }
}

export function getEnvVar<TVar extends ConfigKey> (name: TVar): ConfigObj[TVar] {
  return import.meta.env[name];
}
