import { getAutoEmailSession, getAutoEmailTypeId, isUserInAutoEmailSession } from 'App/services/autoEmailService';
import { logError } from 'App/services/coralogixService';
import { getUserId } from 'App/services/idpTokenService';
import { USP_PARAM_DEFAULTS } from 'Pages/surveyExperience/constants';
import { AutoEmailSessionModel } from 'Shared/models/EventModels';
import { MS_API } from 'Shared/services/apiInterface';
import { getDeviceId } from 'Shared/services/deviceId';
import { getDeviceScreenDimensions, getSurveyDevice, getUspDevice } from "Shared/services/deviceService";
import { getRFGFingerprint } from 'Shared/services/rfgService';
import { IDP_CLIENT_ID_DESKTOP } from 'Shared/services/userActivation/api/idpApiService';

/**
 * Used to get the user's qualifying surveys
 *
 * @param {string} externalProviderIds
 * @param {string} sortingGroups
 * @returns string
 */
const getUSPSurveyRequestUrl = (
  externalProviderIds?: string,
  sortingGroups?      : string,
): string => {
  const baseURL    = process.env.REACT_APP_USP_BASE_URL;
  const url        = `v2/users/${getUserId()}/surveys`;
  const deviceType = getUspDevice();
  const device     = getSurveyDevice();
  const screenSize = getDeviceScreenDimensions();
  const urlParams  = [
    `device=${encodeURIComponent(device)}`,
    `deviceType=${encodeURIComponent(deviceType)}`,
    `externalProviderIds=${encodeURIComponent(externalProviderIds || '')}`,
    `sortingGroups=${encodeURIComponent(sortingGroups || '')}`,
    `fp=${encodeURIComponent(getDeviceId())}`,
    `fp_rfg=${encodeURIComponent(getRFGFingerprint())}`,
    `hasFlash=${encodeURIComponent(USP_PARAM_DEFAULTS.HAS_FLASH)}`,
    `clientAppId=${encodeURIComponent(USP_PARAM_DEFAULTS.CLIENT_APP_ID || '')}`
  ];

  if (screenSize) {
    urlParams.push(`deviceWidth=${encodeURIComponent(screenSize.width)}`);
    urlParams.push(`deviceHeight=${encodeURIComponent(screenSize.height)}`);
  }

  // Auto email attribution
  if (isUserInAutoEmailSession()) {
    const aeAttributionData = getAutoEmailSession() as AutoEmailSessionModel;
    if (aeAttributionData?.aeData?.aeId) {
      urlParams.push(`autoEmailId=${encodeURIComponent(aeAttributionData?.aeData?.aeId)}`);
    }

    if (aeAttributionData?.aeData?.aeCId) {
      urlParams.push(`autoEmailCategoryId=${encodeURIComponent(aeAttributionData?.aeData?.aeCId)}`);
    }

    if (aeAttributionData?.aeData?.aeT) {
      urlParams.push(`autoEmailSentTime=${encodeURIComponent(aeAttributionData?.aeData?.aeT)}`);
    }
  }

  urlParams.push(`sessionTypeId=${getAutoEmailTypeId()}`);

  const urlParamString = urlParams.join('&');

  return `${baseURL}/${url}?${urlParamString}`;
};

function isValidJSON(json: string) {
  try {
    JSON.parse(json);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Streams the user's qualifying surveys
 * @param {function} streamCallback
 * @param {string} externalProviderIds
 * @param {string} sortingGroups
 */
const getUSPSurveysStream = (
  streamCallback      : (data: string, hasMoreSurveys: boolean) => void,
  externalProviderIds?: string,
  sortingGroups?      : string,
): void => {
  const requestUrl                = getUSPSurveyRequestUrl(externalProviderIds, sortingGroups);
  const processedChunks: string[] = [];
  let requestCompleted            = false;

  const chunkProcessor = (responseText: string, hasMoreSurveys: boolean): void => {
    try {
      responseText
        .split('data:')
        .filter((chunk: string) => !!chunk)
        .filter(isValidJSON)
        .forEach((chunk: string) => {
          if (!processedChunks.includes(chunk)) {
            processedChunks.push(chunk);
            streamCallback(chunk, true);
          }
        });
    } catch (error) {
      logError('getUSPSurveysStream', error);
    }

    // fallback in case the above fails
    streamCallback('{}', hasMoreSurveys);
  };

  MS_API({
    method      : 'GET',
    url         : requestUrl,
    onDownloadProgress: event => {
      if (requestCompleted) {
        return;
      }

      const progressEvent: ProgressEvent<XMLHttpRequest> | undefined = event.event;
      chunkProcessor(progressEvent?.target?.responseText ?? '', true);
    }
  }).then(response => {
    // Re-process all the chunks in case we get a partial response
    chunkProcessor(response.data, false);
    requestCompleted = true;
  }).catch(error => {
    // Trigger empty callback in case the request fails
    streamCallback('{}', false);
    logError('getUSPSurveysStream', error);
    requestCompleted = true;
  });
};

/**
 * Determines if the user is eligible to be redirected to a survey
 * @param surveyId
 * @param providerId
 * @returns
 */
const getSurveyEligibility = (surveyId: string, providerId: number): any => {
  const baseURL    = process.env.REACT_APP_USP_BASE_URL;
  const url        = `v1/users/${getUserId()}/surveys/${surveyId}`;
  const deviceType = getUspDevice();
  const device     = getSurveyDevice();
  const screenSize = getDeviceScreenDimensions();

  const urlParams: any = {
    device            : device,
    deviceType        : deviceType,
    fp                : getDeviceId(),
    fp_rfg            : getRFGFingerprint(),
    hasFlash          : USP_PARAM_DEFAULTS.HAS_FLASH,
    clientAppId       : USP_PARAM_DEFAULTS.CLIENT_APP_ID || '',
    externalProviderId: providerId,
    clientId          : IDP_CLIENT_ID_DESKTOP,
    sessionTypeId     : getAutoEmailTypeId()
  };

  // Add screen dimensions if available
  if (screenSize) {
    urlParams.deviceWidth = screenSize.width;
    urlParams.deviceHeight = screenSize.height;
  }

  // Add auto email attribution data if available
  if (isUserInAutoEmailSession()) {
    const aeAttributionData = getAutoEmailSession() as AutoEmailSessionModel;

    if (aeAttributionData?.aeData?.aeId) {
      urlParams.autoEmailId = aeAttributionData.aeData.aeId;
    }

    if (aeAttributionData?.aeData?.aeCId) {
      urlParams.autoEmailCategoryId = aeAttributionData.aeData.aeCId;
    }

    if (aeAttributionData?.aeData?.aeT) {
      urlParams.autoEmailSentTime = aeAttributionData.aeData.aeT;
    }

    urlParams.trigger = 'auto_email';
  }

  // Return the API call with properly structured params
  return MS_API({
    method: 'GET',
    baseURL: baseURL,
    url: url,
    params: urlParams  // Axios will handle the URL encoding and formatting
  });
};

/**
 * Determines if the uer is qualified for a survey after answering the dynamic screening questions
 * @param surveyId
 * @param providerId
 * @returns
 */
const getIsQualifiedForSurvey = (surveyId: string, providerId: number): any => {
  const baseURL    = process.env.REACT_APP_USP_BASE_URL;
  const url        = `v1/users/${getUserId()}/providers/${providerId}/surveys/${surveyId}/qualify`;
  const deviceType = getUspDevice();
  const device     = getSurveyDevice();
  const screenSize = getDeviceScreenDimensions();

  const urlParams: any = {
    device            : device,
    deviceType        : deviceType,
    fp                : getDeviceId(),
    fp_rfg            : getRFGFingerprint(),
    hasFlash          : USP_PARAM_DEFAULTS.HAS_FLASH,
    clientAppId       : USP_PARAM_DEFAULTS.CLIENT_APP_ID || '',
    externalProviderId: providerId,
    clientId          : IDP_CLIENT_ID_DESKTOP,
    sessionTypeId     : getAutoEmailTypeId()
  };

  if (screenSize) {
    urlParams.deviceWidth = screenSize.width;
    urlParams.deviceHeight = screenSize.height;
  }

  if (isUserInAutoEmailSession()) {
    const aeAttributionData = getAutoEmailSession() as AutoEmailSessionModel;

    if (aeAttributionData?.aeData?.aeId) {
      urlParams.autoEmailId = aeAttributionData.aeData.aeId;
    }

    if (aeAttributionData?.aeData?.aeCId) {
      urlParams.autoEmailCategoryId = aeAttributionData.aeData.aeCId;
    }

    if (aeAttributionData?.aeData?.aeT) {
      urlParams.autoEmailSentTime = aeAttributionData.aeData.aeT;
    }

    urlParams.trigger = 'auto_email';
  }

  return MS_API({
    method : 'GET',
    baseURL: baseURL,
    url    : url,
    params : urlParams
  });
};

/**
 * Gets the dynamic screening questions per provider
 * @param providerId
 * @returns
 */
export const getDynamicScreeningQuestions = (providerId: number): Promise<any> => {
  const baseURL = process.env.REACT_APP_USP_BASE_URL;
  const url     = `v1/providers/${providerId}/screening-questions`;

  return MS_API({
    method : 'GET',
    baseURL: baseURL,
    url    : url
  });
};

/**
 * Triggers a survey URL silently so that the user is not redirected but attempts is recorded on the server
 * @param url
 */
const triggerSurveyURLSilently = (url: string): void => {
  fetch(url, {
    method: 'GET',
    mode: 'no-cors', // prevents CORS issues, and doesn't expect a response
  }).catch(error => {
    logError('triggerSurveyURLSilently', error);
  });
};


export {
  getIsQualifiedForSurvey,
  getSurveyEligibility,
  getUSPSurveysStream,
  triggerSurveyURLSilently
};
