import { useEffect, useRef } from 'react';

/**
 * Custom React hook to execute a callback function at specified intervals.
 *
 * @param {() => void} callback - The callback function to execute.
 * @param {number | null} delay - The delay in milliseconds for the interval. A `null` value will prevent the interval.
 */
function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;