import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CakeInitLocation, initializeCake } from 'App/services/cakePixelService';
import { CAIntroBlurb, CAIntroGap, CAIntroWrapper } from 'Pages/createAccount/components/introduction/style';
import CATestimonial from 'Pages/createAccount/components/testimonial/Testimonial';
import { Button, ButtonSizes } from 'Shared/components/design/button/Button';
import { ICON_IDS } from 'Shared/components/icons/constants';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { useGetUserInfo } from 'Shared/services/userActivation/api/idpApiService';

interface Props {
  onContinue   : () => void
}

function CreateAccountIntroduction ({ onContinue }: Props) {
  const { t }              = useTranslation('createAccount');
  const { data: userInfo } = useGetUserInfo();

  // trigger Cake pixels
  useEffect(() => {
    if (userInfo?.crid) {
      initializeCake(CakeInitLocation.BASIC_PROFILE, userInfo.crid);
    }
  }, [userInfo]);

  // Initialize split tests
  initializeSplitTests(SplitTestInitializationLocation.EMAIL_SUBMIT);

  return (
    <>
      <CAIntroWrapper>
        <CAIntroBlurb tabIndex={0}>
          {t('CreateAccount--Intro-blurb')}
        </CAIntroBlurb>
        <Button
          size={ButtonSizes.LARGE}
          iconRight={ICON_IDS.ARROW_RIGHT}
          onClick={onContinue}
        >
          {t('CreateAccount--Intro-button-text')}
        </Button>
        <CAIntroGap />
        <CATestimonial />
      </CAIntroWrapper>
      <CAIntroGap />
    </>
  );
}

export default CreateAccountIntroduction;
