export enum OPA_FLAG {
  // MEW-675 - Surveys Sort
  SURVEYS_SORT = 'spa_survey_mew_675',

  // MEW-1062 - SPA | Remove survey pre-qualification limit for those in the test.
  SURVEY_QUALIFICATION_QUESTIONS_WITH_POPUP = 'spa_survey_mew_1062',

  // SJGROW-2815 - Dynamic Screener
  DYNAMIC_SCREENER = 'SJMAND-5363-dynamic-screener',
}

export enum OPA_VARIABLE {
  // MEW-675 - Surveys Sort
  SURVEYS_SORT_ENABLED = 'spa_mew_675_enabled',

  // MEW-1062 - SPA | Remove survey pre-qualification limit for those in the test.
  SURVEY_QUALIFICATION_QUESTIONS_PROVIDER_IDS = 'spa_mew_1062_provider_ids',
  SURVEY_QUALIFICATION_SURVEY_MEDLEY_ENABLED = 'spa_mew_1062_survey_medley_enabled',

  // SJGROW-2815 - Dynamic Screener
  DYNAMIC_SCREENER_PROVIDERS = 'enabled_provider_list',
  DYNAMIC_SCREENER_SURVEY_MEDLEY_ENABLED = 'survey_medley_enabled'
}

export enum OPA_VARIANT_KEY {
  GAMING_NON_INCENTIVIZED = 'non_incentivized_gaming_v1',
  GAMING_INCENTIVIZED     = 'incentivized_gaming_v1'
}
