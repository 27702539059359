import { INTER_FONT } from 'Shared/styles/designConstants';
import styled, { css } from 'styled-components';

const animationDuration = 0.5;

export const PromoProgressContainer = styled.div`
  display       : flex;
  flex-direction: row;
  align-items   : center;
  gap           : 12px;
  height        : 50px;
  width         : 100%;
`;

export const PromoProgressStartEndText = styled.div`
  ${INTER_FONT.LABEL}
  font-size: 12px;
  color    : var(--System-Text-Text-Subtle, #6F6F6F);
`;

export const PromoProgressBar = styled.div`
  display      : flex;
  flex         : 1;
  height       : 4px;
  border-radius: 4px;
  background   : var(--System-Border-Border-Default, #CBCBCB);
`;

export const PromoProgressFill = styled.div<{ progress: number, animate: boolean }>`
  flex      : ${({ progress }) => progress};
  background: linear-gradient(270deg, #9749F4 0%, rgba(151, 73, 244, 0) 100%);
  ${({ animate }) => (
    animate
    ? css`
      animation : ${animationDuration}s ease-out grow;
      transition: flex ${animationDuration}s ease-out;
    `
    :  ''
  )}

  @keyframes grow {
    0% {
      flex: 0;
    }
    100% {
      flex: ${({ progress }) => progress};
    }
  }
`;

export const PromoProgressRemainder = styled.div<{ progress: number, animate: boolean }>`
  flex: ${({ progress }) => 1 - progress};
  ${({ animate }) => (
    animate
    ? css`
      animation : ${animationDuration}s ease-out shrink;
      transition: flex ${animationDuration}s ease-out;
    `
    :  ''
  )}

  @keyframes shrink {
    0% {
      flex: 1;
    }
    100% {
      flex: ${({ progress }) => 1 - progress};
    }
  }
`;

export const PromoProgressDotContainer = styled.div`
  position       : relative;
  top            : -3px;
  width          : 0;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : center;
  gap            : 6px;
`;

export const PromoProgressDot = styled.div`
  min-width    : 10px;
  min-height   : 10px;
  border-radius: 10px;
  background   : var(--Global-Purple-Purple-10, #9749F4);
  box-shadow   :
    0 0 12px var(--Global-Purple-Purple-10, #9749F4),
    0 0 8px var(--Global-Purple-Purple-10, #9749F4);
`;

export const PromoProgressLabel = styled.div`
  ${INTER_FONT.LABEL}
  font-size  : 12px;
  color      : var(--Global-Purple-Purple-10, #9749F4);
  white-space: nowrap;
`;
