import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { RouteQueryParam } from 'App/routes/constants';
import { removeSurveyById } from 'Pages/surveyExperience/services/surveyService';
import { BannerContextTypes } from 'Shared/components/design/banner/Banner';
import { ProgressBar } from 'Shared/components/design/progressBar/ProgressBar';
import {
  BannerContainer,
  ContentContainer,
  ExitContainer,
  HeaderContainer,
  ProgressBarContainer,
  SurveyMedleyPoints
} from 'Shared/components/design/questionnairePopup/header/style';
import {
  HEADER_CLOSE_TEST_ID,
  HEADER_TEST_ID,
  SKIP_LINK_TEST_ID,
  SURVEY_MEDLEY_QUESTIONNAIRE_EXIT_TEST_ID
} from 'Shared/components/design/questionnairePopup/testId';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { useIsMaxSize } from 'Shared/hooks/useIsMaxSize';

type HeaderProps = {
  exitCallback          : () => void;
  totalQuestions        : number;
  currentQuestionIndex  : number;
  isSurveyQualification?: boolean;
  isSurveyMedley?       : boolean;
  isModal?              : boolean;
  questionnaireCompleted: boolean;
}

/**
 * Returns Header Component
 */
function Header ({
  exitCallback,
  totalQuestions,
  currentQuestionIndex,
  isSurveyQualification = false,
  isSurveyMedley = false,
  isModal = false,
  questionnaireCompleted
}: HeaderProps): JSX.Element {
  const { t }                                                         = useTranslation('questionnairePopup');
  const isMaxMobile                                                   = useIsMaxSize('maxMobile');
  const [isSurveyOutboundLinkClicked, setIsSurveyOutboundLinkClicked] = useState(false);
  const useSearchParams                                               = new URLSearchParams(window.location.search);
  const surveyId                                                      = useSearchParams.get(RouteQueryParam.SURVEY_ID_QUERY_PARAM);

  /**
   * Prevents the user from clicking on the survey link multiple times
   */
  const preventDuplicateClicks = useCallback(event => {
    if (isSurveyOutboundLinkClicked) {
      // Prevent navigation
      event.preventDefault();
    } else {
      exitCallback();

      if (surveyId && isModal && questionnaireCompleted) {
        //Remove survey from the list of surveys
        removeSurveyById(surveyId);
      }
      setIsSurveyOutboundLinkClicked(true);
    }
  }, [exitCallback, isModal, isSurveyOutboundLinkClicked, questionnaireCompleted, surveyId]);

  /**
   * Returns ProgressBar Container for the questionnairePopup
   */
  const renderProgressBarContainer = (): JSX.Element => {
    if (totalQuestions === 1) {
      return <></>;
    }

    return (
      <ProgressBarContainer>
        <ProgressBar
          currentIndex = {currentQuestionIndex}
          total        = {totalQuestions}
          notIndexBased
        />
      </ProgressBarContainer>
    );
  };

  /**
   * Returns dynamic progress bar for the questionnairePopup[ex: truncatedGP or GP]
   */
  const renderProgressBar = (): JSX.Element => {
    return (
      <>
        <ContentContainer>
          {renderProgressBarContainer()}
        </ContentContainer>
      </>
    );
  };

  /**
   *  Conditionally renders the exit icon based on the type of questionnaire
   */
  const renderExitIcon = (): JSX.Element => {
    if (isSurveyMedley || isModal) {
      return (
        <ExitContainer isSurveyMedley={isSurveyMedley} data-testid={SURVEY_MEDLEY_QUESTIONNAIRE_EXIT_TEST_ID}>
          {!isSurveyQualification && <SurveyMedleyPoints>{currentQuestionIndex-1}/{totalQuestions}pts</SurveyMedleyPoints>}
          <button onClick={preventDuplicateClicks} data-testid={HEADER_CLOSE_TEST_ID}>
            <Icon
              iconId = {ICON_IDS.CLOSE}
              width  = {25}
              height = {25}
              color  = {DESIGN_TOKENS.color.text.normal.value}
            />
          </button>
        </ExitContainer>
      );
    } else if (isSurveyQualification) {
      const linkText = isMaxMobile ? t('Questionnaire--header-skipToSurvey-short') : t('Questionnaire--header-skipToSurvey');

      return (
        <ExitContainer>
          <span onClick={preventDuplicateClicks} data-testid={SKIP_LINK_TEST_ID}>{linkText}</span>
        </ExitContainer>
      );
    }

    return <></>;
  };

  /**
   * Renders the modal banner for the survey qualification
   * @returns
   */
  const renderModalBanner = (): JSX.Element | undefined => {
    if ((isModal && !isSurveyMedley) && !questionnaireCompleted) {
      return (
        <BannerContainer
          context={BannerContextTypes.INFO}
          icon={ICON_IDS.INFO_FILL}
          text={'To help you save time, answer these questions to find out if you fit the survey qualifications.'}
        />
      );
    }
  };


  return (
    <>
      <HeaderContainer
        data-testid={HEADER_TEST_ID}
      >
        {renderProgressBar()}
        {renderExitIcon()}
      </HeaderContainer>
      {renderModalBanner()}
    </>
  );
}

export default Header;
