import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { Banner } from 'Shared/components/design/banner/Banner';
import { DEVICE_BREAKPOINT, LABEL_LARGE, LABEL_SMALL, PARAGRAPH, POPUP_HEADER_CONTAINER, QUESTIONNAIRE_CONTENT_MAX_WIDTH } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type ExitContainerProps = {
  isSurveyMedley?: boolean;
}

export const HeaderContainer = styled.div`
  ${POPUP_HEADER_CONTAINER}
  position        : fixed;
  z-index         : 4;
  background-color: ${DESIGN_TOKENS.color.background.primary.value};

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display    : flex;
    align-items: center;
  }

  @media ${DEVICE_BREAKPOINT.maxSmallMobile} {
    height: 50px;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  max-width      : ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
  margin         : auto;
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 100%;

  ${PARAGRAPH.MONOSPACE}

  img {
    width       : 23px;
    padding-left: 4px;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    width  : 100%;
    padding: 0px 16px;
  }
`;

export const SurveyMedleyPoints = styled.div`
  ${LABEL_LARGE.MONOSPACE}
  background-color: ${DESIGN_TOKENS.color.primitive.yellow['200'].value};
  border-radius   : 16px;
  margin-right    : 17px;
  padding         : 2px 16px;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    margin-right: 10px;
  }
`;

export const ExitContainer = styled.div<ExitContainerProps>`
  position       : absolute;
  top            : 0px;
  right          : 20px;
  height         : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;

  button {
    cursor    : pointer;
    padding   : 0px;
    background: none;
    border    : none;
  }

  span {
    ${LABEL_SMALL.DEFAULT}
    text-decoration: underline;
    color: ${DESIGN_TOKENS.color.text.link.value};
    cursor: pointer;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    position    : relative;
    width       : ${props => props.isSurveyMedley ? '130px' : '30px'};
    top         : 0;
    right       : 0;
    margin-right: 10px;
  }
`;

export const BannerContainer = styled(Banner)`
  margin-top: 80px;
  max-width : ${QUESTIONNAIRE_CONTENT_MAX_WIDTH};
`;