export const LOCAL_STORAGE_IDS = {
  USER_DEVICE                  : 'device',
  SURVEY_LIST                  : 'surveyList',
  UNANSWERED_QUESTIONS         : 'unansweredQuestions',
  SURVEY_PROMPT                : 'surveyPrompt',
  RELEVANT_ID_STATUS           : 'rlidStatus',
  RELEVANT_ID_CHECK_TIMESTAMP  : 'rlidCheckTimestamp',
  HAS_SEEN_GP                  : 'hasSeenGP',
  SPLIT_TEST_CACHE             : 'splitTestCache',
  SURVEY_QUAL_DETAILS          : 'surveyQualificationDetails',
  QUESTION_SUPPRESSION_LIST    : 'questionSuppressionList',
  FRESHCHAT_RESTORE            : 'freshChatRestore',
  LAST_SURVEY_REQUEST_TIME     : 'lastSurveyRequestTime',
  LAST_SESSION_ID              : 'lastSessionId',
  SURVEY_COMPLETION_STATUS     : 'surveyCompletionStatus',
  LAST_SURVEY_CALLBACK_STATUS  : 'lastSurveyCallbackStatus',
  AUTO_EMAIL_SESSION_ID        : 'autoEmailSession',
  SESSION_CACHE                : 'sessionCache',
  SURVEY_MEDLEY_SESSION_TRIGGER: 'surveyMedleySessionTrigger',
  SURF_TO_EARN_WIDGET_DONE     : 'steWidgetDone',
  LEADER_BOARD_DATA            : 'leaderBoardData',
  EMAIL_CONFIRMATION_REQUEST   : 'emailConfirmationRequest',
  CALLBACK_REJECT_STATUS_TS    : 'callbackRejectStatusTs',
  MAINTENANCE_MODE_OVERRIDE    : 'maintenanceModeOverride',
  GAMES_PARTNER_ID             : 'gamesPartnerId',
  BRAZE_GAMING_EVENTS          : 'brazeGamingEvents',
  DYNAMIC_SCREENER_QUESTIONS   : 'dynamicScreenerQuestions',
} as const;

export const LOCAL_STORAGE_KEYS = {
  COMPLETED   : 'completed',
  DISQUALIFIED: 'disqualified',
} as const;

export const MINIMUM_REDEMPTION_POINTS     = 500;
export const MAXIMUM_REDEMPTION_POINTS     = 5000;
export const MAXIMUM_REDEMPTION_MANUAL     = 4000;
export const POINTS_TO_CURRENCY_RATIO      = 100;

export const STE_REWARD_POINTS             = 100;
export const STE_MONTHLY_REWARD_POINTS     = 50;
export const STE_MONTHLY_REWARD_PERCENTAGE = 50;

export const SURVEY_MEDLEY_POPUP_THRESHOLD_DAYS = 30;

export const WURFL_BROWSERS = {
  safari : 'Safari',
  chrome : 'Chrome',
  firefox: 'Firefox',
  edge   : 'Edge'
};

export const MOBILE_APP_AVAILABLE_REGIONS = {
  US: 'US',
  UK: 'UK',
};

export const MOBILE_DEEP_LINKS = {
  android: 'surveyjunkie://com.surveyjunkie/promotion',
  ios    : 'surveyjunkie://',
};

export const APP_TYPES = {
  DESKTOP  : 'DESKTOP',
  EXTENSION: 'EXTENSION',
};

export const APP_INSTALL_STAGES = {
  PRE      : 'PRE',
  AFTER    : 'AFTER',
  UNINSTALL: 'UNINSTALL',
};

export const APP_INSTALL_BUTTON_TYPE = {
  HEADER : 'HEADER',
  PANEL  : 'PANEL',
};

export const COUNTRY_CODE_AU = 'au';
export const COUNTRY_CODE_CA = 'ca';
export const COUNTRY_CODE_UK = 'uk';
export const COUNTRY_CODE_US = 'us';

export const PHONE_COUNTRY_CODE_US           = '+1';
export const PHONE_COUNTRY_CODE_CA           = '+1';
export const PHONE_COUNTRY_CODE_AU           = '+61';
export const PHONE_INPUT_LENGTH_US           = 12;
export const PHONE_INPUT_VERIFICATION_LENGTH = 7;

export const APP_ID = 1;

export const BASIC_PROFILE_POINTS_REWARD   = 25;
export const GENERAL_PROFILE_POINTS_REWARD = 50;

export const UTC_TIMEZONE = 'UTC';

// Survey layouts
export enum SURVEY_LAYOUTS {
  'ONE_COLUMN'   = 'ONE_COLUMN',
  'TWO_COLUMN'   = 'TWO_COLUMN',
  'THREE_COLUMN' = 'THREE_COLUMN'
}

