import { PopupStartEventProps } from 'App/models/eventModels';
import { addEventListener } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * AddEventListener: window.mfEvents.Popup.start
 * @param callback
 */
export const addPopupStartEvent = (callback: (props: PopupStartEventProps) => void): void => {
  addEventListener<PopupStartEventProps>(MF_EVENTS.app.popup.start, callback);
};

/**
 * AddEventListener: window.mfEvents.Popup.exit
 * @param callback
 */
export const addPopupExitEvent = (callback: () => void): void => {
  addEventListener(MF_EVENTS.app.popup.exit, callback);
};