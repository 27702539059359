import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type IconProps = {
  isVisible: boolean;
}

export const HeaderContainer = styled.div`
  box-shadow     : 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  display        : flex;
  align-items    : center;
  justify-content: space-around;
  height         : 64px;
  background     : ${DESIGN_TOKENS.color.background.primary.value};

  svg {
    height: 24px;
    width : 24px;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    position: fixed;
    top     : 0;
    right   : 0;
    left    : 0;
    z-index : 10;
  }
`;

export const LeftIconContainer = styled.label<IconProps>`
  visibility: ${$props => $props.isVisible ? 'visible' : 'hidden'};
  position  : absolute;
  left      : 20px;
  cursor    : pointer;
`;

export const RightIconContainer = styled.label<IconProps>`
  visibility: ${$props => $props.isVisible ? 'visible' : 'hidden'};
  position  : absolute;
  right     : 20px;
  cursor    : pointer;
`;

export const TitleContainer = styled.div`
  ${TITLES_NORMAL.FT_01}
`;