import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipArrow, TooltipContainer } from 'Shared/components/design/promoNavButton/promoToolTip/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { patchPromoUiState } from 'Shared/services/promo/api/promoApi';
import {
  getActivePromoData,
  PromoUiState, useUserPromotions,
} from 'Shared/services/promo/app/promoService';

type PromoToolTipProps = {
  closeTooltip: boolean;
  isMobile    : boolean;
  showOnHover?: boolean;
};

/**
 * Renders the Promo Tool Tip
 * @returns
 */
const PromoToolTip: React.FC<PromoToolTipProps> = ({ closeTooltip, isMobile, showOnHover }) => {
  const { activePromotions } = useUserPromotions();
  const promotion            = activePromotions[0]; // Only one promo is active at a time
  const {
    tooltipDesktopShown,
    tooltipMobileShown
  }                               = getActivePromoData(promotion);
  const isTooltipViewedInitially  = isMobile ? tooltipMobileShown : tooltipDesktopShown;
  const [isVisible, setIsVisible] = useState(!isTooltipViewedInitially);
  const { t }                     = useTranslation('promoNavButton');

  useEffect(() => {
    if (closeTooltip && isVisible) {
      setIsVisible(false);

      const tooltipShownStatus: PromoUiState = {
        tooltipMobileShown: true,
      };

      if (isMobile && promotion?.id) {
        patchPromoUiState(promotion.id, tooltipShownStatus);
      }
    }

    if (!isMobile && showOnHover !== undefined && isTooltipViewedInitially) {
      setIsVisible(showOnHover);
    }

  }, [closeTooltip, isMobile, isTooltipViewedInitially, isVisible, showOnHover, promotion?.id]);

  /**
   * Handles closing of the tooltip in desktop view only
   * @returns
   */
  const handleTooltipClose = (): void => {
    if (isMobile) {
      return;
    }

    setIsVisible(false);

    const tooltipShownStatus: PromoUiState = {
      tooltipDesktopShown: true,
    };

    if (promotion?.id) {
      patchPromoUiState(promotion.id, tooltipShownStatus);
    }
  };

  if (!isVisible && closeTooltip) {
    return null;
  }

  return (
    <TooltipContainer isVisible={isVisible} onClick={handleTooltipClose} isMobile={isMobile}>
      <TooltipArrow isMobile={isMobile}/>
      {isMobile ? t('PromoNavButton-tooltip--mobileText') : t('PromoNavButton-tooltip--desktopText')}
      {!isMobile && !isTooltipViewedInitially && <Icon iconId={ICON_IDS.CLOSE} height={16} width={16}/>}
    </TooltipContainer>
  );
};

export default PromoToolTip;
