import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Blurb, SurveyContainer, Title } from 'Shared/components/design/email/commonStyle';
import { ShadowSurveyCard } from 'Shared/components/design/email/surveyFound/styles';
import { BLURB, SURVEY, TITLE } from 'Shared/constants/email/testId';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';

interface Props {
  survey: UspSurveysModel;
}

/**
 * Renders the EmailSurveyFound component
 */
function EmailSurveyFound({ survey }: Props): JSX.Element {
  const { t }    = useTranslation('app');
  const navigate = useNavigate();

  return (
    <>
      <Title data-testid={TITLE}>
        {t('Email--found-title')}
      </Title>
      <Blurb data-testid={BLURB}>
        {t('Email--found-blurb')}
      </Blurb>
      <SurveyContainer data-testid={SURVEY}>
        <ShadowSurveyCard
          survey                = {survey}
          onClick               = {() => navigate('/', { replace: true })}
          position              = {0}
          buttonText            = {t('Email--start-survey')}
          questionKeysQueue     = {[]}
          autoConfirmLegalPrompt
        />
      </SurveyContainer>
    </>
  );
}

export default EmailSurveyFound;
