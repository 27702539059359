export const RdtErrorView = {
  NotFound: 1,
  Completed: 2,
  Expired: 3,
  General: 4,
  Timeout: 5,
} as const;

export const RdtSpecificError = {
  JwtRequestUserIdMismatch: 40005,
} as const;

export type RdtErrorView = typeof RdtErrorView[keyof typeof RdtErrorView];

export type RdtSpecificError = typeof RdtSpecificError[keyof typeof RdtSpecificError];

export const RDT_ERROR_VIEW_SIZE = 10000;
