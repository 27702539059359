import { TranslationKey } from 'react-i18next';

type SurveyCallbackMessage = {
  title             : TranslationKey<'surveyCallback'>,
  text              : TranslationKey<'surveyCallback'>,
  emojiElement      : string,
  buttonText?       : TranslationKey<'surveyCallback'>,
  reportIssue      ?: boolean
  centered         ?: boolean
  emojiComponent   ?: React.FunctionComponent
}

enum SurveyCallbackParams {
  STATUS_PARAM  = "survey-status",
  ID_PARAM      = "survey-id",
  POINTS_PARAM  = "survey-points",
  MESSAGE_PARAM = "survey-message"
}

// commented values are not passed
enum SurveyCallbackStatuses {
  COMPLETE                         = 1,
  QUOTA_FULL                       = 2,
  TERMINATED                       = 3,
  REJECTED                         = 4,
  DUPLICATE                        = 5,
  SURVEY_NOT_AVAILABLE             = 6,
  NO_SURVEY_AVAILABLE              = 7,
  PRE_SCREEN_OUT                   = 8,
  CLIENT_REJECTED                  = 9,
  COMPLETE_BUT_INTERNAL_QUOTA_FULL = 10,
  SJ_PRE_SCREEN_OUT                = 11,
  SJ_USER_PROVIDER_BLOCK           = 12,
  SJ_INCORRECT_DEVICE              = 13,
  SJ_QUOTA_FULL                    = 14,
  SJ_SURVEY_NOT_AVAILABLE          = 15,
  SJ_SURVEY_DUPLICATE              = 16,
  SJ_DISPLAY_RULES                 = 17,
  SJ_OK                            = 18,
  SJ_CLICK_THROTTLED               = 19,
  SJ_PARTNER_REJECTED_CLICK        = 20,
  SJ_SURVEY_INVALID_URL            = 21,
  SJ_SURVEY_URL_MISMATCH           = 22,
  SJ_SURVEY_SURVEY_FILTERED        = 23,
  UNEXPECTED_ERROR                 = -1
}

export {
  SurveyCallbackMessage,
  SurveyCallbackParams,
  SurveyCallbackStatuses
};