import type { RemoteConfig, RemoteConfigSettings, Value as RemoteConfigValue } from 'firebase/remote-config';

// Lazy load Firebase dependencies
const firebaseAppPromise = import('firebase/app');
const firebaseRemoteConfigPromise = import('firebase/remote-config');

let remoteConfig: RemoteConfig | null = null;
let getValueFn: ((remoteConfig: RemoteConfig, key: string) => RemoteConfigValue) | null = null;
let fetchAndActivateFn: ((remoteConfig: RemoteConfig) => Promise<boolean>) | null = null;

const initializeFirebase = async () => {
  const [{ initializeApp }, { getRemoteConfig, getValue, fetchAndActivate }] = await Promise.all([
    firebaseAppPromise,
    firebaseRemoteConfigPromise
  ]);

  // Cache the functions
  getValueFn = getValue;
  fetchAndActivateFn = fetchAndActivate;

  const firebaseConfig = {
    apiKey            : process.env.FIREBASE_CONFIG_API_KEY,
    authDomain        : process.env.FIREBASE_CONFIG_AUTH_DOMAIN,
    databaseURL       : process.env.FIREBASE_CONFIG_DATABASE_URL,
    projectId         : process.env.FIREBASE_CONFIG_PROJECT_ID,
    storageBucket     : process.env.FIREBASE_CONFIG_STORAGE_BUCKET,
    messagingSenderId : process.env.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
    appId             : process.env.FIREBASE_CONFIG_APP_ID,
    measurementId     : process.env.FIREBASE_CONFIG_MEASUREMENT_ID
  };

  const app = initializeApp(firebaseConfig);
  remoteConfig = getRemoteConfig(app);

  const settings: RemoteConfigSettings = {
    minimumFetchIntervalMillis: 0,     // Fetch from server on every fetchAndActivate call
    fetchTimeoutMillis        : 60000, // 60 seconds
  };

  if (remoteConfig) {
    remoteConfig.settings = settings;
  }

  return remoteConfig;
};

// Promise variable cache for concurrent calls
let activeFirebaseRequest: null | Promise<RemoteConfig | null> = null;

// Wrapper for the calls to firebase to prevent concurrent calls
const getFirebaseConfig = async (): Promise<RemoteConfig | null> => {
  if (!remoteConfig) {
    await initializeFirebase();
  }

  if (activeFirebaseRequest !== null) {
    return activeFirebaseRequest;
  }

  if (!remoteConfig || !fetchAndActivateFn) {
    return null;
  }

  activeFirebaseRequest = fetchAndActivateFn(remoteConfig).then(() => remoteConfig);

  activeFirebaseRequest?.finally(() => {
    // Reset to null so that new promise can be set again
    activeFirebaseRequest = null;
  });

  return activeFirebaseRequest;
};

export {
  fetchAndActivateFn,
  getFirebaseConfig,
  getValueFn,
  initializeFirebase,
  remoteConfig
};