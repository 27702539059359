import React, { HTMLAttributes, useState } from 'react';
import { BANNER_TEST_IDS } from 'Shared/components/design/banner/constants';
import {
  BannerClose,
  BannerContainer,
  BannerIcon,
  BannerLabel,
  BannerLabelContainer,
  BannerText,
  BannerTextContent
} from 'Shared/components/design/banner/style';
import {ICON_IDS,IconId} from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

enum BannerContextTypes {
  INFO,
  WARNING,
  POSITIVE,
  NEGATIVE,
  LIGHT_INFO,
  LIGHT_WARNING,
  LIGHT_POSITIVE,
  LIGHT_NEGATIVE,
}

interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  text?                 : React.ReactNode;
  icon?                 : IconId;
  label?                : string;
  close?                : boolean;
  context               : BannerContextTypes;
  closeCallback?        : () => void;
  bannerLabelClick?     : () => void;
  closeAfterBannerClick?: boolean
  fillParent?           : boolean;
}

function Banner({
  text,
  icon,
  label,
  close = false,
  context,
  closeCallback,
  bannerLabelClick,
  closeAfterBannerClick = false,
  fillParent = false,
  ...rest
}: BannerProps) {
  // Show an error if both the label and close are set
  if (close && label) {
    throw new Error(`Banner Component Error: Banner component can't have both a label and close set`);
  }

  if ((label && !bannerLabelClick) || (!label && bannerLabelClick)) {
    throw new Error(`Banner Component Error: Both bannerLabelClick and label are required when using bannerLabelClick or label`);
  }

  // Show an error if context is missing
  if (!BannerContextTypes[context]) {
    throw new Error(`Banner Component Error: Banner component is missing context`);
  }

  const [displayComponent, setDisplayComponent] = useState<boolean>(true);

  /**
   * Conditionally render the icon if it is set
   */
  const renderIcon = (): JSX.Element | undefined => {
    // If the icon exists then render the icon
    if (icon) {
      return (
        <BannerIcon context={context} hasIcon={true} hasLabel={!!label} hasClose={!!close}>
          <Icon iconId={ icon } />
        </BannerIcon>
      );
    }
  };

  /**
   * Close the banner and conditionally call the callback
   */
  const triggerBannerClose = (): void => {
    setDisplayComponent(false);

    if (closeCallback) {
      closeCallback();
    }
  };

  /**
   * Conditionally render the close button if close is set to true
   */
  const renderClose = (): JSX.Element | undefined => {
    if (close === true) {
      return (
        <BannerClose
          context={context}
          hasIcon={!!icon}
          hasLabel={!!label}
          hasClose={!!close}
          onClick={ () => triggerBannerClose() }>
          <Icon iconId={ ICON_IDS.CLOSE } />
        </BannerClose>
      );
    }
  };

  /**
   * Conditionally render the label button if it is set
   */
  const renderLabel = (): JSX.Element | undefined => {
    if (label) {
      return (
        <BannerLabelContainer context={context} hasIcon={!!icon} hasLabel={true} hasClose={!!close}>
          <BannerLabel context={context} hasIcon={!!icon} hasLabel={true} hasClose={!!close}>
            { label }
          </BannerLabel>
        </BannerLabelContainer>
      );
    }
  };

  /**
   * Conditionally render text
   */
  const renderText = (): JSX.Element | undefined => {
    if (text) {
      return (
        <BannerText context={context} hasIcon={!!icon} hasLabel={!!label} hasClose={!!close}>
          <BannerTextContent>{ text } </BannerTextContent>
        </BannerText>
      );
    }
  };

  const bannerClicked = (): void => {
    if (!bannerLabelClick) {
      return;
    }

    if (closeAfterBannerClick) {
      triggerBannerClose();
    }

    bannerLabelClick();
  };

  /**
   * Render the component
   */
  const renderBannerContainer = (): JSX.Element | undefined => {
    return (
      <BannerContainer
        data-testid = {BANNER_TEST_IDS.BANNER_TEST}
        context     = {context}
        hasIcon     = {!!icon}
        hasLabel    = {!!label}
        hasClose    = {!!close}
        onClick     = { () => bannerClicked() }
        fillParent  = {!!fillParent}
        {...rest}
      >
        { renderIcon() }
        { renderText() }
        { renderClose() }
        { renderLabel() }
      </BannerContainer>
    );
  };

  return (
    <>
      { displayComponent ? renderBannerContainer() : '' }
    </>
  );
}

export {
  Banner,
  BannerContextTypes
};
