import { AnalyticsEvent } from 'Shared/services/analytics/config';
import {
  ANALYTICS_EMAIL_STEP_NAMES,
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_EVENT_TYPE,
  ANALYTICS_RESPONSE_CODES,
  ANALYTICS_RESPONSE_MESSAGE,
} from 'Shared/services/analytics/constants';
import { GA4_EVENT_CATEGORY, trackEventConditionally } from "Shared/services/analytics/services/thresholdManager";

/**
 * Triggered when a user completes the survey request
 * @returns void
 */
export const analyticsTrackEmailSurveyRequestEnd = (errorCode?: number): void => {
  const event: AnalyticsEvent = {
    event     : ANALYTICS_EVENT_NAMES.end,
    attributes: {
      [ANALYTICS_EVENT_ATTRIBUTES.type]            : ANALYTICS_EVENT_TYPE.email,
      [ANALYTICS_EVENT_ATTRIBUTES.sub_type]        : ANALYTICS_EVENT_SUB_TYPE.auto_email,
      [ANALYTICS_EVENT_ATTRIBUTES.step_name]       : ANALYTICS_EMAIL_STEP_NAMES.email_survey_request,
      [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : errorCode || ANALYTICS_RESPONSE_CODES.success,
      [ANALYTICS_EVENT_ATTRIBUTES.response_message]: errorCode ? ANALYTICS_RESPONSE_MESSAGE.error  : ANALYTICS_RESPONSE_MESSAGE.success,
    }
  };

  trackEventConditionally(GA4_EVENT_CATEGORY.email, [event]);
};