import { getUserId } from 'App/services/idpTokenService';
import { AxiosRequestConfig } from 'axios';
import { PostAnswerModel, PostDynamicAnswerModel } from 'Shared/components/design/questionnairePopup/AnswerModel';
import { APP_ID } from 'Shared/constants';
import { UPMCategory, UPMQuestionsResponse } from 'Shared/models/swagger/upm';
import { APIResponse, MS_API } from 'Shared/services/apiInterface';
import { getUserCacheData, setUserCacheData, UserSessionCacheKeys } from 'Shared/services/sessionCacheService';

const URL_TYPE = {
  SINGLE_CATEGORY     : 'single-category',
  QUESTIONS           : 'all-questions',
  POST_ANSWERS        : 'post-answers',
  POST_CUSTOM_ANSWERS : 'post-custom-answers',
  POST_DYNAMIC_ANSWERS: 'post-dynamic-answers'
};

const REWARDABLE_HEADER = {
  'X-MEDLEY-ENABLED': 'ALL'
};

const upmPromiseMap: any = {};

/**
 * Correctly structures the UPM URL using a user's ID
 * @param urlType
 */
const getUpmUrl = (urlType: string): string | undefined => {
  switch (urlType) {
    case URL_TYPE.SINGLE_CATEGORY:
      return `v1/apps/${APP_ID}/users/${getUserId()}/categories/`;
    case URL_TYPE.QUESTIONS:
      return `v1/apps/${APP_ID}/questions`;
    case URL_TYPE.POST_ANSWERS:
      return `v1/apps/${APP_ID}/users/${getUserId()}/answers`;
    case URL_TYPE.POST_CUSTOM_ANSWERS:
      return `v1/apps/${APP_ID}/users/${getUserId()}/custom-answers`;
    case URL_TYPE.POST_DYNAMIC_ANSWERS:
      return `v1/apps/${APP_ID}/users/${getUserId()}/screening-answers`;
  }
};

/**
 * Gets status of questionnaire for single category
 * @param categoryId
 */
const getSingleCategory = async (categoryId: number, useSessionCache = false): Promise<UPMCategory> => {
  const baseUrl = process.env.REACT_APP_UPM_BASE_URL;
  const url     = `${getUpmUrl(URL_TYPE.SINGLE_CATEGORY)}${categoryId}`;

  if (useSessionCache) {
    const cachedData = getUserCacheData(UserSessionCacheKeys.CATEGORY_DATA, categoryId.toString());
    if (cachedData) {
      return cachedData;
    }
  }

  if (upmPromiseMap[url]) {
    return upmPromiseMap[url];
  }

  upmPromiseMap[url] = (async () => {
    const response = await MS_API({
      method : 'GET',
      baseURL: baseUrl,
      url    : url
    });

    if (response.data) {
      setUserCacheData(UserSessionCacheKeys.CATEGORY_DATA, response.data, categoryId.toString());
    }

    return response.data;
  })();

  upmPromiseMap[url].finally(() => {
    upmPromiseMap[url] = null;
  });

  return upmPromiseMap[url];
};

/**
 * Gets all questions with an optional param categoryId
 * @param categoryId
 */
const getQuestions = (categoryId?: number): Promise<APIResponse<UPMQuestionsResponse>> => {
  const baseUrl = process.env.REACT_APP_UPM_BASE_URL;
  const url     = `${getUpmUrl(URL_TYPE.QUESTIONS)}`;

  if (upmPromiseMap[url]) {
    return upmPromiseMap[url];
  }

  upmPromiseMap[url] = MS_API({
    method : 'GET',
    baseURL: baseUrl,
    url    : url,
    params : {
      categoryId: categoryId || ''
    }
  });

  upmPromiseMap[url].finally(() => {
    upmPromiseMap[url] = null;
  });

  return upmPromiseMap[url];
};

/**
 * Gets single question with an optional param categoryId
 * @param questionId
 * @param categoryId
 */
const getSingleQuestion = (questionId: number, categoryId?: number): Promise<APIResponse> => {
  const baseUrl = process.env.REACT_APP_UPM_BASE_URL;
  const url     = `${getUpmUrl(URL_TYPE.QUESTIONS)}/${questionId}`;

  return MS_API({
    method : 'GET',
    baseURL: baseUrl,
    url    : url,
    params : {
      categoryId: categoryId || ''
    }
  });
};

/**
 *
 * Gets all answers with an optional param categoryId
 * @param categoryId
 * @param optional
 * @returns
 */
const getAnswers = (categoryId?: number, optional = false): Promise<APIResponse> => {
  if (optional) {
    return upmPromiseMap[''];
  }

  const baseUrl = process.env.REACT_APP_UPM_BASE_URL;
  const url     = `${getUpmUrl(URL_TYPE.POST_ANSWERS)}`;

  if (upmPromiseMap[url]) {
    return upmPromiseMap[url];
  }

  upmPromiseMap[url] = MS_API({
    method : 'GET',
    baseURL: baseUrl,
    url    : url,
    params : {
      categoryId: categoryId || ''
    }
  });

  upmPromiseMap[url].finally(() => {
    upmPromiseMap[url] = null;
  });

  return upmPromiseMap[url];
};

/*
 * Save questionnaire answers
 */
const postAnswers = (questionAnswers: PostAnswerModel, isRewardable = false): Promise<APIResponse> => {
  const baseUrl                    = process.env.REACT_APP_UPM_BASE_URL;
  const url                        = `${getUpmUrl(URL_TYPE.POST_ANSWERS)}`;
  const config: AxiosRequestConfig = {
    method : 'POST',
    baseURL: baseUrl,
    url    : url,
    data   : questionAnswers
  };

  if (isRewardable) {
    config.headers = REWARDABLE_HEADER;
  }

  return MS_API(config);
};

/*
 * Save questionnaire answers
 */
const postCustomAnswers = (questionAnswers: PostAnswerModel, isRewardable = false): Promise<APIResponse> => {
  const baseUrl                    = process.env.REACT_APP_UPM_BASE_URL;
  const url                        = getUpmUrl(URL_TYPE.POST_CUSTOM_ANSWERS);
  const config: AxiosRequestConfig = {
    method : 'POST',
    baseURL: baseUrl,
    url    : url,
    data   : questionAnswers
  };

  if (isRewardable) {
    config.headers = REWARDABLE_HEADER;
  }

  return MS_API(config);
};

/**
 * Save dynamic screener answers
 */
const postDynamicAnswers = (questionAnswers: PostDynamicAnswerModel): Promise<APIResponse> => {
  const baseUrl                    = process.env.REACT_APP_UPM_BASE_URL;
  const url                        = `${getUpmUrl(URL_TYPE.POST_DYNAMIC_ANSWERS)}`;
  const config: AxiosRequestConfig = {
    method : 'POST',
    baseURL: baseUrl,
    url    : url,
    data   : questionAnswers
  };

  return MS_API(config);
};

export {
  getAnswers,
  getQuestions,
  getSingleCategory,
  getSingleQuestion,
  postAnswers,
  postCustomAnswers,
  postDynamicAnswers
};
