import { getUserCacheData, setUserCacheData,UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { UspSurveysModel } from 'Shared/services/usp/models/UspSurveysModel';

/**
 * Retrieves the list of suppressed survey ids from the user session cache
 * @returns suppressedSurveyIds
 */
const getSuppressedSurveyIds = (): string[] => {
  return getUserCacheData(UserSessionCacheKeys.CLICKED_SURVEY_SUPPRESSION_LIST) || [];
};

/**
 * Store up to last 100 survey ids in local storage
 * so that we no longer serve this survey id to
 * the user again.
 * @param surveyId
 */
const addSurveyIdToSurveySuppressionList = (surveyId: string): void => {
  let suppressedSurveyIds = getSuppressedSurveyIds();

  // Check if the surveyId already exists in the array
  if (!suppressedSurveyIds.includes(surveyId)) {
    // Add the survey id to the front of the array
    suppressedSurveyIds.unshift(surveyId);

    // Cut so that it's the last 100 survey ids
    suppressedSurveyIds = suppressedSurveyIds.slice(0, 100);
  }

  // Update the survey cache
  setUserCacheData(UserSessionCacheKeys.CLICKED_SURVEY_SUPPRESSION_LIST, suppressedSurveyIds);
};

/**
 * Removes the survey which are in the survey suppression list
 * @param surveyList
 * @returns filteredSurveyList
 */
const removeSuppressedSurveysById = (surveyList: UspSurveysModel[]):  UspSurveysModel[] => {
  if (surveyList.length === 0) {
    return surveyList;
  }

  const suppressedSurveyIds = getSuppressedSurveyIds();

  if (suppressedSurveyIds.length === 0) {
    return surveyList;
  }

  const surveyIds: string[] = suppressedSurveyIds;

  return surveyList.filter((survey: UspSurveysModel) => !surveyIds.includes(survey.id));
};

export {
  addSurveyIdToSurveySuppressionList,
  getSuppressedSurveyIds,
  removeSuppressedSurveysById
};