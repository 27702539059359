import React, { useState } from 'react';
import { SELECT_TEST_ID } from 'Shared/components/design/select/constants';
import { SelectHelper, SelectInput, SelectLabel } from 'Shared/components/design/select/style';

/**
 * ENUMs defining style for component
 */
export enum SelectFieldTypes {
  DEFAULT,
  ERROR
}

/**
 * ENUMs defining style for component
 */
export type SelectOption = {
  label : string;
  value : string | number;
};

type SelectProps = {
  label?        : string;
  placeholder?  : string;
  helperText?   : string;
  initialValue? : string | number;
  disabled      : boolean;
  options       : readonly SelectOption[];
  changeCallback: (x: string) => void
  fieldType: SelectFieldTypes
}

function Select({
  label,
  placeholder,
  helperText,
  initialValue,
  disabled,
  options,
  changeCallback,
  fieldType = SelectFieldTypes.DEFAULT
}: SelectProps) {
  const [selectValue, setSelectValue] = useState<string|number>(initialValue || '');

  /**
   * Conditionally render label
   */
  const renderLabel = (): JSX.Element | undefined => {
    if (label) {
      return (
        <SelectLabel fieldType={fieldType}>
          { label }
        </SelectLabel>
      );
    }
  };

  /**
   * Conditionally render helper text
   */
  const renderHelperText = (): JSX.Element | undefined => {
    if (helperText) {
      return (
        <SelectHelper>
          { helperText }
        </SelectHelper>
      );
    }
  };

  /**
   * Run callback on text change and set state
   * @param event
   */
  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    event.preventDefault();
    const { value } = event.target;
    setSelectValue(value);
    changeCallback(value);
  };

  const getSelectOptions = (): React.ReactElement[] => {
    return options.map((option: SelectOption): React.ReactElement => {
      return <option key={ option.value } value={ option.value }>{ option.label }</option>;
    });
  };


  return (
    <div data-testid={SELECT_TEST_ID}>
      { renderLabel() }
      <SelectInput
        placeholder = {placeholder}
        value       = {selectValue}
        onChange    = {handleValueChange}
        disabled    = {disabled}
        fieldType   = {fieldType}
      >
        { getSelectOptions() }
      </SelectInput>
      { renderHelperText() }
    </div>
  );
}

export {
  Select
};