import { PopupStartEventProps } from 'App/models/eventModels';
import { LoaderEventProps } from 'Pages/surveyExperience/components/surveysStreamLoader/LoaderEventModels';
import { StePreinstallModalEventProps, SurveyPromptEventProps, SurveyRemoveEventProps } from 'Pages/surveyExperience/models/EventModels';
import { QuestionnaireEventProps } from 'Shared/components/design/questionnairePopup/QuestionnaireEventModels';
import { dispatchEvent } from 'Shared/services/eventsInterface';
import { MF_EVENTS } from 'Shared/services/routingService';

/**
 * Dispatch Survey Remove Event
 */
export const dispatchSurveyRemoveEvent = (eventProps: SurveyRemoveEventProps): void => {
  dispatchEvent<SurveyRemoveEventProps>(MF_EVENTS.surveyExperience.removeSurvey, eventProps);
};

/**
 * Dispatch Survey Prompt Event
 */
export const dispatchSurveyPromptEvent = (eventProps: SurveyPromptEventProps): void => {
  dispatchEvent<SurveyPromptEventProps>(MF_EVENTS.surveyExperience.surveyPrompt, eventProps);
};

/**
 * Dispatch Questionnaire Start Event
 */
export const dispatchQuestionnaireStartEvent = (eventProps: QuestionnaireEventProps): void => {
  const eventModel: PopupStartEventProps = {
    id   : MF_EVENTS.userActivation.questionnaireStart,
    props: eventProps
  };

  dispatchEvent<PopupStartEventProps>(MF_EVENTS.app.popup.start, eventModel);
};

/**
 * Dispatch STE Onboarding Popup Start Event
 */
export const dispatchSteOnboardingEvent = (): void => {
  dispatchEvent(MF_EVENTS.app.onboarding.start);
};

/**
 * Dispatch STE app preinstall modal event
 */
export const dispatchStePreInstallModalEvent = (eventProps: StePreinstallModalEventProps): void => {
  dispatchEvent(MF_EVENTS.app.stePreinstallModal, eventProps);
};

/**
 * Dispatch STE email confirmation modal event
 */
export const dispatchSteEmailConfirmationModalEvent = (): void => {
  dispatchEvent(MF_EVENTS.app.emailModal);
};

/**
 * Dispatch Survey Loading Event
 */
export const dispatchSurveysLoadingEvent = (eventProps: LoaderEventProps): void => {
  dispatchEvent(MF_EVENTS.surveysLoading.action, eventProps);
};

/**
 * Dispatch Promo Optin Start Event
 */
export const dispatchPromoOptinStartEvent = (): void => {
  dispatchEvent(MF_EVENTS.promotions.optinStart);
};
