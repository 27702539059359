export const SCROLL_STOP_CLASSNAME = 'scroll-stop';

/**
 * Calculate and return the width of the scrollbar.
 * @returns {number} Width of the scrollbar.
 */
export const getScrollbarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth;
};

/**
 * Disable page scrolling and add padding to the right of the body
 * to accommodate for the scrollbar width.
 */
export const disableScroll = (): void => {
  const scrollbarWidth = getScrollbarWidth();
  document.body.classList.add(SCROLL_STOP_CLASSNAME);
  document.body.style.paddingRight = `${scrollbarWidth}px`;
};

/**
 * Enable page scrolling and remove the padding from the right of the body.
 */
export const enableScroll = (): void => {
  document.body.classList.remove(SCROLL_STOP_CLASSNAME);
  document.body.style.paddingRight = '0px';
};
