
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { VdsInput } from '@disqotech/vinyl-design-system/react';
import { Button } from 'Shared/components/design/button/Button';
import { transitionSeconds } from 'Shared/components/design/loginModal/style';
import { PARAGRAPH, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface WrapperProps {
  show: boolean;
}

export const ModalWrapper = styled.div<WrapperProps>`
background-color: ${DESIGN_TOKENS.color.background.primary.value};
  position        : absolute;
  inset           : 0;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  opacity         : ${({ show }) => show ? 1 : 0};
  visibility      : ${({ show }) => show ? 'visible' : 'hidden'};
  transition      : opacity ${transitionSeconds}s;
  pointer-events  : ${({ show }) => show ? 'auto' : 'none'};
`;

export const ModalHeader = styled.h1`
  ${TITLES_NORMAL.FT_01};
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  position        : relative;
  width           : 100%;
  text-align      : center;
  padding         : 19px;
  border-bottom   : 1px solid #EAEAEA;
`;

export const BackButton = styled.button`
  cursor         : pointer;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 64px;
  height         : 64px;
  position       : absolute;
  top            : 1px;
  left           : 1px;
  background     : none;
  border         : none;
`;

export const ModalForm = styled.form`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  width         : 360px;
  padding       : 32px 0 40px 0;
  flex-grow     : 1;

  p {
    ${PARAGRAPH.DEFAULT}
    width: 100%;
  }
`;

export const EmailField = styled(VdsInput)`
  margin-top   : 24px;
  width        : 100%;

  &::part(base) {
    padding-right: 42px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 40px;
`;
