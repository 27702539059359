import { logError } from 'App/services/coralogixService';
import { getWurfl } from 'App/services/wurflService';
import { WURFL_BROWSERS } from 'Shared/constants';
import { IDPUserResponse } from 'Shared/models/swagger/idp';
import { API, APIResponse, MS_API } from 'Shared/services/apiInterface';
import { PLATFORM_NAMINGS } from 'Shared/services/pis/constants';
import { pisDeviceDataMutator } from 'Shared/services/pis/services/pisService';
import { EligibleAppType, UninstallAnswersSendType } from 'Shared/services/pis/types/installationFlowCheckTypes';
import { getUserCacheData, UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import { COUNTRY_CODES } from 'Shared/utils/countryCode';
import useSWR from 'swr';

type DeviceEligibilityResponseModel = {
  availableOptions: Array<EligibleAppType>
}

/**
 * Used to get from pis (pulse integration service) passive metering data and stores
 * the promise in memory so that if it is called again, it will return the same promise.
 */
const getPassiveMeteringData = ((): (() => Promise<APIResponse>) => {
  let passiveMeteringPromise: Promise<APIResponse> | null = null;

  return (): Promise<APIResponse> => {
    if (passiveMeteringPromise) {
      return passiveMeteringPromise;
    }

    passiveMeteringPromise = MS_API({
      method : 'GET',
      baseURL: process.env.SJ_PIS_BASE_URL,
      url    : '/passive-metering/data',
    }).finally(() => {
      passiveMeteringPromise = null;
    });

    return passiveMeteringPromise;
  };
})();

/**
 * Check in pulse integration service the device available options
 */
const pisDeviceEligibility = (): Promise<APIResponse> => {
  const wurflDataMutated = pisDeviceDataMutator(getWurfl());

  const params = {
    os            : wurflDataMutated.advertised_device_os,
    osVersion     : wurflDataMutated.advertised_device_os_version,
    browser       : wurflDataMutated.advertised_browser,
    browserVersion: wurflDataMutated.advertised_browser_version,
  };

  return MS_API({
    method : 'GET',
    baseURL: process.env.SJ_PIS_BASE_URL,
    url    : '/options',
    params : params,
  });
};

function addDefaultDeviceEligibility (availableOptions: EligibleAppType[]) {
  const wurfl = getWurfl();
  // If there isn't a desktop app available, add it if supported
  if (!availableOptions.some(opt => opt.type === 'DESKTOP')) {
    if (wurfl.advertised_device_os === PLATFORM_NAMINGS.macOS) {
      availableOptions.push({
        artifactUrl: 'https://pulse.surveyjunkie-staging.com/downloads/survey-junkie.pkg',
        os: 'MAC_OS',
        type: 'DESKTOP',
        url: 'https://pis.surveyjunkie-staging.com/options/install?type=DESKTOP&os=MAC_OS'
      });
    } else if (wurfl.advertised_device_os === PLATFORM_NAMINGS.WINDOWS) {
      availableOptions.push({
        artifactUrl: 'https://pulse.surveyjunkie-staging.com/downloads/survey-junkie.pkg',
        os: 'WINDOWS',
        type: 'DESKTOP',
        url: 'https://pis.surveyjunkie-staging.com/options/install?type=DESKTOP&os=WINDOWS'
      });
    }
  }

  // If there isn't an extension available, add it if supported
  if (!availableOptions.some(opt => opt.type === 'EXTENSION')) {
    if (wurfl.advertised_browser === WURFL_BROWSERS.chrome) {
      availableOptions.push({
        type: 'EXTENSION',
        url: 'https://pis.surveyjunkie-staging.com/options/install?type=EXTENSION&browser=CHROME',
        artifactUrl: 'https://chromewebstore.google.com/detail/survey-junkie/dnfbbeblcdlfnoneibihgklodmlkimfo',
        browser: 'CHROME'
      });
    } else if (wurfl.advertised_browser === WURFL_BROWSERS.edge) {
      availableOptions.push({
        type: 'EXTENSION',
        url: 'https://pis.surveyjunkie-staging.com/options/install?type=EXTENSION&browser=EDGE',
        artifactUrl: 'https://microsoftedge.microsoft.com/addons/detail/hcohkdneahbdhaflbchfhleggjmeoikl',
        browser: 'EDGE'
      });
    } else if (wurfl.advertised_browser === WURFL_BROWSERS.firefox) {
      availableOptions.push({
        type: 'EXTENSION',
        url: 'https://pis.surveyjunkie-staging.com/options/install?type=EXTENSION&browser=FIREFOX',
        artifactUrl: 'https://pulse.surveyjunkie.com/survey-junkie-pulse.xpi',
        browser: 'FIREFOX'
      });
    }
  }

  return availableOptions;
}

/**
 * @returns {Promise<DeviceEligibilityResponseModel>}
 */
const getDeviceEligibility = async (): Promise<DeviceEligibilityResponseModel> => {
  if (getWurfl().is_mobile) {
    return {
      availableOptions: []
    };
  }

  const userData = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_DATES) as IDPUserResponse | null;

  //if the user is not a US user, return an empty array
  if (userData?.country !== COUNTRY_CODES.US) {
    return {
      availableOptions: []
    };
  }

  try {
    const response = await pisDeviceEligibility();
    const availableOptions = addDefaultDeviceEligibility(response.data?.availableOptions ?? []);
    return {
      ...response.data,
      availableOptions
    };
  } catch (error) {
    const availableOptions = addDefaultDeviceEligibility([]);
    logError('getDeviceEligibility', error);
    return { availableOptions };
  }
};

/**
 * @returns {Promise<DeviceEligibilityResponseModel>}
 */
const useCheckDeviceEligibility = () => {
  return useSWR(SwrKeys.CheckDeviceEligibility, getDeviceEligibility);
};

/**
 * Get platform installation data
 */
const getPlatformInstallationData = (params: string): Promise<APIResponse> => {
  return MS_API({
    method : 'GET',
    baseURL: process.env.SJ_PIS_BASE_URL,
    url    : `/options/install?${params}`,
  });
};

/**
 * Send success token to PIS
 */
const putPlatformInstallationSuccess = (downloadToken: string): Promise<APIResponse> => {
  return API({
    method : 'PUT',
    url    : `${process.env.SJ_PIS_BASE_URL}/route/success`,
    data   : {
      pulseOptionDownloadToken: downloadToken
    },
  });
};

/**
 * Get uninstall survey questions
 */
const getUninstallSurvey = (params: string): Promise<APIResponse> => {
  return API({
    method : 'GET',
    baseURL: process.env.SJ_PIS_BASE_URL,
    url    : `/uninstallation-feedback/active-questions?${params}`,
  });
};

/**
 * Get uninstall survey questions
 */
const postUninstallSurvey = (data: UninstallAnswersSendType): Promise<APIResponse> => {
  return API({
    method : 'POST',
    baseURL: process.env.SJ_PIS_BASE_URL,
    url    : `/uninstallation-feedback`,
    data   : data,
  });
};

export {
  getDeviceEligibility,
  getPassiveMeteringData,
  getPlatformInstallationData,
  getUninstallSurvey,
  postUninstallSurvey,
  putPlatformInstallationSuccess,
  useCheckDeviceEligibility
};
