import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { Banner } from 'Shared/components/design/banner/Banner';
import { TextField } from 'Shared/components/design/textField/TextField';
import { DEVICE_BREAKPOINT, PARAGRAPH, POPUP_BACKGROUND, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled, { css } from 'styled-components';

interface CreateAccountProgressFillProps {
  /** The progress amount, as a value from 0-1 */
  progress: number;
}

interface PopupBackgroundProps {
  visible: boolean;
}

interface CreateAccountProgressBarProps {
  hidden: boolean;
}

interface CreateAccountStepCounterProps {
  hidden: boolean;
}

export const CreateAccountWrapper = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : stretch;
  position      : fixed;
  inset         : 0;
  z-index       : 101;
  background    : white;
`;

export const CreateAccountStepCounter = styled.div<CreateAccountStepCounterProps>`
  ${TITLES_NORMAL.FT_01}
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding        : 19px;
  visibility     : ${(props) => props.hidden ? 'hidden' : 'visible'};
`;

export const CreateAccountProgressBar = styled.div<CreateAccountProgressBarProps>`
  height    : 4px;
  background: ${DESIGN_TOKENS.color.border.light.value};
  visibility: ${(props) => props.hidden ? 'hidden' : 'visible'};
`;

export const CreateAccountProgressFill = styled.div<CreateAccountProgressFillProps>`
  background: ${DESIGN_TOKENS.color.background.success.value};
  height    : 100%;
  width     : ${({ progress }) => `${100 * progress}%`};
  transition: width 0.5s;
`;

export const CreateAccountMainWrapper = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  flex          : 1;
  overflow-y    : auto;
`;

export const CreateAccountQuestionBanner = styled(Banner)`
  margin: 0;
`;

export const InputRow = styled.div`
  display       : flex;
  gap           : 20px;
  margin-bottom : -16px; // Reduce bottom gap from CAQuestionWrapper from 40px to 24px

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    flex-direction: column;
  }
`;

export const PopupBackground = styled.div<PopupBackgroundProps>`
  ${POPUP_BACKGROUND}
  ${({visible}) => visible
    ? css`opacity: 1;`
    : css`
      pointer-events: none;
      opacity       : 0;
    `
  }
  transition: opacity 0.5s;
`;

export const NameInput = styled(TextField)`
  flex-grow: 1;
  min-width: 180px;
`;

export const PopupContentContainer = styled.div`
  max-width     : 414px;
  margin        : auto;
  vertical-align: middle;
  display       : flex;
  flex-direction: column;
  gap           : 24px;
  padding-top   : 40px;

  & > div {
    width:100%;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    width  : 100%;
    padding: 40px 16px;
  }
`;

export const SadFaceEmoji = styled.img`
  width      : 56px;
  height     : 56px;
  font-weight: 500;
  font-size  : 48px;
  line-height: 56px;
  display    : flex;
  align-items: center;
`;

export const TextTitle = styled.div`
  ${TITLES_NORMAL.FT_02}
  color: ${DESIGN_TOKENS.color.text.normal.value};
`;

export const TextSubTitle = styled.div`
  ${PARAGRAPH.DEFAULT}
  color: ${DESIGN_TOKENS.color.text.secondary.value};
`;
