import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import {DEVICE_BREAKPOINT, PARAGRAPH, TITLES_NORMAL} from "Shared/styles/designConstants";
import styled from 'styled-components';

const MODAL_WIDTH = 480;

export const ModalButtonContainer = styled.div`
  padding-top   : 32px;
  display       : flex;
  gap           : 16px;
  flex-direction: row-reverse;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    flex-direction: column;
  }
`;

export const ModalCloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  right: 18px;
  top: 26px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ModalContentContainer = styled.div`
  box-shadow: ${ DESIGN_TOKENS.shadow.lg.value };
  position: absolute;
  width: ${ MODAL_WIDTH }px;
  padding: 40px 24px 24px;
  background: ${ DESIGN_TOKENS.color.primitive.purple['100'].value };
  border: 1px solid ${ DESIGN_TOKENS.color.primitive.gray['200'].value };
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: none;
    border-radius: 8px 8px 0 0;
  }
`;

export const ModalContainer = styled.div`
  z-index   : 301;
  position  : fixed;
  top       : 0;
  right     : 0;
  left      : 0;
  bottom    : 0;
  background: ${ DESIGN_TOKENS.color.background.overlay.value };
`;

export const ModalText = styled.div`
  ${ PARAGRAPH.DEFAULT }
  color: ${ DESIGN_TOKENS.color.text.secondary.value };
`;

  export const ModalTitle = styled.div`
  ${ TITLES_NORMAL.FT_03 }
  color        : ${ DESIGN_TOKENS.color.text.normal.value };
  margin-bottom: 16px;

  svg {
    width         : 26px;
    height        : 26px;
    fill          : ${ DESIGN_TOKENS.color.text.normal.value };
    vertical-align: text-top;
  }
`;
