import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, POPUP_HEADER_CONTAINER, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  ${POPUP_HEADER_CONTAINER}
  position        : fixed;
  z-index         : 4;
  background-color: ${DESIGN_TOKENS.color.background.primary.value};

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display    : flex;
    align-items: center;
  }
`;

export const HeaderText = styled.div`
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 100%;
  width          : 100%;

  ${TITLES_NORMAL.FT_01}
`;

export const ExitContainer = styled.div`
  position       : absolute;
  top            : 0px;
  right          : 20px;
  height         : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;

  button {
    cursor    : pointer;
    padding   : 0px;
    background: none;
    border    : none;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    width       : 30px;
    top         : 0;
    right       : 0;
    margin-right: 10px;
  }
`;
