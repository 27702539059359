import React, { ChangeEvent, useEffect, useState } from 'react';
import { CHECKBOX_TEST_ID } from 'Shared/components/design/checkbox/constants';
import {
  CheckBox,
  CheckboxContainer,
  GroupContainer,
  HiddenCheckbox,
  Label
} from 'Shared/components/design/checkbox/style';
import {ICON_IDS} from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

type CheckboxGroupValueType = 'number' | 'string';

type ValueTypeToAnswerValue<TValueType extends CheckboxGroupValueType> =
  TValueType extends 'number' ? number :
  TValueType extends 'string' ? string :
  never

type SingleCheckboxDataModel<TValueType extends CheckboxGroupValueType> = {
  answerValue: ValueTypeToAnswerValue<TValueType>;
  label      : string;
  selected   : boolean;
}

type CheckboxProps<TValueType extends CheckboxGroupValueType> = {
  checkBoxes         : SingleCheckboxDataModel<TValueType>[];
  changeCallback     : (x: ValueTypeToAnswerValue<TValueType>[]) => void;
  changeFilter?      : (x: ValueTypeToAnswerValue<TValueType>[]) => ValueTypeToAnswerValue<TValueType>[];
  id?                : string;
  isAlternateIcon?   : boolean;
  isColorBackground  : boolean;
  disabled           : boolean;
  groupName          : string;
  isHorizontalLayout?: boolean;
  valueType?         : TValueType;
}

/**
 * Renders <CheckboxGroup /> component
 * @param checkBoxes
 * @param changeCallback
 * @param changeFilter allows parent to update which checkboxes are checked
 * @param isAlternateIcon conditonally displays "Minus" icon instead of "Checkmark"
 * @param isColorBackground conditionally animates green background on checked state of checkbox
 * @param disabled
 * @param groupName
 * @param isHorizontalLayout determines the checkboxes layout vertical vs horizontal
 */
function CheckboxGroup<TValueType extends CheckboxGroupValueType = 'number'>({
  checkBoxes,
  changeCallback,
  changeFilter,
  id,
  isAlternateIcon = false,
  isColorBackground,
  disabled,
  groupName,
  isHorizontalLayout = false,
  valueType = 'number' as TValueType
}: CheckboxProps<TValueType>): JSX.Element {

  //show error if both colorbackground and alternate icon are set
  if (isColorBackground && isAlternateIcon) {
    throw new Error(`Checkbox Component Error: Checkbox component cannot have both a color background and alternate icon set`);
  }

  const [checked, setChecked]           = useState<ValueTypeToAnswerValue<TValueType>[]>([]);
  const [initializing, setInitializing] = useState<boolean>(true);

  /**
   * Handles selecting/deselecting of checkboxes
   */
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    let value = event.currentTarget.value as ValueTypeToAnswerValue<TValueType>;
    if (valueType === 'number') {
      value = parseInt(event.currentTarget.value) as ValueTypeToAnswerValue<TValueType>;
    }
    let _checked = [...checked];

    if (_checked.includes(value)) {
      _checked = _checked.filter(x => x !== value);
    } else {
      _checked.push(value);
    }

    if (changeFilter) {
      _checked = changeFilter(_checked);
    }

    setChecked(_checked);
    changeCallback(_checked);
  };

  /**
   * Setting initial checked values of checkboxes
   */
  useEffect(() => {
    if (initializing) {
      const checkedValues: ValueTypeToAnswerValue<TValueType>[] = [];

      for (const checkBox of checkBoxes) {
        if (checkBox.selected) {
          checkedValues.push(checkBox.answerValue);
        }
      }

      setChecked([...checked, ...checkedValues]);
    }
    setInitializing(false);
  }, [initializing, checkBoxes, checked]);

  /**
   * Renders checkmark/minus Icon
   */
  const renderIcon = (): JSX.Element => {
    const ICON_ID = isAlternateIcon ? ICON_IDS.MINUS : ICON_IDS.CHECKMARK;

    return(
      <>
        <Icon iconId={ICON_ID} />
      </>
    );
  };

  const renderCheckboxes = (): JSX.Element[] => {
    const checkboxList = checkBoxes?.map((singleItem: SingleCheckboxDataModel<TValueType>, index: number) => {
      return(
        <CheckboxContainer
          checked            = {checked.includes(singleItem.answerValue)}
          disabled           = {disabled}
          isColorBackground  = {isColorBackground}
          key                = {`${id || 'checkbox'}-${index}`}
          data-checked       = {checked.includes(singleItem.answerValue)}
          data-prev-checked = {checkBoxes[index - 1] && checked.includes(checkBoxes[index - 1].answerValue) }
          data-next-checked  = {checkBoxes[index + 1] && checked.includes(checkBoxes[index + 1].answerValue) }
        >
          <HiddenCheckbox
            type           = "checkbox"
            name           = {groupName}
            value          = {singleItem.answerValue}
            checked        = {checked.includes(singleItem.answerValue)}
            onChange       = {onChangeHandler}
            disabled       = {disabled}
          />
          <CheckBox
            disabled = {disabled}
            checked  = {checked.includes(singleItem.answerValue)}
          >
            {renderIcon()}
          </CheckBox>
          <Label
            checked           = {checked.includes(singleItem.answerValue)}
            disabled          = {disabled}
            isColorBackground = {isColorBackground}
          >
            {singleItem.label}
          </Label>
        </CheckboxContainer>
      );
    });

    return checkboxList;
  };

  return(
    <GroupContainer
      id                 = {id}
      data-testid        = {CHECKBOX_TEST_ID}
      isHorizontalLayout = {isHorizontalLayout}
    >
      { renderCheckboxes() }
    </GroupContainer>
  );
}

export {
  CheckboxGroup,
  SingleCheckboxDataModel
};