import React, { HTMLAttributes, ReactNode } from 'react';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { BUTTON_TEST_IDS } from 'Shared/components/design/button/constants';
import {
  ButtonContainer,
  ButtonIcon
} from 'Shared/components/design/button/style';
import LoadingSpinner from 'Shared/components/design/loadingSpinner/LoadingSpinner';
import { ICON_IDS, IconId } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

export enum ContextTypes {
  PRIMARY,
  SECONDARY,
  NEGATIVE,
  NEGATIVE_SECONDARY,
  PULSE,
  AUXILIARY,
  PAYPAL,
  SURF_TO_EARN,
  SURF_TO_EARN_SECONDARY,
}

export enum ButtonSizes {
  LARGE,
  MEDIUM
}

export enum ButtonIconPosition {
  LEFT,
  RIGHT
}

export enum ButtonWidth {
  FULL,
  NORMAL
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * The context in which the button is being used. Effects color and other styling.
   */
  context?            : ContextTypes;
  /**
   * Whether the button should be disabled. Effects styles & clickability.
   */
  disabled?           : boolean;
  /**
   * The size of the button. Effects font size and padding.
   */
  size?               : ButtonSizes;
  /**
   * Whether the width of the button should fit the content (NORMAL) or be the same width as its container (FULL).
   */
  width?              : ButtonWidth;
  /**
   * The icon to display to the left of the button's text.
   */
  iconLeft?           : IconId;
  /**
   * The icon to display to the right of the button's text.
   */
  iconRight?          : IconId;
  /**
   * The text to display if `isLoading` is set to `true`.
   */
  stillLoadingMessage?: string;
  /**
   * Controls whether to show a spinner and the `stillLoadingMessage` rather than the `children`.
   */
  isLoading?          : boolean;
  /**
   * What action should be taken when the user clicks on the button.
   */
  onClick?            : () => void;
  /**
   * The content of the button to be displayed (as long as `isLoading` is not `true`).
   */
  children?           : ReactNode | ReactNode[];
  /**
   * The value to be provided to the `data-testid` property on the button element.
   */
  testId?             : string;
  /**
   * Whether to use `currentcolor` as the fill for the icons rather than their default fill color.
   */
  iconCurrentColor?   : boolean;
  /**
   * Whether the button is interactable or not
   */
  interactable?       : boolean;
}

export function Button ({
  context = ContextTypes.PRIMARY,
  disabled = false,
  size = ButtonSizes.MEDIUM,
  width = ButtonWidth.NORMAL,
  iconLeft,
  iconRight,
  isLoading = false,
  stillLoadingMessage='',
  onClick,
  children,
  interactable = true,
  testId = BUTTON_TEST_IDS.BUTTON_TEST,
  iconCurrentColor = false,
  ...rest
}: ButtonProps) {

  /**
   * Determines loading spinner color based on button state and type
   */
  const getSpinnerColor = (): string | undefined => {
    if (disabled) {
      return DESIGN_TOKENS.color.text.normal.value;
    }

    switch(context) {
      case ContextTypes.PRIMARY:
      case ContextTypes.NEGATIVE:
      case ContextTypes.PULSE:
      case ContextTypes.AUXILIARY:
        return DESIGN_TOKENS.color.text.onSuccess.value;
      case ContextTypes.SECONDARY:
        return DESIGN_TOKENS.color.text.normal.value;
      case ContextTypes.NEGATIVE_SECONDARY:
        return DESIGN_TOKENS.color.text.critical.value;
    }
  };

  /**
   * Render loading animation
   */
  const renderLoading = (): JSX.Element | undefined => {
    if (isLoading) {
      return (
        <ButtonIcon context={context} isLoading={true} disabled={disabled} size={size}>
          <LoadingSpinner circleColor={getSpinnerColor()}/>
        </ButtonIcon>
      );
    }
  };

  /**
   * Get icon
   */
  const renderIcon = (icon?: IconId, isButtonLoading?: boolean, iconPosition?: ButtonIconPosition): JSX.Element | undefined => {
    if (icon && !isButtonLoading) {
      return (
        <ButtonIcon context={context} isLoading={isButtonLoading} disabled={disabled} size={size} iconPosition={iconPosition} iconCurrentColor={iconCurrentColor}>
          <Icon iconId={ icon } />
        </ButtonIcon>
      );
    }
  };

  /**
   * Conditionally render the icon if it is set
   */
  const renderLeftIcon = (): JSX.Element | undefined => {
    if (!iconLeft) {
      return;
    }

    const icon = isLoading ? ICON_IDS.REFRESH : iconLeft;
    return renderIcon(icon, isLoading, ButtonIconPosition.LEFT);
  };

  /**
   * Conditionally render the icon if it is set
   */
  const renderRightIcon = (): JSX.Element | undefined => {
    if (!iconRight) {
      return;
    }
    const icon = isLoading ? ICON_IDS.REFRESH : iconRight;
    return renderIcon(icon, isLoading, ButtonIconPosition.RIGHT);
  };

  return (
    <ButtonContainer
      data-testid      = {testId}
      context          = {context}
      disabled         = {disabled}
      onClick          = {() => !disabled && interactable && onClick && onClick()}
      width            = {width}
      size             = {size}
      role             = "button"
      aria-disabled    = {disabled}
      tabIndex         = {0}
      {...rest}
    >
        {renderLeftIcon()}
        {renderLoading()}
        {!isLoading ? children : <span>{stillLoadingMessage}</span>}
        {renderRightIcon()}
    </ButtonContainer>
  );
}
