export const REDEMPTION_STATUS_ENUMS = {
  running  : 'RUNNING',
  succeeded: 'SUCCEEDED',
  failed   : 'FAILED',
  timedOut : 'TIMED_OUT',
  aborted  : 'ABORTED'
};

export const REWARD_PROVIDERS = {
  paypal          : 'PayPal',
  virtualIncentive: 'VirtualIncentives',
  dwolla          : 'Dwolla'
};

export const REWARD_PROVIDER_ENUMS = {
  paypal      : 'PAYPAL',
  giftcard    : 'VIRTUAL_INCENTIVES',
  bankTransfer: 'DWOLLA'
};

export const GIFT_CARD_SKU_VALUES = {
  AMAZON   : 'UGC-V-AZ3',
  WALMART  : 'UGC-V-W90',
  I_TUNES  : 'UGC-V-IT9',
  TARGET   : 'UGC-V-TG9',
  STARBUCKS: 'SBX-V-009',
  SEPHORA  : 'UGC-V-SEP',
  GROUPON  : 'UGC-V-G90'
};

export const VISA_CARD_SKU                          = 'UVC-V-V09';

export const VIRTUAL_INCENTIVE_GIFT_CARD_PROGRAM_ID = 27781;

export const VIRTUAL_INCENTIVE_VISA_CARD_PROGRAM_ID = 52936;

enum GiftCardVendor {
  Amazon    = 'Amazon',
  Walmart   = 'Walmart',
  Apple     = 'Apple',
  Target    = 'Target',
  Starbucks = 'Starbucks',
  Sephora   = 'Sephora',
  Groupon   = 'Groupon',
  Visa      = 'Visa'
}

// Object map for SKU to vendor conversion
export const skuToVendorMap: { [key: string]: GiftCardVendor } = {
  [VISA_CARD_SKU]                 : GiftCardVendor.Visa,
  [GIFT_CARD_SKU_VALUES.AMAZON]   : GiftCardVendor.Amazon,
  [GIFT_CARD_SKU_VALUES.WALMART]  : GiftCardVendor.Walmart,
  [GIFT_CARD_SKU_VALUES.I_TUNES]  : GiftCardVendor.Apple,
  [GIFT_CARD_SKU_VALUES.TARGET]   : GiftCardVendor.Target,
  [GIFT_CARD_SKU_VALUES.STARBUCKS]: GiftCardVendor.Starbucks,
  [GIFT_CARD_SKU_VALUES.SEPHORA]  : GiftCardVendor.Sephora,
  [GIFT_CARD_SKU_VALUES.GROUPON]  : GiftCardVendor.Groupon
};
