import { useEffect } from 'react';
import { getUserContext, isCoralogixInitialized, setUserContext } from 'App/services/coralogixService';
import { getUserId, useIsLoggedIn } from 'App/services/idpTokenService';

/**
 * Hook to use Coralogix
 */
const useSetupCoralogixUser = () => {
  const isLoggedIn = useIsLoggedIn();

  // Set user context on userId change
  useEffect(() => {
    const userId              = getUserId(true);
    const existingUserContext = getUserContext();

    if (!userId || !isCoralogixInitialized()) {
      return;
    }

    if (String(userId) === String(existingUserContext?.user_id)) {
      return;
    }

    setUserContext({
      userId,
      userName: ''
    });
  }, [isLoggedIn]);
};

export default useSetupCoralogixUser;