import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from 'App/components/footer/Footer';
import { MENU_ROUTES } from 'App/components/sidebar/constants';
import { Sidebar } from 'App/components/sidebar/Sidebar';
import {
  AppContainer,
  LeftContainer,
  PointsBackground,
  RightContainer,
  RightContentBottomContainer,
  RightContentContainer,
} from 'App/components/style';
import { APP_COMPONENT } from 'App/components/testId';
import { MEMBER_PANELS_URL } from 'App/routes/constants';
import LoginModal from 'Shared/components/design/loginModal/LoginModal';
import NotificationsBanner, { BannerContext } from 'Shared/components/design/notificationsBanner/NotificationsBanner';
import { ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { VERUserVerificationStatus } from 'Shared/models/swagger/ver';
import { useUserPointsFromACT } from 'Shared/services/act/app/actService';
import { initAnalytics } from 'Shared/services/analytics/config';
import { canRedeem, hasRedeemed } from 'Shared/services/analytics/services/redeemHelper';
import { isInMobileApp } from 'Shared/services/mobileAppMessenger';
import { loginToMonolith } from 'Shared/services/monolith/api/monolithApiService';
import initializeSplitTests, { SplitTestInitializationLocation } from 'Shared/services/opa/app/initializeSplitTests';
import { checkAppInstallationAction, passiveMeteringDataTokenVerification } from 'Shared/services/pis/services/pisService';
import { IDPUserResponseInternal } from 'Shared/services/userActivation/api/consts';
import { useGetUserInfo } from 'Shared/services/userActivation/api/idpApiService';
import {
  canTriggerRelevantIdCheck,
  sendRelevantIdEndEvent,
  triggerRelevantIdCheck
} from 'Shared/services/ver/app/relevantId';
import { useUserVerificationStatus } from 'Shared/services/ver/app/verificationService';

const DeviceFingerprint = lazy(() => import('App/components/deviceFingerprint/DeviceFingerprint'));

/**
 * Initialize analytics when user lands on
 * member page.
 */
const _initAnalytics = (
    pointsData: ACTUserPointsResponse | null | undefined,
    userInfo: IDPUserResponseInternal | null | undefined,
    userVerificationStatus: VERUserVerificationStatus | null | undefined
  ) => {
  if (
    !pointsData || !userInfo || !userVerificationStatus ||
    typeof pointsData !== 'object' || typeof userInfo !== 'object' || typeof userVerificationStatus !== 'object'
  ) {
    return;
  }

  // Update GA4 Since Log In
  initAnalytics({
    id                    : userInfo.id,
    affiliate             : userInfo.affiliate,
    signup_source         : userInfo.signupSource,
    current_points        : pointsData.currentPoints,
    lifetime_points       : pointsData.lifetimePoints,
    can_redeem            : canRedeem(pointsData.currentPoints, userVerificationStatus.verificationStatus),
    has_redeemed          : hasRedeemed(pointsData.currentPoints, pointsData.lifetimePoints),
  });
};

/**
 * Renders the main parent app
 */
function App(): JSX.Element {
  const location                                         = useLocation();
  const [searchParams]                                   = useSearchParams();
  const isFullWidth                                      = location.pathname.startsWith(MENU_ROUTES.ACCOUNT_HOME) || location.pathname.startsWith(MENU_ROUTES.REWARDS) || location.pathname.startsWith(MEMBER_PANELS_URL) || location.pathname.startsWith(MENU_ROUTES.GAMES);
  const locationSupportsLargeContainer                   = location.pathname === MENU_ROUTES.HOME;
  const { data: pointsData, isLoading: isPointsLoading } = useUserPointsFromACT();
  const { data: userInfo }                               = useGetUserInfo();
  const { data: verificationStatusData }                 = useUserVerificationStatus();
  const {t}                                              = useTranslation('app');
  const navigate                                         = useNavigate();
  const showFooter                                       = location.pathname === MENU_ROUTES.HOME;
  const hasRunRvCheck                                    = useRef(false);

  // Initialize split tests on page load
  useEffect(() => {
    initializeSplitTests(SplitTestInitializationLocation.INIT_SIGNED_IN);
  }, []);

  //For now we are only showing the banner on the home and rewards page only
  const isBannerPage = (location.pathname === MENU_ROUTES.HOME) || location.pathname.startsWith(MENU_ROUTES.REWARDS);
  const isBannerVisible = !!(verificationStatusData?.contactSupport && isBannerPage);

  useEffect(() => {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const relevantIdCheckProcess = () => {
      if (hasRunRvCheck.current) {
        return;
      }

      hasRunRvCheck.current = true;

      if (canTriggerRelevantIdCheck()) {
        triggerRelevantIdCheck(`${userInfo?.id || ''}`, `${userInfo?.country || ''}`);
      } else {
        sendRelevantIdEndEvent();
      }
    };

    checkAppInstallationAction(searchParams);
    _initAnalytics(pointsData, userInfo, verificationStatusData);

    if (userInfo) {
      relevantIdCheckProcess();
      passiveMeteringDataTokenVerification();
    }

    // Initialize Monolith Session
    loginToMonolith();
  }, [searchParams, pointsData, userInfo, verificationStatusData, location]);

  /**
   * Conditionally Renders the notifications banner
   * @param isHiddenMirroredComponent
   * @returns
   */
  const renderNotificationsBanner = (): JSX.Element => {
    if (isBannerVisible) {
      return(
        <NotificationsBanner
          isButtonVisible = {true}
          buttonText      = {t('NotificationBanner--button-text')}
          bannerContext   = {BannerContext.DEFAULT}
          buttonCallback  = {() => navigate(MENU_ROUTES.ACCOUNT_CONTACT_US)}
        >
          {t('NotificationBanner--message')}
        </NotificationsBanner>
      );
    }

    return <></>;
  };

  /**
   * MEW-173
   * Conditionally renders the navigation bar as currently we hide it on mobile app webview
   * @returns
   */
  const renderNavigationBar = (): JSX.Element => {
    if (isInMobileApp()) {
      return <></>;
    }

    return(
      <LeftContainer>
        <Sidebar />
      </LeftContainer>
    );
  };

  return (
    <>
      <PointsBackground isPointsLoading={isPointsLoading} canRedeem={ !!(pointsData && pointsData.currentPoints >= pointsData.minRedeemablePoints) } />
      {renderNotificationsBanner()}
      <AppContainer data-testid={APP_COMPONENT}>
        {renderNavigationBar()}
        <RightContainer>
          <RightContentContainer
            isThreeColumn = { locationSupportsLargeContainer }
            isFullWidth   = { isFullWidth }
          >
            <Outlet />
          </RightContentContainer>
          { showFooter
            ? <RightContentBottomContainer>
                <Footer />
              </RightContentBottomContainer>
            : null
          }
        </RightContainer>
        <LoginModal />
        <Suspense fallback={null}>
          <DeviceFingerprint />
        </Suspense>
      </AppContainer>
    </>
  );
}

export default App;
