import React from 'react';
import { PROGRESS_BAR_EMPTY, PROGRESS_BAR_TEST_ID } from 'Shared/components/design/progressBar/constants';
import { ProgressBackground, ProgressFill } from 'Shared/components/design/progressBar/style';

enum ProgressBarSizes {
  SMALL,
  LARGE
}

type ProgressBarProps = {
  /**
   * How much of the progress bar should be filled, as a proportion of `total`.
   */
  currentIndex  : number;
  /**
   * The `currentIndex` at which the progress bar will be 100% filled.
   */
  total         : number;
  /**
   * When `true`, ProgressBar renders empty when `currentIndex` is zero.
   * Useful when not working with arrays.
   */
  notIndexBased?: boolean;
  /**
   * The height of the progress bar.
   */
  size?         : ProgressBarSizes;
}

/**
 * Component renders a progress bar.
 * The fill is calculated by the ratio of currentIndex to total(ex. array.length)
 */
function ProgressBar ({
  currentIndex,
  total,
  notIndexBased,
  size = ProgressBarSizes.LARGE
}: ProgressBarProps): JSX.Element {

  /**
   * Calculate width for progress bar
   */
  const calculateWidth = (): number => {
    // skips calculations if 0
    if (total === PROGRESS_BAR_EMPTY) {
      return PROGRESS_BAR_EMPTY;
    }

    // dictates if 1 gets added to currentIndex. It forces arrays(0-based) to have some fill at the first element
    const indexPad = notIndexBased ? 0 : 1;

    // calculates the percentage
    const percentage = ((currentIndex + indexPad)/total) * 100;

    if (isNaN(percentage)) {
      return 0;
    }

    return percentage;
  };

  return (
    <ProgressBackground size={size} data-testid={PROGRESS_BAR_TEST_ID}>
      <ProgressFill size={size} width={calculateWidth()} />
    </ProgressBackground>
  );
}

export {
  ProgressBar,
  ProgressBarSizes
};
