
export const USP_PARAM_DEFAULTS = {
  HAS_FLASH    : false,
  CLIENT_APP_ID: process.env.USP_MRS_CLIENT_APP_ID
};


export const REPDATA_PROVIDER_ID = 31;

export const NEW_MEMBER_THRESHOLD_DAYS = 7;

export const MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_DESKTOP = 3;
export const MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_DESKTOP = 30;
export const MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_DESKTOP    = 30;

export const MAX_NUMBER_EXISTING_MEMBER_FEATURED_SURVEYS_MOBILE = 3;
export const MAX_NUMBER_EXISTING_MEMBER_PRIORITY_SURVEYS_MOBILE = 30;
export const MAX_NUMBER_EXISTING_MEMBER_TOTAL_SURVEYS_MOBILE    = 30;

export const MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_DESKTOP = 0;
export const MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_DESKTOP = 0;
export const MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_DESKTOP    = 30;

export const MAX_NUMBER_NEW_MEMBER_FEATURED_SURVEYS_MOBILE = 3;
export const MAX_NUMBER_NEW_MEMBER_PRIORITY_SURVEYS_MOBILE = 0;
export const MAX_NUMBER_NEW_MEMBER_TOTAL_SURVEYS_MOBILE    = 12;