import React, { useEffect } from 'react';
import Loading from 'App/components/loading';
import { logError } from 'App/services/coralogixService';
import { purgeAppLocalStorage, renewAccessToken } from 'App/services/idpTokenService';

export function RefreshAccessToken() {
  useEffect(() => {
    purgeAppLocalStorage();

    // Just `.catch()` so the promise isn't unhandled, but `renewAccessToken` already handles logging the user out
    renewAccessToken().catch(error => {
      logError('renewAccessToken', error);
    });
  }, []);

  return <Loading />;
}
