import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { DEVICE_BREAKPOINT, INTER_FONT, LABEL_XS, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled, { keyframes } from 'styled-components';

type CelebrationProps = {
  isVisible: boolean;
};

type RewardMessage = {
  fontSize: string;
};

export const PromoWidgetBackdrop = styled.div`
  position        : relative;
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  margin          : 0px 1px 16px 1px;
  display         : flex;
  flex-direction  : column;
  max-width       : 100%;
  border-radius   : 12px;
  overflow        : hidden;
  box-shadow      :
    0px 4px 4px 0px rgba(0, 0, 0, 0.04),
    0px 2px 2px 0px rgba(0, 0, 0, 0.04),
    0px 1px 1px 0px rgba(0, 0, 0, 0.04),
    0px 0px 0px 1px rgba(0, 0, 0, 0.04);
`;

export const PromoWidgetMainContent = styled.div`
  display       : flex;
  padding       : 16px;
  padding-bottom: 0;
  flex-direction: column;
  align-items   : flex-start;
  gap           : 8px;
`;

export const PromoWidgetTopLine = styled.div`
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  align-self     : stretch;
`;

export const PromoWidgetBadge = styled.span`
  ${LABEL_XS.BOLD}
  line-height  : 15px;
  border-radius: 4px;
  border       : 1px solid var(--Global-Gray-Gray-4, #CBCBCB);
  padding      : 0 4px;
  color        : var(--Global-Gray-Gray-6, #7E7E7E);
`;

export const PromoWidgetTitleDescription = styled.div`
  display       : flex;
  flex-direction: column;
`;

export const PromoWidgetTitle = styled.h3`
  ${TITLES_NORMAL.FT_01}
  color: #791CEA;
`;

export const PromoWidgetDescription = styled.div`
  ${INTER_FONT.PARAGRAPH}
  color: var(--System-Text-Text-Subtle, #6F6F6F);
`;

export const PromoWidgetChildren = styled.div`
  margin    : 8px 0;
  align-self: stretch;
`;

export const PromoWidgetChin = styled.div`
  ${INTER_FONT.LABEL}
  font-size      : 12px;
  color          : var(--System-Text-Text-Subtle, #6F6F6F);
  display        : flex;
  padding        : 12px 16px;
  justify-content: space-between;
  align-items    : center;
  gap            : 8px;
  align-self     : stretch;
  border-top     : 1px solid var(--System-Border-Border-Default, #CBCBCB);
`;

export const CelebrationContainer = styled.div<CelebrationProps>`
  background     : radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), conic-gradient(from -15deg at 50% 50%, #F1C6DD 0deg, #C1A4E8 50.05925238132477deg, #B8E2FB 85.93856155872345deg, #F2EFE8 134.97360706329346deg, #F9DCDD 172.04889178276062deg, #E1C1E5 200.75233697891235deg, #BDAFE3 224.6718692779541deg, #C7EDEB 259.35521364212036deg, #E7F5EB 298.8224387168884deg, #F2F0E7 328.72185945510864deg, #DDC1E1 360deg);
  position       : absolute;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  height         : 100%;
  width          : 100%;
  opacity        : ${props => props.isVisible ? '1' : '0'};
  transition     : opacity 1s ease-in-out;
  z-index        : 2;

  svg {
    position: absolute;
    top     : 8px;
    right   : 8px;
    fill    : white;
    cursor  : pointer;
  }
`;

export const RewardMessage = styled.div<RewardMessage>`
  color      : var(--Global-White, #FFF);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.16), 0px 0px 12px rgba(0, 0, 0, 0.50);
  font-family: "Inter", sans-serif;
  font-size  : ${props => props.fontSize};
  font-style : oblique 8deg;
  font-weight: 800;
  line-height: 100%;
  z-index    : 2;
`;

export const ToolTipContainer = styled.div`
  display: block;
  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display: none;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const PromoContainer = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
`;