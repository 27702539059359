import { SplitTest } from '../api/opaApi';
import { OPA_FLAG, OPA_VARIABLE } from '../constants';
import { getActiveDecisionVariables, getSplitTestConfigs } from './splitTestService';

export interface DynamicScreenerSplitTest extends SplitTest {
  variables: {
    [OPA_VARIABLE.DYNAMIC_SCREENER_PROVIDERS]            : string | null,
    [OPA_VARIABLE.DYNAMIC_SCREENER_SURVEY_MEDLEY_ENABLED]: boolean,
  }
}

export interface DynamicScreenerTestData {
  enabled            : boolean,
  providerIds        : number[],
  surveyMedleyEnabled: boolean,
}

/**
 * Parse provider IDs from string format ["1", "2"] to array of numbers
 */
const parseProviderIds = (providerIds: string): number[] => {
  try {
    const ids = JSON.parse(providerIds);
    return Array.isArray(ids) ? ids.map(id => Number(id)) : [];
  } catch {
    return [];
  }
};

/**
 * Returns the split test data for the dynamic screener
 */
export const getDynamicScreenerTestData = (): DynamicScreenerTestData => {
  const testData = dynamicScreenerSplitTest();
  const isUserInTest = testData.enabled && testData.variables[OPA_VARIABLE.DYNAMIC_SCREENER_PROVIDERS] !== '';

  if (!isUserInTest) {
    return {
      enabled            : false,
      providerIds        : [],
      surveyMedleyEnabled: true
    };
  }

  const providerIds = parseProviderIds(testData.variables[OPA_VARIABLE.DYNAMIC_SCREENER_PROVIDERS] || '');

  if (providerIds.length === 0) {
    return {
      enabled            : false,
      providerIds        : [],
      surveyMedleyEnabled: true
    };
  }

  return {
    enabled: true,
    providerIds,
    surveyMedleyEnabled: testData.variables[OPA_VARIABLE.DYNAMIC_SCREENER_SURVEY_MEDLEY_ENABLED],
  };
};

/**
 * Returns the split test configuration
 */
export const dynamicScreenerSplitTest = (): DynamicScreenerSplitTest => {
  const testDecision = getSplitTestConfigs([OPA_FLAG.DYNAMIC_SCREENER]);
  const variables    = getActiveDecisionVariables(OPA_FLAG.DYNAMIC_SCREENER, testDecision);

  return {
    enabled: !!variables,
    variables: {
      [OPA_VARIABLE.DYNAMIC_SCREENER_PROVIDERS]            : variables?.[OPA_VARIABLE.DYNAMIC_SCREENER_PROVIDERS],
      [OPA_VARIABLE.DYNAMIC_SCREENER_SURVEY_MEDLEY_ENABLED]: variables?.[OPA_VARIABLE.DYNAMIC_SCREENER_SURVEY_MEDLEY_ENABLED],
    }
  };
};