import React from 'react';
import { QuestionTrackerContainer } from 'Shared/components/design/questionnairePopup/questionTracker/style';
import { QUESTION_TRACKER_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';

type QuestionTrackerProps = {
  currentIndex: number;
  total       : number;
  isModal    ?: boolean;
}

/**
 * Question Tracker Component
 */
function QuestionTracker ({currentIndex, total, isModal = false}: QuestionTrackerProps): JSX.Element {
  return (
    <div data-testid={QUESTION_TRACKER_TEST_ID}>
      <QuestionTrackerContainer isModal={isModal}>
        {`${currentIndex}/${total}`}
      </QuestionTrackerContainer>
    </div>
  );
}

export default QuestionTracker;
