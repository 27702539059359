import React from 'react';
import { VdsDatepicker } from '@disqotech/vinyl-design-system/react';
import { VdsDatepickerContainer, VdsDatepickerText } from 'Pages/createAccount/components/dateField/style';
import { TextFieldTypes } from 'Shared/components/design/textField/TextField';
import { isIosDevice } from 'Shared/services/deviceService';

interface DateOfBirthProps {
  label     : string;
  value     : string;
  onChange? : (val: string) => void;
  onBlur?   : (val: string) => void;
  helpText? : string
  error?    : string;
  fieldType?: TextFieldTypes;
  min?      : Date | string;
  max?      : Date | string;
}

function getDateString (date: Date | string | undefined) {
  if (!date) {
    return undefined;
  }

  if (typeof date === 'string') {
    return date;
  }

  if (Number.isNaN(date.getTime())) {
    return undefined;
  }

  return JSON.stringify(date).replace(/"/g, '').split('T')[0];
}

/**
 * Renders DateField component
 */
function DateField (props: DateOfBirthProps): JSX.Element {
  const {
    label,
    value,
    onChange,
    onBlur,
    helpText = '',
    error = '',
    fieldType = TextFieldTypes.DEFAULT,
    min,
    max
  } = props;

  const minString = getDateString(min);
  const maxString = getDateString(max);

  return (
    <VdsDatepickerContainer isIOS={isIosDevice()}>
      <VdsDatepicker
        value      = {value}
        onVdsInput = {(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
        onVdsBlur  = {(event: React.ChangeEvent<HTMLInputElement>) => onBlur?.(event.target.value)}
        min        = {minString}
        max        = {maxString}
      >
        <VdsDatepickerText hasError={ fieldType === TextFieldTypes.ERROR }>
          { label }
        </VdsDatepickerText>
        <VdsDatepickerText hasError={ fieldType === TextFieldTypes.ERROR } slot='help-text'>
          { fieldType === TextFieldTypes.ERROR ? error : helpText}
        </VdsDatepickerText>
      </VdsDatepicker>
    </VdsDatepickerContainer>
  );
}

export default DateField;
