import { STEPS_TOTAL } from 'Pages/createAccount/constants/constants';
import { IDPUserResponseInternal } from 'Shared/services/userActivation/api/consts';

/**
 * Determines whether user has signed up based on userInfo data
 * Prevent users who have signed up from
 * accessing the site.
 * @returns
 */
export const isSignedUp = (userInfo: IDPUserResponseInternal | null | undefined ): boolean => {
  // Only show the sign up flow for members who signed up within the last 5 minutes
  // and are missing basic profile information.
  if (userInfo?.firstName && userInfo?.lastName && userInfo?.gender && userInfo?.dateOfBirth && userInfo?.country && userInfo?.postalCode) {
    const signedUpTimestamp       = (new Date(userInfo.createdAt)).getTime();
    const fiveMinutesAgoTimestamp = Date.now() - 1000 * 60 * 5;

    if (fiveMinutesAgoTimestamp > signedUpTimestamp) {
      return true;
    }
  }

  return false;
};

/**
 * Returns the progress bar state
 * @param currentStep
 * @returns
 */
export const getProgressBarState = (currentStep: number): number => {
  return currentStep === STEPS_TOTAL - 1 ? 1 : (0.5 / STEPS_TOTAL) + (currentStep / STEPS_TOTAL);
};