import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import { PARAGRAPH } from 'Shared/styles/designConstants';
import styled from 'styled-components';

interface CheckboxContainerProps {
  checked          : boolean;
  disabled         : boolean;
  isColorBackground: boolean;
}

interface ToggleProps {
  checked     : boolean;
  disabled    : boolean;
  borderColor?: string;
}
interface DescriptionProps {
  checked          ?: boolean;
  isColorBackground: boolean;
  disabled         : boolean;
  color?           : string;
}

interface GroupProp {
  isHorizontalLayout: boolean;
}

function checkboxBackground(checked: boolean, disabled: boolean): string {
  switch (true) {
    case disabled && checked:
      return DESIGN_TOKENS.color.text.disabled.value;
    case checked && !disabled:
      return DESIGN_TOKENS.color.primitive.green['500'].value;
    case !checked && !disabled:
      return DESIGN_TOKENS.color.text.onSuccess.value;
    default:
      return '';
  }
}

function checkLabelColor(disabled: boolean, color?: string, checked?: boolean): string {
  if (disabled) {
    return DESIGN_TOKENS.color.text.disabled.value;
  }
  if (color && color !== '') {
    return color;
  }
  if (checked) {
    return DESIGN_TOKENS.color.text.normal.value;
  }
  return DESIGN_TOKENS.color.primitive.gray['800'].value;
}

function setCheckboxBorder(checked: boolean, disabled: boolean, borderColor?: string): string {
  switch (true) {
    case checked && disabled:
    case checked && !disabled:
      return 'none;';
    case !checked && disabled:
      return `2px solid ${DESIGN_TOKENS.color.text.disabled.value};`;
    case !checked && !disabled && borderColor && borderColor !== '':
      return `2px solid ${borderColor};`;
    case !checked && !disabled:
      return `2px solid ${DESIGN_TOKENS.color.primitive.gray['700'].value};`;
    default:
      return '';
  }
}

function checkedBackground(checked: boolean, disabled: boolean, isColorBackground: boolean): string {
  switch (true) {
    case !isColorBackground:
    case checked && disabled:
    case !checked && disabled:
    case !checked && !disabled:
      return 'none';
    case checked && !disabled:
      return DESIGN_TOKENS.color.primitive.green['50'].value;
    default:
      return '';
  }
}

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox'})`
  // Hide checkbox visually but remain accessible to screen readers.
  position: absolute;
  opacity   : 0;
`;

export const IconContainer = styled.svg`
  fill        : green;
  stroke      : white;
  stroke-width: 2px;
`;

export const CheckBox = styled.div<ToggleProps>`
  width        : 24px;
  height       : 24px;
  background   : ${props => checkboxBackground(props.checked, props.disabled)};
  border       : ${props => setCheckboxBorder(props.checked, props.disabled, props.borderColor)};
  box-sizing   : border-box;
  border-radius: 2px;
  margin       : 12px;

  svg {
    fill      : white;
    height    : 24px;
    width     : 24px;
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`;

export const Label = styled.p<DescriptionProps>`
  ${PARAGRAPH.DEFAULT};
  color : ${props => checkLabelColor(props.disabled, props.color, props.checked)};
  margin: 6px 16px 6px 0;
  word-wrap: break-word;
  user-select: none;
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.label<CheckboxContainerProps>`
  display      : inline-flex;
  width        : auto;
  min-height   : 48px;
  background   : ${props => checkedBackground(props.checked, props.disabled, props.isColorBackground)};
  border-radius: 8px;
  cursor       : ${props => props.disabled ? 'default' : 'pointer'};

  &:has(:focus-visible) {
    z-index      : 1;
    outline      : 2px solid ${DESIGN_TOKENS.color.primitive.gray['800'].value};

    & ${Label} {
      color: ${DESIGN_TOKENS.color.primitive.gray['800'].value};
    }
  }

  &[data-checked="true"][data-prev-checked="false"][data-next-checked="true"] {
    border-radius: 8px 8px 0 0;
  }

  &[data-checked="true"][data-prev-checked="true"][data-next-checked="false"] {
    border-radius: 0 0 8px 8px;
  }

  &[data-checked="true"][data-prev-checked="true"][data-next-checked="true"] {
    border-radius: 0;
  }
`;

export const GroupContainer = styled.div<GroupProp>`
  display: ${props => props.isHorizontalLayout ? 'inline' : 'grid'};
`;
