import React, { ChangeEvent } from "react";
import { RADIO_TEST_ID } from 'Shared/components/design/radio/constants';
import {
  GroupContainer,
  Label,
  RadioButtonCircle,
  RadioButtonInput,
  RadioButtonWrapper,
  RadioContainer
} from 'Shared/components/design/radio/style';

export enum LayoutContext {
  VERTICAL,
  HORIZONTAL
}

type RadioGroupValueType = 'number' | 'string';

type ValueTypeToAnswerValue<TValueType extends RadioGroupValueType> =
  TValueType extends 'number' ? number :
  TValueType extends 'string' ? string :
  never

type SingleRadioDataModel<TValueType extends RadioGroupValueType> = {
  answerValue: ValueTypeToAnswerValue<TValueType>;
  label      : string;
  selected   : boolean;
}

type RadioGroupProps<TValueType extends RadioGroupValueType> = {
  radioButtons     : SingleRadioDataModel<TValueType>[];
  changeCallback   : (x: ValueTypeToAnswerValue<TValueType>) => void;
  isColorBackground: boolean;
  groupName        : string;
  disabled?        : boolean;
  layoutContext?   : number;
  valueType?       : TValueType;
}

/**
 * Renders <RadioGroup /> component
 * @param radioButtons
 * @param changeCallback
 * @param isColorBackground
 * @param groupName
 * @param disabled
 */
function RadioGroup<TValueType extends RadioGroupValueType = 'number'>({
  radioButtons,
  changeCallback,
  isColorBackground,
  groupName,
  disabled = false,
  layoutContext = LayoutContext.VERTICAL,
  valueType = 'number' as TValueType
}: RadioGroupProps<TValueType>): JSX.Element {
  /**
   * Click event handler
   */
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value: string | number = e.currentTarget.value;
    if (valueType === 'number') {
      value = parseInt(value);
    }

    changeCallback(value as ValueTypeToAnswerValue<TValueType>);
  };

  /**
   * Generates the radio button elements
   */
  const renderRadioButtons = (): JSX.Element[] => {
    const radioList = radioButtons?.map((singleItem, index) => {
      return(
        <RadioContainer
          checked           = {singleItem.selected}
          disabled          = {disabled}
          isColorBackground = {isColorBackground}
          key               = {index}
        >
          <RadioButtonWrapper>
            <RadioButtonInput
              type     = "radio"
              name     = {groupName}
              value    = {singleItem.answerValue}
              checked  = {singleItem.selected}
              disabled = {disabled}
              onChange = {handleRadioChange}
            />
            <RadioButtonCircle
              role          = "radio"
              checked       = {singleItem.selected}
              disabled      = {disabled}
              aria-disabled = {disabled}
              aria-selected = {singleItem.selected}
            />
          </RadioButtonWrapper>
          <Label
            checked           = {singleItem.selected}
            disabled          = {disabled}
            isColorBackground = {isColorBackground}
          >
            {singleItem.label}
          </Label>
        </RadioContainer>
      );
    });

    return radioList;
  };

  return(
    <GroupContainer layoutContext = {!!layoutContext} data-testid = {RADIO_TEST_ID}>
      { renderRadioButtons() }
    </GroupContainer>
  );
}

export {
  RadioGroup,
  SingleRadioDataModel
};