import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  CloseIconContainer,
  Container,
  Text,
  Title
} from 'Pages/surveyExperience/components/surveyPrompt/style';
import {
  CONDITIONAL_MODAL_TEST,
  MODAL_TEST,
  MODAL_TEST_CANCEL_BUTTON,
  MODAL_TEST_CONFIRM_BUTTON
} from 'Pages/surveyExperience/components/surveyPrompt/testId';
import { SurveyPromptEventProps } from 'Pages/surveyExperience/models/EventModels';
import { addSurveyPromptEvent } from 'Pages/surveyExperience/routes/addEvents';
import { dispatchSurveyRemoveEvent } from 'Pages/surveyExperience/routes/dispatchEvents';
import { removeSurveyPromptEvent } from 'Pages/surveyExperience/routes/removeEvents';
import { setViewedClosed, setViewedRouted } from 'Pages/surveyExperience/services/surveyPromptService';
import { getLocalSurveyCache } from 'Pages/surveyExperience/services/surveyService';
import { Button, ButtonSizes, ContextTypes } from 'Shared/components/design/button/Button';
import Dialog from 'Shared/components/design/dialog/Dialog';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { ANALYTICS_EVENT_SUB_TYPE } from 'Shared/services/analytics/constants';
import { analyticsTrackActivityClick } from 'Shared/services/analytics/events/surveyClick';
import { navigateToExternalSurvey } from 'Shared/services/routingService';

type SurveyPromptProps = {
  isAutoEmail?: boolean
}

/**
 * Survey Prompt component triggers on users first survey click before routing to the actual survey.
 */
function SurveyPrompt({
  isAutoEmail = false
}: SurveyPromptProps): JSX.Element {
  const { t }                                       = useTranslation('surveyExperience');
  const [surveyId, setSurveyId]                     = useState<string>('');
  const [surveyPoints, setSurveyPoints]             = useState<number>(0);
  const [surveyProviderId, setSurveyProviderId]     = useState<number>(0);
  const [surveyLOI, setSurveyLOI]                   = useState<number>(0);
  const [surveyPosition, setSurveyPosition]         = useState<number>(0);
  const [surveyUrl, setSurveyUrl]                   = useState<string>('');
  const [promptActive, setPromptActive]             = useState<boolean>(false);
  const [surveyLoadStatus, setSurveyLoadStatus]     = useState<string>('');
  const [surveyDescription, setSurveyDescription]   = useState<string | null>(null);
  const [descriptionVisible, setDescriptionVisible] = useState<boolean>(false);
  const [onAccept, setOnAccept]                     = useState<(() => void) | null>(null);
  const showDescription                             = descriptionVisible && surveyDescription && surveyDescription?.length > 0;

  /**
   * Add event Listener for survey legal prompt
   */
  useEffect(() => {
    const eventListenerCallback = (event: SurveyPromptEventProps): void => {
      setSurveyId(event.surveyId);
      setSurveyPoints(event.surveyPoints);
      setSurveyProviderId(event.surveyProviderId);
      setSurveyLOI(event.surveyLOI);
      setSurveyPosition(event.surveyPosition);
      setSurveyUrl(event.url);
      setSurveyLoadStatus(event.surveyLoadStatus);
      setSurveyDescription(event.description);
      setDescriptionVisible(event.descriptionVisible);
      // have to use a set action here otherwise event.onAccept will be treated like one and called immediately
      setOnAccept(() => event.onAccept ?? null);
      setPromptActive(true);
    };

    addSurveyPromptEvent(eventListenerCallback);

    return () => {
      removeSurveyPromptEvent(eventListenerCallback);
    };
  },[]);

  /**
   * Route directly to external provider (open in new browser tab)
   * @param url - survey url
   */
  const routeToExternalProvider = (): void => {
    navigateToExternalSurvey(surveyUrl, true, true);

    analyticsTrackActivityClick({
      id                  : surveyId,
      points              : surveyPoints,
      provider_id         : surveyProviderId,
      loi                 : surveyLOI,
      position            : isAutoEmail ? 0 : surveyPosition,
      sub_type            : isAutoEmail ? ANALYTICS_EVENT_SUB_TYPE.survey_email_click : ANALYTICS_EVENT_SUB_TYPE.survey_click,
      has_prequalification: false,
      survey_count        : getLocalSurveyCache().length,
      survey_load_status  : surveyLoadStatus
    });
  };

  /**
   * Handling closing out of the prompt
   */
  const closeIconClickCallback = () => {
    // if this is not a survey description prompt, handle the first survey prompt logic
    if (!showDescription) {
      setViewedClosed();
    }

    setPromptActive(false);
  };

  /**
   * Returns the prompt title based on the existence of survey description
   * @returns '
   */
  const getPromptTitle = (): string => {
    if (showDescription) {
      return t('Survey--description-prompt-title');
    }

    return t('Survey--firstPrompt-title');
  };

  /**
   * Returns the prompt message based on the existence of survey description
   * @returns
   */
  const getPromptMessage = (): string => {
    if (showDescription) {
      return surveyDescription;
    }

    return t('Survey--firstPrompt-description');
  };

  /**
   * Function for clicking button to continue to external survey
   */
  const confirmButtonClickCallback = (): void => {
    if (!showDescription) {
      setViewedRouted();
    }

    if (onAccept) {
      onAccept();
    } else {
      routeToExternalProvider();
      const removeSurveyProps = {
        surveyId: surveyId
      };

      /**
       * Dispatch an event to update client survey list and
       * remove clicked survey from survey lits
       */
      dispatchSurveyRemoveEvent(removeSurveyProps);
    }
    setPromptActive(false);
  };

  const renderCloseIcon = (): JSX.Element | undefined => {
    return (
      <CloseIconContainer onClick={ closeIconClickCallback } data-testid={MODAL_TEST_CANCEL_BUTTON}>
        <Icon iconId={ ICON_IDS.CLOSE_FILL } />
      </CloseIconContainer>
    );
  };

  /**
   * Prompt to show before the user is routed to survey
   * @returns modal
   */
  const displayPrompt = (): JSX.Element | undefined => {
    return (
      <Dialog
        data-testid             = { MODAL_TEST }
        show                    = { promptActive }
        onBackdropClick         = { closeIconClickCallback }
        maxWidth                = { 480 }
        mobileHorizontalSpacing = { 8 }
      >
        <Container>
          {renderCloseIcon()}
          <Title>{getPromptTitle()}</Title>
          <Text>{getPromptMessage()}</Text>
          <ButtonContainer>
            <Button
              size      = { ButtonSizes.LARGE }
              context   = { ContextTypes.AUXILIARY }
              onClick   = { confirmButtonClickCallback }
              testId    = { MODAL_TEST_CONFIRM_BUTTON }
              iconRight = { ICON_IDS.EXTERNAL_LINK }
            >
              {t('Survey--prompt-button')}
            </Button>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  };

  return(
    <div data-testid={CONDITIONAL_MODAL_TEST}>
      {displayPrompt()}
    </div>
  );
}

export default SurveyPrompt;
