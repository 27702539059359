import React, { useEffect, useState } from 'react';
import { AnswerModel } from 'Shared/components/design/questionnairePopup/AnswerModel';
import { SEARCH_THRESHOLD } from 'Shared/components/design/questionnairePopup/constants';
import { QuestionPopupModel } from 'Shared/components/design/questionnairePopup/QuestionModel';
import { QuestionTitle, SearchContainer } from 'Shared/components/design/questionnairePopup/style';
import { SINGLE_SELECT_TEST_ID } from 'Shared/components/design/questionnairePopup/testId';
import { RadioGroup, SingleRadioDataModel } from 'Shared/components/design/radio/RadioGroup';
import { TextField, TextFieldTypes } from 'Shared/components/design/textField/TextField';
import { UPMPredefinedAnswer } from 'Shared/models/swagger/upm';

type SingleSelectProps = {
  question              : QuestionPopupModel;
  existingAnswer?       : AnswerModel;
  selectedAnswerCallback: (x: AnswerModel) => void;
}

/**
 * Single Select Component
 */
function SingleSelect ({question, existingAnswer, selectedAnswerCallback}: SingleSelectProps): JSX.Element {
  const [inputText, setInputText]     = useState<string>('');
  const [reRenderKey, setReRenderKey] = useState<number>(Date.now());
  const showSearchBar         = question?.data?.[0]?.predefinedAnswers?.length >= SEARCH_THRESHOLD;

  useEffect(() => {
    setInputText('');
    setReRenderKey(Date.now());
  }, [question]);

  /**
   * Send the changed value back to the parent component
   * @param changedValue
   */
  const handleChange = (changedValue: number | string): void => {
    const newAnswer = {
      questionKey: question.key,
      value      : [changedValue]
    };

    selectedAnswerCallback(newAnswer);
  };

  /**
   * Generates array with input for radio buttons components
   * @param predefinedAnswers - array of predefined answers
   */
  const generateRadios = (predefinedAnswers: UPMPredefinedAnswer[]): SingleRadioDataModel<any>[] => {
    const radios = predefinedAnswers.map((value: UPMPredefinedAnswer): SingleRadioDataModel<any> => {
      let previouslySelected = false;

      if (existingAnswer && existingAnswer.value.includes(value.answerValue)) {
        previouslySelected = true;
      }

      return {
        answerValue: value.answerValue,
        label      : value.label,
        selected   : previouslySelected
      };
    });

    return radios;
  };

  const valueType = typeof question.data[0].predefinedAnswers[0].answerValue === 'number'
    ? 'number'
    : 'string';

  const filteredAnswers = () => {
    return generateRadios(question.data[0].predefinedAnswers).filter((answer: SingleRadioDataModel<any>) => {
      return answer.label.toLowerCase().includes(inputText.toLowerCase());
    });
  };

  return (
    <div data-testid={SINGLE_SELECT_TEST_ID}>
      <QuestionTitle>
        {question.data[0].question}
      </QuestionTitle>
      { showSearchBar ? (
        <SearchContainer>
          <TextField
            key            = {reRenderKey}
            placeholder    = {'Search'}
            fieldType      = {TextFieldTypes.DEFAULT}
            changeCallback = {setInputText}
            isSearchBar    = {true}
          />
        </SearchContainer>
      ) : null }
      <RadioGroup
        radioButtons      = {filteredAnswers()}
        changeCallback    = {handleChange}
        isColorBackground = {true}
        groupName         = {question.key}
        valueType         = {valueType}
      />
    </div>
  );
}

export default SingleSelect;
