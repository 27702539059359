import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { MENU_ROUTES, REWARDS_ROUTE } from 'App/components/sidebar/constants';
import { MenuToggle } from 'App/components/sidebar/mobileMenu/menuToggle/MenuToggle';
import { SideMenu } from 'App/components/sidebar/mobileMenu/sideMenu/SideMenu';
import {
  BackdropContainer,
  LinkContainer,
  LogoContainer,
  NavBarButtons,
  NavbarContainer,
  NavbarTitle,
  Points,
  ReferralBonusLink,
  SidebarContainer,
  SidebarLinksContainer,
} from 'App/components/sidebar/style';
import { DESKTOP_SIDEBAR, SIDEBAR } from 'App/components/sidebar/testId';
import { addPromoOptinStartEvent } from 'Pages/surveyExperience/routes/addEvents';
import { removePromoOptinStartEvent } from 'Pages/surveyExperience/routes/removeEvents';
import Logo from 'Shared/assets/images/logo.png';
import PromoNavButton from 'Shared/components/design/promoNavButton/PromoNavButton';
import PromoSlideOut from 'Shared/components/design/promoSlideOut/PromoSlideOut';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';
import { useUserPointsFromACT } from 'Shared/services/act/app/actService';
import {
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_SOURCE,
} from 'Shared/services/analytics/constants';
import { analyticsTrackReferralBonusStart } from 'Shared/services/analytics/events/referralBonusStart';
import { isMobile } from 'Shared/services/deviceService';
import { viewedGamesPage } from 'Shared/services/opa/app/gamesSplitTest';
import { useUserPromotions } from 'Shared/services/promo/app/promoService';
import { useGetUserInfoWithInviteId } from 'Shared/services/userActivation/api/idpApiService';

enum ActiveItem {
  NONE,
  HOME,
  REWARDS,
  ACCOUNT,
  GAMES
}

/**
 * Renders </Sidebar>
 */
function Sidebar(): JSX.Element {
  const location                        = useLocation();
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const [promoOpen, setPromoOpen]       = useState<boolean>(false);
  const { t }                           = useTranslation('app');
  const currentURL                      = window.location.href;
  const navigateTo                      = useNavigate();
  const { data: pointsData }            = useUserPointsFromACT();
  const { data: userInfo }              = useGetUserInfoWithInviteId();
  const currentPoints: number           = pointsData?.currentPoints || 0;
  const minRedeemablePoints: number     = pointsData?.minRedeemablePoints || 0;
  const { showPromoWidget: showPromo }  = useUserPromotions();

  /**
   * Listener auto open promo slide out on promo opt-in
   */
  useEffect(() => {
    const openPromoCallback = (): void => {
      if (!promoOpen && isMobile()) {
        setPromoOpen(true);
      }
    };

    addPromoOptinStartEvent(openPromoCallback);

    return () => {
      removePromoOptinStartEvent(openPromoCallback);
    };
  },[promoOpen]);

  /**
   * Track a link visit event for referral bonuses in analytics.
   *
   * This function calls `analyticsTrackReferralBonusStart` with attributes specifically designed
   * to track a link click action in the context of referral bonus.
   *
   * @returns {void} No return value
   */
  const trackReferralBonusLinkEvent = useCallback((): void => {
    analyticsTrackReferralBonusStart({
      [ANALYTICS_EVENT_ATTRIBUTES.source]: ANALYTICS_EVENT_SOURCE.top_bar,
    });
  }, []);

  /**
   * Setting the mobile navbar title according to URL
   */
  const getNavBarTitle = () => {
    const isAccountPage = currentURL.includes(MENU_ROUTES.ACCOUNT_HOME);
    const isRewardsPage = currentURL.includes(MENU_ROUTES.REWARDS);
    const isGamesPage   = currentURL.includes(MENU_ROUTES.GAMES);

    if (isAccountPage) {
      return t('navbarTitleAccount');
    } else if (isRewardsPage) {
      return t('navbarTitleRewards');
    } else if (isGamesPage) {
      return t('navbarTitleGames');
    } else {
      return t('navbarTitleHome');
    }
  };

  /**
   * Returns the active menu item
   */
  const activeItem: ActiveItem = ((): ActiveItem => {
    if (location.pathname === "/") {
      return ActiveItem.HOME;
    } else if (location.pathname.includes(MENU_ROUTES.REWARDS)) {
      return ActiveItem.REWARDS;
    } else if (location.pathname.includes(MENU_ROUTES.GAMES)) {
      return ActiveItem.GAMES;
    } else if (location.pathname.startsWith(MENU_ROUTES.ACCOUNT_HOME)) {
      return ActiveItem.ACCOUNT;
    } else {
      return ActiveItem.NONE;
    }
  })();

  /**
   * Toggles mobile menu
   */
  const sideMenuToggleHandler = () => {
    setSideMenuOpen(!sideMenuOpen);

    // Close the promo slide out if it is open
    if (promoOpen) {
      setPromoOpen(false);
    }
  };

  /**
   * Returns the logo
   * @returns
   */
  const renderLogo = (): JSX.Element => {
    return(
      <LinkContainer
        isLogo   = {true}
        onClick  = {() => navigateTo(MENU_ROUTES.HOME)}
        isActive = {false}
      >
        <LogoContainer src={Logo} alt="" />
      </LinkContainer>
    );
  };

  /**
   * Renders the mobile promo slide out
   * @returns
   */
  const renderMobilePromoSlideOut = (): JSX.Element | undefined => {
    return (
      <PromoSlideOut isOpen={promoOpen} />
    );
  };

  /**
   * Handles the points pill click
   */
  const handlePointsClick = () => {
    if (promoOpen) {
      setPromoOpen(false);
    }
    navigateTo(REWARDS_ROUTE);
  };

  /**
   * Renders Navbar items conditionally
   */
  const renderNavbarItems = (): JSX.Element | undefined => {
    const navBarTitle     = getNavBarTitle();
    const isPointsVisible = !!pointsData && activeItem === ActiveItem.HOME;

    if (isPointsVisible) {
      return (
        <>
          <NavbarTitle>
            { navBarTitle }
          </NavbarTitle>
          <NavBarButtons>
            {showPromo && <PromoNavButton clickCallback={() => setPromoOpen(!promoOpen)} isActive={true} />}
            {isMobile() && userInfo?.inviteId && (
              <ReferralBonusLink to={MENU_ROUTES.ACCOUNT_INVITE} onClick={trackReferralBonusLinkEvent} />
            )}
            <Points onClick={handlePointsClick} currentPoints={currentPoints} minRedeemablePoints={minRedeemablePoints}>
              {currentPoints}
            </Points>
          </NavBarButtons>
        </>
      );
    }

    return(
      <NavbarTitle>
        { navBarTitle }
      </NavbarTitle>
    );
  };

  /**
   * Renders Mobile Navbar with menu button
   */
  const renderMobileNavbar = (): JSX.Element | undefined => {
    return(
      <>
        <MenuToggle changeCallback={sideMenuToggleHandler}/>
        <NavbarContainer>
          { renderNavbarItems() }
        </NavbarContainer>
      </>
    );
  };

  /**
   * Renders desktop Home button
   */
  const renderHome = (): JSX.Element => {
    const iconId = (activeItem === ActiveItem.HOME) ? ICON_IDS.HOUSE_FILL : ICON_IDS.HOUSE_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigateTo(MENU_ROUTES.HOME)}
        isActive = {location.pathname === "/"}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuHomeButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders Games menu item
   */
  const renderGames = (): JSX.Element => {
    if (!viewedGamesPage()) {
      return <></>;
    }

    const iconId = (activeItem === ActiveItem.GAMES) ? ICON_IDS.GAMES_FILL : ICON_IDS.GAMES;

    return(
      <LinkContainer
        onClick  = {() => navigateTo(MENU_ROUTES.GAMES)}
        isActive = {location.pathname === MENU_ROUTES.GAMES}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuGamesButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders desktop Rewards button
   */
  const renderRewards = (): JSX.Element | undefined=> {
    const iconId = (activeItem === ActiveItem.REWARDS) ? ICON_IDS.COIN_USA : ICON_IDS.COIN_USA_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigateTo(MENU_ROUTES.REWARDS)}
        isActive = {location.pathname.includes(MENU_ROUTES.REWARDS)}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuRewardsButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Renders desktop Account button
   */
  const renderAccount = (): JSX.Element => {
    const iconId = (activeItem === ActiveItem.ACCOUNT) ? ICON_IDS.AVATAR_FILL : ICON_IDS.AVATAR_OUTLINE;

    return(
      <LinkContainer
        onClick  = {() => navigateTo(MENU_ROUTES.ACCOUNT_SUPPORT)}
        isActive = {location.pathname.includes(MENU_ROUTES.ACCOUNT_HOME)}
      >
        <Icon iconId={iconId}/>
        <h1>{t('menuAccountButtonLabel')}</h1>
      </LinkContainer>
    );
  };

  /**
   * Conditionally renders mobile menu transparent grey backdrop
   */
  const renderMobileBackdrop = (): JSX.Element | undefined => {
    return (
      <>
        <BackdropContainer isMenuOpen={ sideMenuOpen } />
      </>
    );
  };

  return(
    <>
      <SidebarContainer data-testid={SIDEBAR} isMenuOpen={sideMenuOpen}>
        <SideMenu changeCallback={sideMenuToggleHandler} show={sideMenuOpen} />
        { renderMobileBackdrop() }
        { renderLogo() }
        { renderMobileNavbar() }
        <SidebarLinksContainer data-testid={DESKTOP_SIDEBAR}>
          { renderHome() }
          { renderGames() }
          { renderRewards() }
          { renderAccount() }
        </SidebarLinksContainer>
      </SidebarContainer>
      { renderMobilePromoSlideOut() }
    </>
  );
}

export {
  ActiveItem,
  Sidebar
};
