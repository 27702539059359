import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSizes, ButtonWidth } from 'Shared/components/design/button/Button';
import {
  BackButton,
  BannerIcon,
  BannerText,
  EmailAddress,
  EmailBanner,
  EmailTitle,
  ModalContent,
  ModalHeader,
  ModalWrapper,
  ReturnLink,
  SubmitButton,
} from 'Shared/components/design/loginModal/components/forgotPasswordConfirm/style';
import { ICON_IDS } from 'Shared/components/icons/constants';
import Icon from 'Shared/components/icons/icon';

interface ForgotPasswordConfirmProps {
  email                : string;
  show                 : boolean;
  goBackToLogin        : () => void;
  returnToForotPassword: () => void;
}

/**
 * Renders PartialLoginModal component
 */
const ForgotPasswordConfirm = ({ email, show, goBackToLogin, returnToForotPassword }: ForgotPasswordConfirmProps) => {
  const { t } = useTranslation('app');

  return (
    <ModalWrapper show={show}>
      <ModalHeader>
        <BackButton type='button' onClick={() => goBackToLogin()}>
          <Icon iconId={ICON_IDS.ARROW_LEFT} width={24} height={24} />
        </BackButton>
        {t('LoginModal--forgotPassword-title')}
      </ModalHeader>
      <ModalContent>
        <EmailBanner>
          <BannerIcon
            iconId = {ICON_IDS.EMAIL_CHECK_OUTLINE}
            width  = {24}
            height = {24}
          />
          <BannerText>
            {t('LoginModal--forgotPassword-banner')}
          </BannerText>
        </EmailBanner>
        <EmailTitle>Email Address</EmailTitle>
        <EmailAddress>{email}</EmailAddress>
        <ReturnLink onClick={() => returnToForotPassword()}>
          {t('LoginModal--forgotPassword-resendLink')}
        </ReturnLink>
        <SubmitButton
          size      = {ButtonSizes.LARGE}
          width     = {ButtonWidth.FULL}
          onClick   = {goBackToLogin}
        >
          {t('LoginModal--forgotPassword-return')}
        </SubmitButton>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ForgotPasswordConfirm;
