export const ICON_TEST_ID = 'icon-component';

export const ICON_IDS = {
  THREE_DOTS_HORIZONTAL    : "icon-3-Dots-Horizontal",
  THREE_DOTS_VERTICAL      : "icon-3-Dots-Vertical",
  ADD                      : "icon-Add",
  ALERT_BADGE              : "icon-Alert-Badge",
  ALT_ROUTE                : "icon-Alt-Route",
  ARCHIVE_FILL             : "icon-Archive-Fill",
  ARCHIVE_OUTLINE          : "icon-Archive-Outline",
  ARROW_DOWN               : "icon-Arrow-Down",
  ARROW_LEFT               : "icon-Arrow-Left",
  ARROW_RIGHT              : "icon-Arrow-Right",
  ARROW_UP                 : "icon-Arrow-Up",
  AVATAR_FILL              : "icon-Avatar-Fill",
  AVATAR_OUTLINE           : "icon-Avatar-Outline",
  BADGE_FILL               : "icon-Badge-Fill",
  BADGE_OUTLINE            : "icon-Badge-Outline",
  BANK_FILL                : "icon-Bank-Fill",
  BANK_CHECK_FILL          : "icon-Bank-Check-Fill",
  BANK_OUTLINE             : "icon-Bank-Outline",
  BAR_CHART                : "icon-Bar-Chart",
  BELL_ALERT               : "icon-Bell-Alert",
  BELL_NOTIFICATION        : "icon-Bell-Notification",
  BELL                     : "icon-Bell",
  BLUR                     : "icon-Blur",
  BOLT                     : "icon-Bolt",
  BOOKMARK_FILL            : "icon-Bookmark-Fill",
  BOOKMARK_OUTLINE         : "icon-Bookmark-Outline",
  BOX_UP_FILL              : "icon-Box-Up-Fill",
  BOX_UP_OUTLINE           : "icon-Box-Up-Outline",
  BRIGHTNESS               : "icon-Brightness",
  BUILDING                 : "icon-Building",
  BULB_1                   : "icon-Bulb-1",
  BULB                     : "icon-Bulb",
  CALCULATOR               : "icon-Calculator",
  CANCEL                   : "icon-Cancel",
  CARET_DOWN               : "icon-Caret-Down",
  CARET_LEFT               : "icon-Caret-Left",
  CARET_RIGHT              : "icon-Caret-Right",
  CARET_UP                 : "icon-Caret-Up",
  CHAT_FILL                : "icon-Chat-Fill",
  CHAT_NOTIFICATION_OUTLINE: "icon-Chat-Notification-Outline",
  CHAT_OUTLINE             : "icon-Chat-Outline",
  CHAT_WARNING             : "icon-Chat-Warning",
  CHECK_FILL               : "icon-Check-Fill",
  CHECKMARK                : "icon-Checkmark",
  CLIPBOARD_FILL           : "icon-Clipboard-Fill",
  CLIPBOARD_OUTLINE        : "icon-Clipboard-Outline",
  CLOCK_FAST_FORWARD       : "icon-Clock-Fast-Forward",
  CLOCK_REWIND             : "icon-Clock-Rewind",
  CLOCK                    : "icon-Clock",
  CLOSE_FILL               : "icon-Close-Fill",
  CLOSE                    : "icon-Close",
  CLOUD_FILL               : "icon-Cloud-Fill",
  COIN_USA_OUTLINE         : "icon-Coin-USA-Outline",
  COIN_USA                 : "icon-Coin-USA",
  COLLAPSE_VERTICAL        : "icon-Collapse-Vertical",
  COLLAPSE                 : "icon-Collapse",
  COMMENT_FILL             : "icon-Comment-Fill",
  COMMUNITY                : "icon-Community",
  COMMUTE                  : "icon-Commute",
  COMPASS                  : "icon-Compass",
  CONVERSATION             : "icon-Conversation",
  COPY                     : "icon-Copy",
  CREDIT_CARD              : "icon-Credit-Card",
  CROP_1                   : "icon-Crop-1",
  CROP_ROTATE_FILL         : "icon-Crop-Rotate-Fill",
  CROP_ROTATE              : "icon-Crop-Rotate",
  CROP                     : "icon-Crop",
  DASHBOARD_ALERT          : "icon-Dashboard-Alert",
  DASHBOARD                : "icon-Dashboard",
  DESKTOP                  : "icon-Desktop",
  DIAMOND                  : "icon-Diamond",
  DOWN_CHEVRON             : "icon-Down-Chevron",
  DOWNLOAD_FILL            : "icon-Download-Fill",
  DOWNLOAD                 : "icon-Download",
  EAST                     : "icon-East",
  EDIT_NOTE                : "icon-Edit-Note",
  EMAIL_CHECK_FILL         : "icon-Email-Check-Fill",
  EMAIL_CHECK_OUTLINE      : "icon-Email-Check-Outline",
  EMAIL_DRAFT_FILL         : "icon-Email-Draft-Fill",
  EMAIL_DRAFT_OUTLINE      : "icon-Email-Draft-Outline",
  EMAIL_FILL               : "icon-Email-Fill",
  EMAIL_FORWARD            : "icon-Email-Forward",
  EMAIL_NOTIFICATION_FILL  : "icon-Email-Notification-Fill",
  EMAIL_NOTIFICATION       : "icon-Email-Notification",
  EMAIL                    : "icon-Email",
  EXPAND_HORIZONTAL        : "icon-Expand-Horizontal",
  EXPAND_VERTICAL          : "icon-Expand-Vertical",
  EXPAND                   : "icon-Expand",
  EXTERNAL_LINK            : "icon-External-Link",
  FAST_REWIND_FILL         : "icon-Fast-Rewind-Fill",
  FILTER_CIRCLES           : "icon-Filter-Circles",
  FILTER                   : "icon-Filter",
  FINGERPRINT              : "icon-Fingerprint",
  FLAG_OUTLINE             : "icon-Flag-Outline",
  FOLDER_ADD_FILL          : "icon-Folder-Add-Fill",
  FOLDER_ADD_OUTLINE       : "icon-Folder-Add-Outline",
  FOLDER_AVATAR_FILL       : "icon-Folder-Avatar-Fill",
  FOLDER_FILL              : "icon-Folder-Fill",
  FOLDER_OUTLINE           : "icon-Folder-Outline",
  FOLDER_SHARED_OUTLINE    : "icon-Folder-Shared-Outline",
  HTTP                     : "icon-HTTP",
  HAMBURGER                : "icon-Hamburger",
  HASH                     : "icon-Hash",
  HEART_FILL               : "icon-Heart-Fill",
  HEART_OUTLINE            : "icon-Heart-Outline",
  HIGH_ALERT_FULL          : "icon-High-Alert-Full",
  HIGH_ALERT               : "icon-High-Alert",
  HOUSE_FILL               : "icon-House-Fill",
  HOUSE_OUTLINE            : "icon-House-Outline",
  INFO_FILL                : "icon-Info-Fill",
  INFO_OUTLINE             : "icon-Info-Outline",
  INSIGHTS                 : "icon-Insights",
  LAPTOP                   : "icon-Laptop",
  LEFT_CHEVRON             : "icon-Left-Chevron",
  LINK_ADD                 : "icon-Link-Add",
  LINK_DISABLED            : "icon-Link-Disabled",
  LINK                     : "icon-Link",
  LOCATION                 : "icon-Location",
  LOCK                     : "icon-Lock",
  LOCK_GRAY                : "icon-Lock-Gray",
  LOCK_FILL                : "icon-Lock-Fill",
  LOYALTY_FILL             : "icon-Loyalty-Fill",
  MAC                      : "icon-Mac",
  MAP_PIN_FILL             : "icon-Map-Pin-Fill",
  MIC_ON                   : "icon-Mic-On",
  MINUS_CIRCLE_FILL        : "icon-Minus-Circle-Fill",
  MINUS_CIRCLE_OUTLINE     : "icon-Minus-Circle-Outline",
  MINUS                    : "icon-Minus",
  MOBILE_SHARING           : "icon-Mobile-Sharing",
  MOBILE                   : "icon-Mobile",
  MOON                     : "icon-Moon",
  MOSAIC_FILL              : "icon-Mosaic-Fill",
  MOSAIC_OUTLINE           : "icon-Mosaic-Outline",
  NO_VISIBILITY            : "icon-No-Visibility",
  NO                       : "icon-No",
  NORTH                    : "icon-North",
  PAGE_FIRST               : "icon-Page-First",
  PAGE_LAST                : "icon-Page-Last",
  PANORAMIC_FILL           : "icon-Panoramic-Fill",
  PAUSE_FILL               : "icon-Pause-Fill",
  PENCIL_FILL              : "icon-Pencil-Fill",
  PENCIL_OUTLINE           : "icon-Pencil-Outline",
  PEOPLE_OUTLINE           : "icon-People-Outline",
  PERSON_CHECK_FILL        : "icon-Person-Check-Fill",
  PERSON_CHECK_OUTLINE     : "icon-Person-Check-Outline",
  PHONE_OUTLINE            : "icon-Phone-Outline",
  PLUS_CIRCLE_FILL         : "icon-Plus-Circle-Fill",
  PLUS_CIRCLE_OUTLINE      : "icon-Plus-Circle-Outline",
  PRIVACY_TIP_1            : "icon-Privacy-Tip-1",
  PRIVACY_TIP              : "icon-Privacy-Tip",
  PUSH_PIN_OUTLINE         : "icon-Push-Pin-Outline",
  PUSH_PIN                 : "icon-Push-Pin",
  QR                       : "icon-QR",
  QUESTION_FILL            : "icon-Question-Fill",
  QUESTION_OUTLINE         : "icon-Question-Outline",
  REACTION_FILL            : "icon-Reaction-Fill",
  REACTION_OUTLINE         : "icon-Reaction-Outline",
  RECIEPT                  : "icon-Reciept",
  REFRESH_HORIZONTAL       : "icon-Refresh-Horizontal",
  REFRESH_VERTICAL         : "icon-Refresh-Vertical",
  REFRESH                  : "icon-Refresh",
  REWIND_FILL              : "icon-Rewind-Fill",
  RIGHT_CHEVRON            : "icon-Right-Chevron",
  SAVINGS_FILL             : "icon-Savings-Fill",
  SAVINGS_OUTLINE          : "icon-Savings-Outline",
  SCREENSHARE_FILL         : "icon-Screenshare-Fill",
  SEARCH_PERSON            : "icon-Search-Person",
  SEARCH                   : "icon-Search",
  SETTINGS_FILL            : "icon-Settings-Fill",
  SETTINGS_OUTLINE         : "icon-Settings-Outline",
  SHARE_OUTLINE            : "icon-Share-Outline",
  SHARE_IOS                : "icon-Share-iOS",
  SHOPPING_BAG_FILL        : "icon-Shopping-Bag-Fill",
  SHOPPING_BAG_OUTLINE     : "icon-Shopping-Bag-Outline",
  SHOPPING_CART_FILL       : "icon-Shopping-Cart-Fill",
  SHOPPING_CART_OUTLINE    : "icon-Shopping-Cart-Outline",
  SIGN_OUT                 : "icon-Sign-Out",
  SOUND_OFF                : "icon-Sound-Off",
  SOUND_ON                 : "icon-Sound-On",
  SOUND_WAVES              : "icon-Sound-Waves",
  SOUTH                    : "icon-South",
  SPARKLE_OUTLINE          : "icon-Sparkle-Outline",
  STAR_FILL                : "icon-Star-Fill",
  STAR_OUTLINE             : "icon-Star-Outline",
  STE_EMAIL                : "ste-email",
  STE_MODAL_EMAIL          : "ste-modal-email",
  SWAP                     : "icon-Swap",
  SWIPE                    : "icon-Swipe",
  SWITCHES                 : "icon-Switches",
  TAG_PERSON               : "icon-Tag-Person",
  THUMBS_DOWN_FILL         : "icon-Thumbs-Down-Fill",
  THUMBS_DOWN_OUTLINE      : "icon-Thumbs-Down-Outline",
  THUMBS_UP_FILL           : "icon-Thumbs-Up-Fill",
  THUMBS_UP_OUTLINE        : "icon-Thumbs-Up-Outline",
  TIMER_OUTLINE            : "icon-Timer-Outline",
  TOLL                     : "icon-Toll",
  TRASH_FILL               : "icon-Trash-Fill",
  TRASH_OUTLINE            : "icon-Trash-Outline",
  TRENDING_DOWN            : "icon-Trending-Down",
  TRENDING_UP              : "icon-Trending-Up",
  UNLOCK                   : "icon-Unlock",
  UNVERIFIED               : "icon-Unverified",
  UP_CHEVRON               : "icon-Up-Chevron",
  UPGRADE                  : "icon-Upgrade",
  UPLOAD_FILL              : "icon-Upload-Fill",
  VERIFIED_FILL_1          : "icon-Verified-Fill-1",
  VERIFIED_FILL            : "icon-Verified-Fill",
  VIDEO                    : "icon-Video",
  VISIBILITY               : "icon-Visibility",
  WALLET_FILL              : "icon-Wallet-Fill",
  WALLET_OUTLINE           : "icon-Wallet-Outline",
  WARNING_ALERT            : "icon-Warning-Alert",
  WARNING_FILL             : "icon-Warning-Fill",
  WARNING                  : "icon-Warning",
  WEST                     : "icon-West",
  WINDOWS                  : "icon-Windows",
  WORLD_OUTLINE            : "icon-World-Outline",
  SHIELD_INFO_OUTLINE      : "icon-ShieldInfo-Outline",
  IOS_APPLE_LOGO           : "ios-apple-logo",
  PROMO_BOLT               : "promo-bolt",
  ACTIVE_INDICATOR         : "active-indicator",
  ACTIVE_STEP              : "active-step",
  PENDING_STEP             : "pending-step",
  LEADER_CROWN             : "leader-crown",
  CIRCLE_ONE               : "circle-one",
  CIRCLE_TWO               : "circle-two",
  GAMES                    : "icon-games",
  GAMES_FILL               : "icon-games-fill"
} as const;

export type IconKey = keyof typeof ICON_IDS;
export type IconId  = typeof ICON_IDS[IconKey];
