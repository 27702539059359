import { Link } from 'react-router-dom';
import { DESIGN_TOKENS } from '@disqotech/vinyl-design-system/design-tokens';
import referralIcon from 'Shared/assets/images/referral-icon.png';
import { useFullHeight } from 'Shared/context/bannerHeight';
import { DEVICE_BREAKPOINT, LABEL_LARGE, LABEL_SMALL, MOBILE_HEADER_HEIGHT, TITLES_NORMAL } from 'Shared/styles/designConstants';
import styled from 'styled-components';

type LinkProps = {
  isActive: boolean;
  isLogo? : boolean;
}

type SidebarContainerProps = {
  isMenuOpen: boolean;
}

type PointsProps = {
  currentPoints: number;
  minRedeemablePoints: number;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  box-shadow      : ${DESIGN_TOKENS.shadow.xs.value};
  background-color: ${DESIGN_TOKENS.color.background.primary.value};
  text-align      : center;
  padding-top     : 24px;
  width           : 88px;
  min-height      : ${() => useFullHeight()};

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    min-height    : 0;
    text-align    : left;
    padding-top   : 0px;
    position      : relative;
    width         : 100%;
    height        : ${ MOBILE_HEADER_HEIGHT };
    text-align    : center;
    vertical-align: middle;
    line-height   : ${ MOBILE_HEADER_HEIGHT };
    top           : 0px;
    z-index       : ${props => props.isMenuOpen ? '300' : '100'};
  };
`;

export const LinkContainer = styled.a<LinkProps>`
  text-align   : center;
  height       : 60px;
  width        : 72px;
  margin       : 0 auto;
  margin-bottom: 16px;
  color        : ${$props => $props.isActive ? DESIGN_TOKENS.color.text.success.value : DESIGN_TOKENS.color.text.secondary.value};
  border-radius: 16px;
  background   : ${$props => $props.isActive ? DESIGN_TOKENS.color.background.success.light.value : 'none'};
  cursor       : pointer;
  display      : ${$props => $props.isLogo && 'contents'};
  ${LABEL_SMALL.DEFAULT}

  svg {
    width      : 24px;
    height     : 24px;
    margin     : 0 auto;
    margin-top : 6px;
    fill       : ${$props => $props.isActive ? DESIGN_TOKENS.color.text.success.value : DESIGN_TOKENS.color.text.secondary.value};
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display: none;
  }
`;

export const SidebarLinksContainer = styled.div`
  margin-top: 48px;
  display   : grid;
`;

export const LogoContainer = styled.img`
  height       : 40px;
  width        : 40px;
  border-radius: 100px;
  margin       : 0 auto;
  text-align   : center;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display: none;
  }
`;

export const NavbarTitle = styled.div`
  visibility: hidden;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    ${TITLES_NORMAL.FT_01}
    margin-top: 11px;
    visibility: visible;
    display   : flex;
    font-size : 18px;
    color     : ${DESIGN_TOKENS.color.text.normal.value};
  }
`;

export const NavbarContainer = styled.div`
  display: none;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display        : flex;
    justify-content: center;
  }
`;

export const BackdropContainer = styled.div<SidebarContainerProps>`
  display: none;

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    visibility      : ${$props => $props.isMenuOpen ? 'visible' : 'hidden'};
    opacity         : ${$props => $props.isMenuOpen ? '1' : '0'};
    background-color: rgba(0, 0, 0, 0.32);
    transition      : all 0.35s ease-in;
    position        : fixed;
    display         : block;
    z-index         : 2;
    min-height      : ${() => useFullHeight()};
    inset           : 0;
  }
`;

const getPointsBackground = (currentPoints: number, minRedeemablePoints: number) => {
  if (currentPoints < 200) {
    return DESIGN_TOKENS.color.primitive.yellow['200'].value;
  } else if (currentPoints < 300) {
    return DESIGN_TOKENS.color.primitive.orange['100'].value;
  } else if (currentPoints < 400) {
    return DESIGN_TOKENS.color.primitive.pink['200'].value;
  } else if (currentPoints < minRedeemablePoints) {
    return DESIGN_TOKENS.color.primitive.blue['200'].value;
  } else if (currentPoints >= minRedeemablePoints) {
    return `linear-gradient(90deg, rgba(255,199,2,1) 0%, rgba(224,77,217,1) 55%, rgba(0,183,204,1) 79%)`;
  }
};

export const NavBarButtons = styled.div`
  position       : absolute;
  right          : 22px;
  top            : 11px;
  display        : flex;
  align-items    : center;
;`;

export const Points = styled.div<PointsProps>`
  ${LABEL_LARGE.MONOSPACE}
  cursor         : pointer;
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 28px;
  color          : ${ props => props.currentPoints > props.minRedeemablePoints ? DESIGN_TOKENS.color.text.onSelected.value : DESIGN_TOKENS.color.text.normal.value };
  background     : ${ props => getPointsBackground(props.currentPoints, props.minRedeemablePoints) };
  padding        : 2px 16px;
  border-radius  : ${DESIGN_TOKENS.borderRadius.round.value};
  z-index        : 1;
`;

export const ReferralBonusLink = styled(Link)`
  width       : 32px;
  height      : 100%;
  display     : flex;
  align-items : center;
  margin-right: 10px;

  &:after {
    content         : '';
    display         : block;
    width           : 32px;
    height          : 24px;
    background-image: url(${referralIcon});
    background-size : contain;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  display : none;
  right   : 0;
  top     : 0;

  svg {
    position: absolute;
    width   : 24px;
    height  : 24px;
    right   : 15px;
    top     : 13px;
  }

  @media ${DEVICE_BREAKPOINT.maxMobile} {
    display: block;
  }
`;