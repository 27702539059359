import styled, { keyframes } from 'styled-components';

type SpinnerProps = {
  heightWidth : string;
  circleColor : string;
  thickness   : string;
  speed       : number;
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

function getBorder(thickness: string, circleBackgroundColor: string): string {
  return `${thickness} solid ${circleBackgroundColor}`;
}

function getBackgroundBorder(thickness: string): string {
  return `${thickness} solid transparent`;
}

export const StyledSpinner = styled.svg<SpinnerProps>`
  border       : ${props => getBackgroundBorder(props.thickness)};
  border-top   : ${props => getBorder(props.thickness, props.circleColor)};
  border-right : ${props => getBorder(props.thickness, props.circleColor)};
  border-bottom: ${props => getBorder(props.thickness, props.circleColor)};
  border-radius: 50%;
  width        : ${$props => $props.heightWidth};
  height       : ${$props => $props.heightWidth};
  animation    : ${spin} ${$props => 1/$props.speed}s linear infinite;
`;