import { SIGNUP_STEPS } from 'Pages/createAccount/constants/constants';
import {
  ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE,
  ANALYTICS_BASIC_PROFILE_TYPES,
  ANALYTICS_EVENT_ATTRIBUTES,
  ANALYTICS_EVENT_SUB_TYPE,
  ANALYTICS_RESPONSE_CODES,
  ANALYTICS_RESPONSE_MESSAGE
} from 'Shared/services/analytics/constants';
import { analyticsTrackBasicProfileAgeConfirmationEnd } from 'Shared/services/analytics/events/basicProfileAgeConfirmationEnd';
import { analyticsTrackBasicProfileEnd } from 'Shared/services/analytics/events/basicProfileEnd';
import { analyticsTrackBasicProfileStart } from 'Shared/services/analytics/events/basicProfileStart';
import { analyticsTrackBasicProfileStepEnd } from 'Shared/services/analytics/events/basicProfileStepEnd';
import { analyticsTrackBasicProfileStepStart } from 'Shared/services/analytics/events/basicProfileStepStart';
import { analyticsTrackTransitionStart } from 'Shared/services/analytics/events/transitionStart';
import { IDPUserResponseInternal } from 'Shared/services/userActivation/api/consts';

/**
 * This is called to track the user is under age to ga4
 * @param userInfo
 * @param currentStep
 * @param stepsTotal
 */
export const trackUnderageProfileSubmission = (userInfo: IDPUserResponseInternal | null | undefined, currentStep: number, stepsTotal: number) => {
  const signUpSource = userInfo?.signupSource || '';

  analyticsTrackBasicProfileStepEnd({
    [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
    [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.date_of_birth,
    [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
    [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
    [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style,
  });

  analyticsTrackBasicProfileEnd({
    [ANALYTICS_EVENT_ATTRIBUTES.login_method]    : signUpSource,
    [ANALYTICS_EVENT_ATTRIBUTES.total_steps]     : stepsTotal,
    [ANALYTICS_EVENT_ATTRIBUTES.item_variant]    : ANALYTICS_BASIC_PROFILE_TYPES.monolith_style,
    [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : ANALYTICS_RESPONSE_CODES.error,
    [ANALYTICS_EVENT_ATTRIBUTES.response_message]: ANALYTICS_RESPONSE_MESSAGE.under_age,
    [ANALYTICS_EVENT_ATTRIBUTES.current_step]    : currentStep,
  });

  analyticsTrackTransitionStart({
    [ANALYTICS_EVENT_ATTRIBUTES.sub_type]: ANALYTICS_EVENT_SUB_TYPE.under_age,
  });
};

/**
 * Sends analytics events for each step of the signup process
 * @param userInfo
 * @param currentStep
 * @param stepsTotal
 */
export const trackSignupStep = (userInfo: IDPUserResponseInternal | null | undefined , currentStep: number, stepsTotal: number): void => {
  const signUpSource = userInfo?.signupSource || '';

  switch (currentStep) {
    case SIGNUP_STEPS.INTRO:
      analyticsTrackBasicProfileStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.intro,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep + 1,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      break;
    case SIGNUP_STEPS.POSTAL_CODE:
      analyticsTrackBasicProfileStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.intro,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.zip_code,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep + 1,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      break;
    case SIGNUP_STEPS.DATE_OF_BIRTH:
      analyticsTrackBasicProfileStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.zip_code,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.date_of_birth,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep + 1,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      break;
    case SIGNUP_STEPS.GENDER:
      analyticsTrackBasicProfileStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.date_of_birth,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.gender,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep + 1,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      break;
    case SIGNUP_STEPS.NAME:
      analyticsTrackBasicProfileStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.gender,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileStepStart({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.name_password,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep + 1,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      break;
    case SIGNUP_STEPS.CONFIRMATION:
      analyticsTrackBasicProfileStepEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]: signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.step_name]   : ANALYTICS_BASIC_PROFILE_STEPS_VERBOSE.name_password,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]: currentStep,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps] : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]: ANALYTICS_BASIC_PROFILE_TYPES.monolith_style
      });

      analyticsTrackBasicProfileEnd({
        [ANALYTICS_EVENT_ATTRIBUTES.login_method]    : signUpSource,
        [ANALYTICS_EVENT_ATTRIBUTES.total_steps]     : stepsTotal,
        [ANALYTICS_EVENT_ATTRIBUTES.item_variant]    : ANALYTICS_BASIC_PROFILE_TYPES.monolith_style,
        [ANALYTICS_EVENT_ATTRIBUTES.response_code]   : ANALYTICS_RESPONSE_CODES.success,
        [ANALYTICS_EVENT_ATTRIBUTES.response_message]: ANALYTICS_RESPONSE_MESSAGE.success,
        [ANALYTICS_EVENT_ATTRIBUTES.current_step]    : currentStep,
      });
      break;
  }
};

/**
 * Track that the user did say they are under age
 **/
export const trackBasicProfileAgeConfirmationEnd = (responseMessage: ANALYTICS_RESPONSE_MESSAGE) =>  {
  analyticsTrackBasicProfileAgeConfirmationEnd({
    [ANALYTICS_EVENT_ATTRIBUTES.response_message]: responseMessage
  });
};