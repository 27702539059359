import React, { ReactNode } from 'react';
import { Button, ButtonProps, ButtonSizes, ButtonWidth, ContextTypes } from 'Shared/components/design/button/Button';
import {ICON_IDS} from 'Shared/components/icons/constants';

enum SurveyButtonDesign {
  DEFAULT,
  LOCKED,
  ADM,
}

const buttonDesigns: Record<SurveyButtonDesign, ButtonProps>  = {
  [SurveyButtonDesign.ADM]: {
    iconRight: ICON_IDS.ARROW_RIGHT,
    context  : ContextTypes.SURF_TO_EARN
  },
  [SurveyButtonDesign.LOCKED]: {
    iconLeft: ICON_IDS.LOCK,
    disabled: true
  },
  [SurveyButtonDesign.DEFAULT]: {
    iconRight: ICON_IDS.ARROW_RIGHT,
    context  : ContextTypes.PRIMARY
  },
};

type SurveyButtonProps = {
  design?          : SurveyButtonDesign;
  children?        : ReactNode;
  onClick?         : () => void;
  isLoading?       : boolean;
  loadingMessage?  : string;
}
/**
 * Survey button component
 * @return Button component
 */
function SurveyButton({
  design = SurveyButtonDesign.DEFAULT,
  children,
  onClick,
  isLoading = false,
  loadingMessage = '',
}: SurveyButtonProps ) {

  const buttonDesign =  buttonDesigns[design];

  return(
    <Button
      disabled            = {buttonDesign.disabled}
      size                = {ButtonSizes.LARGE}
      width               = {ButtonWidth.FULL}
      iconLeft            = {buttonDesign.iconLeft}
      iconRight           = {buttonDesign.iconRight}
      context             = {buttonDesign.context ? buttonDesign.context : ContextTypes.PRIMARY}
      onClick             = {onClick}
      isLoading           = {isLoading}
      stillLoadingMessage = {loadingMessage}
    >
      {children}
    </Button>
  );
}

export {
  SurveyButton,
  SurveyButtonDesign
};
