import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockTooltipArrow, LockTooltipContainer } from 'Shared/components/design/promoNavButton/promoLockToolTip/style';
import { isMobile } from 'Shared/services/deviceService';


type PromoLockToolTipProps = {
  closeTooltip: boolean;
  showOnHover?: boolean;
};

/**
 * Renders the Promo Lock Tool Tip
 * @returns
 */
const PromoLockToolTip: React.FC<PromoLockToolTipProps> = ({ closeTooltip, showOnHover }) => {
  const [isLockVisible, setIsLockVisible] = useState(false);
  const { t }                             = useTranslation('promoNavButton');

  useEffect(() => {
    if (closeTooltip && isLockVisible) {
      setIsLockVisible(false);
    }

    if (showOnHover !== undefined) {
      setIsLockVisible(showOnHover);
    }
  }, [closeTooltip, isLockVisible, showOnHover]);

  if (!isLockVisible && closeTooltip) {
    return null;
  }

  return (
    <LockTooltipContainer isVisible={isLockVisible}>
      <LockTooltipArrow isMobile={isMobile()}/>
      {t('PromoNavButton-tooltip--locked-state')}
    </LockTooltipContainer>
  );
};

export default PromoLockToolTip;
