import React, { useEffect } from 'react';
import { addPromoOptinStartEvent } from 'Pages/surveyExperience/routes/addEvents';
import { removePromoOptinStartEvent } from 'Pages/surveyExperience/routes/removeEvents';
import PromoStepper from 'Shared/components/design/promoStepper/PromoStepper';
import { PromoWidget } from 'Shared/components/design/promoWidget/PromoWidget';
import { useUserPromotions } from 'Shared/services/promo/app/promoService';

/**
 * Component that renders all active promo widgets and listens for promo opt-in events
 * @returns
 */
function PromoWidgets(): JSX.Element {
  const { activePromotions, showPromoWidget,  mutate: refetchPromotions} = useUserPromotions();

  /**
   * Listener to refetch promotions (triggered on promo opt-in)
   */
  useEffect(() => {
    const refetchCallback = (): void => {
      refetchPromotions();
    };

    addPromoOptinStartEvent(refetchCallback);

    return () => {
      removePromoOptinStartEvent(refetchCallback);
    };
  },[refetchPromotions]);

  if (!showPromoWidget) {
    return <></>;
  }

  return (
    <div>
      {activePromotions.map((promotion, index) => {
        if (!promotion.segments) {
          return <></>;
        }

        return (
          <PromoWidget
            key={index}
            promotion={promotion}
          >
            <PromoStepper segment={promotion.segments} />
          </PromoWidget>
        );
      })}
    </div>
  );
}

export default PromoWidgets;