import React from 'react';
import { useTranslation } from 'react-i18next';
import { CenterAlignedDiv, StyledAnchor } from 'Pages/forgotPassword/components/signUpLink/style';

const PRE_MEMBER_SIGN_UP_URL = `${process.env.PRE_MEMBER_BASE_URL}/?sign-up`;

const SignUpLink = () => {
  const { t } = useTranslation('forgotPassword');

  return (
    <CenterAlignedDiv>
      <StyledAnchor href={PRE_MEMBER_SIGN_UP_URL}>{t('ForgotPassword--noAccountSignUp')}</StyledAnchor>
    </CenterAlignedDiv>
  );
};

export default SignUpLink;